import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Select, Button, DatePicker, TimePicker, message, Typography, Row, Col, Card, Divider, Spin } from 'antd';
import moment from 'moment';
import api from "../authentication/axiosInstance";
import { AuthContext } from '../context/AuthContext';
import { UserOutlined, PhoneOutlined, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const SnowEdgeRequestForm = () => {
  const [form] = Form.useForm();
  const [clientSites, setClientSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userRole, clientId, userEmail, userId } = useContext(AuthContext);

  useEffect(() => {
    const fetchSitesByClientId = async () => {
      if (clientId && userId) {
        try {
          const sitesResponse = await api.get(`/clients/${clientId}/accessible-sites/`, {
            params: { user_id: userId }
          });
          setClientSites(sitesResponse.data);
        } catch (error) {
          console.error('Error fetching accessible sites:', error);
          message.error('Failed to fetch accessible sites for your account.');
        }
      }
    };

    fetchSitesByClientId();
  }, [clientId, userId]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = {
        ...values,
        client_email: userEmail,
        requested_date: values.requested_date.format('YYYY-MM-DD'),
        requested_time: values.requested_time.format('HH:mm:ss'),
        site_id: values.site,
      };

      console.log('Submitting form data:', formData);

      const response = await api.post('/request-manager/snow-edge-requests/', formData);
      console.log('Server response:', response.data);
      message.success('SnowEdge request submitted successfully');
      form.resetFields();
    } catch (error) {
      console.error('Error submitting SnowEdge request:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);

        if (error.response.data && typeof error.response.data === 'object') {
          const errorMessages = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
          message.error(`Failed to submit SnowEdge request: ${errorMessages}`);
        } else {
          message.error(`Failed to submit SnowEdge request: ${error.response.data || 'Unknown error'}`);
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
        message.error('Failed to submit SnowEdge request: No response from server');
      } else {
        console.error('Error setting up request:', error.message);
        message.error(`Failed to submit SnowEdge request: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="snow-edge-request-form" style={{ padding: '40px', backgroundColor: '#f7f9fc', minHeight: '100vh' }}>
      <Spin spinning={loading}>
        <Card bordered={false} style={{ maxWidth: '900px', margin: '0 auto', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)', borderRadius: '12px', backgroundColor: '#ffffff' }}>
          <Title level={2} style={{ textAlign: 'center', fontFamily: 'Plus Jakarta Sans, sans-serif', color: '#034832', marginBottom: '20px' }}>Submit a SnowEdge Request</Title>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Divider orientation="left" style={{ fontFamily: 'Plus Jakarta Sans, sans-serif', color: '#034832', fontSize: '16px' }}>Contact Information</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="contact_name"
                  label="Contact Name"
                  rules={[{ required: true, message: 'Please enter the contact name' }]}
                >
                  <Input prefix={<UserOutlined style={{ color: '#034832' }} />} placeholder="Enter contact name" style={{ borderRadius: '8px', borderColor: '#bdc3c7' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contact_phone"
                  label="Contact Phone"
                  rules={[
                    { required: true, message: 'Please enter the contact phone number' },
                    {
                      pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                      message: 'Please enter a valid phone number'
                    },
                    {
                      validator: (_, value) => {
                        if (!value) return Promise.resolve();
                        // Remove all non-numeric characters
                        const numericValue = value.replace(/\D/g, '');
                        if (numericValue.length < 10) {
                          return Promise.reject('Phone number must have at least 10 digits');
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => {
                    // Optional: Format the phone number as user types
                    if (!value) return value;
                    const phoneNumber = value.replace(/\D/g, '');
                    if (phoneNumber.length <= 3) return phoneNumber;
                    if (phoneNumber.length <= 6) 
                      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
                  }}
                >
                  <Input 
                    prefix={<PhoneOutlined style={{ color: '#034832' }} />} 
                    placeholder="(123) 456-7890"
                    style={{ borderRadius: '8px', borderColor: '#bdc3c7' }} 
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="site"
              label="Site"
              rules={[{ required: true, message: 'Please select a site' }]}
            >
              <Select placeholder="Select a site" style={{ borderRadius: '8px', borderColor: '#bdc3c7' }}>
                {clientSites.map(site => (
                  <Option key={site.site_id} value={site.site_id}>{site.site_name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Divider orientation="left" style={{ fontFamily: 'Plus Jakarta Sans, sans-serif', color: '#034832', fontSize: '16px' }}>Request Details</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="requested_date"
                  label="Requested Date"
                  rules={[{ required: true, message: 'Please select a date' }]}
                >
                  <DatePicker 
                    style={{ width: '100%', borderRadius: '8px', borderColor: '#bdc3c7' }} 
                    prefix={<CalendarOutlined style={{ color: '#034832' }} />}
                    disabledDate={(current) => {
                      // Can't select days before today
                      return current && current < moment().startOf('day');
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="requested_time"
                  label="Requested Time"
                  rules={[{ required: true, message: 'Please select a time' }]}
                >
                  <TimePicker style={{ width: '100%', borderRadius: '8px', borderColor: '#bdc3c7' }} format="HH:mm" prefix={<ClockCircleOutlined style={{ color: '#034832' }} />} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="notes"
              label="Notes"
            >
              <TextArea rows={4} placeholder="Add any additional notes here" style={{ borderRadius: '8px', borderColor: '#bdc3c7' }} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#034832', borderColor: '#034832', borderRadius: '8px', fontFamily: 'Plus Jakarta Sans, sans-serif', fontSize: '16px', height: '50px' }}>
                Submit SnowEdge Request
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default SnowEdgeRequestForm;
