// LoaderAllocationDispatchPage.js
import React, { useState, useEffect } from "react";
import {
  ConfigProvider,
  Table as AntTable,
  Button,
  Modal,
  Spin,
  Divider,
  message,
  Input,
  DatePicker,
  Form,
  Select,
  TimePicker,
  Popconfirm,
} from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import api from "../authentication/axiosInstance";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import {
  IconFile,
  IconTractor,
  IconUser,
  IconBuildingFactory2,
} from "@tabler/icons-react";
import styles from './WalkwayCrewAllocationPage.module.css';
import moment from "moment";
import { useNavigate } from 'react-router-dom';


const { Option } = Select;

const LoaderAllocationDispatchPage = () => {
  const [showHistoryModal, setShowHistoryModal] = useState(false); // New state for history modal
  const [sites, setSites] = useState([]);
  const [storms, setStorms] = useState([]);
  const [stormType, setStormType] = useState("");
  const [stormSeverity, setStormSeverity] = useState("");
  const [dateOfStorm, setDateOfStorm] = useState(null);
  const [stormModal, setStormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dispatchMessage, setDispatchMessage] = useState("");
  const [selectedDispatches, setSelectedDispatches] = useState([]); // To store multiple dispatch objects
  const [dispatchLogs, setDispatchLogs] = useState([]);
  const [selectedStorm, setSelectedStorm] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [staff, setStaff] = useState([]);
  const [selectedStaffToAdd, setSelectedStaffToAdd] = useState({}); // Tracks selected staff per dispatch_id
  const [dispatchObjects, setDispatchObjects] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();


  // Fetch Functions
  const fetchStorms = async () => {
    try {
      const response = await api.get("/dispatch/storm/");
      setStorms(response.data);
    } catch (error) {
      console.error("Failed to fetch storms:", error);
      message.error("Failed to fetch storms");
    }
  };

  const fetchDispatchObjects = async () => {
    try {
      const response = await api.get("/dispatch/dispatchObjects/Loader/");
      setDispatchObjects(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch objects:", error);
      message.error("Failed to fetch dispatch objects");
      setDispatchObjects([]); // Ensure it defaults to an empty array on error
    }
  };

  const fetchDispatchLogs = async () => {
    try {
      const response = await api.get("/dispatch/dispatchLogs/Loader/");
      console.log("loader dispatch logs:", response.data);
      setDispatchLogs(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch logs:", error);
      message.error("Failed to fetch dispatch logs");
      setDispatchLogs([]); // Ensure it defaults to an empty array on error
    }
  };

  const fetchStaff = async () => {
    try {
      const response = await api.get("/staff/loader_staff/");
      setStaff(response.data);
    } catch (error) {
      console.error("Failed to fetch staff:", error);
      message.error("Failed to fetch staff");
    }
  };

  const fetchSites = async () => {
    try {
      const response = await api.get("/sites/");
      setSites(response.data);
    } catch (error) {
      console.error("Failed to fetch sites:", error);
      message.error("Failed to fetch sites");
    }
  };

  useEffect(() => {
    fetchStorms();
    fetchDispatchObjects();
    fetchDispatchLogs();
    fetchStaff();
    fetchSites();
  }, []);

  // Handler Functions

  // Handle selecting a staff member to add
  const handleSelectStaffToAdd = (dispatchId, staffId) => {
    setSelectedStaffToAdd((prev) => ({
      ...prev,
      [dispatchId]: staffId,
    }));
  };

  // Handle adding a staff member to the dispatch object
  const handleAddStaff = async (dispatchId) => {
    // Check if there is already a staff assigned to this dispatch object
    const dispatchObject = dispatchObjects.find(obj => obj.dispatch_id === dispatchId);
    if (dispatchObject && dispatchObject.staff_details && dispatchObject.staff_details.length >= 1) {
      message.error("A staff member is already assigned to this dispatch.");
      return;
    }
    
    const staffId = selectedStaffToAdd[dispatchId];
    if (!staffId) {
      message.warning("Please select a staff member to add.");
      return;
    }
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        add_staff_to_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to add staff.");
      }
    } catch (error) {
      console.error("Failed to add staff:", error);
      message.error(error.response?.data?.error || "Failed to add staff.");
    } finally {
      // Reset the selected staff for this dispatch
      setSelectedStaffToAdd((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  // Handle removing a staff member from the dispatch object
  const handleRemoveStaff = async (dispatchId, staffId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        remove_staff_from_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to remove staff.");
      }
    } catch (error) {
      console.error("Failed to remove staff:", error);
      message.error(error.response?.data?.error || "Failed to remove staff.");
    }
  };

  const createStorm = async () => {
    if (!stormType || !stormSeverity || !dateOfStorm) {
      message.error("Please fill out all fields");
      return;
    }

    try {
      const response = await api.post("/dispatch/storm/", {
        type: stormType,
        severity: stormSeverity,
        date_of_storm: dateOfStorm.format("YYYY-MM-DD"), // Formatting date for API
      });
      message.success("Storm created successfully");
      setDateOfStorm(null); // Reset date after successful creation
      setStormType(""); // Reset storm type after successful creation
      setStormSeverity(""); // Reset storm severity after successful creation
      setStormModal(false); // Close modal after successful creation
      fetchStorms(); // Refresh storm data
    } catch (error) {
      console.error("Failed to create storm:", error);
      message.error("Failed to create storm");
    }
  };

  // Handle opening the confirm dispatch modal for single dispatch
  const handleOpenConfirmModal = (
    vehicleId,
    staffId,
    plowId,
    doubleDownName,
    sites,
    dispatchid
  ) => {
    setSelectedDispatches([{
      vehicleId,
      staffId,
      plowId,
      doubleDownName,
      sites,
      dispatchid
    }]); // Set as single dispatch in an array
    setShowConfirmModal(true);
  };

  // **Handle opening the confirm dispatch modal for multiple dispatches**
  const handleOpenMultiConfirmModal = (selectedRows) => {
    // **Transform the selectedRows to include a 'sites' property derived from 'site_details'**
    const transformedDispatches = selectedRows.map(record => ({
      vehicleId: record.vehicle, // Vehicle ID
      staffId: record.staff_details[0]?.staff_id, // First staff member's ID
      plowId: record.plow, // Plow ID (can be null)
      doubleDownName: record.doubleDown_name, // DoubleDown name
      sites: record.site_details, // Array of site objects
      dispatchid: record.dispatch_id // Dispatch ID
    }));
    setSelectedDispatches(transformedDispatches);
    setShowConfirmModal(true);
  };

  const handleConfirmDispatch = async () => {
    if (!selectedDispatches || selectedDispatches.length === 0) {
      message.error("No dispatch details selected.");
      setShowConfirmModal(false);
      return;
    }

    if (!selectedStorm) {
      message.error("Please select a storm to dispatch the vehicle.");
      setShowConfirmModal(false);
      return;
    }

    // Check if both date and time are selected
    if (!selectedDate || !selectedTime) {
      message.error("Please select both a date and time for the dispatch.");
      return;
    }

    try {
      // **Ensure that 'sites' exists and is an array for each dispatch**
      const dispatchList = selectedDispatches.map((dispatch, index) => {
        const { vehicleId, staffId, plowId, doubleDownName, sites, dispatchid } = dispatch;

        if (!staffId) {
          throw new Error(`Dispatch ${index + 1}: No staff assigned.`);
        }

        if (!sites || !Array.isArray(sites)) {
          throw new Error(`Dispatch ${index + 1}: Sites data is invalid.`);
        }

        const siteids = sites.map((site) => site.site_id);
        return {
          vehicle_id: vehicleId,
          staff_id: staffId,
          plow_id: plowId,
          double_down_name: doubleDownName,
          site_ids: siteids,
          dispatch_id: dispatchid,
          storm_id: selectedStorm,
          message: dispatchMessage,
          dispatched_for: moment(
            `${selectedDate.format("YYYY-MM-DD")} ${selectedTime.format("HH:mm")}`
          ).toISOString(),
        };
      });

      await createLoaderDispatch(dispatchList);
      setShowConfirmModal(false);
      setDispatchMessage(""); // Reset the message after dispatch
      setSelectedDispatches([]);
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedStorm(null);
      setSelectedRowKeys([]); // Clear selected rows after dispatch
      fetchDispatchLogs();
      fetchDispatchObjects();
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.message || "Failed to create dispatch");
      // Optionally, you can also keep the modal open or provide more detailed feedback
    }
  };

  const handleHistoryModalOpen = () => {
    setShowHistoryModal(true);
  };

  const handleHistoryModalCancel = () => {
    setShowHistoryModal(false); // Close the history modal
  };

  // Updated createLoaderDispatch to handle multiple dispatches
  const createLoaderDispatch = async (dispatchList) => {
    try {
      const response = await api.post("dispatch/dispatchLogs/", {
        create_loader_dispatch_logs: true,
        dispatches: dispatchList,
      });

      // Assuming the backend returns a results array as per the updated backend function
      const { results } = response.data;

      // Process results to show messages to the user
      results.forEach((result) => {
        const { dispatch_number, status, message: msg, error, whatsapp_response } = result;
        if (status === "success") {
          message.success(`Dispatch ${dispatch_number}: ${msg}`);
        } else if (status === "partial_success") {
          message.warning(`Dispatch ${dispatch_number}: ${msg}`);
          if (error) {
            message.error(`Error: ${error}`);
          }
          if (whatsapp_response) {
            console.error(`WhatsApp Response for Dispatch ${dispatch_number}:`, whatsapp_response);
          }
        } else if (status === "failed") {
          message.error(`Dispatch ${dispatch_number}: ${msg}`);
        }
      });
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.response?.data?.message || "Failed to create dispatch");
    }
  };

  const syncDispatchData = async () => {
    try {
      setLoading(true); // Optional: Show loading spinner while syncing
      const response = await api.post("/dispatch/dispatchObjects/", {
        create_loader_dispatch_objects: true, // Trigger the backend function
      });
      message.success("Loader Dispatch data synced successfully!");
      fetchDispatchObjects(); // Refresh the dispatch objects after syncing
    } catch (error) {
      console.error("Failed to sync dispatch data:", error);
      message.error("Failed to sync dispatch data");
    } finally {
      setLoading(false); // Optional: Hide loading spinner
    }
  };

  // Handle row selection change
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Define rowSelection for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected dispatch objects based on selectedRowKeys
  const selectedRows = dispatchObjects.filter(obj => selectedRowKeys.includes(obj.dispatch_id));

  const columns = [
    {
      title: "Vehicle Info",
      key: "vehicle_info",
      width: 150,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>
          <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type}</span>     
          <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_model}</span>    
        </div>
      ),
    },
    {
      title: "Staff Details",
      dataIndex: "staff_details",
      key: "staff_details",
      width: 275,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '85px' }}>
          {record.staff_details.slice(0, 3).map((staff) => (
            <div 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'flex-start', 
                flexDirection: 'row', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: "ellipsis", 
                maxWidth: '255px' 
              }} 
              key={staff.staff_id}
            >
              <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                <IconUser size={14} style={{ marginRight: '3px' }} />
                {staff.staff_name}
              </span>
              <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {staff.staff_position}
              </span>       
            </div>
          ))}

          {/* Show "+ x more staff" if there are more than 3 staff */}
          {record.staff_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.staff_details.length - 3} more staff
            </span>
          )}

          {record.staff_details.length === 0 && 'No Staff Assigned'}
        </div>
      ),
    },
    {
      title: "Assigned Sites",
      dataIndex: "site_details",
      key: "site_details",
      width: 275,
      render: (text, record) =>  (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '85px' }}>
          {record.site_details.slice(0, 3).map((site) => (
            <div 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'flex-start', 
                flexDirection: 'row', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: "ellipsis", 
                maxWidth: '255px' 
              }} 
              key={site.site_id}
            >
              <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                {site.client_name}
              </span>
              <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {site.site_name}
              </span>       
            </div>
          ))}
          
          {/* Show "+ x more sites" if there are more than 3 sites */}
          {record.site_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.site_details.length - 3} more sites
            </span>
          )}
    
          {record.site_details.length === 0 && 'No Sites Assigned'}
        </div>
      ),
    },
    {
      title: "Last Dispatched",
      dataIndex: "last_dispatched",
      key: "last_dispatched",
      width: 171,
      render: (last_dispatched) =>
        last_dispatched ? <div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <span>{moment(last_dispatched).format("MMMM Do YYYY")} </span>
          <span>{moment(last_dispatched).format("h:mm:ss a")}</span>
          </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
      sorter: (a, b) => moment(a.last_dispatched).unix() - moment(b.last_dispatched).unix(),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 171,
      render: (updated_at) =>
        updated_at ? <div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <span>{moment(updated_at).format("MMMM Do YYYY")} </span>
          <span>{moment(updated_at).format("h:mm:ss a")}</span>
          </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
      sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Actions",
      key: "actions",
      width: 110,
      render: (_, record) => (
        <div
          className={styles.tableButton}
          style={{ width: "100px", cursor: "pointer" }}
          onClick={() => {
            if (!record.staff_details || record.staff_details.length === 0) {
              message.error("No staff assigned to this vehicle.");
              return;
            }
            handleOpenConfirmModal(
              record.vehicle, // Vehicle ID
              record.staff_details[0].staff_id, // First staff member's ID
              record.plow, // Plow ID (can be null)
              record.doubleDown_name, // DoubleDown name
              record.site_details, // Array of site objects
              record.dispatch_id // Dispatch ID
            );
          }}
        >
          Dispatch
        </div>
      ),
    },
  ];

  const SyncDispatchButton = () => {
    const confirmSync = () => {
      syncDispatchData(); // Call your sync function here
    };
  
    return (
      <Popconfirm
        title="Are you sure you want to sync the dispatch data?"
        onConfirm={confirmSync}
        okText="Yes"
        cancelText="No"
      >
        <div
          className={styles.tableButton}
          style={{ fontSize: '14px', padding: '8px 15px', cursor: 'pointer' }}
        >
          Sync Dispatch Data
        </div>
      </Popconfirm>
    );
  };

  const DispatchSelectedButton = () => {
    const handleDispatchSelected = () => {
      if (selectedRows.length === 0) {
        message.warning("Please select at least one dispatch to proceed.");
        return;
      }
      // Check if all selected dispatches have at least one staff assigned
      const dispatchesWithoutStaff = selectedRows.filter(dispatch => !dispatch.staff_details || dispatch.staff_details.length === 0);
      if (dispatchesWithoutStaff.length > 0) {
        message.error("One or more selected dispatches have no staff assigned.");
        return;
      }
      handleOpenMultiConfirmModal(selectedRows);
    };

    return (
      <Button
        type="primary"
        onClick={handleDispatchSelected}
        disabled={selectedRows.length === 0}
        style={{ marginLeft: '20px', height: "100%" }}
      >
        Dispatch Selected ({selectedRows.length})
      </Button>
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLinkHover: "#034832",
          colorPrimary: "#034832",
          controlItemBgActive: "#E4F2EB",
        },
      }}
    >
      <Theme>
        <DndProvider backend={HTML5Backend}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <div style={{ width: 'calc(100% - 10px)', marginLeft: '20px' }}>
            <style>
              {`.locked-row {
                  background-color: #f0f0f0 !important;
                }
              `}
            </style>
            <style>
              {`.ant-table-tbody > tr:hover > td {
                  background: none !important;
                }
              `}
            </style>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {/* Left-aligned elements */}
              <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <span
                  style={{
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontWeight: '700',
                    fontSize: '28px',
                  }}
                >
                  Loader Dispatch
                </span>
                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/walkwayCrewDispatch')}
                  style={{
                    fontSize: '14px',
                    padding: '6px 15px',
                    cursor: 'pointer',
                    backgroundColor: '#7cb6f7',
                    color: 'white',
                  }}
                >
                  Walkway Crew Dispatch
                </div>
                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/SaltingRouteDispatch')}
                  style={{
                    fontSize: '14px',
                    padding: '6px 15px',
                    cursor: 'pointer',
                    backgroundColor: '#7cb6f7',
                    color: 'white',
                  }}
                >
                  Salting Route Dispatch
                </div>
                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/dispatchHistory')}
                  style={{
                    fontSize: '14px',
                    padding: '6px 15px',
                    cursor: 'pointer',
                    backgroundColor: '#7cb6f7',
                    color: 'white',
                  }}
                >
                  Dispatch History
                </div>
              </div>

              {/* Right-aligned elements */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <div
                  className={styles.tableButton}
                  onClick={() => setStormModal(true)}
                  style={{ fontSize: "14px", padding: "8px 15px" }}
                >
                  Create Storm
                </div>
                <SyncDispatchButton />
                <DispatchSelectedButton /> {/* New Dispatch Selected Button */}
              </div>
            </div>


              <Spin spinning={loading}>
                <>
                  <AntTable
                    dataSource={dispatchObjects}
                    columns={columns}
                    rowKey="dispatch_id"
                    pagination={false}
                    scroll={{ y: 690 }}
                    rowSelection={rowSelection} // Enable row selection
                    expandable={{
                      expandedRowRender: (record) => (
                        <div style={{ display: "flex", flexDirection: "column", padding: "4px" }}>
                          
                          {/* Staff Section */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "8px",
                            }}
                          >
                            
                            {/* Left Half: Staff Members */}
                            <div style={{ flex: 1, marginRight: "16px" }}>
                              <h4>Staff Members</h4>
                              {record.staff_details && record.staff_details.length > 0 ? (
                                record.staff_details.map((staffMember) => (
                                  <div
                                    key={staffMember.staff_id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "8px",
                                      padding: "8px",
                                      border: "1px solid #f0f0f0",
                                      borderRadius: "4px",
                                      backgroundColor: "#fafafa",
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                      <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                        <IconUser size={14} style={{ marginRight: '3px' }} />
                                        {staffMember.staff_name}
                                      </span>
                                      <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                        - {staffMember.staff_position}
                                      </span> 
                                    </div>
                                    <Button
                                      onClick={() =>
                                        handleRemoveStaff(record.dispatch_id, staffMember.staff_id)
                                      }
                                      type="primary"
                                      danger
                                    >
                                      Remove Staff
                                    </Button>
                                  </div>
                                ))
                              ) : (
                                <p>No staff assigned.</p>
                              )}
                            </div>
                      
                            {/* Right Half: Add Staff */}
                            <div style={{ flex: 1 }}>
                              <h4>Add Staff</h4>
                              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: "10px" }}>
                              <Select
                                placeholder="Select a staff member"
                                style={{ width: "100%", marginBottom: "8px" }}
                                value={selectedStaffToAdd[record.dispatch_id] || null}
                                onChange={(value) =>
                                  handleSelectStaffToAdd(record.dispatch_id, value)
                                }
                                allowClear
                              >
                                {staff.map((staffMember) => (
                                  <Option key={staffMember.staff_id} value={staffMember.staff_id}>
                                    {staffMember.name} - {staffMember.position}
                                  </Option>
                                ))}
                              </Select>
                              <Button
                                type="primary"
                                onClick={() => handleAddStaff(record.dispatch_id)}
                                disabled={!selectedStaffToAdd[record.dispatch_id]}
                                style={{ marginTop: '-7px' }}
                              >
                                Add Staff
                              </Button>
                              </div>
                            </div>
                          </div>

                          {/* You can add similar sections for Sites here if needed */}

                        </div>
                      ),
                      // Optional: control row expansion behavior
                      // You can add more properties here if necessary
                    }}
                  />
                </>
              </Spin>

              {/* Dispatch History Modal */}
              <Modal
                title={<span style={{ fontSize: "18px", fontWeight: "600" }}>Dispatch History</span>}
                open={showHistoryModal}
                onCancel={handleHistoryModalCancel}
                footer={null}
                width={800}
              >
                {dispatchLogs.length > 0 ? (
                  dispatchLogs.map((record) => (
                    <div key={record.dispatch_log_id} style={{ marginBottom: "16px" }}>
                      <Divider />
                      <p>
                        <strong>Dispatch ID:</strong> {record.dispatch_log_id}
                      </p>
                      <p>
                        <strong>Type:</strong> {record.type || "N/A"}
                      </p>
                      <p>
                        <strong>Storm:</strong> {record.storm_type} ({record.storm_severity})
                      </p>
                      <p>
                        <strong>Vehicle:</strong> {record.vehicle_name} ({record.vehicle_model})
                      </p>
                      <p>
                        <strong>Plow:</strong> {record.plow_name} ({record.plow_model})
                      </p>
                      <p>
                        <strong>DoubleDown:</strong> {record.doubleDown_name}
                      </p>
                      <p>
                        <strong>Dispatch Time:</strong>
                        <span>
                          {record.dispatched_at
                            ? new Date(record.dispatched_at).toLocaleString("en-US", {
                                timeZone: "America/New_York",
                              })
                            : "N/A"}{" "}
                          EST
                        </span>
                      </p>
                      <p>
                        <strong>Dispatch For:</strong>
                        <span>
                          {record.dispatched_for
                            ? new Date(record.dispatched_for).toLocaleString("en-US", {
                                timeZone: "America/New_York",
                              })
                            : "N/A"}{" "}
                          EST
                        </span>
                      </p>

                      {/* Staff Details */}
                      <p>
                        <strong>Staff:</strong>
                      </p>
                      {record.staff_details && record.staff_details.length > 0 ? (
                        <ul>
                          {record.staff_details.map((staff) => (
                            <li key={staff.staff_id}>
                              {staff.staff_name} - {staff.staff_position} - {staff.staff_phone}{" "}
                              {staff.status ? `(${staff.status})` : ""}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No staff assigned.</p>
                      )}

                      {/* Site Details */}
                      <p>
                        <strong>Sites:</strong>
                      </p>
                      {record.site_details && record.site_details.length > 0 ? (
                        <ul>
                          {record.site_details.map((site) => (
                            <li key={site.site_id}>
                              {site.client_name} - {site.site_name}, {site.site_address} ({site.site_region})
                              {site.notes ? ` - Notes: ${site.notes}` : ""}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No sites assigned.</p>
                      )}
                      <p>
                        <strong>Message:</strong> {record.message || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No dispatch records available.</p>
                )}
              </Modal>

              {/* Confirmation Modal */}
              <Modal
                title="Confirm Dispatch"
                open={showConfirmModal}
                onCancel={() => {
                  setShowConfirmModal(false)
                  setSelectedDate(null);
                  setSelectedTime(null);
                  setSelectedStorm(null);
                  setDispatchMessage("");
                  setSelectedDispatches([]);
                }}
                onOk={handleConfirmDispatch}
                okText="Confirm"
                cancelText="Cancel"
              >
                <p>Are you sure you want to dispatch the selected vehicle(s)?</p>

                {/* Dropdown for selecting a storm */}
                <div style={{ marginBottom: 16 }}>
                  <label htmlFor="storm-select">Select Storm:</label>
                  <Select
                    id="storm-select"
                    placeholder="Select a storm"
                    style={{ width: "100%" }}
                    value={selectedStorm}
                    onChange={(value) => setSelectedStorm(value)}
                    allowClear
                  >
                    {storms.map((storm) => (
                      <Option key={storm.storm_id} value={storm.storm_id}>
                        {`${storm.type.charAt(0).toUpperCase() + storm.type.slice(1)} - ${
                          storm.severity.charAt(0).toUpperCase() + storm.severity.slice(1)
                        } (${new Date(storm.date_of_storm).toLocaleDateString()})`}
                      </Option>
                    ))}
                  </Select>
                </div>

                {/* Date Picker for selecting the dispatch date */}
                <div style={{ marginBottom: 16 }}>
                  <label htmlFor="date-picker" style={{ display: "block", marginBottom: 8 }}>
                    Select Dispatch Date:
                  </label>
                  <DatePicker
                    id="date-picker"
                    style={{ width: "100%" }}
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                  />
                </div>

                {/* Time Picker for selecting the dispatch time */}
                <div style={{ marginBottom: 16 }}>
                  <label htmlFor="time-picker" style={{ display: "block", marginBottom: 8 }}>
                    Select Dispatch Time:
                  </label>
                  <TimePicker
                    id="time-picker"
                    style={{ width: "100%" }}
                    value={selectedTime}
                    onChange={(time) => setSelectedTime(time)}
                    format="HH:mm" // Ensure the time is in 24-hour format
                  />
                </div>
                <label style={{ display: "block", marginBottom: 8 }}>
                  Optional Message:
                </label>
                <Input.TextArea
                  placeholder="Optional message (max 200 characters)"
                  maxLength={200}
                  value={dispatchMessage}
                  onChange={(e) => setDispatchMessage(e.target.value)}
                />
              </Modal>

              {/* Modal to Create a Storm */}
              <Modal
                title="Create Storm"
                visible={stormModal}
                onCancel={() => {
                  setStormModal(false);
                  setDateOfStorm(null);
                  setStormType("");
                  setStormSeverity("");
                }}
                onOk={createStorm}
                okText="Create"
                cancelText="Cancel"
              >
                <Form layout="vertical">
                  {/* Storm Type Dropdown */}
                  <Form.Item label="Storm Type">
                    <Select
                      placeholder="Select storm type"
                      value={stormType}
                      onChange={(value) => setStormType(value)}
                    >
                      <Option value="snow">Snow</Option>
                      <Option value="freezing_rain">Freezing Rain</Option>
                      <Option value="salt">Salt</Option>
                    </Select>
                  </Form.Item>

                  {/* Storm Severity Dropdown */}
                  <Form.Item label="Storm Severity">
                    <Select
                      placeholder="Select storm severity"
                      value={stormSeverity}
                      onChange={(value) => setStormSeverity(value)}
                    >
                      <Option value="light">Light</Option>
                      <Option value="medium">Medium</Option>
                      <Option value="heavy">Heavy</Option>
                    </Select>
                  </Form.Item>

                  {/* Date of Storm (Calendar Input) */}
                  <Form.Item label="Date of Storm">
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select date of storm"
                      value={dateOfStorm}
                      onChange={(date) => setDateOfStorm(date)}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
        </DndProvider>
      </Theme>
    </ConfigProvider>
  );
};

export default LoaderAllocationDispatchPage;
