import React, { useEffect, useState } from 'react';
import { ConfigProvider, Table, message, Tag, Tooltip, Spin, Select } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IconBuildingFactory2, IconCheck, IconCloudRain, IconGrain, IconMessage, IconSnowflake, IconTool, IconX } from '@tabler/icons-react';
import styled from 'styled-components';
import moment from "moment";
import { IconTractor, IconUser } from '@tabler/icons-react';
import api from "../authentication/axiosInstance";
import { useNavigate } from 'react-router-dom';
import styles from './WalkwayCrewAllocationPage.module.css';
const { Option } = Select;


const ToggleButtonGroup = styled.div`
  display: flex;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 5px 15px;
  background-color: ${({ isSelected }) => (isSelected ? '#e6f7ff' : '#fff')};
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#034832' : '#000')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e6f7ff;
    color: #034832;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
`;

const getStaffCardBackgroundColor = (status) => {
    switch (status) {
      case "Confirmed":
        return "#E5F2EC"; // Light Green
      case "Denied":
        return "#FDEAEA"; // Light Red
      default:
        return "#f0f0f0"; // Default Grey
    }
  };
  

const stormSeverityColors = [
    { severity: "light", color: "#034832" , bg: "#E5F2EC"},
    { severity: "medium", color: "#FFC107", bg: "#FFF8E1" },
    { severity: "heavy", color: "#FF4D4E", bg: "#FDEAEA" },
]

const getSeverityColor = (severity) => {
    const severityColor = stormSeverityColors.find(item => item.severity === severity);
    return severityColor ? severityColor : { color: "#000000", bg: "#FFFFFF" }; // Default fallback
};

const walkwayCrewsColumns = [
    {
        title: "Crew Name",
        dataIndex: "walkway_crew_name", // Specifies the data field
        key: "walkway_crew_name",
        width: 60,
        sorter: (a, b) => {
            const nameA = a.walkway_crew_name ? a.walkway_crew_name.toLowerCase() : "";
            const nameB = b.walkway_crew_name ? b.walkway_crew_name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) => <b>{text}</b>, // Renders the text in bold
    },    
    {
        title: "Region",
        dataIndex: "region", // Use dataIndex for better integration with Ant Design
        key: "region",
        width: 60,
        sorter: (a, b) => {
            // Handle cases where region might be null or undefined
            const regionA = a.region ? a.region.toLowerCase() : "";
            const regionB = b.region ? b.region.toLowerCase() : "";
            if (regionA < regionB) return -1;
            if (regionA > regionB) return 1;
            return 0;
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) => <b>{text}</b>,
    },
    {
        title: "Event",
        key: "storm_type",
        width: 130,
        // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
        render: (text, record) => {
            const { color, bg } = getSeverityColor(record.storm_severity);
            return (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.storm_type === "snow" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconSnowflake size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Snow Storm</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "freezing_rain" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconCloudRain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Freezing Rain</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "salt" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconGrain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                        <IconTool size={18} stroke={2} />
                    </div>
                )}
            </div>
        )}
    },
    {
        title: "Vehicle",
        key: "vehicle",
        width: 100,
        render: (text, record) => (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '50px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>
            <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type} ({record.vehicle_model})</span>     
            </div>
        ),
    },
    {
        title: "Dispatched At",
        dataIndex: "dispatched_at",
        key: "dispatched_at",
        width: 120,
        render: (dispatched_at) =>
          dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
      },
      {
        title: "Dispatched For",
        dataIndex: "dispatched_for",
        key: "dispatched_for",
        width: 120,
        render: (dispatched_for) =>
          dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_for).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
      },
      {
        title: "Overall Confirmation",
        key: "overall_staff_confirmation",
        width: 100,
        sorter: (a, b) => {
            // Convert boolean to number for comparison: true -> 1, false -> 0
            return (b.overall_staff_confirmation === a.overall_staff_confirmation) ? 0 :
                   b.overall_staff_confirmation ? 1 : -1;
        },
        render: (record) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.overall_staff_confirmation ? (
                    <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                        <IconCheck size={18} stroke={2} />
                    </div>
                ) : (
                    <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                    </Tooltip>
            )}
            </div>
        ),
      }
]

const walkwayCrewsExpandedRow = (record) => (
    <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
        {/* Staff */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                {record.staff_details.map((staff, index) => (
                   <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
                   <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column', 
                    }} 
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconUser size={14} style={{ marginRight: '3px' }} />
                      {staff.staff_name}
                    </span>
                    <span style={{ fontSize: '14px'}}>
                      {staff.staff_position}
                    </span>       
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip title="Send Reminder"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
                </div>
                  </div>
                ))}
            </div>
        </div>
        {/* Sites */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                {record.site_details.map((site, index) => (
                    <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column',
                      border: '1px solid #e0e0e0', 
                      borderRadius: '5px', 
                      padding: '5px 10px', 
                      backgroundColor: '#f0f0f0',
                      width: "250px",
                      position: 'relative',
                    }} 
                    key={index}
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.client_name}
                    </span>
                    <span style={{ fontSize: '14px', }}>
                      {site.site_name}
                    </span>    
                    <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                        {site.site_region}
                    </span>   
                  </div>
                ))}
            </div>
        </div>
        {/* Equipment */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Equipment:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                {record.equipment_details.map((equipment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '250px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: '#f0f0f0' }}>
                        <span style={{ fontWeight: '500', fontSize: '16px'}}>
                            <IconTractor size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                            {equipment.equipment_name}
                        </span>
                        <span style={{ fontSize: '14px' }}>{equipment.equipment_type_name}</span>
                    </div>
                ))}
            </div>
        </div>
        {/* Notes */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Notes:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <span style={{ fontSize: '14px'}}>
                        {record.message || "N/A"}
                    </span>
                </div>
            </div>
        </div>
    </div>
)

const saltingRoutesColumns = [
    {
        title: "Route Name",
        dataIndex: "salting_route_name", // Specifies the data field
        key: "salting_route_name",
        width: 60,
        sorter: (a, b) => {
            const nameA = a.salting_route_name ? a.salting_route_name.toLowerCase() : "";
            const nameB = b.salting_route_name ? b.salting_route_name.toLowerCase() : "";
            return nameA.localeCompare(nameB);
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) => <b>{text || "N/A"}</b>, // Renders the text in bold, shows "N/A" if null
    },    
    {
        title: "Region",
        dataIndex: "region", // Use dataIndex for better integration with Ant Design
        key: "region",
        width: 60,
        sorter: (a, b) => {
            // Handle cases where region might be null or undefined
            const regionA = a.region ? a.region.toLowerCase() : "";
            const regionB = b.region ? b.region.toLowerCase() : "";
            if (regionA < regionB) return -1;
            if (regionA > regionB) return 1;
            return 0;
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) => <b>{text}</b>,
    },
    {
        title: "Event",
        key: "storm_type",
        width: 130,
        // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
        render: (text, record) => {
            const { color, bg } = getSeverityColor(record.storm_severity);
            return (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.storm_type === "snow" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconSnowflake size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Snow Storm</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "freezing_rain" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconCloudRain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Freezing Rain</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "salt" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconGrain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                        <IconTool size={18} stroke={2} />
                    </div>
                )}
            </div>
        )}
    },
    {
        title: "Vehicle",
        key: "vehicle",
        width: 100,
        render: (text, record) => (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '50px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>
            <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type} ({record.vehicle_model})</span>     
            </div>
        ),
    },
    {
        title: "Dispatched At",
        dataIndex: "dispatched_at",
        key: "dispatched_at",
        width: 120,
        render: (dispatched_at) =>
          dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
      },
      {
        title: "Dispatched For",
        dataIndex: "dispatched_for",
        key: "dispatched_for",
        width: 120,
        render: (dispatched_for) =>
          dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_for).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
      },
      {
        title: "Overall Confirmation",
        key: "overall_staff_confirmation",
        width: 100,
        sorter: (a, b) => {
            // Convert boolean to number for comparison: true -> 1, false -> 0
            return (b.overall_staff_confirmation === a.overall_staff_confirmation) ? 0 :
                   b.overall_staff_confirmation ? 1 : -1;
        },
        render: (record) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.overall_staff_confirmation ? (
                    <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                        <IconCheck size={18} stroke={2} />
                    </div>
                ) : (
                    <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                    </Tooltip>
            )}
            </div>
        ),
      }
]

const saltingRoutesExpandedRow = (record) => (
    <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
    {/* Staff */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
            {record.staff_details.map((staff, index) => (
               <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
               <div 
                style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column', 
                }} 
              >
                <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                  <IconUser size={14} style={{ marginRight: '3px' }} />
                  {staff.staff_name}
                </span>
                <span style={{ fontSize: '14px'}}>
                  {staff.staff_position}
                </span>       
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip title="Send Reminder"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
            </div>
              </div>
            ))}
        </div>
    </div>
    {/* Sites */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
            {record.site_details.map((site, index) => (
                <div 
                style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column',
                  border: '1px solid #e0e0e0', 
                  borderRadius: '5px', 
                  padding: '5px 10px', 
                  backgroundColor: '#f0f0f0',
                  width: "350px",
                  position: 'relative',
                }} 
                key={index}
              >
                <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                  <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                  {site.client_name}
                </span>
                <span style={{ fontSize: '14px', }}>
                  {site.site_name}
                </span>    
                <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                    {site.site_region}
                </span>   
              </div>
            ))}
        </div>
    </div>
    {/* Notes */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Notes:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span style={{ fontSize: '14px'}}>
                    {record.message || "N/A"}
                </span>
            </div>
        </div>
    </div>
</div>
)

const loadersColumns = [
    {
        title: "Dispatch ID",
        key: "dispatch_log_id",
        width: 71,
        render: (record) => <b>{record.dispatch_log_id}</b>,
    },
    {
        title: "Event",
        key: "storm_type",
        width: 135,
        // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
        render: (text, record) => {
            const { color, bg } = getSeverityColor(record.storm_severity);
            return (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.storm_type === "snow" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconSnowflake size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Snow Storm</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "freezing_rain" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconCloudRain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Freezing Rain</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : record.storm_type === "salt" ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                        <div style={{ 
                            backgroundColor: bg,
                            height: '30px', 
                            width: '30px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            border: `1px solid ${color}`, 
                            borderRadius: '4px', 
                            color: color 
                        }}>
                            <IconGrain size={18} stroke={2} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                            <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                        <IconTool size={18} stroke={2} />
                    </div>
                )}
            </div>
        )}
    },
    {
        title: "Vehicle",
        key: "vehicle",
        width: 120,
        render: (text, record) => (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '50px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>
            <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type} ({record.vehicle_model})</span>     
            </div>
        ),
    },
    {
        title: "Dispatched At",
        dataIndex: "dispatched_at",
        key: "dispatched_at",
        width: 120,
        render: (dispatched_at) =>
          dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
      },
      {
        title: "Dispatched For",
        dataIndex: "dispatched_for",
        key: "dispatched_for",
        width: 120,
        render: (dispatched_for) =>
          dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span>
            <span>{moment(dispatched_for).format("h:mm:ss a")}</span>
            </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
        sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
      },
      {
        title: "Overall Confirmation",
        key: "overall_staff_confirmation",
        width: 90,
        sorter: (a, b) => {
            // Convert boolean to number for comparison: true -> 1, false -> 0
            return (b.overall_staff_confirmation === a.overall_staff_confirmation) ? 0 :
                   b.overall_staff_confirmation ? 1 : -1;
        },
        render: (record) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                {record.overall_staff_confirmation ? (
                    <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                        <IconCheck size={18} stroke={2} />
                    </div>
                ) : (
                    <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                    </Tooltip>
            )}
            </div>
        ),
      }
]

const loadersExpandedRow = (record) => (
    <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
    {/* Staff */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
            {record.staff_details.map((staff, index) => (
               <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
               <div 
                style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column', 
                }} 
              >
                <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                  <IconUser size={14} style={{ marginRight: '3px' }} />
                  {staff.staff_name}
                </span>
                <span style={{ fontSize: '14px'}}>
                  {staff.staff_position}
                </span>       
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip title="Send Reminder"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
            </div>
              </div>
            ))}
        </div>
    </div>
    {/* Sites */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
            {record.site_details.map((site, index) => (
                <div 
                style={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-start', 
                  flexDirection: 'column',
                  border: '1px solid #e0e0e0', 
                  borderRadius: '5px', 
                  padding: '5px 10px', 
                  backgroundColor: '#f0f0f0',
                  width: "350px",
                  position: 'relative',
                }} 
                key={index}
              >
                <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                  <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                  {site.client_name}
                </span>
                <span style={{ fontSize: '14px', }}>
                  {site.site_name}
                </span>    
                <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                    {site.site_region}
                </span>   
              </div>
            ))}
        </div>
    </div>
    {/* Notes */}
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
        <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Notes:</span>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span style={{ fontSize: '14px'}}>
                    {record.message || "N/A"}
                </span>
            </div>
        </div>
    </div>
</div>
)

const DispatchHistoryPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedObjectType, setSelectedObjectType] = useState("Walkway Crew's");
    const [dispatchLogs, setDispatchLogs] = useState({ "walkwayCrews": [], "saltingRoutes": [], "loaders": [] });
    
    // Separate region lists
    const [walkwayCrewRegions, setWalkwayCrewRegions] = useState([]);
    const [saltingRouteRegions, setSaltingRouteRegions] = useState([]);
    
    // Separate selected regions
    const [selectedWalkwayCrewRegion, setSelectedWalkwayCrewRegion] = useState('All');
    const [selectedSaltingRouteRegion, setSelectedSaltingRouteRegion] = useState('All');

    const getButtonStyle = (list) => ({
        backgroundColor: selectedObjectType === list ? '#e6f2ed' : '#fff',
        color: selectedObjectType === list ? '#034832' : '#000',
        fontWeight: '550',
        padding: '8px 8px',
        fontSize: '13px',
        width: '140px'
    });

    useEffect(() => {
        const fetchAllDispatchLogs = async () => {
            setLoading(true);
            try {
                const [walkwayResponse, saltingResponse, loadersResponse] = await Promise.all([
                    api.get("/dispatch/dispatchLogs/Walkway/"),
                    api.get("/dispatch/dispatchLogs/Salting/"),
                    api.get("/dispatch/dispatchLogs/Loader/"),
                ]);

                setDispatchLogs({
                    walkwayCrews: walkwayResponse.data,
                    saltingRoutes: saltingResponse.data,
                    loaders: loadersResponse.data,
                });

                // Extract unique regions for Walkway Crews
                const walkwayRegionsSet = new Set();
                walkwayResponse.data.forEach(item => {
                    if(item.region) walkwayRegionsSet.add(item.region);
                });
                setWalkwayCrewRegions(['All', ...Array.from(walkwayRegionsSet).sort()]);

                // Extract unique regions for Salting Routes
                const saltingRegionsSet = new Set();
                saltingResponse.data.forEach(item => {
                    if(item.region) saltingRegionsSet.add(item.region);
                });
                setSaltingRouteRegions(['All', ...Array.from(saltingRegionsSet).sort()]);

                // Reset selected regions
                setSelectedWalkwayCrewRegion('All');
                setSelectedSaltingRouteRegion('All');
            } catch (error) {
                console.error("Failed to fetch dispatch logs:", error);
                message.error("Failed to fetch dispatch logs");
            } finally {
                setLoading(false);
            }
        };

        fetchAllDispatchLogs();
    }, []);

    // Reset selected regions when object type changes
    useEffect(() => {
        if(selectedObjectType === "Walkway Crew's") {
            setSelectedWalkwayCrewRegion('All');
        } else if(selectedObjectType === 'Salting Routes') {
            setSelectedSaltingRouteRegion('All');
        }
    }, [selectedObjectType]);

    // Handlers for region changes
    const handleWalkwayCrewRegionChange = (value) => {
        setSelectedWalkwayCrewRegion(value);
    };

    const handleSaltingRouteRegionChange = (value) => {
        setSelectedSaltingRouteRegion(value);
    };

    // Function to get filtered data based on selected object type and region
    const getFilteredData = () => {
        let data = [];
        if(selectedObjectType === "Walkway Crew's") {
            data = dispatchLogs.walkwayCrews;
            if(selectedWalkwayCrewRegion !== 'All') {
                data = data.filter(item => item.region === selectedWalkwayCrewRegion);
            }
        } else if(selectedObjectType === 'Salting Routes') {
            data = dispatchLogs.saltingRoutes;
            if(selectedSaltingRouteRegion !== 'All') {
                data = data.filter(item => item.region === selectedSaltingRouteRegion);
            }
        } else if(selectedObjectType === 'Loaders') {
            data = dispatchLogs.loaders;
            // Assuming Loaders don't have regions; if they do, implement similar filtering
        }

        return data;
    }

    return (
        <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
          <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
                <div style={{ width: 'calc(100% - 10px)', marginLeft: '20px'}}>
                  <style>
                    {`.locked-row {
                        background-color: #f0f0f0 !important;
                      }
                    `}
                  </style>
                  <style>
                    {`.ant-table-tbody > tr:hover > td {
                        background: none !important;
                      }
                  `}
                  </style>
                  <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '10px',
                        marginBottom: '15px',
                    }}
                    >
                    {/* Left-aligned elements: Span and New Buttons */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                        {/* Dispatch History Span */}
                        <span
                        style={{
                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                            fontWeight: '600',
                            fontSize: '28px',
                        }}
                        >
                        Dispatch History <span style={{ fontWeight: '400' }}> - {selectedObjectType}</span>
                        </span>

                        <div
                        className={styles.tableButton}
                        style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                        onClick={() => navigate('/walkwayCrewDispatch')}
                        >
                            Walkway Crew Dispatch
                        </div>
                        <div
                        className={styles.tableButton}
                        style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                        onClick={() => navigate('/SaltingRouteDispatch')}
                        >
                            Salting Route Dispatch
                        </div>
                        <div
                        className={styles.tableButton}
                        style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                        onClick={() => navigate('/loaderDispatch')}
                        >
                            Loader Dispatch
                        </div>
                    </div>

                    {/* Region Filter Dropdown with Label */}
                    {(selectedObjectType === "Walkway Crew's" || selectedObjectType === 'Salting Routes') && (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <span style={{ fontWeight: '500' }}>Region:</span>
                        <Select
                            value={
                            selectedObjectType === "Walkway Crew's"
                                ? selectedWalkwayCrewRegion
                                : selectedSaltingRouteRegion
                            }
                            onChange={
                            selectedObjectType === "Walkway Crew's"
                                ? handleWalkwayCrewRegionChange
                                : handleSaltingRouteRegionChange
                            }
                            style={{ width: 200 }}
                            placeholder="Filter by Region"
                        >
                            {selectedObjectType === "Walkway Crew's"
                            ? walkwayCrewRegions.map((region) => (
                                <Option key={region} value={region}>
                                    {region}
                                </Option>
                                ))
                            : saltingRouteRegions.map((region) => (
                                <Option key={region} value={region}>
                                    {region}
                                </Option>
                                ))}
                        </Select>
                        </div>
                    )}

                    {/* Right-aligned elements */}
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        {/* Object Type Toggle Buttons */}
                        <ToggleButtonGroup>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType("Walkway Crew's");
                            }}
                            isSelected={selectedObjectType === "Walkway Crew's"}
                            style={getButtonStyle("Walkway Crew's")}
                        >
                            Walkway Crew's
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType('Salting Routes');
                            }}
                            isSelected={selectedObjectType === 'Salting Routes'}
                            style={getButtonStyle('Salting Routes')}
                        >
                            Salting Routes
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType('Loaders');
                            }}
                            isSelected={selectedObjectType === 'Loaders'}
                            style={getButtonStyle('Loaders')}
                        >
                            Loaders
                        </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    </div>

                  <Spin spinning={loading}>
                    <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 180px)' }}>
                      <Table
                        dataSource={getFilteredData()}
                        columns={selectedObjectType === "Walkway Crew's" ? walkwayCrewsColumns : selectedObjectType === 'Salting Routes' ? saltingRoutesColumns : loadersColumns}
                        rowKey="dispatch_log_id"
                        pagination={false}
                        scroll={{ y: 690 }}
                        expandable={{
                          expandedRowRender: (record) => (
                            selectedObjectType === "Walkway Crew's" ? walkwayCrewsExpandedRow(record) : 
                            selectedObjectType === 'Salting Routes' ? saltingRoutesExpandedRow(record) : 
                            loadersExpandedRow(record)
                          ),
                        }}
                        />
                    </div>
                    </Spin>
                </div>
              </div>
          </DndProvider>
        </ConfigProvider>
    );
};

export default DispatchHistoryPage;