import React from 'react';
import { StyledEngineProvider } from "@mui/system";
import { AuthProvider } from './context/AuthContext';
import LoadScriptWrapper from './components/LoadScriptWrapper';
import AppContent from './AppContent';

import './App.css';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <AuthProvider>
          <LoadScriptWrapper>
            <AppContent />
          </LoadScriptWrapper>
      </AuthProvider>
    </StyledEngineProvider>
  );
}

export default App;
