// EquipmentsPage.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Popconfirm, message, Input, Form, Select, InputNumber, Row, Col } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { jsPDF } from 'jspdf';
import companyLogo from '../assets/company-logo.png'; // Adjust the path as needed
import api from '../authentication/axiosInstance';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';  // Add this import at the top of your file
const { Option } = Select;

const { Search } = Input;

const EquipmentsPage = () => {
  const [equipment, setEquipment] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingEquipment, setEditingEquipment] = useState(null);

  // Added these new state variables for individual filters
  const [searchName, setSearchName] = useState('');
  const [searchBrand, setSearchBrand] = useState(undefined);
  const [searchModel, setSearchModel] = useState('');
  const [searchType, setSearchType] = useState(undefined);
  const [searchStatus, setSearchStatus] = useState(undefined);

  // Added debounced search handlers for name and model search fields
  const debouncedSearchName = debounce((value) => {
    setSearchName(value);
  }, 300);

  const debouncedSearchModel = debounce((value) => {
    setSearchModel(value);
  }, 300);

  const applyFilters = () => {
    const filtered = equipment.filter((item) => {
      const filterName = item.equipment_name.toLowerCase().includes(searchName.toLowerCase());
      const filterBrand = searchBrand ? item.equipment_brand.toLowerCase().includes(searchBrand.toLowerCase()) : true;
      const filterModel = item.equipment_model.toLowerCase().includes(searchModel.toLowerCase());
      const filterType = searchType ? item.equipment_type_name.toLowerCase().includes(searchType.toLowerCase()) : true;
      const filterStatus = searchStatus ? item.equipment_status_name.toLowerCase().includes(searchStatus.toLowerCase()) : true;
      return filterName && filterBrand && filterModel && filterType && filterStatus;
    });
    setFilteredEquipment(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [searchName, searchBrand, searchModel, searchType, searchStatus]);

  // Fetch equipment data from the API
  const fetchEquipment = async () => {
    setLoading(true);
    try {
      const response = await api.get('/equipment/');
      setEquipment(response.data);
      setFilteredEquipment(response.data);
    } catch (error) {
      console.error('Failed to fetch equipment:', error);
      message.error('Failed to fetch equipment');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipment();
  }, []);

  // Filter equipment based on search text
  // const filterEquipment = (value) => {
  //   const lowercasedValue = value.toLowerCase();
  //   const filtered = equipment.filter((item) =>
  //     item.equipment_name.toLowerCase().includes(lowercasedValue) ||
  //     item.equipment_brand.toLowerCase().includes(lowercasedValue) ||
  //     item.equipment_model.toLowerCase().includes(lowercasedValue) ||
  //     item.equipment_serial_number.toLowerCase().includes(lowercasedValue)
  //   );
  //   setFilteredEquipment(filtered);
  // };


  // Handle editing of equipment
  const showEditModal = (record) => {
    setEditingEquipment(record);
    setIsEditModalVisible(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
    setEditingEquipment(null);
  };

  const handleEditSubmit = async (values) => {
    try {
      // Include the equipment_id in the update request
      const updatedEquipment = {
        ...values,
        equipment_id: editingEquipment.equipment_id
      };

      await api.put(`/equipment/${editingEquipment.equipment_id}/`, updatedEquipment);
      message.success('Equipment updated successfully');
      fetchEquipment();
      handleEditModalClose();
    } catch (error) {
      console.error('Failed to update equipment:', error);
      if (error.response && error.response.data) {
        console.error('Error details:', error.response.data);
      }
      message.error('Failed to update equipment');
    }
  };

  // Handle deletion of equipment
  const handleDelete = async (id) => {
    try {
      await api.delete(`/equipment/${id}/`);
      message.success('Equipment deleted successfully');
      fetchEquipment();
    } catch (error) {
      console.error('Failed to delete equipment:', error);
      message.error('Failed to delete equipment');
    }
  };

  // Handle viewing equipment details
  const showEquipmentDetails = (record) => {
    setSelectedEquipment(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedEquipment(null);
  };

  // Generate PDF for equipment details
  const generatePDF = (equipmentItem) => {
    if (!equipmentItem) {
      message.error('No equipment data available to generate PDF.');
      return;
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const lineSpacing = 8;
    const indentSpacing = 10;

    // Add company logo
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', margin, margin, 66, 13);
    }

    // Header
    doc.setFontSize(16);
    const headerText = `${equipmentItem.equipment_name} - Equipment Details`;
    const headerTextWidth = doc.getTextWidth(headerText);
    const rightAlignedX = pageWidth - headerTextWidth - 10;
    doc.text(headerText, rightAlignedX, 17);

    // Details
    doc.setFontSize(12);
    const xPos = margin;
    let yPos = 30;
    const details = [
      { label: 'Equipment Name:', value: equipmentItem.equipment_name },
      { label: 'Brand:', value: equipmentItem.equipment_brand || 'N/A' },
      { label: 'Model:', value: equipmentItem.equipment_model || 'N/A' },
      { label: 'Serial Number:', value: equipmentItem.equipment_serial_number || 'N/A' },
      { label: 'Type:', value: equipmentItem.equipment_type_name || 'N/A' },
      { label: 'Status:', value: equipmentItem.equipment_status_name || 'N/A' },
      { label: 'Group:', value: equipmentItem.equipment_group_name || 'N/A' },
      { label: 'Walks Coverage Rate:', value: equipmentItem.walks_coverage_rate },
      { label: 'Lots Coverage Rate:', value: equipmentItem.lots_coverage_rate },
      { label: 'Travel KM:', value: equipmentItem.travel_km || '0' },
      { label: 'Flag Type:', value: equipmentItem.flag_type || 'N/A' },
      { label: 'Staff Limit:', value: equipmentItem.staff_limit },
    ];

    details.forEach((detail) => {
      doc.text(`${detail.label} ${detail.value}`, xPos, yPos);
      yPos += lineSpacing;
    });

    doc.save(`${equipmentItem.equipment_name}_Details.pdf`);
  };

  // Define table columns
  const columns = [
    {
      title: 'Equipment Name',
      dataIndex: 'equipment_name',
      key: 'equipment_name',
    },
    {
      title: 'Brand',
      dataIndex: 'equipment_brand',
      key: 'equipment_brand',
    },
    {
      title: 'Model',
      dataIndex: 'equipment_model',
      key: 'equipment_model',
    },
    {
      title: 'Serial Number',
      dataIndex: 'equipment_serial_number',
      key: 'equipment_serial_number',
    },
    {
      title: 'Type',
      dataIndex: 'equipment_type_name',
      key: 'equipment_type_name',
    },
    {
      title: 'Status',
      dataIndex: 'equipment_status_name',
      key: 'equipment_status_name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            type="primary"
            onClick={() => showEquipmentDetails(record)}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete equipment "${record.equipment_name}"?`}
            onConfirm={() => handleDelete(record.equipment_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              type="danger"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Box style={{ padding: 30 }}>
      <Box style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Equipments</h2>
        <div>
          <Input.Search
            placeholder="Search name"
            onSearch={setSearchName}
            onChange={(e) => debouncedSearchName(e.target.value)}
            style={{ width: 200, marginRight: 8 }}
            prefix={<SearchOutlined />}
          />
          <Select
            placeholder="Search by brand"
            value={searchBrand}
            onChange={setSearchBrand}
            style={{ width: 200, marginRight: 8 }}
            allowClear
          >
            {Array.from(new Set(equipment.map((item) => item.equipment_brand))) // Extract unique brands
              .map((brand) => (
                <Option key={brand} value={brand}>
                  {brand}
                </Option>
            ))}
          </Select>
          <Input.Search
            placeholder="Search model"
            onSearch={setSearchModel}
            onChange={(e) => debouncedSearchModel(e.target.value)}
            style={{ width: 200, marginRight: 8 }}
            prefix={<SearchOutlined />}
          />
          <Select
            placeholder="Search by type"
            value={searchType}
            onChange={setSearchType}
            style={{ width: 200, marginRight: 8 }}
            allowClear
          >
            {Array.from(new Set(equipment.map((item) => item.equipment_type_name))) // Extract unique types
              .map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
            ))}
          </Select>
          <Select
            placeholder="Search by status"
            value={searchStatus}
            onChange={setSearchStatus}
            style={{ width: 200 }}
            allowClear
          >
            {Array.from(new Set(equipment.map((item) => item.equipment_status_name))) // Extract unique statuses
              .map((status) => (
                <Option key={status} value={status}>
                  {status}
                </Option>
            ))}
          </Select>
        </div>
      </Box>
      <Table
        dataSource={filteredEquipment}
        columns={columns}
        rowKey="equipment_id"
        loading={loading}
        pagination={false} // Disable pagination
        scroll={{ y: 640 }} // Add vertical scrolling with a fixed height
      />

      {/* Equipment Details Modal */}
      <Modal
        title="Equipment Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="pdf" type="primary" onClick={() => generatePDF(selectedEquipment)}>
            Download PDF
          </Button>,
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedEquipment ? (
          <div>
            <p><strong>Equipment Name:</strong> {selectedEquipment.equipment_name}</p>
            <p><strong>Brand:</strong> {selectedEquipment.equipment_brand || 'N/A'}</p>
            <p><strong>Model:</strong> {selectedEquipment.equipment_model || 'N/A'}</p>
            <p><strong>Serial Number:</strong> {selectedEquipment.equipment_serial_number || 'N/A'}</p>
            <p><strong>Type:</strong> {selectedEquipment.equipment_type_name || 'N/A'}</p>
            <p><strong>Status:</strong> {selectedEquipment.equipment_status_name || 'N/A'}</p>
            <p><strong>Group:</strong> {selectedEquipment.equipment_group_name || 'N/A'}</p>
            <p><strong>Walks Coverage Rate:</strong> {selectedEquipment.walks_coverage_rate}</p>
            <p><strong>Lots Coverage Rate:</strong> {selectedEquipment.lots_coverage_rate}</p>
            <p><strong>Travel KM:</strong> {selectedEquipment.travel_km || '0'}</p>
            <p><strong>Flag Type:</strong> {selectedEquipment.flag_type || 'N/A'}</p>
            <p><strong>Staff Limit:</strong> {selectedEquipment.staff_limit}</p>
            {/* Add more details if necessary */}
          </div>
        ) : (
          <p>No equipment details available.</p>
        )}
      </Modal>

      {/* Edit Equipment Modal */}
      <Modal
        title="Edit Equipment"
        visible={isEditModalVisible}
        onCancel={handleEditModalClose}
        footer={null}
        width={800} // Increase the width of the modal
      >
        {editingEquipment && (
          <Form
            initialValues={editingEquipment}
            onFinish={handleEditSubmit}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="equipment_name"
                  label="Equipment Name"
                  rules={[{ required: true, message: 'Please input the equipment name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="equipment_brand"
                  label="Brand"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="equipment_model"
                  label="Model"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="equipment_serial_number"
                  label="Serial Number"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="equipment_type_name"
                  label="Type"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="equipment_status_name"
                  label="Status"
                >
                  <Select>
                    <Select.Option value="In-Service">In-Service</Select.Option>
                    <Select.Option value="Out-of-Service">Out-of-Service</Select.Option>
                    <Select.Option value="Sold">Sold</Select.Option>
                    <Select.Option value="Removed">Removed</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="equipment_group_name"
                  label="Group"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="walks_coverage_rate"
                  label="Walks Coverage Rate"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="lots_coverage_rate"
                  label="Lots Coverage Rate"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="travel_km"
                  label="Travel KM"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="flag_type"
                  label="Flag Type"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="staff_limit"
                  label="Staff Limit"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Equipment
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </Box>
  );
};

export default EquipmentsPage;
