// SaltingRoutesDispatchPage.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  ConfigProvider,
  Table,
  Button,
  Spin,
  message,
  Input,
  Select,
  Modal,
  Form,
  Divider,
  DatePicker,
  TimePicker,
  Popconfirm,
} from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import api from '../authentication/axiosInstance';
import { Box } from '@mui/material';
import styles from './SaltingRoutesAllocationPage.module.css';
import {
  IconTractor,
  IconUser,
  IconBuildingFactory2,
} from '@tabler/icons-react';
import moment from "moment";
import { useNavigate } from 'react-router-dom';


const { Option } = Select;

const SaltingRoutesDispatchPage = () => {
  // State Variables
  const [loading, setLoading] = useState(false);
  const [dispatchMessage, setDispatchMessage] = useState('');
  const [selectedDispatches, setSelectedDispatches] = useState([]); // Array to handle multiple dispatches
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [storms, setStorms] = useState([]);
  const [stormType, setStormType] = useState("");
  const [stormSeverity, setStormSeverity] = useState("");
  const [dateOfStorm, setDateOfStorm] = useState(null);
  const [stormModal, setStormModal] = useState(false);
  const [dispatchObjects, setDispatchObjects] = useState([]);
  const [dispatchLogs, setDispatchLogs] = useState([]);
  const [selectedStorm, setSelectedStorm] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [sites, setSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [selectedStaffToAdd, setSelectedStaffToAdd] = useState({}); // Tracks selected staff per dispatch_id
  const [selectedSiteToAdd, setSelectedSiteToAdd] = useState({}); // Tracks selected site per dispatch_id
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // For multi-select dispatch
  const [selectedRegion, setSelectedRegion] = useState('all');
  const navigate = useNavigate();


  // Extract unique regions from dispatchObjects
  const regions = useMemo(() => {
    const allRegions = dispatchObjects.map(obj => obj.region).filter(region => region);
    return Array.from(new Set(allRegions));
  }, [dispatchObjects]);

    // Filter dispatch objects based on selectedRegion
  const filteredDispatchObjects = useMemo(() => {
    if (!selectedRegion || selectedRegion === 'all') return dispatchObjects;
    return dispatchObjects.filter(obj => obj.region === selectedRegion);
  }, [dispatchObjects, selectedRegion]);

  // Fetch Functions
  const fetchStorms = async () => {
    try {
      const response = await api.get("/dispatch/storm/");
      console.log("storms:", response.data);
      setStorms(response.data);
    } catch (error) {
      console.error("Failed to fetch storms:", error);
      message.error("Failed to fetch storms");
    }
  };

  const fetchDispatchObjects = async () => {
    try {
      const response = await api.get("/dispatch/dispatchObjects/Salting/");
      console.log("salting dispatch objects:", response.data);
      
      // Ensure response.data is an array, or set an empty array
      setDispatchObjects(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch objects:", error);
      message.error("Failed to fetch dispatch objects");
      setDispatchObjects([]);  // Ensure it defaults to an empty array on error
    }
  };

  const fetchDispatchLogs = async () => {
    try {
      const response = await api.get("/dispatch/dispatchLogs/Salting/");
      console.log("salting dispatch logs:", response.data);
      
      // Ensure response.data is an array, or set an empty array
      setDispatchLogs(response.data);
    } catch (error) {
      console.error("Failed to fetch dispatch Logs:", error);
      message.error("Failed to fetch dispatch Logs");
      setDispatchLogs([]);  // Ensure it defaults to an empty array on error
    }
  };

  const fetchStaff = async () => {
    try{
        const response = await api.get("/staff/salting_staff/");
        console.log("staff:", response.data);
        setStaff(response.data);
    } catch (error) {
        console.error("Failed to fetch staff:", error);
        message.error("Failed to fetch staff");
    }
  }

  const fetchSites = async () => {
    try {
      const response = await api.get("/sites/");
      console.log("sites:", response.data);
      setSites(response.data);
    } catch (error) {
      console.error("Failed to fetch sites:", error);
      message.error("Failed to fetch sites");
    }
  };

  useEffect(() => {
    fetchStorms();
    fetchDispatchLogs();
    fetchDispatchObjects();
    fetchStaff();
    fetchSites();
  }, []);

  // Handler Functions

  // Handle selecting a staff member to add
  const handleSelectStaffToAdd = (dispatchId, staffId) => {
    setSelectedStaffToAdd((prev) => ({
      ...prev,
      [dispatchId]: staffId,
    }));
  };

  // Handle adding a staff member to the dispatch object
  const handleAddStaff = async (dispatchId) => {
    const staffId = selectedStaffToAdd[dispatchId];
    if (!staffId) {
      message.warning("Please select a staff member to add.");
      return;
    }
    console.log("Adding staffId:", staffId, "to dispatchId:", dispatchId);
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        add_staff_to_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to add staff.");
      }
    } catch (error) {
      console.error("Failed to add staff:", error);
      message.error(error.response?.data?.error || "Failed to add staff.");
    } finally {
      // Reset the selected staff for this dispatch
      setSelectedStaffToAdd((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  // Handle removing a staff member from the dispatch object
  const handleRemoveStaff = async (dispatchId, staffId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        remove_staff_from_dispatch: true,
        dispatch_id: dispatchId,
        staff_id: staffId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to remove staff.");
      }
    } catch (error) {
      console.error("Failed to remove staff:", error);
      message.error(error.response?.data?.error || "Failed to remove staff.");
    }
  };

  // Handle selecting a site to add
  const handleSelectSiteToAdd = (dispatchId, siteId) => {
    setSelectedSiteToAdd((prev) => ({
      ...prev,
      [dispatchId]: siteId,
    }));
  };

  // Handle adding a site to the dispatch object
  const handleAddSite = async (dispatchId) => {
    const siteId = selectedSiteToAdd[dispatchId];
    if (!siteId) {
      message.warning("Please select a site to add.");
      return;
    }
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        add_site_to_dispatch: true,
        dispatch_id: dispatchId,
        site_id: siteId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to add site.");
      }
    } catch (error) {
      console.error("Failed to add site:", error);
      message.error(error.response?.data?.error || "Failed to add site.");
    } finally {
      // Reset the selected site for this dispatch
      setSelectedSiteToAdd((prev) => ({
        ...prev,
        [dispatchId]: null,
      }));
    }
  };

  // Handle removing a site from the dispatch object
  const handleRemoveSite = async (dispatchId, siteId) => {
    try {
      const response = await api.post("/dispatch/dispatchObjects/", {
        remove_site_from_dispatch: true,
        dispatch_id: dispatchId,
        site_id: siteId,
      });

      if (response.data.status === "success") {
        message.success(response.data.message);
        // Refresh dispatch objects to reflect changes
        fetchDispatchObjects();
      } else {
        message.error(response.data.error || "Failed to remove site.");
      }
    } catch (error) {
      console.error("Failed to remove site:", error);
      message.error(error.response?.data?.error || "Failed to remove site.");
    }
  };

  // Create Storm
  const createStorm = async () => {
    if (!stormType || !stormSeverity || !dateOfStorm) {
      message.error("Please fill out all fields");
      return;
    }

    try {
      const response = await api.post("/dispatch/storm/", {
        type: stormType,
        severity: stormSeverity,
        date_of_storm: dateOfStorm.format("YYYY-MM-DD"), // Formatting date for API
      });
      message.success("Storm created successfully");
      setDateOfStorm(null); // Reset date after successful creation
      setStormType(""); // Reset storm type after successful creation
      setStormSeverity(""); // Reset storm severity after successful creation
      setStormModal(false); // Close modal after successful creation
      fetchStorms(); // Refresh storm data
    } catch (error) {
      console.error("Failed to create storm:", error);
      message.error("Failed to create storm");
    }
  };

  // Handle opening the confirm dispatch modal for single dispatch
  const handleOpenConfirmModal = (record) => {
    const vehicle_id = record.vehicle;
    const staff_ids = record.staff_details.map(staff => staff.staff_id);
    const site_ids = record.site_details.map(site => site.site_id);
    const dispatch_id = record.dispatch_id;

    setSelectedDispatches([{
      vehicle_id,
      staff_ids,
      site_ids,
      dispatch_id
    }]); // Set as single dispatch in an array
    setShowConfirmModal(true);
  };

  // Handle opening the confirm dispatch modal for multiple dispatches
  const handleOpenMultiConfirmModal = (selectedRows) => {
    const transformedDispatches = selectedRows.map(record => ({
      vehicle_id: record.vehicle,
      staff_ids: record.staff_details.map(staff => staff.staff_id),
      site_ids: record.site_details.map(site => site.site_id),
      dispatch_id: record.dispatch_id
    }));
    setSelectedDispatches(transformedDispatches);
    setShowConfirmModal(true);
  };

  // Handle confirming dispatch (both individual and multiple)
  const handleConfirmDispatch = async () => {
    if (!selectedDispatches || selectedDispatches.length === 0) {
      message.error("No dispatch details selected.");
      setShowConfirmModal(false);
      return;
    }

    if (!selectedStorm) {
      message.error("Please select a storm to dispatch the vehicle.");
      setShowConfirmModal(false);
      return;
    }

    // Check if both date and time are selected
    if (!selectedDate || !selectedTime) {
      message.error("Please select both a date and time for the dispatch.");
      return;
    }

    // Prepare the dispatched_for datetime
    const dispatchedFor = moment(`${selectedDate.format("YYYY-MM-DD")} ${selectedTime.format("HH:mm")}`).toISOString();

    // Prepare dispatch list with shared parameters
    const dispatchList = selectedDispatches.map(dispatch => ({
      vehicle_id: dispatch.vehicle_id,
      staff_ids: dispatch.staff_ids,
      site_ids: dispatch.site_ids,
      dispatch_id: dispatch.dispatch_id,
      storm_id: selectedStorm,
      message: dispatchMessage,
      dispatched_for: dispatchedFor,
    }));

    try {
      await createSaltingRouteDispatch(dispatchList);
      setShowConfirmModal(false);
      setDispatchMessage(""); // Reset the message after dispatch
      setSelectedDispatches([]);
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedStorm(null);
      setSelectedRowKeys([]); // Clear selected rows after dispatch
      fetchDispatchLogs();
      fetchDispatchObjects();
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.message || "Failed to create dispatch");
    }
  };

  // Function to handle dispatching (calls the API)
  const createSaltingRouteDispatch = async (dispatchList) => {
    try {
      const response = await api.post("/dispatch/dispatchLogs/", {
        create_salting_route_dispatch_logs: true,
        dispatches: dispatchList,
      });

      // Assuming the backend returns a results array as per the updated backend function
      const { results } = response.data;

      // Process results to show messages to the user
      results.forEach((result) => {
        const { dispatch_number, status, message: msg, error, whatsapp_response } = result;
        if (status === "success") {
          message.success(`Dispatch ${dispatch_number}: ${msg}`);
        } else if (status === "partial_success") {
          message.warning(`Dispatch ${dispatch_number}: ${msg}`);
          if (error) {
            message.error(`Error: ${error}`);
          }
          if (whatsapp_response) {
            console.error(`WhatsApp Response for Dispatch ${dispatch_number}:`, whatsapp_response);
          }
        } else if (status === "failed") {
          message.error(`Dispatch ${dispatch_number}: ${msg}`);
        }
      });
    } catch (error) {
      console.error("Failed to create dispatch:", error);
      message.error(error.response?.data?.message || "Failed to create dispatch");
    }
  };

  // Sync Dispatch Data
  const syncDispatchData = async () => {
    try {
      setLoading(true);  // Optional: Show loading spinner while syncing
      const response = await api.post("/dispatch/dispatchObjects/", {
        create_salting_route_dispatch_objects: true,  // Trigger the backend function
      });
    message.success("Salting Route Dispatch data synced successfully!");
      fetchDispatchObjects();  // Refresh the dispatch objects after syncing
    } catch (error) {
      console.error("Failed to sync dispatch data:", error);
      message.error("Failed to sync dispatch data");
    } finally {
      setLoading(false);  // Optional: Hide loading spinner
    }
  };

  // Handle row selection change for multi-select
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Define rowSelection for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected dispatch objects based on selectedRowKeys
  const selectedRows = dispatchObjects.filter(obj => selectedRowKeys.includes(obj.dispatch_id));

  // Dispatch Selected Button Component
  const DispatchSelectedButton = () => {
    const handleDispatchSelected = () => {
      if (selectedRows.length === 0) {
        message.warning("Please select at least one dispatch to proceed.");
        return;
      }
      // Check if all selected dispatches have at least one staff assigned
      const dispatchesWithoutStaff = selectedRows.filter(dispatch => !dispatch.staff_details || dispatch.staff_details.length === 0);
      if (dispatchesWithoutStaff.length > 0) {
        message.error("One or more selected dispatches have no staff assigned.");
        return;
      }
      handleOpenMultiConfirmModal(selectedRows);
    };

    return (
      <Button
        type="primary"
        onClick={handleDispatchSelected}
        disabled={selectedRows.length === 0}
        style={{ marginLeft: '20px', height: "100%" }}
      >
        Dispatch Selected ({selectedRows.length})
      </Button>
    );
  };


  // Columns Definition
  const columns = [
    {
      title: 'Route Name',
      key: 'salting_route_name',
      width: 120,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.salting_route_name}</span>
        </div>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region', // Added dataIndex for better integration with sorting
      key: 'region',
      width: 100,
      sorter: (a, b) => {
          const regionA = a.region ? a.region.toLowerCase() : '';
          const regionB = b.region ? b.region.toLowerCase() : '';
          return regionA.localeCompare(regionB);
      },
      sortDirections: ['ascend', 'descend'], // Optional: Define sort directions
      render: (text) => (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%',  height: '85px' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}>
                  {text || 'N/A'}
              </span>
          </div>
      ),
    },
    {
      title: 'Vehicle Info',
      key: 'vehicle_info',
      width: 150,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', height: '85px' }}>
          <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>
          <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_type}</span>     
          <span style={{ fontSize: '14px', marginTop: '-2px' }}>{record.vehicle_model}</span>    
        </div>
      ),
    },
    {
      title: 'Staff Assigned',
      key: 'staff_assigned',
      width: 300,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '85px' }}>
          {record.staff_details.slice(0, 3).map((staff) => (
            <div 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'flex-start', 
                flexDirection: 'row', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: "ellipsis", 
                maxWidth: '280px' 
              }} 
              key={staff.staff_id}
            >
              <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                <IconUser size={14} style={{ marginRight: '3px' }} />
                {staff.staff_name}
              </span>
              <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {staff.staff_position}
              </span>       
            </div>
          ))}

          {/* Show "+ x more staff" if there are more than 3 staff */}
          {record.staff_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.staff_details.length - 3} more staff
            </span>
          )}

          {record.staff_details.length === 0 && 'No Staff Assigned'}
        </div>
      ),
    },
    {
      title: 'Sites Assigned',
      key: 'sites_assigned',
      width: 300,
      render: (text, record) =>  (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '85px' }}>
          {record.site_details.slice(0, 3).map((site) => (
            <div 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'flex-start', 
                flexDirection: 'row', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: "ellipsis", 
                maxWidth: '280px' 
              }} 
              key={site.site_id}
            >
              <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                {site.client_name}
              </span>
              <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                - {site.site_name}
              </span>       
            </div>
          ))}
          
          {/* Show "+ x more sites" if there are more than 3 sites */}
          {record.site_details.length > 3 && (
            <span style={{ fontSize: '14px', color: '#888' }}>
              + {record.site_details.length - 3} more sites
            </span>
          )}

          {record.site_details.length === 0 && 'No Sites Assigned'}
        </div>
      ),
    },
    {
      title: "Last Dispatched",
      dataIndex: "last_dispatched",
      key: "last_dispatched",
      width: 171,
      render: (last_dispatched) =>
        last_dispatched ? <div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <span>{moment(last_dispatched).format("MMMM Do YYYY")} </span>
          <span>{moment(last_dispatched).format("h:mm:ss a")}</span>
          </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
      sorter: (a, b) => moment(a.last_dispatched).unix() - moment(b.last_dispatched).unix(),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 171,
      render: (updated_at) =>
        updated_at ? <div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <span>{moment(updated_at).format("MMMM Do YYYY")} </span>
          <span>{moment(updated_at).format("h:mm:ss a")}</span>
          </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
      sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 200,
      render: (text, record) => (
        <Box 
          className={styles.tableButton} 
          style={{width: '100px', cursor: 'pointer'}} 
          onClick={() => handleOpenConfirmModal(record)}
        >
          Dispatch
        </Box>
      ),
    },
  ];

  // Sync Dispatch Data Button Component
  const SyncDispatchButton = () => {
    const confirmSync = () => {
      syncDispatchData(); // Call your sync function here
    };
  
    return (
      <Popconfirm
        title="Are you sure you want to sync the dispatch data?"
        onConfirm={confirmSync}
        okText="Yes"
        cancelText="No"
      >
        <div
          className={styles.tableButton}
          style={{ fontSize: '14px', padding: '8px 15px', cursor: 'pointer' }}
        >
          Sync Dispatch Data
        </div>
      </Popconfirm>
    );
  };

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px' }}>
          <div style={{ width: 'calc(100% - 10px)', marginLeft: '20px' }}>
            {/* Custom Styles */}
            <style>
              {`.locked-row {
                  background-color: #f0f0f0 !important;
                }
              `}
            </style>
            <style>
              {`.ant-table-tbody > tr:hover > td {
                  background: none !important;
                }
              `}
            </style>
            {/* Header */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '15px',
              }}
            >
              {/* Left-aligned elements: Title and Region Filter */}
              <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <span
                  style={{
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontWeight: '600',
                    fontSize: '28px',
                  }}
                >
                  Salting Route Dispatch
                </span>

                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/walkwayCrewDispatch')}
                  style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                >
                  Walkway Crew Dispatch
                </div>
                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/loaderDispatch')}
                  style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                >
                  Loader Dispatch
                </div>
                <div
                  className={styles.tableButton}
                  onClick={() => navigate('/dispatchHistory')}
                  style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer', backgroundColor: '#7cb6f7', color: 'white' }}
                >
                  Dispatch History
                </div>

                {/* Region Filter */}
                <Select
                  placeholder="Filter by Region"
                  style={{ width: 200 }}
                  value={selectedRegion}
                  onChange={(value) => setSelectedRegion(value)}
                >
                  <Option value="all">All Regions</Option> {/* "All" Option */}
                  {regions.map(region => (
                    <Option key={region} value={region}>
                      {region}
                    </Option>
                  ))}
                </Select>
              </div>

              {/* Right-aligned elements: Action Buttons */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <div
                  className={styles.tableButton}
                  onClick={() => setStormModal(true)}
                  style={{ fontSize: "14px", padding: "8px 15px", cursor: 'pointer' }}
                >
                  Create Storm
                </div>
                <SyncDispatchButton />
                <DispatchSelectedButton /> {/* New Dispatch Selected Button */}
              </div>
            </div>


            {/* Loading Spinner */}
            <Spin spinning={loading}>
              <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                <Table
                  dataSource={filteredDispatchObjects}
                  columns={columns}
                  rowKey="dispatch_id"
                  pagination={false}
                  scroll={{ y: 690 }}
                  rowSelection={rowSelection} // Enable row selection
                  expandable={{
                    expandedRowRender: (record) => (
                      <div style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>
                        {/* Staff Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                          {/* Left Half: Staff Members */}
                          <div style={{ flex: 1, marginRight: '16px' }}>
                            <h4>Staff Members</h4>
                            {record.staff_details && record.staff_details.length > 0 ? (
                              record.staff_details.map((staffMember) => (
                                <div
                                  key={staffMember.staff_id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: '#fafafa',
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconUser size={14} style={{ marginRight: '3px' }} />
                                      {staffMember.staff_name}
                                    </span>
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {staffMember.staff_position}
                                    </span> 
                                  </div>
                                  <Button
                                    onClick={() =>
                                      handleRemoveStaff(record.dispatch_id, staffMember.staff_id)
                                    }
                                    type="primary"
                                    danger
                                  >
                                    Remove Staff
                                  </Button>
                                </div>
                              ))
                            ) : (
                              <p>No staff assigned.</p>
                            )}
                          </div>

                          {/* Right Half: Add Staff */}
                          <div style={{ flex: 1 }}>
                            <h4>Add Staff</h4>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: "10px" }}>
                              <Select
                                placeholder="Select a staff member"
                                style={{ width: "100%", marginBottom: "8px" }}
                                value={selectedStaffToAdd[record.dispatch_id] || null}
                                onChange={(value) =>
                                  handleSelectStaffToAdd(record.dispatch_id, value)
                                }
                                allowClear
                              >
                                {staff.map((staffMember) => (
                                  <Option key={staffMember.staff_id} value={staffMember.staff_id}>
                                    {staffMember.name} - {staffMember.position}
                                  </Option>
                                ))}
                              </Select>
                              <Button
                                type="primary"
                                onClick={() => handleAddStaff(record.dispatch_id)}
                                disabled={!selectedStaffToAdd[record.dispatch_id]}
                                style={{ marginTop: '-7px' }}
                              >
                                Add Staff
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Divider />
                        {/* Sites Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {/* Left Half: Sites List */}
                          <div style={{ flex: 1, marginRight: '16px' }}>
                            <h4>Sites</h4>
                            {record.site_details && record.site_details.length > 0 ? (
                              record.site_details.map((site) => (
                                <div
                                  key={site.site_id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    padding: '8px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '4px',
                                    backgroundColor: '#fafafa',
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <span style={{ fontWeight: '600', fontSize: '16px', marginRight: "4px" }}>
                                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                                      {site.client_name}
                                    </span>
                                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                                      - {site.site_name}
                                    </span> 
                                  </div>
                                  <Button
                                    onClick={() => handleRemoveSite(record.dispatch_id, site.site_id)}
                                    type="primary"
                                    danger
                                  >
                                    Remove Site
                                  </Button>
                                </div>
                              ))
                            ) : (
                              <p>No sites assigned.</p>
                            )}
                          </div>
                          {/* Right Half: Add Site */}
                          <div style={{ flex: 1 }}>
                            <h4>Add Site</h4>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: "10px" }}>
                              <Select
                                placeholder="Select a site"
                                style={{ width: "100%", marginBottom: "8px" }}
                                value={selectedSiteToAdd[record.dispatch_id] || null}
                                onChange={(value) => handleSelectSiteToAdd(record.dispatch_id, value)}
                                allowClear
                              >
                                {sites.map((site) => (
                                  <Option key={site.site_id} value={site.site_id}>
                                    {site.client_name} - {site.site_name}
                                  </Option>
                                ))}
                              </Select>
                              <Button
                                type="primary"
                                onClick={() => handleAddSite(record.dispatch_id)}
                                disabled={!selectedSiteToAdd[record.dispatch_id]}
                                style={{ marginTop: '-7px' }}
                              >
                                Add Site
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
            </Spin>
          </div>
        </div>

        {/* Confirmation Modal */}
        <Modal
          title="Confirm Dispatch"
          open={showConfirmModal}
          onCancel={() => {
            setShowConfirmModal(false)
            setSelectedStorm(null)
            setSelectedDate(null)
            setSelectedTime(null)
            setDispatchMessage("")
            setSelectedDispatches([])
          }}
          onOk={handleConfirmDispatch}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure you want to dispatch the selected vehicle(s)?</p>

          {/* Dropdown for selecting a storm */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="storm-select">Select Storm:</label>
            <Select
              id="storm-select"
              placeholder="Select a storm"
              style={{ width: "100%" }}
              value={selectedStorm}
              onChange={(value) => setSelectedStorm(value)}
              allowClear
            >
              {storms.map((storm) => (
                <Option key={storm.storm_id} value={storm.storm_id}>
                  {`${storm.type.charAt(0).toUpperCase() + storm.type.slice(1)} - ${storm.severity.charAt(0).toUpperCase() + storm.severity.slice(1)} (${new Date(storm.date_of_storm).toLocaleDateString()})`}
                </Option>
              ))}
            </Select>
          </div>

          {/* Date Picker for selecting the dispatch date */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="date-picker" style={{ display: "block", marginBottom: 8 }}>
              Select Dispatch Date:
            </label>
            <DatePicker
              id="date-picker"
              style={{ width: "100%" }}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </div>

          {/* Time Picker for selecting the dispatch time */}
          <div style={{ marginBottom: 16 }}>
            <label htmlFor="time-picker" style={{ display: "block", marginBottom: 8 }}>
              Select Dispatch Time:
            </label>
            <TimePicker
              id="time-picker"
              style={{ width: "100%" }}
              value={selectedTime}
              onChange={(time) => setSelectedTime(time)}
              format="HH:mm" // Ensure the time is in 24-hour format
            />
          </div>
          <label style={{ display: "block", marginBottom: 8 }}>
            Optional Message:
          </label>
          <Input.TextArea
            placeholder="Optional message (max 200 characters)"
            maxLength={200}
            value={dispatchMessage}
            onChange={(e) => setDispatchMessage(e.target.value)}
          />
        </Modal>

        {/* Modal to Create a Storm */}
        <Modal
          title="Create Storm"
          visible={stormModal}
          onCancel={() => {
            setStormModal(false)
            setDateOfStorm(null)
            setStormType("")
            setStormSeverity("")
          }}
          onOk={createStorm}
          okText="Create"
          cancelText="Cancel"
        >
          <Form layout="vertical">
            {/* Storm Type Dropdown */}
            <Form.Item label="Storm Type">
              <Select
                placeholder="Select storm type"
                value={stormType}
                onChange={(value) => setStormType(value)}
              >
                <Option value="snow">Snow</Option>
                <Option value="freezing_rain">Freezing Rain</Option>
                <Option value="salt">Salt</Option>
              </Select>
            </Form.Item>

            {/* Storm Severity Dropdown */}
            <Form.Item label="Storm Severity">
              <Select
                placeholder="Select storm severity"
                value={stormSeverity}
                onChange={(value) => setStormSeverity(value)}
              >
                <Option value="light">Light</Option>
                <Option value="medium">Medium</Option>
                <Option value="heavy">Heavy</Option>
              </Select>
            </Form.Item>

            {/* Date of Storm (Calendar Input) */}
            <Form.Item label="Date of Storm">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select date of storm"
                value={dateOfStorm}
                onChange={(date) => setDateOfStorm(date)}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Form>
        </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default SaltingRoutesDispatchPage;
