import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Spin, message, Input, Modal, Form, Checkbox, ConfigProvider } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import api from '../authentication/axiosInstance';
import { Box } from '@mui/material';
import useAllocationDrop from '../hooks/useAllocationDrop';
import { LinearProgress } from '@mui/material';
import { saveAs } from 'file-saver';
import { IconBuildingFactory2, IconEdit, IconFile, IconInfoCircle, IconLock, IconLockOpen, IconPlus, IconTool, IconTractor, IconUser } from '@tabler/icons-react';
import styles from './PlowRouteAllocationPage.module.css';

// tech debt, at first this page was only supposed to allocate condos to plow routes, but the scope has expanded to include regular sites, so regular sites are referred to as loadersites

const { confirm } = Modal;

const Card = styled.div`
  background-color: #f0f0f0;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 300px;
  max-height: 650px;
  overflow-y: scroll;
`;

const ToggleButtonGroup = styled.div`
  display: flex;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  overflow: hidden;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 5px 15px;
  background-color: ${({ isSelected }) => (isSelected ? '#e6f7ff' : '#fff')};
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#034832' : '#000')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e6f7ff;
    color: #034832;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
`;

const AllocateCell = ({ record, allocateItem }) => {
  const { isOver, drop } = useAllocationDrop(record, () => {}, () => {}, () => {}, () => {}, allocateItem);

  return (
    <div ref={drop} style={{ width: '80%', height: 140, marginTop: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', border: isOver ? "2px dashed #034832" : "2px dashed #c9c9c9", borderRadius: '5px', color: isOver ? "#034832" : "#c9c9c9", fontSize: '16px', fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
      Drag Here to Allocate
    </div>
  );
};

const SortableItem = ({ site, index, moveSite, routeId, deallocateItem }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'site',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSite(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'site',
    item: { type: 'site', id: site.plowRouteSite.plowRouteSite_id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '8px',
        marginBottom: '4px',
        backgroundColor: '#fafafa',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>
        <strong>{index + 1}. </strong>
        {site.plowRouteSite.plowRouteSite_name} - {site.plowRouteSite.plowRouteSite_address} - {site.plowRouteSite_region} - lots: {site.plowRouteSite.parking_lots_square_footage} sq. ft. - walkways: {site.plowRouteSite.walkways_square_footage} sq. ft.
      </span>
      <Button onClick={() => deallocateItem(routeId, site.plowRouteSite.plowRouteSite_id)} size="small">Deallocate</Button>
    </li>
  );
};

const SortableList = ({ sites, moveSite, routeId, deallocateItem }) => {
  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {sites.map((site, index) => (
        <SortableItem 
          key={site.plowRouteSite.plowRouteSite_id} 
          site={site} 
          index={index} 
          moveSite={moveSite} 
          routeId={routeId}
          deallocateItem={deallocateItem}
        />
      ))}
      {sites.length === 0 && (
        <li style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
          No Condos allocated
        </li>
      )}
    </ul>
  );
};

const generatePlowRoutePDF = async () => {
  try {
    const response = await api.get('/plowRoute/plowroutes/pdf/', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'plow_route_allocation_report.pdf');
  } catch (error) {
    console.error('Failed to generate PDF:', error);
    message.error('Failed to generate PDF report');
  }
};

const PlowRouteAllocationPage = () => {
  const [plowRoutes, setPlowRoutes] = useState([]);
  const [sites, setSites] = useState([]);
  const [loaderSites, setLoaderSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [plowVehicles, setPlowVehicles] = useState([]);
  const [plowRouteEquipment, setPlowRouteEquipment] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allocationMode, setAllocationMode] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [selectedList, setSelectedList] = useState('sites');
  const [selectedStaffPosition, setSelectedStaffPosition] = useState('All Walkway Staff');

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentPlowRoute, setCurrentPlowRoute] = useState(null);
  const [newPlowRouteName, setNewPlowRouteName] = useState('');
  const [newRequiredNumStaff, setNewRequiredNumStaff] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedPlowRoute, setSelectedPlowRoute] = useState(null);
  const [plowRouteName, setplowRouteName] = useState('');
  const [region, setRegion] = useState('');
  const [requiredNumStaff, setRequiredNumStaff] = useState(0);

  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [unlockRouteId, setUnlockRouteId] = useState(null);
  const [password, setPassword] = useState("");

  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [filteredLoaderSites, setFilteredLoaderSites] = useState([]);
  const [searchTextStaff, setSearchTextStaff] = useState("");
  const [searchTextSite, setSearchTextSite] = useState("");
  const [searchTextVehicle, setSearchTextVehicle] = useState("");
  const [searchTextEquipment, setSearchTextEquipment] = useState("");
  const [searchTextLoaderSite, setSearchTextLoaderSite] = useState("");

  const DragCard = ({ item, allocationMode }) => {
    let itemId;
    let itemType;
  
    let isAllocated;
    if (selectedList === 'sites') {
      itemId = item.plowRouteSite_id;
      itemType = 'sites';
      isAllocated = item.assigned_plow_route_bool;
    } else if (selectedList === 'staff') {
      itemId = item.staff_id;
      itemType = 'staff';
      isAllocated = item.assigned_walkway_crew_bool || item.assigned_salting_route_bool || item.assigned_plow_route_bool || item.assigned_bool;
    } else if (selectedList === 'vehicles') {
      itemId = item.vehicle_id;
      itemType = 'vehicle';
      isAllocated = item.assigned_walkway_crew_bool || item.assigned_salting_route_bool || item.assigned_plow_route_bool || ((item.allocated_sites && item.allocated_sites.length > 0));
    } else if (selectedList === 'equipment') {
      itemId = item.equipment_id;
      itemType = 'equipment';
      isAllocated = item.assigned_walkway_crew_bool || item.assigned_plow_route_bool;
    } else if (selectedList === 'loaderSites') {
      itemId = item.site_id;
      itemType = 'loaderSites';
      isAllocated = item.assigned_plow_route_bool;
    }

    // Update the canDrag logic to allow dragging of already allocated sites
    let canDrag;
    if (itemType === 'sites' || itemType === 'loaderSites') {
      canDrag = allocationMode
    }
    else {
      canDrag = allocationMode && !isAllocated;
    }
  
    const [{ isDragging }, drag] = useDrag({
      type: itemType,
      item: { id: itemId, type: itemType },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: canDrag,
    });
  
    const backgroundColor = isAllocated ? '#E5F2EC' : '#f0f0f0';
    const borderColor = (selectedList === 'sites' || selectedList == 'loaderSites') && item.assigned_plow_route_bool ? '#cdaf5e' : 'transparent';
  
    // Fetch the allocation count for sites
    // const allocationCount = item.assigned_walkway_crew_count || 0;
  
    return (
      <Card
        ref={canDrag ? drag : null}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: backgroundColor,
          cursor: canDrag ? 'grab' : 'pointer',
          borderColor: borderColor,
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
      >
        <Box style={{ width: '100%', padding: '10px' }}>
          {selectedList === 'sites' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: '550', fontSize: '16px' }}><IconBuildingFactory2 size={14} style={{ marginRight: '3px' }}/>{item.plowRouteSite_name}</span>
                {/* <span style={{ fontSize: '15px', }}>({allocationCount} crews)</span> */}
              </div>
              <span style={{ fontSize: '14px' }}>{item.plowRouteSite_address}</span>       
            </div>
          )}
          {selectedList === 'loaderSites' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: '550', fontSize: '16px' }}><IconBuildingFactory2 size={14} style={{ marginRight: '3px' }}/>{item.site_name}</span>
                {/* <span style={{ fontSize: '15px', }}>({allocationCount} crews)</span> */}
              </div>
              <span style={{ fontSize: '14px' }}>{item.client_name}</span>       
            </div>
          )}
          {selectedList === 'staff' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconUser size={14} style={{ marginRight: '3px' }} />{item.name}</span>
              <span style={{ fontSize: '14px', marginBottom: 4 }}>{item.position}</span>       
              <span style={{ fontSize: '14px' }}>City: {item.city ? item.city : 'Unspecified'}</span>       
              <span style={{ fontSize: '14px' }}>Rating: {item.staff_rating ? item.staff_rating : 'Unspecified'}</span>  
              <span style={{ fontSize: '14px' }}>Overall Rating: {item.staff_overall_rating ? item.staff_overall_rating : 'Unspecified'}</span>  
            </div>
          )}
          {selectedList === 'vehicles' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}>
                <IconTractor size={14} style={{ marginRight: '3px' }} />
                {item.vehicle_name}
              </span>
              <span style={{ fontSize: '14px' }}>
                {item.vehicle_type}
                {item.vehicle_type === 'Wheel Loader' && ` - ${item.vehicle_subtype}`}
              </span>       
            </div>
          )}
          {selectedList === 'equipment' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTool size={14} style={{ marginRight: '3px' }} />{item.equipment_name}</span>
              <span style={{ fontSize: '14px' }}>{item.equipment_type_name}</span>       
            </div>
          )}
        </Box>
      </Card>
    );
  };

  const SortableSiteList = ({ sites, routeId, fetchPlowRoutes, deallocateItem }) => {
    const [siteList, setSiteList] = useState(sites);
  
    useEffect(() => {
      setSiteList(sites);
    }, [sites]);
  
    const moveSite = useCallback((dragIndex, hoverIndex) => {
      const updatedSites = [...siteList];
      const [removed] = updatedSites.splice(dragIndex, 1);
      updatedSites.splice(hoverIndex, 0, removed);
      setSiteList(updatedSites);
    }, [siteList]);
  
    const handleReorder = async () => {
      const site_order = siteList.map(site => site.plowRouteSite.plowRouteSite_id);
      try {
        await api.post(`/plowRoute/plowroutes/${routeId}/reorder_sites/`, { site_order });
        message.success('Site order updated successfully');
        fetchPlowRoutes();
        if (isDetailsModalVisible){
          handleDetailsModalCancel();
        }
      } catch (error) {
        console.error('Failed to reorder sites:', error.response?.data?.message);
        message.error(error.response?.data?.message || 'Failed to reorder sites');
      }
    };
  
    return (
      <Box style={{ width: '100%' }}>
        <DndProvider backend={HTML5Backend}>
          <SortableList 
            sites={siteList} 
            moveSite={moveSite} 
            routeId={routeId}
            deallocateItem={deallocateItem}
          />
        </DndProvider>
        <Button type="primary" disabled={sites.length === 0} onClick={handleReorder} style={{ marginTop: '10px', marginLeft: 'auto', boxShadow: 'none' }}>
          Save Order
        </Button>
      </Box>
    );
  };
  

  const finalizePlowRouteAllocation = async (routeId) => {
    try {
      await api.post(`/plowRoute/plowroutes/${routeId}/finalize_plow_route_allocation/`);
      message.success("Plow Route finalized successfully");
      if (expandedRowKey) {
        const expandedRow = plowRoutes.find(
          (route) => route.plowRoute_id === expandedRowKey
        );
        if (expandedRow && expandedRow.finalized_bool) {
          setExpandedRowKey(null);
          setAllocationMode(false);
        }
      }
      fetchPlowRoutes();
    } catch (error) {
      console.error("Failed to finalize Plow Route allocation:", error);
      message.error("Failed to finalize Plow Route allocation");
    }
  };

  const UnlockPlowRouteAllocation = async (routeId, password) => {
    try {
      await api.post(`/plowRoute/plowroutes/${routeId}/unlock_plow_route_allocation/`, {
        password,
      });
      message.success("Plow Route allocation unlocked successfully");
      fetchPlowRoutes();
    } catch (error) {
      console.error("Failed to unlock Plow Route allocation:", error);
      message.error("Failed to unlock Plow Route allocation");
    }
  };

  useEffect(() => {
    fetchSites();
    fetchStaff();
    fetchPlowRoutes();
    fetchPlowRouteVehicles();
    fetchPlowRouteEquipment();
    fetchLoaderSites();
  }, []);

  useEffect(() => {
    updateFilteredItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, sites, staff, plowVehicles, selectedRegion, selectedStaffPosition, plowRouteEquipment, loaderSites]);

  const fetchPlowRoutes = async () => {
    setLoading(true);
    try {
      const response = await api.get('/plowRoute/plowroutes/');
      setPlowRoutes(response.data);
    } catch (error) {
      console.error('Failed to fetch plow routes:', error);
      message.error('Failed to fetch plow routes');
    } finally {
      setLoading(false);
    }
  }

  const fetchLoaderSites = async () => {
    setLoading(true);
    try {
      const response = await api.get('/sites/');
      setLoaderSites(response.data);
      setFilteredLoaderSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    } finally {
      setLoading(false);
    }
  };

  const fetchSites = async () => {
    setLoading(true);
    try {
      const response = await api.get('/plowRoute/plowroutesites/');
      setSites(response.data);
      setFilteredSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchStaff = async () => {
    setLoading(true);
    try {
      const response = await api.get('/staff/plow_route_staff/');
      setStaff(response.data);
      setFilteredStaff(response.data);
    } catch (error) {
      console.error('Failed to fetch staff:', error);
      message.error('Failed to fetch staff');
    } finally {
      setLoading(false);
    }
  };

  const fetchPlowRouteVehicles = async () => {
    setLoading(true);
    try {
      const response = await api.get('/vehicles/plow_route_vehicles/');
      setPlowVehicles(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error('Failed to fetch crew vehicles:', error);
      message.error('Failed to fetch crew vehicles');
    } finally {
      setLoading(false);
    }
  };

  const fetchPlowRouteEquipment = async () => {
    setLoading(true);
    try {
      const response = await api.get('/equipment/plow_route_equipment/');
      setPlowRouteEquipment(response.data);
      setFilteredEquipment(response.data);
    } catch (error) {
      console.error('Failed to fetch plow route equipment:', error);
      message.error('Failed to fetch plow route equipment');
    } finally {
      setLoading(false);
    }
  };

  const updateFilteredItems = () => {
    if (selectedList === 'sites') {
      const filtered = selectedRegion === 'all' ? sites : sites.filter((site) => site.region === selectedRegion);
      setFilteredItems(filtered);
    } else if (selectedList === 'staff') {
      let filtered = staff;
      if (selectedStaffPosition !== 'All Walkway Staff') {
        filtered = staff.filter((member) => member.position === selectedStaffPosition);
      }
      setFilteredItems(filtered);
    } else if (selectedList === 'vehicles') {
      setFilteredItems(plowVehicles);
    } else if (selectedList === 'equipment') {
      setFilteredItems(plowRouteEquipment);
    } else if (selectedList === 'loaderSites') {
      setFilteredItems(loaderSites);
    }
  };

  const allocateItem = async (routeId, itemId) => {
    try {
      await api.post(`/plowRoute/plowroutes/${routeId}/allocate_item/`, { item_id: itemId, item_type: selectedList });
      message.success('Allocated successfully');
      fetchPlowRoutes();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchPlowRouteVehicles();
      if (selectedList === 'equipment') fetchPlowRouteEquipment();
      if (selectedList === 'loaderSites') fetchLoaderSites();
    } catch (error) {
      console.error('Failed to allocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to allocate');
    }
  };

  const deallocateItem = async (routeId, itemId) => {
    try {
      await api.post(`/plowRoute/plowroutes/${routeId}/deallocate_item/`, {
        item_id: itemId,
        item_type: selectedList,
      });
      message.success('Deallocated successfully');
      fetchPlowRoutes();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchPlowRouteVehicles();
      if (selectedList === 'equipment') fetchPlowRouteEquipment();
      if (selectedList === 'loaderSites') fetchLoaderSites();
    } catch (error) {
      console.error('Failed to deallocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to deallocate');
    }
  };

  const handleUpdatePlowRoute = async () => {
    if (!newPlowRouteName.trim()) {
      message.error('Plow Route Name cannot be empty.');
      return;
    }
    if (
      newRequiredNumStaff === '' ||
      isNaN(newRequiredNumStaff) ||
      Number(newRequiredNumStaff) < 0
    ) {
      message.error('Required Number of Staff must be a non-negative number.');
      return;
    }
  
    try {
      await api.patch(`/plowRoute/plowroutes/${currentPlowRoute.plowRoute_id}/`, {
        plowRoute_name: newPlowRouteName,
        required_num_staff: Number(newRequiredNumStaff),
      });
      message.success('Plow Route updated successfully');
      fetchPlowRoutes();
      setIsEditModalVisible(false);
      setCurrentPlowRoute(null);
      setNewPlowRouteName('');
      setNewRequiredNumStaff(0);
    } catch (error) {
      if (error.response && error.response.data) {
        console.error('Failed to update Plow Route:', error.response.data);
      } else {
        console.error('Failed to update Plow Route:', error);
        message.error('Failed to update Plow Route');
      }
    }
  };

  const toggleList = (list) => {
    setSelectedList(list);
    if (list === 'sites') {
      setSelectedRegion('all');
    } else if (list === 'staff') {
      setSelectedStaffPosition('All Walkway Staff');
    }
  };

  const getButtonStyle = (list) => ({
    backgroundColor: selectedList === list ? '#e6f2ed' : '#fff',
    color: selectedList === list ? '#034832' : '#000',
    fontWeight: '550',
    padding: '8px 6px',
    fontSize: '13px',
  });

  const handleCreatePlowRoute = async () => {
    try {
      await api.post('/plowRoute/plowroutes/generate_plow_route/', {
        plowRoute_name: plowRouteName,
        plowRoute_region: region,
        required_num_staff: requiredNumStaff,
      });
      message.success('Plow Route created successfully');
      fetchPlowRoutes();
      setIsModalVisible(false);
      setplowRouteName('');
      setRegion('');
      setRequiredNumStaff(0);
    } catch (error) {
      console.error('Failed to create Plow Route:', error);
      message.error('Failed to create Plow Route');
    }
  };

  const deletePlowRoute = async (routeId) => {
    try {
      const response = await api.delete(`/plowRoute/plowroutes/${routeId}/delete_plow_route/`);
      message.success(response.data.message);
      fetchPlowRoutes();
    } catch (error) {
      console.error('Failed to delete Plow Route:', error.response?.data?.message || error.message);
      message.error(error.response?.data?.message || 'Failed to delete Plow Route');
    }
  };

  const showDeleteConfirm = (routeId) => {
    confirm({
      title: 'Are you sure you want to delete this Plow Route?',
      content: 'This action cannot be undone, and you must deallocate all items before deletion.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletePlowRoute(routeId);
      },
      onCancel() {},
    });
  };

  const showDetailsModal = (route) => {
    setSelectedPlowRoute(route);
    setIsDetailsModalVisible(true);
  };

  const showEditModal = (route) => {
    setCurrentPlowRoute(route);
    setNewPlowRouteName(route.plowRoute_name);
    setNewRequiredNumStaff(route.required_num_staff);
    setIsEditModalVisible(true);
  };

  const handleDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setSelectedPlowRoute(null);
  };

  const PlowRouteDetailsModal = ({ visible, onCancel, route }) => {
    if (!route) return null;

    return (
      <Modal
        title={<span style={{ fontSize: '24px', fontWeight: '600' }}>{route.plowRoute_name}</span>}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <p><strong>Region:</strong> {route.plowRoute_region}</p>
        <p><strong>Assigned Vehicle:</strong> {route.assigned_vehicle_bool ? 'Yes' : 'No'}</p>
        <p><strong>Parking Lots Square Footage:</strong> {route.parking_lots_square_footage}</p>
        <p><strong>Walkways Square Footage:</strong> {route.walkways_square_footage}</p>
        <p><strong>Number of Staff:</strong> {route.number_of_staff}</p>
        <p><strong>Required Number of Staff:</strong> {route.required_num_staff}</p>
        <p><strong>Assigned Sites:</strong></p>
        <SortableSiteList sites={route.site_allocations} routeId={route.plowRoute_id} fetchPlowRoutes={fetchPlowRoutes} deallocateItem={deallocateItem}/>
        <Button
          type="default"
          danger
          onClick={() => {
              showDeleteConfirm(route.plowRoute_id)
            }
          }
          style={{ marginTop: '5px' }}
        >
          Delete Route
        </Button>
      </Modal>
    );
  };

  const expandedRowRender = (record) => {
    const getAssignedLabel = () => {
      switch (selectedList) {
        case "sites":
          return "Assigned Condos";
        case "staff":
          return "Assigned Staff";
        case "vehicles":
          return "Assigned Vehicles";
        case "equipment":
          return "Assigned Equipment";
          case "loaderSites":
            return "Assigned Sites";
        default:
          return "Assigned Items";
      }
    };

    const renderAssignedItems = () => {
      switch (selectedList) {
        case "sites":
          return (
            <SortableSiteList 
              sites={record.site_allocations} 
              routeId={record.plowRoute_id}
              fetchPlowRoutes={fetchPlowRoutes}
              deallocateItem={deallocateItem}
            />
          );
        case "equipment":
          return (
            <ul>
            {record.equipment_allocations.map((equipment) => (
              <li key={equipment.equipment.equipment_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {equipment.equipment_name} - {equipment.equipment_type_name}
                <Button
                  onClick={() => deallocateItem(record.plowRoute_id, equipment.equipment.equipment_id)}
                >
                  Deallocate
                </Button>
              </li>
            ))}
            {record.equipment_allocations.length === 0 && (
              <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                No Equipment allocated
              </div>
            )}
          </ul>
          );
        case "loaderSites":
          return (
            <ul>
            {record.loader_site_allocations.map((loaderSite) => (
              <li key={loaderSite.site.site_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {loaderSite.client_name} - {loaderSite.site_name} - lots: {loaderSite.site.parking_lots_sqft} sq. ft. - walkways: {loaderSite.site.walkway_sqft} sq. ft.
                <Button
                  onClick={() => deallocateItem(record.plowRoute_id, loaderSite.site.site_id)}
                >
                  Deallocate
                </Button>
              </li>
            ))}
            {record.loader_site_allocations.length === 0 && (
              <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                No Sites allocated
              </div>
            )}
          </ul>
          );
        case "staff":
          return (
            <ul>
              {record.staff_allocations.map((staff) => (
                <li key={staff.staff.staff_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {staff.staff_name} - {staff.staff_position}, Rating: {staff.staff.staff_rating ? staff.staff.staff_rating : "Unspecified"}
                  <Button
                    onClick={() => deallocateItem(record.plowRoute_id, staff.staff.staff_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.staff_allocations.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Staff allocated
                </div>
              )}
            </ul>
          );
        case "vehicles":
          return (
            <ul>
              {record.vehicle_allocations.map((vehicle) => (
                <li key={vehicle.vehicle.vehicle_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {vehicle.vehicle_type} - {vehicle.vehicle_name}
                  <Button
                    onClick={() => deallocateItem(record.plowRoute_id, vehicle.vehicle.vehicle_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.vehicle_allocations.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Vehicles allocated
                </div>
              )}
            </ul>
          );
        default:
          return null;
      }
    };

    return (
      <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', marginRight: 10 }}>
          <AllocateCell
            record={record}
            allocateItem={allocateItem}
          />
        </Box>
        <Box style={{ flex: 1 }}>
          <p style={{ fontWeight: 'bold' }}>{getAssignedLabel()}:</p>
          {renderAssignedItems()}
        </Box>
      </Box>
    );
  };

  const onTableExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.plowRoute_id : null);
    setAllocationMode(expanded);
  };

  const handlePlowRouteDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setSelectedPlowRoute(null);
  };

  const handleSearchStaff = (value) => {
    setSearchTextStaff(value); 
    const filtered = staff.filter((member) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStaff(filtered);
  };

  const handleSearchSite = (value) => {
    setSearchTextSite(value); 
    const filtered = sites.filter((site) =>
      site.plowRouteSite_address.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleSearchVehicle = (value) => {
    setSearchTextVehicle(value); 
    const filtered = plowVehicles.filter((vehicle) =>
      vehicle.vehicle_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  const handleSearchEquipment = (value) => {
    setSearchTextEquipment(value); 
    const filtered = plowRouteEquipment.filter((equipment) =>
      equipment.equipment_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredEquipment(filtered);
  };

  const handleSearchLoaderSite = (value) => {
    setSearchTextLoaderSite(value);
    const filtered = loaderSites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLoaderSites(filtered);
  };
  
  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832" } }}>
      <DndProvider backend={HTML5Backend}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
              <span style={{fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px'}}>
                Plow Routes
              </span>
              
              <ToggleButtonGroup>
                <ToggleButton onClick={() => toggleList('sites')} isSelected={selectedList === 'sites'} style={getButtonStyle('sites')}>
                  Condos
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('staff')} isSelected={selectedList === 'staff'} style={getButtonStyle('staff')}>
                  Staff
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('vehicles')} isSelected={selectedList === 'vehicles'} style={getButtonStyle('vehicles')}>
                  Vehicles
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('equipment')} isSelected={selectedList === 'equipment'} style={getButtonStyle('equipment')}>
                  Equipment
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('loaderSites')} isSelected={selectedList === 'loaderSites'} style={getButtonStyle('loaderSites')}>
                  Sites
                </ToggleButton>
              </ToggleButtonGroup>

              {/* {selectedList === 'sites' && (
                <Select
                  placeholder="Select Region"
                  value={selectedRegion}
                  style={{ width: '100%' }}
                  onChange={handleRegionSelect}
                >
                  <Option value="all">All Regions</Option>
                  {siteRegions.map((region) => (
                    <Option key={region} value={region}>
                      {region.charAt(0).toUpperCase() + region.slice(1)}
                    </Option>
                  ))}
                </Select>
              )} */}

              {/* {selectedList === 'staff' && (
                <Select
                  placeholder="Select Position"
                  value={selectedStaffPosition}
                  style={{ width: '100%' }}
                  onChange={handleStaffPositionSelect}
                >
                  <Option value="All Walkway Staff">All Walkway Staff</Option>
                  <Option value="Walkway Technician">Walkway Technician</Option>
                  <Option value="Walkway Vehicle Operator">Walkway Vehicle Operator</Option>
                </Select>
              )} */}

            {selectedList === 'sites' && (
              <div style={{width:"100%"}}>
                <Input
                  style={{ width: '100%', marginTop:10}}
                  value={searchTextSite}
                  onChange={(e) => handleSearchSite(e.target.value)}
                  placeholder="Search condo"
                />
              </div>
            )}

            {selectedList === 'staff' && (
              <div style={{width:"100%"}}>
                <Input
                  style={{ width: '100%', marginTop:10}}
                  value={searchTextStaff}
                  onChange={(e) => handleSearchStaff(e.target.value)}  
                  placeholder="Search staff"
                />
              </div>
            )}

            {selectedList === 'vehicles' && (
              <div style={{width:"100%"}}>
                <Input
                  style={{ width: '100%', marginTop:10}}
                  value={searchTextVehicle}
                  onChange={(e) => handleSearchVehicle(e.target.value)}  
                  placeholder="Search vehicle"
                />
              </div>
            )}

            {selectedList === 'equipment' && (
              <div style={{width:"100%"}}>
                <Input
                  style={{ width: '100%', marginTop:10}}
                  value={searchTextEquipment}
                  onChange={(e) => handleSearchEquipment(e.target.value)}  
                  placeholder="Search equipment"
                />
              </div>
            )}

            {selectedList === 'loaderSites' && (
              <div style={{width:"100%"}}>
                <Input
                  style={{ width: '100%', marginTop: 10 }}
                  value={searchTextLoaderSite}
                  onChange={(e) => handleSearchLoaderSite(e.target.value)}
                  placeholder="Search sites"
                />
              </div>
            )}

            <ListContainer>
              {selectedList === 'staff' && filteredStaff.map((staff) => (
                <DragCard key={staff.staff_id} item={staff} allocationMode={allocationMode} />
              ))}
              {selectedList === 'sites' && filteredSites.map((site) => (
                <DragCard key={site.plowRouteSite_id} item={site} allocationMode={allocationMode} /> //tech debt this is actually a condo
              ))}
              {selectedList === 'vehicles' && filteredVehicles.map((vehicle) => (
                <DragCard key={vehicle.vehicle_id} item={vehicle} allocationMode={allocationMode} />
              ))}
              {selectedList === 'equipment' && filteredEquipment.map((equipment) => (
                <DragCard key={equipment.equipment_id} item={equipment} allocationMode={allocationMode} />
              ))}
              {selectedList === 'loaderSites' && filteredLoaderSites.map((loaderSite) => (
                <DragCard key={loaderSite.site_id} item={loaderSite} allocationMode={allocationMode} /> //tech debt, these are actually regular sites
              ))}
            </ListContainer>
            </div>
            <div style={{ width: 'calc(100% - 320px)', marginLeft: '20px'}}>
              <style>
                {`.locked-row {
                    background-color: #f0f0f0 !important;
                  }
                `}
              </style>
              <style>
                {`.ant-table-tbody > tr:hover > td {
                    background: none !important;
                  }
              `}
              </style>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', marginBottom: '15px' }}>
                <div className={styles.tableButton} onClick={() => setIsModalVisible(true)} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconPlus size={16}/>
                  Create Plow Route
                </div>
                <div className={styles.tableButton} onClick={generatePlowRoutePDF} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconFile size={16}/>
                  Generate PDF Report
                </div>
              </div>
              <Spin spinning={loading}>
                <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                  <Table
                    dataSource={plowRoutes}
                    columns={[
                      {
                        title: 'Route Name',
                        dataIndex: 'plowRoute_name',
                        key: 'plowRoute_name',
                      },
                      {
                        title: 'Region',
                        dataIndex: 'plowRoute_region',
                        key: 'plowRoute_region',
                      },
                      {
                        title: 'Assigned Vehicle',
                        dataIndex: 'assigned_vehicle_bool',
                        key: 'assigned_vehicle_bool',
                        render: (text, record) => (
                          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Checkbox
                              checked={record.assigned_vehicle_bool}
                              disabled
                            />
                          </Box>
                        ),
                      },
                      {
                        title: 'Lots SQFT',
                        dataIndex: 'parking_lots_square_footage',
                        key: 'parking_lots_square_footage',
                        render: (text, record) => (
                          <span>{text.toLocaleString()} sq. ft.</span>
                        ),
                      },
                      {
                        title: 'Walkways SQFT',
                        dataIndex: 'walkways_square_footage',
                        key: 'walkways_square_footage',
                        render: (text, record) => (
                          <span>{text.toLocaleString()} sq. ft.</span>
                        )
                      },
                      // {
                      //   title: 'Staff Allocated',
                      //   dataIndex: 'number_of_staff',
                      //   key: 'number_of_staff',
                      // },
                      // {
                      //   title: 'Staff Required',
                      //   dataIndex: 'required_num_staff',
                      //   key: 'required_num_staff',
                      //   width: 100,
                      // },
                      {
                        title: 'Staff Allocation Percentage',
                        key: 'staff_allocation_percentage',
                        render: (text, record) => {
                          const { number_of_staff, required_num_staff } = record;
                      
                          // Check if required_num_staff is greater than 0 to avoid division by zero
                          if (required_num_staff && required_num_staff > 0) {
                            const percentage = (number_of_staff / required_num_staff) * 100;
                      
                            // Cap the percentage at 100% for the progress bar
                            const displayPercentage = percentage > 100 ? 100 : percentage;
                      
                            return (
                              <span>
                                {/* Display the percentage with one decimal place */}
                                {number_of_staff} allocated / {required_num_staff} required {' '}
                                <Box style={{ display: "flex", alignItems: "center", width: 250, marginTop: 8 }}>
                                  <LinearProgress 
                                    style={{ flex: 9, marginRight: 8 }} 
                                    variant="determinate" 
                                    value={displayPercentage}
                                  />
                                  <Box style={{ flex: 1 }}>
                                    {percentage.toFixed(1)}%
                                  </Box>
                                </Box>
                                {/* Show 'Over Allocated' if percentage exceeds 100% */}
                                {percentage > 100 && (
                                  <Box style={{ color: 'Orange', marginTop: 4 }}>
                                    Over Allocated
                                  </Box>
                                )}
                              </span>
                            );
                          } else {
                            // Display 'N/A' if required_num_staff is 0 or not provided
                            return <span>{number_of_staff} allocated / {required_num_staff} required {' '}</span>;
                          }
                        },
                      },
                      {
                        title: "Locked",
                        key: "locked",
                        width: 100,
                        render: (_, record) => (
                          <Box>
                            {record.finalized_bool ? (
                              <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                                <IconLock size={18} stroke={2} />
                              </div>
                              
                            ) : (
                              <div style={{ backgroundColor: '#f5f5f5', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ebeaea', borderRadius: '4px', color: "#034832" }}>
                                <IconLockOpen size={18} stroke={2} />
                              </div>
                            )}
                          </Box>
                        ),
                      },
                      {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => (
                          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', gap: '5px' }}>
                            <div
                              className={styles.tableButtonGold}
                              style={{ width: '135px' }}
                              onClick={() => {
                                if (record.finalized_bool) {
                                  setUnlockRouteId(record.plowRoute_id);
                                  setPasswordModalVisible(true);
                                } else {
                                  finalizePlowRouteAllocation(record.plowRoute_id);
                                }
                              }}
                            >
                              {record.finalized_bool ? "Unlock Finalization" : "Finalize Allocation"}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
                              <div
                                onClick={() => showEditModal(record)}
                                className={styles.tableButton}
                              >
                                  <IconEdit size={14} /> Edit
                              </div>
                              <div
                                onClick={() => showDetailsModal(record)}
                                className={styles.tableButton}
                              >
                                <IconInfoCircle size={14} />
                                Details
                              </div>
                            </div>
                            
                            {/* <Button
                              type="primary"
                              danger
                              onClick={() => {
                                  showDeleteConfirm(record.plowRoute_id)
                                }
                              }
                            >
                              Delete
                            </Button> */}
                          </Box>
                        ),
                      },
                    ]}
                    rowKey="plowRoute_id"
                    pagination={false}
                    rowClassName={(record) =>
                      record.finalized_bool ? 'locked-row' : ''
                    }
                    expandable={{
                      expandedRowRender,
                      expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                      onExpand: onTableExpand,
                      rowExpandable: (record) => !record.finalized_bool,
                    }}
                  />
                </div> 
              </Spin>
            </div>
          </div>

          <Modal
            title="Create Plow Route"
            visible={isModalVisible}
            onCancel={() => {
                setIsModalVisible(false)
                setplowRouteName('');
                setRegion('');
                setRequiredNumStaff(0);
              }
            }
            onOk={handleCreatePlowRoute}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="Plow Route Name" required>
                <Input
                  value={plowRouteName}
                  onChange={(e) => setplowRouteName(e.target.value)}
                  placeholder="Enter Plow Route Name"
                />
              </Form.Item>
              <Form.Item label="Region" required style={{ marginTop: '-15px' }}>
                <Input
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder="Enter Region"
                />
              </Form.Item>
              <Form.Item label="Required Number of Staff" required style={{ marginTop: '-15px' }}>
                <Input
                  value={requiredNumStaff}
                  onChange={(e) => setRequiredNumStaff(e.target.value)}
                  placeholder="Enter # of Staff"
                />
              </Form.Item>
            </Form>
          </Modal>

          <PlowRouteDetailsModal
            visible={isDetailsModalVisible}
            onCancel={handlePlowRouteDetailsModalCancel}
            route={selectedPlowRoute}
          />
          <Modal
            title="Edit Plow Route"
            visible={isEditModalVisible}
            onCancel={() => setIsEditModalVisible(false)}
            onOk={handleUpdatePlowRoute}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="Plow Route Name" required>
                <Input
                  value={newPlowRouteName}
                  onChange={(e) => setNewPlowRouteName(e.target.value)}
                  placeholder="Enter Plow Route Name"
                />
              </Form.Item>
              <Form.Item label="Required Number of Staff" required style={{ marginTop: '-15px' }}>
                <Input
                  type="number"
                  min="0"
                  value={newRequiredNumStaff}
                  onChange={(e) => setNewRequiredNumStaff(e.target.value)}
                  placeholder="Enter Required Number of Staff"
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Enter Password to Unlock"
            open={passwordModalVisible}
            onCancel={() => setPasswordModalVisible(false)}
            onOk={async () => {
              if (password) {
                await UnlockPlowRouteAllocation(unlockRouteId, password);
                setPasswordModalVisible(false);
                setPassword("");
              } else {
                message.error("Please enter a password");
              }
            }}
          >
            <Input.Password
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default PlowRouteAllocationPage;
