import React, { useState, useEffect } from "react";
import { ConfigProvider, Select, Table, Button, Modal, Spin, message, Input, Dropdown, Menu, Checkbox, AutoComplete, Badge, Tag } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import api from "../authentication/axiosInstance";
import styled from "styled-components";
import { Box, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { IconTractor, IconUser, IconLock, IconLockOpen } from "@tabler/icons-react";
import styles from '../components/EquipmentAllocationTable.module.css';
import debounce from "lodash/debounce";
import { Theme, Table as RadixTable, DataList } from '@radix-ui/themes';


const { Option } = Select;

const StaffCard = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StaffList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  max-height: 650px; /* Set a fixed height */
  overflow-y: scroll; /* Enable vertical scrolling */
  margin: 0;
  padding: 0;
`;

const StaffAllocationPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [staffFilter, setStaffFilter] = useState('any');
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [allocationMode, setAllocationMode] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const navigate = useNavigate();
  const MAX_EXPANDED_ROWS = 3;
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [unlockSiteId, setUnlockSiteId] = useState(null);
  const [password, setPassword] = useState("");
  const [selectedContractStatus, setSelectedContractStatus] = useState("all");
  const [isExportMenuVisible, setIsExportMenuVisible] = useState(false);
  const [selectedContractStatuses, setSelectedContractStatuses] = useState(['Estimate', 'Unconfirmed', 'Confirmed']);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("all");
  const [staffPositions, setStaffPositions] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
  }, 500);

  const handleSearch = (value) => {
    setSearchText(value);
  
    const filtered = staff.filter((member) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    );
  
    setFilteredStaff(filtered);
  
    setAutoCompleteOptions(
      filtered.map((staff) => ({
        value: staff.name,
        label: staff.name,
        key: staff.staff_id,
      }))
    );
  };

  const handleSelect = (value) => {
    const selectedStaff = filteredStaff.find(staff => staff.name === value);
    if (selectedStaff) {
      setFilteredStaff([selectedStaff]); // Set the filteredStaff to just the selected one
    }
  };

  const StaffPopup = ({ staff, onClose }) => {
    return (
      <Modal title="Staff Details" open={true} onCancel={onClose} footer={null}>
        <p>Name: {staff.name}</p>
        <p>Position: {staff.position}</p>
        <p>City: {staff.city}</p>
        <p>Contract Type: {staff.contract_type}</p>
        <p>Pay Rate: ${parseFloat(staff.pay_rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        <br></br>
        <p>Assigned Vehicles:</p>
        {staff.assigned_vehicles.length > 0 ? (
          <ul>
            {staff.assigned_vehicles.map(vehicle => (
              <li key={vehicle.vehicle_id}>
                {vehicle.vehicle_type}: {vehicle.vehicle_name}, {vehicle.vehicle_model}
              </li>
            ))}
          </ul>
        ) : (
          <ul>No Vehicles Assigned</ul>
        )}
      </Modal>
    );
  };

  const StaffDragCard = ({ staff, allocationMode }) => {
    const isAllocated = staff.assigned_bool || staff.assigned_plow_route_bool;
    const [{ isDragging }, drag] = useDrag({
      type: "staff",
      item: {
        id: staff.staff_id,
        type: "staff",
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: allocationMode && !(isAllocated),
    });

    const [showPopup, setShowPopup] = useState(false);

    const handleClick = (e) => {
      e.preventDefault();
      setShowPopup(true);
    };

    return (
      <>
        <StaffCard ref={allocationMode ? drag : null} style={{ opacity: isDragging ? 0.5 : 1,
          backgroundColor: isAllocated ? '#cce6cc' : '#f0f0f0',
          cursor: (allocationMode && !staff.assigned_bool) ? "grab" : "pointer"
          }}
          onClick={allocationMode ? undefined : handleClick}
        >
          <Box style={{ width: '100%', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconUser size={14} style={{ marginRight: '3px' }} />{staff.name}</span>
              <span style={{ fontSize: '14px', marginBottom: 4 }}>{staff.position}</span>       
              <span style={{ fontSize: '14px' }}>City: {staff.city ? staff.city : 'Unspecified'}</span>       
              <span style={{ fontSize: '14px' }}>Rating: {staff.staff_rating ? staff.staff_rating : 'Unspecified'}</span>       
              <span style={{ fontSize: '14px' }}>Overall Rating: {staff.staff_overall_rating ? staff.staff_overall_rating : 'Unspecified'}</span>       
            </div>
            <Box style={{ display: "flex", alignItems: "center", marginTop: '15px', marginBottom: "5px"}}>
              <Box style={{ flex: 1 }}>
                {staff.assigned_bool ? (
                  <div style={{ backgroundColor: "#034832", width: '100%', height: '4px', borderRadius: '10px' }} />
                ) : (
                  <div style={{ backgroundColor: "#9DB5AF", width: '100%', height: '4px', borderRadius: '10px' }} />
                )}
              </Box>
            </Box>
            <Box style={{fontSize:"12px", position: 'absolute', top: '0', right: '0', marginRight: '-5px', fontWeight: '500', color: "#010101"}}>
              {staff.assigned_bool ? (
                  <Tag style={{ backgroundColor: "#034832", color: "white", padding: '0px 15px' }}>Assigned</Tag>
                ) : (
                  <Tag style={{ padding: '0px 10px' }}>Assignable</Tag>
                )}
            </Box>
          </Box>
        </StaffCard>
        {showPopup && (
          <StaffPopup staff={staff} onClose={() => setShowPopup(false)} />
        )}
      </>
    );
  };

  const fetchStaff = async () => {
    try{
        const response = await api.get("/staff/loader_staff/");
        setStaff(response.data);
        setFilteredStaff(response.data);
        
        // Extract unique positions from staff data
        const positions = [...new Set(response.data.map(s => s.position))];
        setStaffPositions(positions);
    } catch (error) {
        console.error("Failed to fetch staff:", error);
        message.error("Failed to fetch staff");
    }
  }

  const fetchSites = async () => {
    setLoading(true);
    try {
      const response = await api.get("/sites/");
      setSites(response.data);
      setFilteredSites(response.data); // Initially, all sites are displayed
    } catch (error) {
      console.error("Failed to fetch sites:", error);
      message.error("Failed to fetch sites");
    } finally {
      setLoading(false);
    }
  };

  const fetchRegions = async () => {
    try {
      const response = await api.get("/sites/site_regions/");
      setRegions(response.data);
    } catch (error) {
      console.error("Failed to fetch regions:", error);
      message.error("Failed to fetch regions");
    }
  };

  useEffect(() => {
    fetchStaff();
    fetchSites();
    fetchRegions();
  }, []);

  // Client-side filtering
  useEffect(() => {
    let filtered = sites;

    if (selectedRegion !== "all") {
      filtered = filtered.filter((site) => site.region === selectedRegion);
    }

    if (selectedContractStatus !== "all") {
      filtered = filtered.filter((site) => site.contract_status === selectedContractStatus);
    }

    setFilteredSites(filtered);
  }, [selectedRegion, selectedContractStatus, sites]);

  const allocateStaff = async (vehicleId, staff_id) => {
    try {
      await api.post(
        `/vehicles/${vehicleId}/allocate_staff_to_vehicle/`,
        { staff_id: staff_id }
      ).then(response => {
        message.success(response.data.message);
      });
      fetchStaff();
      fetchSites();
    } catch (error) {
      console.error("Failed to allocate Staff:", error.response?.data?.message || error.message);
      message.error(error.response?.data?.message || error.message);
    }
  };

  const deallocateStaff = async (vehicleId, staff_id) => {
    try {
      await api.post(
        `/vehicles/${vehicleId}/deallocate_staff_from_vehicle/`,
        { staff_id: staff_id }
      ).then(response => {
        message.success(response.data.message);
      });
      fetchStaff();
      fetchSites();
    } catch (error) {
      console.error("Failed to deallocate Staff:", error.response?.data?.message || error.message);
      message.error(error.response?.data?.message || error.message);
    }
  }

  const generateStaffPDF = async () => {
    try {
      const statusParams = selectedContractStatuses.map(status => `status=${encodeURIComponent(status)}`).join('&');
      const response = await api.get(`/vehicles/staff_pdf/?${statusParams}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Staff_report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Staff PDF generated successfully");
      setIsExportMenuVisible(false);  // Close the dropdown after generating PDF
    } catch (error) {
      console.error("Failed to generate staff pdf:", error);
      message.error(error.response?.data?.message || "Failed to generate staff pdf");
    }
  };

  const exportMenu = (
    <Menu>
      <Menu.SubMenu key="1" title="Export Staff Allocation">
        <Menu.ItemGroup>
          <div style={{ padding: '8px 16px' }}>
            <Checkbox.Group
              options={[
                { label: 'Estimate', value: 'Estimate' },
                { label: 'Unconfirmed', value: 'Unconfirmed' },
                { label: 'Confirmed', value: 'Confirmed' },
              ]}
              value={selectedContractStatuses}
              onChange={setSelectedContractStatuses}
            />
          </div>
          <div style={{ padding: '8px 16px' }}>
            <Button 
              onClick={generateStaffPDF} 
              disabled={selectedContractStatuses.length === 0}
              type="primary"
            >
              Generate PDF
            </Button>
          </div>
        </Menu.ItemGroup>
      </Menu.SubMenu>
    </Menu>
  );

  const showProjectDetails = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const handleStaffTypeFilterChange = (value) => {
    setStaffFilter(value);
    if (value === "any") {
      setFilteredStaff(staff);
    } else {
      setFilteredStaff(staff.filter((s) => s.position === value));
    }
  };

  const finalizeStaffAllocation = async (siteId) => {
    try {
      await api.post(`/sites/${siteId}/finalize_staff_allocation/`);
      message.success("Staff allocation finalized successfully");
      if (expandedRowKeys.includes(siteId)) {
        setExpandedRowKeys(prevKeys => prevKeys.filter(key => key !== siteId));
        if (expandedRowKeys.length === 1) {
          setAllocationMode(false);
        }
      }
      fetchSites();
    } catch (error) {
      console.error("Failed to finalize staff allocation:", error);
      message.error("Failed to finalize staff allocation");
    }
  };

  const UnlockStaffAllocation = async (siteId, password) => {
    try {
      await api.post(`/sites/${siteId}/unlock_staff_allocation/`, {
        password,  // Pass the password in the request body
      });
      message.success("Staff allocation unlocked successfully");
      // Update the local state to reflect the change
      fetchSites();
    } catch (error) {
      console.error("Failed to unlock staff allocation:", error);
      message.error("Failed to unlock staff allocation");
    }
  };

  const handleContractStatusChange = (value) => {
    setSelectedContractStatus(value);
    // No need to call fetchSites here as filtering is now client-side
  };

  const handleRegionSelect = (value) => {
    setSelectedRegion(value);
    // No need to call fetchSites here as filtering is now client-side
  };

  const columns = [
    { 
      title: "Customer Name", 
      dataIndex: "client_name", 
      key: "client_name",
      width: 150
    },
    { 
      title: "Site Name", 
      dataIndex: "site_name", 
      key: "site_name",
      width: 175
    },
    {
      title: "Vehicles Allocated",
      dataIndex: "vehicles_lots",
      key: "vehicles_allocated",
      width: 200,
      render: (vehicles_lots, record) => (
        <ul style={{ listStyleType: "none", paddingLeft: 0, margin: 0 }}>
          {vehicles_lots.length === 0 ? (
            <li>N/A</li>
          ) : (
            vehicles_lots.map(vehicle => (
              <li key={vehicle.vehicle_id}>
                {vehicle.vehicle_subtype}, {vehicle.vehicle_name}
              </li>
            ))
          )}
        </ul>
      )
    },
    {
      title: "Lots Area (sq. ft)",
      dataIndex: "parking_lots_sqft",
      key: "parking_lots_sqft",
      width: 240,
      render: (lotsArea, record) => {
        const allocationPercentage = (record.parking_lots_sqft_allocated / lotsArea) * 100;
        const displayPercentage = allocationPercentage > 100 ? 100 : allocationPercentage;

        return (
          <span>
            {record.parking_lots_sqft_allocated.toLocaleString()} / {lotsArea.toLocaleString()}
            <Box style={{ display: "flex", alignItems: "center", width: 200 }}>
              <LinearProgress 
                style={{ flex: 9, marginRight: 8 }} 
                variant="determinate" 
                value={displayPercentage}
              />
              <Box style={{ flex: 1 }}>
                {allocationPercentage.toFixed(1)}%
              </Box>
            </Box>
            {allocationPercentage > 100 && (
              <Box style={{ color: 'Orange', marginTop: 4 }}>
                Over Allocated
              </Box>
            )}
          </span>
        )
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: 120
    },
    {
      title: "Locked",
      key: "locked",
      width: 81,
      render: (_, record) => (
        <Box>
        {record.staff_allocation_finalized ? (
          <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
            <IconLock size={18} stroke={2} />
          </div>
          
        ) : (
          <div style={{ backgroundColor: '#f5f5f5', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ebeaea', borderRadius: '4px', color: "#034832" }}>
            <IconLockOpen size={18} stroke={2} />
          </div>
        )}
      </Box>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Box style={{ display: "flex", alignItems: "flex-start" }}>
          <div
              className={styles.tableButtonGold}
              style={{ width: '135px' }}
              onClick={() => {
                if (record.staff_allocation_finalized) {
                  // If allocation is locked, show password modal
                  setUnlockSiteId(record.site_id);
                  setPasswordModalVisible(true);
                } else {
                  // Finalize allocation if it's not locked
                  finalizeStaffAllocation(record.site_id);
                }
              }}
            >
              {record.staff_allocation_finalized ? "Unlock Finalization" : "Finalize Allocation"}
            </div>
        </Box>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Box style={{ marginBottom: "8px" }}>
        <p style={{ fontWeight: "bold", marginBottom: "4px", fontSize: "16px" }}>Allocated Loaders:</p>
        {record.vehicles_lots?.length > 0 ? (
          <RadixTable.Root size="1">
            <RadixTable.Header>
              <RadixTable.Row>
                <RadixTable.ColumnHeaderCell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Allocate Staff</RadixTable.ColumnHeaderCell>
                <RadixTable.ColumnHeaderCell style={{ width: "30%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Vehicle</RadixTable.ColumnHeaderCell>
                <RadixTable.ColumnHeaderCell style={{ width: "30%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Assigned Staff</RadixTable.ColumnHeaderCell>
                <RadixTable.ColumnHeaderCell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Actions</RadixTable.ColumnHeaderCell>
              </RadixTable.Row>
            </RadixTable.Header>
  
            <RadixTable.Body>
              {record.vehicles_lots.map((vehicle) => (
                <RadixTable.Row key={vehicle.vehicle_id}>
                  <RadixTable.Cell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "14px" }}>
                    <AllocateStaffCell
                      vehicle={vehicle}
                      allocateStaff={allocateStaff}
                      isDragging={isDragging}
                    />
                  </RadixTable.Cell>
                  <RadixTable.Cell style={{ width: "30%", textAlign: "left", padding: "4px 8px", fontSize: "14px" }}>
                    <strong>{vehicle.vehicle_name}</strong> ({vehicle.vehicle_subtype})
                  </RadixTable.Cell>
                  <RadixTable.Cell style={{ width: "30%", textAlign: "left", padding: "4px 8px", fontSize: "14px" }}>
                    {vehicle.assigned_staff && vehicle.assigned_staff.length > 0 ? (
                      <ul style={{ paddingLeft: 0, margin: 0 }}>
                        {vehicle.assigned_staff.map((staff) => (
                          <li key={staff.staff_id} style={{ listStyle: "none", marginBottom: "4px" }}>
                            <strong>{staff.name}</strong> - {staff.position}, Rating: {staff.staff_rating ? staff.staff_rating : "Unspecified"}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "Unassigned"
                    )}
                  </RadixTable.Cell>
                  <RadixTable.Cell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "14px" }}>
                    {vehicle.assigned_staff && vehicle.assigned_staff.length > 0 ? (
                      vehicle.assigned_staff.map((staff) => (
                        <Button
                          key={staff.staff_id}
                          onClick={() => deallocateStaff(vehicle.vehicle_id, staff.staff_id)}
                          type="primary"
                          danger
                          size="small"
                          style={{ marginRight: "4px", height: 30, width: 150 }}
                        >
                          Deallocate
                        </Button>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </RadixTable.Cell>
                </RadixTable.Row>
              ))}
            </RadixTable.Body>
          </RadixTable.Root>
        ) : (
          <p style={{ fontSize: "14px" }}>No Loaders Allocated</p>
        )}
      </Box>
    );
  };

  const AllocateStaffCell = ({ vehicle, allocateStaff }) => {
    const [{ isOver }, drop] = useDrop({
      accept: "staff",
      drop: (item) => allocateStaff(vehicle.vehicle_id, item.id),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    return (
      <Button
        ref={drop}
        type="primary"
        style={{
          backgroundColor: isOver ? "#cdaf5e" : "#cdaf5e",
          borderColor: isOver ? "#cdaf5e" : "#cdaf5e",
          opacity: 1,
          width: 250,
          height: 50,
        }}
      >
        <strong>{isOver ? "Release to Allocate" : "Allocate Staff"}</strong>
      </Button>
    );
  };

  const onTableExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => {
        if (prevKeys.length < MAX_EXPANDED_ROWS) {
          setAllocationMode(true);
          return [...prevKeys, record.site_id];
        }
        message.warning(`You can only expand up to ${MAX_EXPANDED_ROWS} sites at a time.`);
        return prevKeys;
      });
    } else {
      setExpandedRowKeys((prevKeys) => {
        const newKeys = prevKeys.filter((key) => key !== record.site_id);
        if (newKeys.length === 0) {
          setAllocationMode(false);
        }
        return newKeys;
      });
    }
  };

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
        <style>{`
          .ant-table-tbody > tr:hover > td {
            background: transparent !important; /* Removes hover background */
          }
          .locked-row {
            background-color: #f0f0f0 !important;
          }
        `}</style>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
                <span style={{fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px'}}>
                  Staff
                </span>
                {/* Add the staff filter select here */}
                <Select
                  style={{ width: 'calc(100% - 20px)' }}
                  value={staffFilter}
                  onChange={handleStaffTypeFilterChange}
                >
                  <Option value="any">All Positions</Option>
                  {staffPositions.map(position => (
                    <Option key={position} value={position}>{position}</Option>
                  ))}
                </Select>
                <Input
                  style={{ width: '100%' }}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  placeholder="Search staff"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)} // use the debounced function or directly handleSearch
              />
                <StaffList>
                  {filteredStaff.map((staff) => (
                    <StaffDragCard
                      key={staff.staff_id}
                      staff={staff}
                      allocationMode={allocationMode}
                    />
                  ))}
                </StaffList>
            </div>
            <div style={{ width: 'calc(100% - 320px)', marginLeft: '20px'}}>
              <div style={{ marginBottom: 16, display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <Select
                    placeholder="Select Region"
                    value={selectedRegion}
                    style={{ width: 150 }}
                    onChange={handleRegionSelect}
                  >
                    <Option value="all">All Regions</Option>
                    {regions.map((region) => (
                      <Option key={region} value={region}>
                        {region.charAt(0).toUpperCase() + region.slice(1)}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Select Contract Status"
                    value={selectedContractStatus}
                    style={{ width: 150 }}
                    onChange={handleContractStatusChange}
                  >
                    <Option value="all">All Statuses</Option>
                    <Option value="Estimate">Estimate</Option>
                    <Option value="Unconfirmed">Unconfirmed</Option>
                    <Option value="Confirmed">Confirmed</Option>
                  </Select>
                  <Dropdown overlay={exportMenu} trigger={['click']}>
                    <Button style={{ width: 150 }}>
                      PDF Export <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
                <div className={styles.tableButton} onClick={() => navigate('/allocation')} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconTractor size={16}/>
                  Loader Allocation
                </div>
              </div>
              <Spin spinning={loading}>
                <Table
                  dataSource={filteredSites}
                  columns={columns}
                  rowKey="site_id"
                  pagination={false}
                  scroll={{ y: 690 }}
                  rowClassName={(record) =>
                    record.staff_allocation_finalized ? 'locked-row' : ''
                  }
                  expandable={{
                    expandedRowRender,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => {
                      if (!record.staff_allocation_finalized) {
                        onTableExpand(expanded, record);
                      }
                    },
                    expandIcon: ({ expanded, onExpand, record }) =>
                      record.staff_allocation_finalized ? null : (
                        <Button
                          onClick={(e) => onExpand(record, e)}
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          disabled={!expanded && expandedRowKeys.length >= MAX_EXPANDED_ROWS}
                        >
                          {expanded ? '-' : '+'}
                        </Button>
                      ),
                  }}
                />
              </Spin>
              <Modal title="Vehicle Details" open={showModal} onCancel={handleModalCancel} footer={null}>
                {selectedProject && (
                  <div>
                    <p>Vehicle Name: {selectedProject.vehicle_name}</p>
                    <p>Make/Model: {selectedProject.vehicle_make}, {selectedProject.vehicle_model}</p>
                    <p>Surface Type: {selectedProject.surface_type}</p>
                    <p>Max Capacity: {selectedProject.vehicle_capacity.toLocaleString()} sq. ft</p>
                    <p>Remaining Capacity: {selectedProject.remaining_capacity.toLocaleString()} sq. ft</p>
                    <br></br>
                    <p>Assigned Staff:</p>
                    {selectedProject.assigned_staff.length > 0 ? (
                      <ul>
                        {selectedProject.assigned_staff.map((staff) => (
                          <li key={staff.staff_id}>{staff.name} - {staff.position}</li>
                        ))}
                      </ul>
                    ) : (
                      <ul><li>No Staff Assigned</li></ul>
                    )}
                  </div>
                )}
              </Modal>
            </div>
          </div>
        <Modal
          title="Enter Password to Unlock"
          open={passwordModalVisible}
          onCancel={() => setPasswordModalVisible(false)}
          onOk={async () => {
            if (password) {
              await UnlockStaffAllocation(unlockSiteId, password);
              setPasswordModalVisible(false);
              setPassword("");
            } else {
              message.error("Please enter a password");
            }
          }}
        >
          <Input.Password
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default StaffAllocationPage;
