import React, { useState, useEffect } from 'react';
import { Button, Input, List, message, Form, Table } from 'antd';
import { Box } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import api from '../authentication/axiosInstance';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftPanel = styled.div`
  width: 300px;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
  border-right: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
`;

const CenterPanel = styled.div`
  flex: 6;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
  border-right: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
`;

const FormSection = styled.div`
  flex: 1;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
`;

const EstimatePage = () => {
  const [sites, setSites] = useState([]);
  const [projections, setProjections] = useState([{}]);
  const [projectionData, setProjectionData] = useState([]);
  const [selectedProjection, setSelectedProjection] = useState(null);
  const [isCreatingEstimate, setIsCreatingEstimate] = useState(false);
  const [form] = Form.useForm();
  const [projectionParameters, setProjectionParameters] = useState(null);

  const fetchProjections = async () => {
    try {
      const response = await api.get('/projection/projections/');
      setProjections(response.data);
    } catch (error) {
      console.error('Failed to fetch projections:', error);
      message.error('Failed to fetch projections');
    }
  };

  const fetchProjectionData = async () => {
    try {
      const response = await api.get('/projection/projection-data/');
      setProjectionData(response.data);
    } catch (error) {
      console.error('Failed to fetch projectionData:', error);
      message.error('Failed to fetch projectionData');
    }
  }

  useEffect(() => {
    fetchSites();
    fetchProjections();
    fetchProjectionData();
  }, []);

  const fetchSites = async () => {
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    }
  };

  const handleCreateProjection = () => {
    setIsCreatingEstimate(true);
    setSelectedProjection(null);
    form.resetFields();
  };

  const handleGenerateProjection = () => {
    const values = form.getFieldsValue();
    setProjectionParameters({
      total_snowfall: values.total_snowfall,
      max_ground_snow: values.max_ground_snow,
      num_salting_events: values.num_salting_events,
      num_plowing_events: values.num_plowing_events,
      avg_temp_q4: values.avg_temp_q4,
      avg_temp_q1: values.avg_temp_q1,
      salt_price: values.salt_price,
      fuel_cost: values.fuel_cost,
      subcontractor_standby: values.subcontractor_standby,
      labour_standby: values.labour_standby,
      subcontractor_hourly: values.subcontractor_hourly,
      labour_hourly: values.labour_hourly,
      subcontractor_labour_ratio: values.subcontractor_labour_ratio,
    });
  };

  useEffect(() => {
    if (projectionParameters) {
      const generateProjection = async () => {
        try {
          await api.post('/projection/projections/generate_projection/', projectionParameters)
          .then((response) => {
            message.success(response.data.message);
          });
          fetchProjections();
          fetchProjectionData();
        } catch (error) {
          const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An unknown error occurred';
          message.error('Failed to generate projection: ' + errorMessage);
        }
      };
      generateProjection();
    }
  }, [projectionParameters]);

  const handleDeleteProjection = (projection) => {
    return async () => {
      try {
        await api.delete(`/projection/projections/${projection.id}/`);
        fetchProjections();
        message.success('Projection deleted successfully');
      } catch (error) {
        console.error('Failed to delete estimate:', error);
        message.error('Failed to delete estimate');
      }
    };
  };

  const columns = [
    { title: 'Site Name', dataIndex: 'site_name', key: 'site_name',
      render: text => <span style={{ fontSize: '12px' }}>{text}</span> 
    },
    { title: 'Subcontractor Hourly Expenses', dataIndex: 'subcontractor_hourly_expenses', key: 'subcontractor_hourly_expenses' },
    { title: 'Subcontractor Standby Expenses', dataIndex: 'subcontractor_standby_expenses', key: 'subcontractor_standby_expenses' },
    { title: 'Labour Hourly Expenses', dataIndex: 'labour_hourly_expenses', key: 'labour_hourly_expenses' },
    { title: 'Labour Standby Expenses', dataIndex: 'labour_standby_expenses', key: 'labour_standby_expenses' },
    { title: 'Lots Material Expenses', dataIndex: 'lots_material_expenses', key: 'lots_material_expenses' },
    { title: 'Walkway Material Expenses', dataIndex: 'walkway_material_expenses', key: 'walkway_material_expenses' },
    { title: 'Loader Expenses', dataIndex: 'loader_expenses', key: 'loader_expenses' },
    { title: 'Vehicle Expenses', dataIndex: 'vehicle_expenses', key: 'vehicle_expenses' },
    { title: 'Other Equipment Expenses', dataIndex: 'other_equipment_expenses', key: 'other_equipment_expenses' },
    { title: 'Fuel and Repair Expenses', dataIndex: 'fuel_and_repair_expenses', key: 'fuel_and_repair_expenses' },
    { title: 'Overhead Expenses', dataIndex: 'overhead_expenses', key: 'overhead_expenses' },
    { title: 'Base Revenue', dataIndex: 'base_revenue', key: 'base_revenue' },
    { title: 'Additional Revenue', dataIndex: 'additional_revenue', key: 'additional_revenue' },
    { title: 'Total Revenue', dataIndex: 'total_revenue', key: 'total_revenue' },
    { title: 'Net Profit Amount ($)', dataIndex: 'net_profit_amount', key: 'net_profit_amount' },
    { title: 'Net Profit (%)', dataIndex: 'net_profit_percentage', key: 'net_profit_percentage' },
  ];

  const filteredData = projectionData.filter(data => data.projection === selectedProjection?.id).map((data, index) => ({
    key: index,
    site_name: data.site_name,
    subcontractor_hourly_expenses: data.subcontractor_hourly_expenses || 0,
    subcontractor_standby_expenses: data.subcontractor_standby_expenses || 0,
    labour_hourly_expenses: data.labour_hourly_expenses || 0,
    labour_standby_expenses: data.labour_standby_expenses || 0,
    lots_material_expenses: data.lots_material_expenses || 0,
    walkway_material_expenses: data.walkway_material_expenses || 0,
    loader_expenses: data.loader_expenses || 0,
    vehicle_expenses: data.vehicle_expenses || 0,
    other_equipment_expenses: data.other_equipment_expenses || 0,
    fuel_and_repair_expenses: data.fuel_and_repair_expenses || 0,
    overhead_expenses: data.overhead_expenses || 0,
    base_revenue: data.base_revenue || 0,
    additional_revenue: data.additional_revenue || 0,
    total_revenue: data.total_revenue || 0,
    net_profit_amount: data.net_profit_amount || 0,
    net_profit_percentage: data.net_profit_percentage || 0,
  }));

  return (
    <Box style={{ padding: 30 }}>
      <TopBar>
        <Button type="primary" onClick={handleCreateProjection} style={{ marginBottom: '10px' }}>
          New Projection
        </Button>
      </TopBar>
      <Container style={{ display: 'flex' }}>
        <LeftPanel style={{ flex: '1' }}>
          <h3>Projections</h3>
          <List
            bordered
            dataSource={projections}
            renderItem={(projection) => (
              <List.Item
                onClick={() => {
                  setSelectedProjection(projection);
                  setIsCreatingEstimate(false);
                }}
              >
                <div>
                  <h4>Projection: {projection.id}</h4>
                  <p>Total Snowfall (cm): {parseFloat(projection.total_snowfall).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <Button onClick={handleDeleteProjection(projection)}>Delete Projection</Button>
                </div>
              </List.Item>
            )}
          />
        </LeftPanel>
        <CenterPanel style={{ flex: '6' }}>
          {isCreatingEstimate ? (
            <FormSection style={{ display: 'flex', flexDirection: 'column' }}>
              <h3>Enter Projection Information</h3>
              <Form layout="vertical" form={form}>
                <h4>Projection Details</h4>
                <Form.Item label="Total Snowfall (cm)" name="total_snowfall">
                  <Input />
                </Form.Item>
                <Form.Item label="Maximum Ground Snow (cm)" name="max_ground_snow">
                  <Input />
                </Form.Item>
                <Form.Item label="Total # of Salting Events" name="num_salting_events">
                  <Input />
                </Form.Item>
                <Form.Item label="Total # of Plowing Events" name="num_plowing_events">
                  <Input />
                </Form.Item>
                <Form.Item label="Average Q4 Temperature (Oct - Dec)" name="avg_temp_q4">
                  <Input />
                </Form.Item>
                <Form.Item label="Average Q1 Temperature (Jan - Mar)" name="avg_temp_q1">
                  <Input />
                </Form.Item>
                <h4>Financial Details</h4>
                <Form.Item label="Salt Price ($/ton)" name="salt_price">
                  <Input />
                </Form.Item>
                <Form.Item label="Fuel Cost ($/Litre)" name="fuel_cost">
                  <Input />
                </Form.Item>
                <Form.Item label="Subcontractor Standby ($/month)" name="subcontractor_standby">
                  <Input />
                </Form.Item>
                <Form.Item label="Labour Standby ($/month)" name="labour_standby">
                  <Input />
                </Form.Item>
                <Form.Item label="Subcontractor Hourly ($/hour)" name="subcontractor_hourly">
                  <Input />
                </Form.Item>
                <Form.Item label="Labour Hourly ($/hour)" name="labour_hourly">
                  <Input />
                </Form.Item>
                <Form.Item label="Subcontractor / Labour Ratio (0 - 100%):" name="subcontractor_labour_ratio">
                  <Input />
                </Form.Item>
              </Form>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleGenerateProjection} variant="contained" color="primary">
                  Generate Projection
                </Button>
              </Box>
            </FormSection>
          ) : (
            selectedProjection && (
              <>
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <h3>Projection: {selectedProjection.id}</h3>
                  <Button onClick={() => handleDeleteProjection(selectedProjection)}style={{ width: '25%' }}>
                    Delete Projection
                  </Button>
                </Box>
                <Table columns={columns} dataSource={filteredData} pagination={false} />
              </>
            )
          )}
        </CenterPanel>
      </Container>
    </Box>
  );
};

export default EstimatePage;