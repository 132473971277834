import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Popconfirm, Input, Select } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Box, MenuItem, FormControl, } from '@mui/material';
import { jsPDF } from 'jspdf';
import companyLogo from '../assets/company-logo.png';
import api from '../authentication/axiosInstance';
import debounce from 'lodash/debounce';
const { Option } = Select;

const EquipmentListView = () => {
  const [vehicles, setVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState(undefined);
  const [searchModel, setSearchModel] = useState('');
  const [searchMake, setSearchMake] = useState(undefined);
  const [searchYear, setSearchYear] = useState(undefined);
  const [searchSubtype, setSearchSubtype] = useState(undefined);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const fetchVehicles = async () => {
    try {
      const response = await api.get('/vehicles/');
      setVehicles(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error("Failed to fetch vehicles:", error);
      message.error("Failed to fetch vehicles");
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  const addGenericLoader = async () => {
    if (!selectedValue) {
      message.error("Please select a generic loader subtype before adding a generic loader.");
      return;
    }

    try {
      const response = await api.post('/vehicles/add_generic_loader/', {
        selected_value: selectedValue, // Include the selected dropdown value
      });
      message.success('Generic loader added successfully');
      fetchVehicles(); // Refresh the list
    } catch (error) {
      console.error("Failed to add generic loader:", error);
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        message.error(`Failed to add generic loader: ${error.response.data.error || 'Unknown error'}`);
      } else if (error.request) {
        console.error("Error request:", error.request);
        message.error('Failed to add generic loader: No response received from server');
      } else {
        console.error('Error message:', error.message);
        message.error(`Failed to add generic loader: ${error.message}`);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/vehicles/${id}/`)
      message.success('Vehicle deleted successfully');
      fetchVehicles();
    } catch (error) {
      console.error('Failed to delete vehicle:', error);
      message.error('Failed to delete vehicle')
    }
  };

  const generatePDF = (vehicle) => {
    if (!vehicle) {
      throw new Error('Error generating vehicle details pdf, no vehicle data provided');
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const lineSpacing = 8;
    const indentSpacing = 10;
  
    doc.addImage(companyLogo, 'PNG', margin, margin, 66, 13);

    doc.setFontSize(16);
    const headerText = `${vehicle.vehicle_name} - Vehicle Details`;
    const headerTextWidth = doc.getTextWidth(headerText);
    const rightAlignedX = pageWidth - headerTextWidth - 10;
    doc.text(`${vehicle.vehicle_name} - Vehicle Details`, rightAlignedX, 17);

    doc.setFontSize(14);
    const xPos = 20;
    let yPos = 40;
    let columnSpacing = 70;

    const vehicleCost = vehicle.vehicle_cost
      ? Number(vehicle.vehicle_cost).toFixed(2)
      : '0.00';

    const details = [
      { label: 'Vehicle Name:', value: vehicle.vehicle_name },
      { label: 'Vehicle Type:', value: vehicle.vehicle_type },
      { label: 'Sub Type:', value: vehicle.vehicle_subtype },
      { label: 'Model:', value: vehicle.vehicle_model },
      { label: 'Make:', value: vehicle.vehicle_make },
      { label: 'Year:', value: vehicle.vehicle_year },
      { label: 'Serial Number:', value: vehicle.vehicle_serial_number },
      { label: 'Capacity:', value: vehicle.vehicle_capacity.toString() },
      { label: 'Remaining Capacity:', value: vehicle.remaining_capacity.toString() },
      { label: 'Vehicle Costs:', value: `$${vehicleCost}` },
      { label: 'Surface Type:', value: vehicle.surface_type },
      { label: 'Number of Staff Assigned:', value: vehicle.number_staff_assigned.toString() },
      { label: 'Status:', value: vehicle.status },
      { label: 'Plow Size:', value: vehicle.plow_size },
    ];

    details.forEach((detail) => {
      doc.text(detail.label, xPos, yPos);
      doc.text(detail.value || '', xPos + columnSpacing, yPos);
      yPos += lineSpacing;
    });

    if (vehicle.assigned_plow) {
      yPos += lineSpacing;
      doc.setFontSize(14);
      doc.text('Assigned Plow Details:', xPos, yPos);
      yPos += lineSpacing;
      doc.setFontSize(12);

      const plowDetails = [
        { label: 'Plow Name:', value: vehicle.assigned_plow.plow_name },
        { label: 'Plow Model:', value: vehicle.assigned_plow.plow_model }
      ];

      columnSpacing = 40;
      plowDetails.forEach((detail) => {
        doc.text(detail.label, xPos + indentSpacing, yPos);
        doc.text(detail.value || '', xPos + columnSpacing, yPos);
        yPos += lineSpacing;
      });
    }

    if (vehicle.allocated_sites && vehicle.allocated_sites.length > 0) {
      yPos += lineSpacing;
      doc.setFontSize(14);
      doc.text('Allocated Sites:', xPos, yPos);
      yPos += lineSpacing;
      doc.setFontSize(12);

      vehicle.allocated_sites.forEach((site) => {
        doc.text(`- ${site.site_name} (Client: ${site.client_name ? site.client_name : 'N/A'})`, xPos + indentSpacing, yPos);
        yPos += lineSpacing;
      });
    }

    doc.save(`${vehicle.vehicle_name}.pdf`);
  };
  
  const isAllocationPresent = (vehicles) => {
    return (vehicles.assigned_walkway_crew_bool || vehicles.assigned_salting_route_bool || vehicles.assigned_plow || vehicles.assigned_doubleDown || vehicles.assigned_plow_route_bool ||
      (vehicles.allocated_sites && vehicles.allocated_sites.length > 0) || (vehicles.number_staff_assigned > 0));
  }

  const columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicle_name',
      key: 'vehicle_name',
    },
    {
      title: 'Type',
      dataIndex: 'vehicle_type',
      key: 'vehicle_type',
    },
    {
      title: 'Subtype',
      dataIndex: 'vehicle_subtype',
      key: 'vehicle_subtype',
    },
    {
      title: 'Plow Size',
      dataIndex: 'plow_size',
      key: 'plow_size',
    },
    {
      title: 'Model',
      dataIndex: 'vehicle_model',
      key: 'vehicle_model',
    },
    {
      title: 'Make',
      dataIndex: 'vehicle_make',
      key: 'vehicle_make',
    },
    {
      title: 'Year',
      dataIndex: 'vehicle_year',
      key: 'vehicle_year',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <div className="action-buttons" >
          <Button
            onClick={() => showVehicleDetails(record)}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
            View Details
          </Button>
          {isAllocationPresent(record) ? ( "" ) : 
          (<Popconfirm
            title={`Are you sure you want to delete equipment ${record.vehicle_name}?`}
            onConfirm={() => handleDelete(record.vehicle_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              type="danger"
              className="delete-button"
            />
          </Popconfirm>)}
          
        </div>
      ),
    },
  ];

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedVehicle(null);
  };

  const showVehicleDetails = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
  };

  //Filtering and searching
  const debouncedSearchName = debounce((value) => {
    handleSearchName(value);
  }, 300);

  const handleSearchName = (value) => {
    setSearchName(value);
  };


  const debouncedSearchModel = debounce((value) => {
    handleSearchModel(value);
  }, 300);

  const handleSearchModel = (value) => {
    setSearchModel(value);
  };
  
  const applyFilters = () => {
    const filtered = vehicles.filter((vehicle) => {
      const filterName = vehicle.vehicle_name.toLowerCase().includes(searchName.toLowerCase());
      const filterType = searchType ? vehicle.vehicle_type.toLowerCase().includes(searchType.toLowerCase()) : true;
      const filterModel = vehicle.vehicle_model.toLowerCase().includes(searchModel.toLowerCase());
      const filterMake = searchMake ? vehicle.vehicle_make.toLowerCase().includes(searchMake.toLowerCase()) : true;
      const filterYear = searchYear ? vehicle.vehicle_year.toString() === searchYear.toString() : true;
      const filterSubtype = searchSubtype ? vehicle.vehicle_subtype.toString() === searchSubtype.toString() : true;
      return filterName && filterType && filterModel && filterMake && filterYear && filterSubtype;
    });
    setFilteredVehicles(filtered);
  };
  
  useEffect(() => {
    applyFilters();
  }, [searchName, searchType, searchModel, searchMake, searchYear, searchSubtype]);


  return (
    <Box style={{ padding: 30 }}>
      <Box style={{ marginBottom: 16, display: 'flex' }}>
        <div style={{display:"flex", justifyContent:'space-between', width:'100%'}}>
          <div>
            <Button onClick={addGenericLoader} type="primary">
              Add Generic Loader
            </Button>
            <Select
              value={selectedValue}
              allowClear
              onChange={(value) => setSelectedValue(value)}
              placeholder="Generic Loader Subtype"
              style={{ width: 250 , marginLeft: 10}}
            >
              <Option value="321">321</Option>
              <Option value="344">344</Option>
              <Option value="444">444</Option>
              <Option value="524">524</Option>
              <Option value="621">621</Option>
              <Option value="624">624</Option>
              <Option value="644">644</Option>
              <Option value="skidsteer">skidsteer</Option>
            </Select>
          </div>
          <div>
            <Input.Search
              placeholder="Search name"
              onSearch={setSearchName}
              onChange={(e) => debouncedSearchName(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Search by type"
              value={searchType}
              onChange={(value) => setSearchType(value)} // Updates searchType
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_type))) // Extract unique types
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
            <Select
              placeholder="Search by subtype"
              value={searchSubtype}
              onChange={(value) => setSearchSubtype(value)} // Updates searchType
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_subtype))) // Extract unique types
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
            <Input.Search
              placeholder="Search model"
              onSearch={setSearchModel}
              onChange={(e) => debouncedSearchModel(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Search by make"
              value={searchMake} // Dropdown value
              onChange={(value) => setSearchMake(value)} // Updates searchMake
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_make))) // Extract unique makes
                .map((make) => (
                  <Option key={make} value={make}>
                    {make}
                  </Option>
              ))}
            </Select>
            <Select
              placeholder="Search by year"
              value={searchYear} // Dropdown value
              onChange={(value) => setSearchYear(value)} // Updates searchYear
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(vehicles.map((vehicle) => vehicle.vehicle_year))) // Extract unique years
                .map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
              ))}
            </Select>
          </div>
        </div>
      </Box>
      <Table dataSource={filteredVehicles} columns={columns} rowKey="site_id" pagination={false} scroll={{ y: 640 }} />
      <Modal
        title="Vehicle Details"
        open={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key="download" onClick={() => generatePDF(selectedVehicle)}>
              Download PDF
          </Button>,
        ]}
      >
        {selectedVehicle && (
          <div>
            <p>Name: {selectedVehicle.vehicle_name}</p>
            <p>Type: {selectedVehicle.vehicle_type}</p>
            <p>Sub Type: {selectedVehicle.vehicle_subtype}</p>
            <p>Capacity: {selectedVehicle.vehicle_capacity}</p>
            <p>Serial Number: {selectedVehicle.vehicle_serial_number}</p>
            <br />
            <p><b>Financial Details</b></p>
            <p>Vehicle Costs: ${selectedVehicle.vehicle_cost}</p>
          </div>
        )}
      </Modal>
    </Box>
  );
};

export default EquipmentListView;
