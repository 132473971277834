// ClientRequestForm.js
import React, { useState, useEffect, useContext } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Typography,
  Row,
  Col,
  DatePicker,
  Spin,
  Alert,
  Divider,
  Card,
  Checkbox
} from 'antd';
import { UploadOutlined, EnvironmentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import api from "../authentication/axiosInstance";
import moment from 'moment';
import debounce from 'lodash/debounce';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext to access user data
import { Grid } from 'antd';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import Joyride, { STATUS } from 'react-joyride';
import { motion } from 'framer-motion'; // You'll need to install: npm install framer-motion

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

// Define a custom marker icon
const markerIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const ClientRequestForm = () => {
  const [form] = Form.useForm();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [clientSites, setClientSites] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [sitesDropdown, setSitesDropdown] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [useCustomLocation, setUseCustomLocation] = useState(false);
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [runTutorial, setRunTutorial] = useState(false);
  const [steps] = useState([
    {
      target: '.client-request-form',
      content: (
        <div style={{ padding: '10px' }}>
          <h2 style={{ margin: '0 0 10px', color: '#034832' }}>👋 Welcome!</h2>
          <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
            Let's walk through submitting your service request together. 
            This quick tour will show you everything you need to know.
          </p>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
    },
    {
      target: '[data-tour="client-fields"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>📋 Client Information</h3>
          <p>These fields are pre-filled with your account details for convenience.</p>
        </div>
      ),
      placement: 'right',
    },
    {
      target: '[data-tour="site-selector"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>🏢 Select Your Site</h3>
          <p>Choose the location where you need service. The map will automatically update to show you the selected site.</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '[data-tour="custom-location"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>📍 Precise Location</h3>
          <p>Need service at a specific spot? Enable this option to pinpoint the exact location on the map.</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '[data-tour="map"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>🗺️ Interactive Map</h3>
          <p>View and adjust your service location. You can zoom, pan, and click to select specific spots.</p>
        </div>
      ),
      placement: 'left',
    },
    {
      target: '[data-tour="request-details"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>✏️ Service Details</h3>
          <p>Tell us what type of service you need and provide any important details that will help us serve you better.</p>
        </div>
      ),
      placement: 'right',
    },
    {
      target: '[data-tour="photos"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>📸 Add Photos</h3>
          <p>Upload relevant photos to help our team better understand your needs and prepare accordingly.</p>
        </div>
      ),
      placement: 'top',
    },
    {
      target: '[data-tour="submit"]',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>🚀 Ready to Submit!</h3>
          <p>You're all set! Click here to submit your service request.</p>
        </div>
      ),
      placement: 'top',
    },
  ]);

  const { userRole, clientId, userEmail, userId } = useContext(AuthContext);
  console.log('Auth context values:', { userRole, clientId, userEmail, userId });
  const screens = useBreakpoint();

  // Location Marker Component
  const LocationMarker = () => {
    const mapInstance = useMap(); // Use useMap hook to get the current map instance

    useMapEvents({
      click(e) {
        if (useCustomLocation) {
          const { lat, lng } = e.latlng;
          setMarkerPosition([lat, lng]);
          setLocation({ lat, lng });
          form.setFieldsValue({
            latitude: lat,
            longitude: lng
          });
        }
      },
    });

    useEffect(() => {
      if (markerPosition && mapInstance) {
        mapInstance.flyTo(markerPosition, 16);
      }
    }, [markerPosition, mapInstance]);

    return markerPosition ? (
      <Marker position={markerPosition} icon={markerIcon} />
    ) : null;
  };

  // Reverse Geocoding Function
  const reverseGeocode = async (latlng) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}`);
      const data = await response.json();
      setAddress(data.display_name);
      form.setFieldsValue({ address: data.display_name });
    } catch (error) {
      console.error('Error reverse geocoding:', error);
      message.error('Failed to fetch address from location.');
    }
  };
  const handleMapCreated = (mapInstance) => {
    setMap(mapInstance);  // Store map reference in state
  };
  // Function to fetch sites based on client_id
  const fetchSitesByClientId = async (clientId) => {
    try {
      const sitesResponse = await api.get(`/clients/${clientId}/accessible-sites/`, {
        params: { user_id: userId }
      });
      const sites = sitesResponse.data;
      setClientSites(sites);
      setSitesDropdown(sites.map(site => ({
        value: site.site_id,
        label: site.site_name,
        latitude: site.latitude,
        longitude: site.longitude
      })));
    } catch (error) {
      console.error('Error fetching accessible sites:', error);
      setFetchError('Failed to fetch accessible sites for your account.');
    }
  };

  // Initialize form based on user role
  useEffect(() => {
    const fetchClientData = async () => {
      if (clientId && userId) {
        setClientLoading(true);
        try {
          const response = await api.get(`/clients/${clientId}/`);
          console.log('Client data received:', response.data);
          setClientData(response.data);
          form.setFieldsValue({
            client_name: response.data.client_name,
            client_email: userEmail
          });
          await fetchSitesByClientId(clientId);
        } catch (error) {
          console.error('Error fetching client data:', error);
          setFetchError('Failed to fetch client data.');
        } finally {
          setClientLoading(false);
        }
      } else {
        console.log('No clientId or userId available');
        form.setFieldsValue({
          client_email: userEmail
        });
      }
    };

    fetchClientData();
  }, [clientId, userEmail, userId, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === 'date' && values[key]) {
          formData.append('preferred_service_date', values[key].format('YYYY-MM-DD'));
        } else if (values[key] !== undefined && values[key] !== null) {
          formData.append(key, values[key]);
        }
      });

      // Always include site_id
      const selectedSite = clientSites.find(site => site.site_id === values.site);
      if (!selectedSite) {
        throw new Error('Please select a site');
      }
      formData.append('site_id', selectedSite.site_id);

      // Add location data
      if (useCustomLocation && location) {
        // Use custom location coordinates within the site
        formData.append('latitude', location.lat);
        formData.append('longitude', location.lng);
        formData.append('address', address);
      } else {
        // Use site's default coordinates
        formData.append('latitude', selectedSite.latitude);
        formData.append('longitude', selectedSite.longitude);
        formData.append('address', selectedSite.site_name);
      }

      // Append client_id if available
      if (clientId) {
        formData.append('client_id', clientId);
      }

      // Log the form data for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      api.post('/request-manager/requests/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (serviceRequest) {
        if (values['photos']) {
          message.info('Uploading photos starting...');
          values['photos'].fileList.forEach(async (file) => {
            api.post(`/request-manager/requests/${serviceRequest.data.id}/photo-upload-signed-url`)
            .then(function (result) {
              var signedUrl = result.data.signed_url;
              var options = {
                headers: {
                  'Content-Type': "image/jpeg"
                }
              };
              // return axios.put(signedUrl, file, options);
              return axios.put(signedUrl, file.originFileObj, options);
            })
            .then(function (result) {
              console.log(result);
            })
            .catch(function (err) {
              console.log(err);
            })
            .finally(() => {
              message.info('Uploading photos complete!');
            });
          })
        }
      })

      message.success('Request submitted successfully');

      // Reset form but preserve client info
      const preservedInfo = {
        client_name: form.getFieldValue('client_name'),
        client_email: form.getFieldValue('client_email'),
      };
      form.resetFields();
      form.setFieldsValue(preservedInfo);

      setLocation(null);
      setAddress('');
      setUseCustomLocation(false);

    } catch (error) {
      console.error('Error submitting request:', error);
      if (error.response) {
        console.error('Server error response:', error.response.data);
        message.error(`Server error: ${error.response.data.message || JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        message.error('No response received from server. Please check your internet connection.');
      } else {
        console.error('Error setting up request:', error.message);
        message.error(error.message || 'An error occurred while preparing the request. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Client Email Change Handler for Non-Client Users
  const handleClientEmailChange = debounce(async (email) => {
    if (!email) {
      setClientSites([]);
      setSitesDropdown([]);
      form.resetFields(['client_name', 'client_email', 'site']);
      setFetchError(null);
      return;
    }

    setClientLoading(true);
    setFetchError(null);

    try {
      // Fetch client data by email
      const response = await api.get(`/clients/by-email`, {
        params: { email: email },
      });
      setClientData(response.data);

      const clientId = response.data.client_id;

      // Fetch client sites using client ID and userId
      await fetchSitesByClientId(clientId);

      form.setFieldsValue({
        client_name: response.data.client_name,
        client_email: response.data.contact_email,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Client not found, allow manual input
        setClientSites([]);
        setSitesDropdown([]);
        form.resetFields(['client_name', 'client_email', 'site']);
        message.info('No existing client found. Please enter your details.');
      } else {
        console.error('Error fetching client data:', error);
        setFetchError('Failed to fetch client data.');
      }
    } finally {
      setClientLoading(false);
    }
  }, 500); // 500ms debounce

  const geocodeAddress = async (address) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address + ', Canada')}`);
      const data = await response.json();
      if (data && data.length > 0) {
        return {
          latitude: parseFloat(data[0].lat),
          longitude: parseFloat(data[0].lon)
        };
      }
      throw new Error('Unable to geocode address');
    } catch (error) {
      console.error('Geocoding error:', error);
      throw error;
    }
  };

  const handleSiteChange = (value) => {
    if (value) {
      const selectedSite = clientSites.find(site => site.site_id === value);
      if (selectedSite) {
        const newPosition = [selectedSite.latitude, selectedSite.longitude];
        setMarkerPosition(newPosition);
        setLocation({ lat: selectedSite.latitude, lng: selectedSite.longitude });

        form.setFieldsValue({
          latitude: selectedSite.latitude,
          longitude: selectedSite.longitude
        });

        // Fly to the new position immediately if the map is available
        if (map) {
          map.flyTo(newPosition, 16);
        }
      }
    }
  };

  // Add this function to get the Mapbox tile layer URL
  const getMapboxUrl = () => {
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    return `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`;
  };

  const photoUploadProps = {
    onRemove: (photo) => {
      const index = photos.indexOf(photo);
      const newPhotoList = photos.slice();
      newPhotoList.splice(index, 1);
      setPhotos(newPhotoList);
    },
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG files!');
        return Upload.LIST_IGNORE;
      }

      if (!isLt5M) {
        message.error('Image must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }

      setPhotos([...photos, file]);
      return false;
    },
    photos,
    maxCount: 5, // Maximum number of files
    accept: '.jpg,.jpeg,.png', // Accepted file types
  };

  // Add tutorial handler
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTutorial(false);
      // Save to localStorage so we don't show the tutorial again
      localStorage.setItem('serviceRequestTutorialComplete', 'true');
    }
  };

  // Add effect to check if we should show tutorial
  useEffect(() => {
    const tutorialComplete = localStorage.getItem('serviceRequestTutorialComplete');
    if (!tutorialComplete) {
      setRunTutorial(true);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Joyride
        steps={steps}
        run={runTutorial}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#034832',
            zIndex: 1000,
          },
          tooltip: {
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            padding: '20px',
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          buttonNext: {
            backgroundColor: '#034832',
            borderRadius: '6px',
            color: '#fff',
            padding: '8px 16px',
            fontSize: '14px',
            fontWeight: '500',
            transition: 'all 0.3s',
            '&:hover': {
              backgroundColor: '#023623',
              transform: 'translateY(-1px)',
            }
          },
          buttonBack: {
            marginRight: 10,
            color: '#666',
            padding: '8px 16px',
            fontSize: '14px',
            fontWeight: '500',
          },
          buttonSkip: {
            color: '#666',
            fontSize: '14px',
          },
          buttonClose: {
            color: '#666',
            fontSize: '14px',
          },
          spotlight: {
            backgroundColor: 'rgba(255, 255, 255, 0.45)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
          }
        }}
      />

      <div className="client-request-form">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Row 
            justify="space-between" 
            align="middle" 
            style={{ 
              marginBottom: 24,
              background: 'linear-gradient(to right, #fff, #f0f7f4)',
              padding: '20px 24px',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Col>
              <Title level={3} style={{ 
                margin: 0, 
                background: 'linear-gradient(45deg, #034832, #0a6245)',
                WebkitBackgroundClip: 'text', 
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
              }}>
                Service Request Form
              </Title>
            </Col>
            <Col>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button 
                  type="primary"
                  icon={<QuestionCircleOutlined />}
                  onClick={() => setRunTutorial(true)}
                  style={{
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    background: 'linear-gradient(45deg, #034832, #0a6245)',
                    border: 'none',
                    boxShadow: '0 2px 8px rgba(3, 72, 50, 0.35)',
                    padding: '8px 16px',
                    height: 'auto',
                    fontFamily: 'Plus Jakarta Sans, sans-serif',
                  }}
                >
                  Show Tutorial
                </Button>
              </motion.div>
            </Col>
          </Row>
        </motion.div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            client_email: userEmail,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* Add data-tour attributes to elements */}
              <Card bordered={false} style={{ width: '100%' }} data-tour="client-info">
                {/* Client Information */}
                <Divider orientation="left">Client Information</Divider>
                <div data-tour="client-fields">
                  <Form.Item
                    name="client_name"
                    label="Client Name"
                    rules={[{ required: true, message: 'Please enter the client name' }]}
                  >
                    <Input disabled={userRole === 'client'} />
                  </Form.Item>
                  <Form.Item
                    name="client_email"
                    label="Client Email"
                    rules={[
                      { required: true, message: 'Please enter the client email' },
                      { type: 'email', message: 'Please enter a valid email' }
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </div>
                <Form.Item
                  name="site"
                  label="Site"
                  rules={[{ required: true, message: 'Please select a site' }]}
                  data-tour="site-selector"
                >
                  <Select
                    placeholder="Select a site"
                    options={sitesDropdown}
                    loading={clientLoading}
                    onChange={handleSiteChange}
                  />
                </Form.Item>

                <Form.Item data-tour="custom-location">
                  <Checkbox
                    checked={useCustomLocation}
                    onChange={(e) => {
                      setUseCustomLocation(e.target.checked);
                      if (!e.target.checked) {
                        const siteId = form.getFieldValue('site');
                        if (siteId) {
                          const selectedSite = clientSites.find(site => site.site_id === siteId);
                          if (selectedSite) {
                            const newPosition = [selectedSite.latitude, selectedSite.longitude];
                            setMarkerPosition(newPosition);
                            setLocation({ lat: selectedSite.latitude, lng: selectedSite.longitude });
                            form.setFieldsValue({
                              latitude: selectedSite.latitude,
                              longitude: selectedSite.longitude
                            });
                          }
                        }
                      }
                    }}
                  >
                    Use custom location within site
                  </Checkbox>
                </Form.Item>

                {useCustomLocation && (
                  <Form.Item
                    label="Custom Coordinates"
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="latitude"
                      style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                      <Input placeholder="Latitude" readOnly />
                    </Form.Item>
                    <Form.Item
                      name="longitude"
                      style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                      <Input placeholder="Longitude" readOnly />
                    </Form.Item>
                  </Form.Item>
                )}

                {/* Request Details */}
                <Divider orientation="left">Request Details</Divider>
                <div data-tour="request-details">
                  <Form.Item
                    name="request_type"
                    label="Request Type"
                    rules={[{ required: true, message: 'Please select a request type' }]}
                  >
                    <Select
                      placeholder="Select a request type"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      <Option value="salting_lots">Salting Lots</Option>
                      <Option value="plowing_lots">Plowing Lots</Option>
                      <Option value="emergency_exits">Emergency Exits</Option>
                      <Option value="loading_docks">Loading Docks</Option>
                      <Option value="walkways">Walkways</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="details"
                    label="Request Details"
                    rules={[{ required: true, message: 'Please provide details about your request' }]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Describe your request in detail"
                      allowClear
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  name="photos"
                  label={
                    <span>
                      Photos 
                      <Text type="secondary" style={{ marginLeft: 8, fontSize: '12px' }}>
                        (Max: 5 photos, 5MB each, JPG/PNG only)
                      </Text>
                    </span>
                  }
                  valuePropName="photos"
                  data-tour="photos"
                >
                  <Upload {...photoUploadProps} listType="picture">
                    <Button icon={<UploadOutlined />}>Upload photos (optional)</Button>
                  </Upload>
                </Form.Item>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card bordered={false} style={{ width: '100%', height: '100%' }} data-tour="map">
                <Divider orientation="left">Select Location</Divider>
                <div style={{ height: screens.md ? '450px' : '300px', width: '100%' }}>
                  <MapContainer
                    center={[43.6532, -79.3832]}
                    zoom={12}
                    whenCreated={handleMapCreated}  // Make sure this is properly set
                    style={{ height: '100%', width: '100%' }}
                  >
                    <TileLayer
                      url={getMapboxUrl()}
                      attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                      maxZoom={19}
                    />
                    <LocationMarker />
                  </MapContainer>
                </div>
                <Text type="secondary">
                  {useCustomLocation
                    ? "Click on the map to select a specific location within the site"
                    : "Map shows the selected site's location"}
                </Text>
              </Card>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row justify="center" style={{ marginTop: '20px' }}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item data-tour="submit">
                <Button type="primary" htmlType="submit" block size={screens.xs ? 'large' : 'middle'}>
                  Submit Request
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default ClientRequestForm;
