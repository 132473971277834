// LoginPage.js
import React, { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../authentication/axiosInstance';
import companyLogo from '../assets/company-logo.png';
import styles from './LoginPage.module.css';
import FormTextField from '../components/FormTextField';
import { motion } from 'framer-motion';
import image1 from '../assets/login-page.jpg';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserRole, checkAuth } = useContext(AuthContext);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    if (!email) {
      setNotificationMessage('Email is required.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (!emailPattern.test(email)) {
      setNotificationMessage('Invalid email.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (!password) {
      setNotificationMessage('Password is required.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    try {
      const response = await api.post('/users/login/', { email, password });

      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);

      // localStorage.setItem('csrfToken', response.data.csrfToken);

      // Update local AuthContext state immediately
      setIsAuthenticated(true);
      setUserRole(response.data.role);

      // Fetch updated user data for the new session
      await checkAuth();

      // Redirect to the homepage or dashboard
      navigate('/');
    } catch (error) {
      setNotificationMessage('Login failed.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      console.error('Login failed:', error);
      if (error.response && error.response.data) {
        console.log(error.response);
      }
    }
  };

  const handleForgotPassword = () => {
    console.log('Forgot Password Clicked');
  };

  return (
    <Box className={styles.main}>
      <Box className={styles.left}>
        <img src={companyLogo} alt="Company Logo" className={styles.logo} />
        <motion.div 
          initial={{ opacity: 0, translateY: 200 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
          }}
          className={styles.formContainer}
        >
          <Box className={styles.textContainer}>
            <Typography className={styles.title}>
              Welcome Back!
            </Typography>
            <Typography className={styles.subtitle}>
              Please sign in to continue.
            </Typography>
          </Box>
          <Box className={styles.form}>
            <FormTextField
              labelName="Email"
              idName="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter your email..."
            />
            <FormTextField
              labelName="Password"
              idName="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter your password..."
              type="password"
            />
            <Typography className={styles.forgotPassword} onClick={handleForgotPassword}>
              Forgot Password?
            </Typography>
            <Box onClick={handleSignIn} className={styles.submitButton}>
              Sign In
            </Box>
          </Box>
        </motion.div>
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity={notificationSeverity} className={styles.alert}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        <Typography className={styles.copyright}>
            &copy; {new Date().getFullYear()} Facilitate
          </Typography>
      </Box>
      <Box className={styles.right}>
        <Box className={styles.rightImage} component="img" src={image1} />
      </Box>
    </Box>
  );
};

export default LoginPage;
