import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import api from '../authentication/axiosInstance';
import companyLogo from '../assets/company-logo.png';

const allocatedColor = rgb(0.8039, 0.6863, 0.3686); // Facilitate gold/brown color
const allocatedSectionColor = rgb(0.6, 0.4, 0.2);   // brownish color

export const drawParkingLotAllocation = (page, site, x, y, size) => {
  const parkingLotsSqft = site.parking_lots_sqft || 0;
  const parkingLotsAllocated = site.parking_lots_sqft_allocated || 0;

  if (parkingLotsSqft === 0) {
    throw new Error('Parking lot sqft cannot be zero');
  }

  const allocatedPercentage = parkingLotsAllocated / parkingLotsSqft;

  page.drawEllipse({
    x,
    y,
    xScale: size,
    yScale: size,
    color: rgb(0.8, 0.8, 0.8),
  });

  if (allocatedPercentage > 0) {
    const startAngle = Math.PI / 2; // starting at top of cirle (12 o'clock)
    const allocatedAngle = -2 * Math.PI * allocatedPercentage; // filling clockwise
    drawArc(page, x, y, size, startAngle, startAngle + allocatedAngle, allocatedColor);
  }
  // section allocated portion into vehicle capacities
  if (site.vehicles_lots && site.vehicles_lots.length > 0 && parkingLotsAllocated > 0) {
    const totalCapacity = site.vehicles_lots.reduce((sum, vehicle) => sum + vehicle.allocated_capacity, 0);
    if (totalCapacity > 0) {
      let currentAngle = Math.PI / 2;
      site.vehicles_lots.forEach((vehicle) => {
        const vehicleCapacityPercentage = vehicle.allocated_capacity / parkingLotsSqft;
        const vehicleAngle = -2 * Math.PI * vehicleCapacityPercentage;
        const endAngle = currentAngle + vehicleAngle;

        drawLineFromCenter(page, x, y, size, currentAngle, allocatedSectionColor);
        drawLineFromCenter(page, x, y, size, endAngle, allocatedSectionColor);

        currentAngle = endAngle;
      });
      drawLineFromCenter(page, x, y, size, Math.PI / 2 + -2 * Math.PI * allocatedPercentage, allocatedSectionColor);
    }
  }
};

export const drawWalkwayAllocation = (page, site, x, y, size) => {
  const walkwaySqft = site.walkway_sqft || 0;
  const walkwayAllocated = site.walkway_sqft_allocated || 0;

  if (walkwaySqft === 0) {
    throw new Error('Walkway sqft cannot be zero');
  }

  const allocatedPercentage = walkwayAllocated / walkwaySqft;

  page.drawEllipse({
    x,
    y,
    xScale: size,
    yScale: size,
    color: rgb(0.8, 0.8, 0.8),
  });

  if (allocatedPercentage > 0) {
    const startAngle = Math.PI / 2; // starting at top of cirle (12 o'clock)
    const allocatedAngle = -2 * Math.PI * allocatedPercentage; // filling clockwise
    drawArc(page, x, y, size, startAngle, startAngle + allocatedAngle, allocatedColor);
  }
};

const drawArc = (page, x, y, radius, startAngle, endAngle, color) => {
  const steps = 250;
  const stepAngle = (endAngle - startAngle) / steps;
  let currentAngle = startAngle;

  for (let i = 0; i <= steps; i++) {
    const newX = x + radius * Math.cos(currentAngle);
    const newY = y + radius * Math.sin(currentAngle);
    page.drawLine({
      start: { x, y },
      end: { x: newX, y: newY },
      color,
      thickness: 1,
    });
    currentAngle += stepAngle;
  }
};

const drawLineFromCenter = (page, x, y, radius, angle, color) => {
  const endX = x + radius * Math.cos(angle);
  const endY = y + radius * Math.sin(angle);
  page.drawLine({
    start: { x, y },
    end: { x: endX, y: endY },
    color,
    thickness: 1,
  });
};

export const generateSiteDetailsPDF = async (site) => {
  if (!site) {
    throw new Error('Error generating site details pdf, no site data provided');
  }

  // // create new pdf document
  const pdfDoc = await PDFDocument.create();
  const pageWidth = 612;
  const pageHeight = 792;
  const page = pdfDoc.addPage([pageWidth, pageHeight]); // letter size page in points
  const margin = 36; // 1/2 inch margin = 36 points
  const chartSize = 40;
  let yPos = pageHeight - margin * 3;
  const lineSpacing = 20;
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // // header logo
  const logoImageBytes = await fetch(companyLogo).then(res => res.arrayBuffer());
  const logoImage = await pdfDoc.embedPng(logoImageBytes);
  const logoDims = logoImage.scale(0.2);

  page.drawImage(logoImage, {
    x: margin,
    y: page.getHeight() - logoDims.height - 20,
    width: logoDims.width,
    height: logoDims.height,
  });

  // // site map
  try {
    const response = await api.get(`/sites/${site.site_id}/fetch_map/`);
    if (response.data && response.data.map_image) {
      const mapImageBytes = await fetch(`data:image/png;base64,${response.data.map_image}`).then(res => res.arrayBuffer());
      const mapImage = await pdfDoc.embedPng(mapImageBytes);
      const mapDims = mapImage.scale(0.5);

      page.drawImage(mapImage, {
        x: pageWidth - margin - mapDims.width,
        y: pageHeight - margin * 2 - mapDims.height,
        width: mapDims.width,
        height: mapDims.height,
      });

      const latLongText = `Latitude: ${site.latitude}, Longitude: ${site.longitude}`;
      const latLongTextSize = 12;
      page.drawText(latLongText, {
        x: pageWidth - margin - mapDims.width,
        y: pageHeight - margin * 2 - mapDims.height - latLongTextSize,
        size: latLongTextSize,
        font: font,
        color: rgb(0, 0, 0),
      });
    }
  } catch (error) {
    console.error('Error fetching map image:', error);
  }

  // // site information
  page.drawText('Site Name:', { x: margin, y: yPos, size: 14, font: boldFont, color: rgb(0, 0, 0) });
  yPos -= lineSpacing;
  page.drawText(`${site.site_name || 'N/A'}`, { x: margin + 20, y: yPos, size: 14, font: font, color: rgb(0, 0, 0) });
  yPos -= lineSpacing * 1.5;

  page.drawText('Client Name:', { x: margin, y: yPos, size: 14, font: boldFont, color: rgb(0, 0, 0) });
  yPos -= lineSpacing;
  page.drawText(`${site.client_name || 'N/A'}`, { x: margin + 20, y: yPos, size: 14, font: font, color: rgb(0, 0, 0) });
  yPos -= lineSpacing * 1.5;

  page.drawText('Address:', { x: margin, y: yPos, size: 14, font: boldFont, color: rgb(0, 0, 0) });
  yPos -= lineSpacing;
  page.drawText(`${site.address || 'N/A'}`, { x: margin + 20, y: yPos, size: 14, font: font, color: rgb(0, 0, 0) });
  yPos -= lineSpacing * 1.5;

  // // allocation charts
  const pieChartY = yPos - 120;
  const leftHalfXCenter = pageWidth / 4;
  const rightHalfXCenter = (pageWidth * 3) / 4;
  // parking lot allocation chart & info
  const lotsTitle = 'Parking Lots Allocation';
  const lotsTitleWidth = font.widthOfTextAtSize(lotsTitle, 14);
  page.drawText(lotsTitle, { 
    x: leftHalfXCenter - lotsTitleWidth / 2, 
    y: pieChartY + 60, 
    size: 14, 
    font: boldFont, 
    color: rgb(0, 0, 0) 
  });

  drawParkingLotAllocation(page, site, leftHalfXCenter, pieChartY, chartSize);

  const parkingLotsSqft = site.parking_lots_sqft || 0;
  const parkingLotsAllocated = site.parking_lots_sqft_allocated || 0;
  const allocatedParkingLotsPercentage = parkingLotsSqft !== 0 ? parkingLotsAllocated / parkingLotsSqft : -1;
  
  const formattedParkingLotsSqft = parkingLotsSqft.toLocaleString();
  const formattedParkingLotsAllocated = parkingLotsAllocated.toLocaleString();
  const parkingSummary = `${formattedParkingLotsAllocated} sqft of ${formattedParkingLotsSqft} sqft allocated, ${allocatedParkingLotsPercentage !== -1 ? Math.round(allocatedParkingLotsPercentage * 100) : 'N/A'}%`;
  const parkingSummaryWidth = font.widthOfTextAtSize(parkingSummary, 12);
  page.drawText(parkingSummary, {
    x: leftHalfXCenter - parkingSummaryWidth / 2,
    y: pieChartY - chartSize - lineSpacing * 1.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // walkway allocation chart & info
  const walksTitle = 'Walkway Allocation';
  const walksTitleWidth = font.widthOfTextAtSize(walksTitle, 14);
  page.drawText(walksTitle, { 
    x: rightHalfXCenter - walksTitleWidth / 2, 
    y: pieChartY + 60, 
    size: 14, 
    font: boldFont, 
    color: rgb(0, 0, 0) 
  });

  drawWalkwayAllocation(page, site, rightHalfXCenter, pieChartY, chartSize);

  const walkwaySqft = site.walkway_sqft || 0;
  const walkwayAllocated = site.walkway_sqft_allocated || 0;
  const allocatedWalkwayPercentage = walkwaySqft !== 0 ? walkwayAllocated / walkwaySqft : -1;

  const formattedWalkwaySqft = walkwaySqft.toLocaleString();
  const formattedWalkwayAllocated = walkwayAllocated.toLocaleString();
  const walkwaySummary = `${formattedWalkwayAllocated} sqft of ${formattedWalkwaySqft} sqft allocated, ${allocatedWalkwayPercentage !== -1 ? Math.round(allocatedWalkwayPercentage * 100) : 'N/A'}%`;
  const walkwaySummaryWidth = font.widthOfTextAtSize(walkwaySummary, 12);
  page.drawText(walkwaySummary, {
    x: rightHalfXCenter - walkwaySummaryWidth / 2,
    y: pieChartY - chartSize - lineSpacing * 1.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // // vehicle allocation information
  yPos = pieChartY - chartSize - lineSpacing * 4;
  page.drawText('Loader Allocations:', { x: margin, y: yPos, size: 14, font: boldFont, color: rgb(0, 0, 0) });
  yPos -= lineSpacing * 1.5;

  if (site.vehicles_lots && site.vehicles_lots.length > 0) {
    const tableX = margin + 20;
    const columnWidths = [100, 175, 225]; // widths for loader, plow, and staff columns
    let tableY = yPos;

    page.drawLine({
      start: { x: tableX, y: tableY - 5 },
      end: { x: tableX + columnWidths.reduce((a, b) => a + b, 0), y: tableY - 5 },
      thickness: 1,
      color: rgb(0, 0, 0),
    });
  
    // table headers
    page.drawText('Loader', { x: tableX, y: tableY, size: 12, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText('Plow', { x: tableX + columnWidths[0], y: tableY, size: 12, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText('Staff', { x: tableX + columnWidths[0] + columnWidths[1], y: tableY, size: 12, font: boldFont, color: rgb(0, 0, 0) });
    
    tableY -= lineSpacing * 1.5;
  
    site.vehicles_lots.forEach((vehicle) => {
      console.log('Vehicle:', vehicle);
      // loader column
      page.drawText(`${vehicle.vehicle_name} (${vehicle.vehicle_subtype || 'N/A'})`, {
        x: tableX,
        y: tableY,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
  
      // plow column
      if (vehicle.assigned_plow) {
        const plow = vehicle.assigned_plow;
        const plowText = `${plow.plow_name} (${plow.plow_model}, ${plow.plow_size} ft)`;
        page.drawText(plowText, {
          x: tableX + columnWidths[0],
          y: tableY,
          size: 12,
          font,
          color: rgb(0, 0, 0),
        });
      } else {
        page.drawText('No plow assigned', {
          x: tableX + columnWidths[0],
          y: tableY,
          size: 12,
          font,
          color: rgb(0, 0, 0),
        });
      }
  
      // staff column (as bullet list)
      let staffY = tableY;
      if (vehicle.assigned_staff && vehicle.assigned_staff.length > 0) {
        vehicle.assigned_staff.forEach((staff, index) => {
          page.drawText(`• ${staff.name} - ${staff.position}`, {
            x: tableX + columnWidths[0] + columnWidths[1],
            y: staffY,
            size: 12,
            font,
            color: rgb(0, 0, 0),
          });
          staffY -= lineSpacing;
        });
      } else {
        page.drawText('No staff assigned', {
          x: tableX + columnWidths[0] + columnWidths[1],
          y: tableY,
          size: 12,
          font,
          color: rgb(0, 0, 0),
        });
      }
  
      tableY = staffY - lineSpacing * 0.5; // space between rows
    });
  } else {
    page.drawText('No Vehicles Allocated', {
      x: pageWidth / 2 - font.widthOfTextAtSize('No Vehicles Allocated', 14) / 2,
      y: yPos,
      size: 14,
      font: boldFont,
      color: rgb(0, 0, 0),
    });
  }

  // // save PDF and trigger download
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${site.site_name}.pdf`;
  link.click();
};