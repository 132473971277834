import {
    Autocomplete,
    FormControl,
    InputBase,
    InputLabel,
    Popper,
    Typography,
    styled,
  } from "@mui/material";
  import React, { useEffect, useRef } from "react";
  
  const BootstrapInput = styled(InputBase)(({ theme, error, label }) => ({
    "label + &": {
      marginTop: label !== "" ? theme.spacing(3) : 0,
    },
    "& .MuiInputBase-input": {
      borderRadius: 6,
      position: "relative",
      backgroundColor: "white",
      border: `1px solid ${error ? "red" : "rgb(229, 234, 239)"}`,
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      "&:hover": {
        borderColor: error ? "red" : "rgb(229, 234, 239)",
      },
      "&:focus": {
        boxShadow: error ? "red 0 0 0 0.03rem" : "#004630 0 0 0 0.03rem",
        borderColor: error ? "red" : "#004630",
      },
    },
  }));
  
  const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: 1200,
    border: `1px solid rgb(229, 234, 239);`,
    borderRadius: "5px",
    "& .MuiAutocomplete-groupLabel": {
      fontFamily: "'Plus Jakarta Sans', sans-serif",
    },
    "&[data-popper-placement*='bottom'] .MuiAutocomplete-paper": {
      animation: `fadeIn 200ms ${theme.transitions.easing.easeOut}`,
    },
    "&[data-popper-placement*='top'] .MuiAutocomplete-paper": {
      animation: `fadeIn 200ms ${theme.transitions.easing.easeOut}`,
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
        transform: "translateY(-10px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
    "@keyframes fadeOut": {
      "0%": {
        opacity: 1,
        transform: "translateY(0)",
      },
      "100%": {
        opacity: 0,
        transform: "translateY(10px)",
      },
    },
  }));
  
  export default function FormTextField({
    idName,
    labelName,
    value,
    onChange,
    disabled,
    placeholder,
    type,
    onSubmit,
    multiline,
    select,
    options,
    autofocus,
    maxLength,
    error, // Add error prop
  }) {
    const inputRef = useRef(null);
  
    const handlePhoneInputChange = (event) => {
      const onlyNums = event.target.value.replace(/[^\d]/g, "");
      if (onlyNums.length <= 10) {
        let formattedNumber = onlyNums;
        if (onlyNums.length > 3) {
          formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
        }
        if (onlyNums.length > 6) {
          formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(
            3,
            6
          )}-${onlyNums.slice(6)}`;
        }
        onChange({ target: { value: formattedNumber } });
      }
    };
  
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [value]);
  
    if (select && options) {
      return (
        <FormControl variant="standard" fullWidth>
          <InputLabel
            shrink
            htmlFor={idName}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              color: "#000",
              fontWeight: "600",
            }}
          >
            {labelName}
          </InputLabel>
          <Autocomplete
            id={idName}
            fullWidth
            autoFocus={false}
            options={options}
            getOptionLabel={(option) => option}
            value={value}
            forcePopupIcon={false}
            disableClearable
            disabled={disabled}
            onChange={(event, newValue) => {
              onChange({ target: { value: newValue } });
            }}
            PopperComponent={StyledPopper}
            renderInput={(params) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor={idName}
                  sx={{
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  {labelName}
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  label={labelName}
                  {...params.InputProps}
                  inputRef={params.InputProps.ref}
                  placeholder={placeholder}
                  name={idName}
                  disabled={disabled}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  error={error} // Pass error state
                />
              </FormControl>
            )}
          />
        </FormControl>
      );
    }
    return (
      <FormControl variant="standard" fullWidth>
        {labelName !== "" && (
          <InputLabel
            shrink
            htmlFor={idName}
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              color: "#000",
              fontWeight: "600",
              "&.Mui-focused": {
                color: "#004630",
              },
            }}
          >
            {labelName}
          </InputLabel>
        )}
        <BootstrapInput
          id={idName}
          onChange={type === "phone" ? handlePhoneInputChange : onChange}
          inputRef={inputRef}
          placeholder={placeholder}
          value={value}
          name={idName}
          multiline={multiline}
          rows={multiline ? 3 : undefined}
          disabled={disabled}
          autoFocus={!!autofocus}
          type={type === "phone" ? "tel" : type}
          onKeyDown={(event) => {
            if (event.key === "Enter" && type === "password" && onSubmit) {
              event.preventDefault();
              onSubmit();
            }
          }}
          style={{ width: type === "search" ? "250px" : "100%" }}
          inputProps={{
            maxLength: maxLength,
          }}
          error={!!error} // Pass error state
        />
        {error && (
          <Typography
            variant="body2"
            color="red"
            sx={{ fontFamily: "'Plus Jakarta Sans', sans-serif", marginTop: "3px" }}
          >
            {error}
          </Typography>
        )}
      </FormControl>
    );
  }
  