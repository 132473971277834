import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import { ConfigProvider, Button, Modal, Spin, message, AutoComplete, Select, Divider } from 'antd';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useAllocationDrop from '../hooks/useAllocationDrop';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import api from "../authentication/axiosInstance";
import './MapView.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';
import BlueMarker from '../assets/BlueMarker.png';
import MarkerShadow from '../assets/MarkerShadow.png';
import RedMarker from '../assets/RedMarker.png';
import GreenMarker from '../assets/GreenMarker.png';
import YellowMarker from '../assets/YellowMarker.png';
import { IconTable } from '@tabler/icons-react';
import styles from './EquipmentAllocationTable.module.css';
import { DataList } from '@radix-ui/themes';

const { Option } = Select;

const customBlueMarkerIcon = L.icon({
  iconUrl: BlueMarker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const customRedMarkerIcon = L.icon({
  iconUrl: RedMarker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const customGreenMarkerIcon = L.icon({
  iconUrl: GreenMarker,
  iconSize: [25, 45],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const customYellowMarkerIcon = L.icon({
  iconUrl: YellowMarker,
  iconSize: [25, 45],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const VehicleCard = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
`;

const VehicleList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 250px;
`;

const EquipmentCard = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  max-width: 150px;
`;

const EquipmentList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  max-width: 200px;
`;

const EquipmentPopup = ({ equipment, onClose }) => {
  return (
    <Modal
      title="Equipment Details"
      visible={true}
      onCancel={onClose}
      footer={null}
    >
      <p>Name: {equipment.equipment_name}</p>
      <p>Brand: {equipment.equipment_brand}</p>
      <p>Type: {equipment.equipment_type_name}</p>
      <p>Assigned to:</p>
      {equipment.assigned_sites && equipment.assigned_sites.length > 0 ? (
        <ul>
          {equipment.assigned_sites.map((siteId) => (
            <li key={siteId}>Site {siteId}</li>
          ))}
        </ul>
      ) : (
        <p>Not assigned to any sites</p>
      )}
    </Modal>
  );
};

const EquipmentDragCard = ({ equipment, allocationMode }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'equipment',
    item: {
      id: equipment.equipment_id,
      type: 'equipment',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: false,
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  return (
    <>
      <EquipmentCard
        ref={allocationMode ? drag : null}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        onClick={allocationMode ? undefined : handleClick}
      >
        {equipment.equipment_name}
      </EquipmentCard>
      {showPopup && (
        <EquipmentPopup equipment={equipment} onClose={() => setShowPopup(false)} />
      )}
    </>
  );
};

const MapView = () => {
  const [sites, setSites] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allocationMode, setAllocationMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [equipmentCurrentPage, setEquipmentCurrentPage] = useState(1);
  const [vehicleCurrentPage, setVehicleCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(10);
  const pageSize = 8;
  const mapRef = React.createRef();
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [surfaceTypeFilter, setSurfaceTypeFilter] = useState("none");
  const navigate = useNavigate();

  const VehiclePopup = ({ vehicle, onClose }) => {
    return (
      <Modal
        title="Vehicle Details"
        open={true}
        onCancel={onClose}
        footer={null}
      >
        <p>Model: {vehicle.vehicle_model}</p>
        <p>Surface Type: {vehicle.surface_type}</p>
        <p>Max Capacity: {vehicle.vehicle_capacity.toLocaleString()} sq. ft</p>
        <p>Remaining Capacity: {vehicle.remaining_capacity.toLocaleString()} sq. ft</p>
        <p>Assigned Sites: </p>
          {vehicle.allocated_sites && vehicle.allocated_sites.length > 0 ? (
            <ul>
              {vehicle.allocated_sites.map((siteAllocation) => {
                const site = sites.find(s => s.site_id === siteAllocation.site_id);
                return (
                  <li key={siteAllocation.site_id}>
                    {site 
                      ? `Site ${site.site_id}: ${site.site_name} - Allocated Capacity: ${siteAllocation.allocated_capacity.toLocaleString()} sq. ft` 
                      : `Site ${siteAllocation.site_id} not found`}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>Not assigned to any sites</p>
          )}
      </Modal>
    );
  };
  
  const VehicleDragCard = ({ vehicle, allocationMode }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "vehicle",
      item: {
        id: vehicle.vehicle_id,
        type: "vehicle",
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: false,
    });
  
    const [showPopup, setShowPopup] = useState(false);
  
    const handleClick = (e) => {
      e.preventDefault();
      setShowPopup(true);
    };
  
    return (
      <>
        <VehicleCard
          ref={allocationMode ? drag : null}
          style={{ opacity: isDragging ? 0.5 : 1 }}
          onClick={allocationMode ? undefined : handleClick}
        >
          <Box style={{ width:"200px"}}>
          <Box>{vehicle.vehicle_name}, {vehicle.vehicle_model}</Box>
            <Box style={{display:"flex", alignItems: "center"}}>
              <LinearProgress style={{ minWidth:"50px", flex:9 , marginRight: 8 }}variant="determinate" value={((vehicle.vehicle_capacity - vehicle.remaining_capacity)/vehicle.vehicle_capacity)*100}/>
              <Box style={{ flex:1 }}>{(((vehicle.vehicle_capacity - vehicle.remaining_capacity)/vehicle.vehicle_capacity)*100).toFixed(1)}%</Box>
            </Box>
          </Box>
        </VehicleCard>
        {showPopup && (
          <VehiclePopup vehicle={vehicle} onClose={() => setShowPopup(false)} />
        )}
      </>
    );
  };

  useEffect(() => {
    fetchSites();
    fetchVehicles();
    fetchEquipment();
  }, []);

  const fetchSites = async () => {
    setLoading(true);
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    }
    setLoading(false);
  };

  const fetchVehicles = async () => {
    try {
      const response = await api.get("/vehicles/");
      const fetchedVehicles = response.data;

      let filteredVehicles; //apply filter immediately on fetch
      if (surfaceTypeFilter === "lots") {
        filteredVehicles = fetchedVehicles.filter(vehicle => vehicle.surface_type === "lots");
      } else if (surfaceTypeFilter === "walks") {
        filteredVehicles = fetchedVehicles.filter(vehicle => vehicle.surface_type === "walks");
      } else if (surfaceTypeFilter === "walks crew") {
        filteredVehicles = fetchedVehicles.filter(vehicle => vehicle.surface_type === "walks crew");
      } else {
        filteredVehicles = fetchedVehicles;
      }

      setVehicles(fetchedVehicles);
      setFilteredVehicles(filteredVehicles)
    } catch (error) {
      console.error("Failed to fetch vehicles:", error);
      message.error("Failed to fetch vehicles");
    }
  };

  const fetchEquipment = async () => {
    try {
      const response = await api.get('/equipment/');
      setEquipment(response.data);
    } catch (error) {
      console.error('Failed to fetch equipment:', error);
      message.error('Failed to fetch equipment');
    }
  };

  const handleMarkerClick = (site) => {
    setSelectedSite(site);
    if (!allocationMode){
      setShowModal(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedSite(null);
  };

  const allocateVehicle = async (siteId, vehicleId) => {
    try {
      await api.post(
        `/vehicles/${vehicleId}/assign_site/`,
        {
          site_id: siteId,
        },
      ).then(response => {
        message.success(response.data.message)
      });
      fetchSites();
      fetchVehicles();
    } catch (error) {
      console.error("Failed to assign vehicle:", error.response.data.message);
      message.error(error.response.data.message);
    }
  };

  const allocateEquipment = async (siteId, equipmentId) => {
    try {
      await api.post(`/equipment/${equipmentId}/assign_site/`, {
        site_id: siteId,
      });
      message.success('Equipment assigned to site successfully');
      fetchSites();
      fetchEquipment();
    } catch (error) {
      console.error('Failed to assign equipment:', error);
      message.error('Failed to assign equipment');
    }
  };

  const ActionButton = ({ site }) => {
    const { isOver, drop } = useAllocationDrop(site, allocateVehicle, allocateEquipment);

    return (
      <Box style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"10px"}}>
        <Box style={{ fontWeight: "bold", fontSize: "16px" }}>{site.site_name}</Box>
        {/* <Button
          ref={drop}
          type="primary"
          style={{
            backgroundColor: isOver ? '#cdaf5e' : '#cdaf5e',
            borderColor: isOver ? '#cdaf5e' : '#cdaf5e',
            width:100,
          }}
          onClick={() => handleMarkerClick(site)}
        >
          {allocationMode ? 'Allocate' : 'View Details'}
        </Button> */}
        <Box>
          {site.parking_lots_sqft_allocated.toLocaleString()} / {site.parking_lots_sqft.toLocaleString()} square feet
          <Box style={{display:"flex", alignItems: "center", width:250}}>
            <LinearProgress style={{ flex:9 , marginRight: 8 }} variant="determinate" value={site.parking_lots_sqft_allocated/site.parking_lots_sqft*100}/>
            <Box style={{ flex: 1 }}>{(site.parking_lots_sqft_allocated/site.parking_lots_sqft*100).toFixed(1)}%</Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleZoomEnd = () => {
    setZoomLevel(mapRef.current.leafletElement.getZoom());
  };

  const handleSearchChange = (value) => {
    const filteredSites = sites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    const options = filteredSites.map((site) => ({
      value: site.site_name,
      label: site.site_name,
      site: site,
    }));
    setSearchOptions(options);
  };

  const handleSearchSelect = (value, option) => {
    setSelectedSite(option.site);
  };

  const handleSearchClick = () => {
    if (selectedSite) {
      const { latitude, longitude } = selectedSite;
      if (latitude && longitude) {
        mapRef.current.flyTo([parseFloat(latitude), parseFloat(longitude)], mapRef.current.getMaxZoom());
      }
    }
  };

  const getRegionCenter = (region) => {
    const regionSites = sites.filter((site) => site.region === region);
    const latitudes = regionSites.map((site) => parseFloat(site.latitude));
    const longitudes = regionSites.map((site) => parseFloat(site.longitude));
    const centerLatitude = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
    const centerLongitude = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
    return [centerLatitude, centerLongitude];
  };

  const getUniqueRegions = () => {
    const regions = sites.map((site) => site.region);
    return [...new Set(regions)];
  };

  const handleSwitchTableView = () => {
    navigate('/allocation');
  }

  const getMarkerIcon = (site) => {
    const allocated_percentage = (site.parking_lots_sqft_allocated/site.parking_lots_sqft)*100;
    if (allocated_percentage === 100) { //fully allocated
      return customGreenMarkerIcon; //green marker
    } else if (allocated_percentage >= 50) { //over half allocated
      return customYellowMarkerIcon; //yellow marker
    } else { //less than half allocated
      return customRedMarkerIcon; //red marker
    }
  }

  const handleSurfaceTypeFilterChange = (value) => {
    setSurfaceTypeFilter(value);
  };

  // Reset the current page to 1 when filter changes changes
  useEffect(() => {
    setVehicleCurrentPage(1);
  }, [surfaceTypeFilter]);

  useEffect(() => {
    if (surfaceTypeFilter === "any") {
      setFilteredVehicles(vehicles);
    } else {
      setFilteredVehicles(vehicles.filter(vehicle => vehicle.surface_type === surfaceTypeFilter));
    }

  }, [surfaceTypeFilter]);

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '82vh', padding: '20px' }}>
            <div style={{ flex: 1, display: 'flex' }}>
              <div style={{ flex: 1, position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Select a region"
                        value={selectedRegion}
                        onChange={(value) => setSelectedRegion(value)}
                      >
                        {getUniqueRegions().map((region) => (
                          <Select.Option key={region} value={region}>
                            {region}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <AutoComplete
                      style={{ width: 200 }}
                      options={searchOptions}
                      onSelect={handleSearchSelect}
                      onSearch={handleSearchChange}
                      placeholder="Search sites"
                    />
                    <Button onClick={handleSearchClick}>Search</Button>
                  </div>
  
                  <div className={styles.tableButton} onClick={handleSwitchTableView} style={{ fontSize: '14px', padding: '10px 18px' }}>
                      <IconTable size={16}/>
                      Table View
                    </div>
                </div>
    
                <Spin spinning={loading}>
                  <MapContainer
                    center={selectedRegion ? getRegionCenter(selectedRegion) : [43.6532, -79.3832]}
                    zoom={selectedRegion ? 11 : 10}
                    style={{ height: '80vh', width: '100%' }}
                    ref={mapRef}
                    whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
                    onZoomEnd={handleZoomEnd}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {sites
                      .filter((site) => !selectedRegion || site.region === selectedRegion)
                      .map((site) => (
                        <Marker
                          key={site.site_id}
                          position={[parseFloat(site.latitude), parseFloat(site.longitude)]}
                          icon={getMarkerIcon(site)}
                          eventHandlers={{
                            click: () => handleMarkerClick(site),
                          }}
                        >
                          {zoomLevel >= 12 && <Tooltip permanent direction="top">{site.site_name}</Tooltip>}
                          {allocationMode && (
                            <Popup>
                              <ActionButton site={site} />
                            </Popup>
                          )}
                        </Marker>
                      ))}
                  </MapContainer>
                </Spin>
    
                <Modal
                  title={selectedSite ? selectedSite.site_name : ''}
                  visible={showModal}
                  onCancel={handleModalCancel}
                  footer={null}
                  centered
                >
                  {selectedSite && (
                    <div>
                      <span style={{ fontSize: '16px', fontWeight: '550'}}>General Information</span>
                      <DataList.Root style={{ gap: '5px', marginTop: '10px' }}>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Site Name</DataList.Label>
                          <DataList.Value>{selectedSite.site_name}</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Parking Lots Area</DataList.Label>
                          <DataList.Value>{selectedSite.parking_lots_sqft.toLocaleString()} sq. ft</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Walks Area</DataList.Label>
                          <DataList.Value>{selectedSite.walkway_sqft.toLocaleString()} sq. ft</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Salt Usage</DataList.Label>
                          <DataList.Value>{selectedSite.salt_done ? "Yes" : "No"}</DataList.Value>
                        </DataList.Item>
                      </DataList.Root>
                      
                      <Divider />

                      <span style={{ fontSize: '16px', fontWeight: '550'}}>More Information</span>

                      <p>Parking Lots Assigned Vehicles:</p>
                      {selectedSite.vehicles_lots?.length > 0 ? (
                        <ul>
                          {selectedSite.vehicles_lots.map((vehicle) => (
                            <li key={vehicle.vehicle_id}>
                              {vehicle.vehicle_name}, {vehicle.vehicle_model} (Assigned Capacity: {vehicle.allocated_capacity.toLocaleString()} sq. ft)
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p style={{ color: "#a9a9a9" }}>No Parking Lots Assigned Vehicles</p>
                      )}
                      <p>Assigned Equipment:</p>
                      {selectedSite.equipment?.length > 0 ? (
                        <ul>
                          {selectedSite.equipment.map((eq) => (
                            <li key={eq.equipment_id}>{eq.equipment_name}</li>
                          ))}
                        </ul>
                      ) : (
                        <p style={{ color: "#a9a9a9" }}>No assigned equipment</p>
                      )}
                    </div>
                  )}
                </Modal>
              </div>
            </div>
          </div>
      </DndProvider>
    </ConfigProvider>
  );
};

export default MapView;
