// WalkwayCrewAllocationPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, Table, Button, Spin, message, Input, Select, Modal, Form, Checkbox } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import api from '../authentication/axiosInstance';
import { Box } from '@mui/material';
import useAllocationDrop from '../hooks/useAllocationDrop';
import {LinearProgress} from '@mui/material';
import { saveAs } from 'file-saver';
import { IconPlus, IconFile, IconTool, IconBuildingFactory2, IconUser, IconTractor, IconLock, IconLockOpen, IconInfoCircle, IconEdit } from '@tabler/icons-react';
import styles from './WalkwayCrewAllocationPage.module.css';

const { Option } = Select;
const { confirm } = Modal;

const Card = styled.div`
  background-color: #f0f0f0;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 300px;
  max-height: 650px;
  overflow-y: scroll;
`;

const ToggleButtonGroup = styled.div`
  display: flex;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  overflow: hidden;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 5px 15px;
  background-color: ${({ isSelected }) => (isSelected ? '#e6f7ff' : '#fff')};
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#034832' : '#000')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e6f7ff;
    color: #034832;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
`;

const AllocateCell = ({ record, allocateItem }) => {
  const { isOver, drop } = useAllocationDrop(record, () => {}, () => {}, () => {}, () => {}, allocateItem);

  return (
    <div ref={drop} style={{ width: '80%', height: 140, marginTop: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', border: isOver ? "2px dashed #034832" : "2px dashed #c9c9c9", borderRadius: '5px', color: isOver ? "#034832" : "#c9c9c9", fontSize: '16px', fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
      Drag Here to Allocate
    </div>
  );
};

const SortableItem = ({ site, index, moveSite, crewId, deallocateItem }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'site',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSite(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'site',
    item: { type: 'site', id: site.site.site_id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '8px',
        marginBottom: '4px',
        backgroundColor: '#fafafa',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>
        <strong>{index + 1}. </strong>
        {site.site.client_name} - {site.site.site_name} - {site.region} - {site.square_footage_allocated} sqft
      </span>
      <Button onClick={() => deallocateItem(crewId, site.site.site_id)} size="small">Deallocate</Button>
    </li>
  );
};

const SortableList = ({ sites, moveSite, crewId, deallocateItem }) => {
  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {sites.map((site, index) => (
        <SortableItem 
          key={site.site.site_id} 
          site={site} 
          index={index} 
          moveSite={moveSite} 
          crewId={crewId}
          deallocateItem={deallocateItem}
        />
      ))}
      {sites.length === 0 && (
        <li style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
          No sites allocated
        </li>
      )}
    </ul>
  );
};

const generateWalkwayCrewPDF = async () => {
  try {
    const response = await api.get('/walkwayCrew/walkwaycrews/pdf/', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'walkway_crew_allocation_report.pdf');
  } catch (error) {
    console.error('Failed to generate PDF:', error);
    message.error('Failed to generate PDF report');
  }
};

const WalkwayCrewAllocationPage = () => {
  const [sites, setSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [siteRegions, setSiteRegions] = useState([]);
  const [crewVehicles, setCrewVehicles] = useState([]);
  const [walkwayEquipment, setWalkwayEquipment] = useState([]);
  const [walkwayCrews, setWalkwayCrews] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allocationMode, setAllocationMode] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [selectedList, setSelectedList] = useState('sites');
  const [selectedStaffPosition, setSelectedStaffPosition] = useState('All Walkway Staff');

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentWalkwayCrew, setCurrentWalkwayCrew] = useState(null);
  const [newWalkwayCrewName, setNewWalkwayCrewName] = useState('');
  const [newRequiredNumStaff, setNewRequiredNumStaff] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedWalkwayCrew, setSelectedWalkwayCrew] = useState(null);
  const [walkwayCrewName, setWalkwayCrewName] = useState('');
  const [region, setRegion] = useState('');
  const [requiredNumStaff, setRequiredNumStaff] = useState(0);

  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [unlockCrewId, setUnlockCrewId] = useState(null);
  const [password, setPassword] = useState("");

  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [searchTextStaff, setSearchTextStaff] = useState("");
  const [searchTextSite, setSearchTextSite] = useState("");
  const [searchTextVehicle, setSearchTextVehicle] = useState("");
  const [searchTextEquipment, setSearchTextEquipment] = useState("");
  const [searchTextCondo, setSearchTextCondo] = useState("");


  const DragCard = ({ item, allocationMode }) => {
    let itemId;
    let itemType;
  
    let isAllocated;
    if (selectedList === 'sites') {
      itemId = item.site_id;
      itemType = 'sites';
      isAllocated = item.assigned_walkway_crew_bool;
    } else if (selectedList === 'staff') {
      itemId = item.staff_id;
      itemType = 'staff';
      isAllocated = item.assigned_walkway_crew_bool|| item.assigned_plow_route_bool;
    } else if (selectedList === 'vehicles') {
      itemId = item.vehicle_id;
      itemType = 'vehicle';
      isAllocated = item.assigned_walkway_crew_bool || item.assigned_plow_route_bool;
    } else if (selectedList === 'equipment') {
      itemId = item.equipment_id;
      itemType = 'equipment';
      isAllocated = item.assigned_walkway_crew_bool || item.assigned_plow_route_bool;
    }
  
    // Update the canDrag logic to allow dragging of already allocated sites
    let canDrag;
    if (itemType === 'sites') { //sites  can be allocated to multiple walkway crews
      canDrag = allocationMode
    }
    else {
      canDrag = allocationMode && !isAllocated;
    }
  
    const [{ isDragging }, drag] = useDrag({
      type: itemType,
      item: { id: itemId, type: itemType },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: canDrag,
    });
  
    const backgroundColor = isAllocated ? '#E5F2EC' : '#f0f0f0';
    const borderColor = (selectedList === 'sites') && item.assigned_walkway_crew_bool ? '#cdaf5e' : 'transparent';
  
    // Fetch the allocation count for sites
    const allocationCount = item.assigned_walkway_crew_count || 0;
  
    return (
      <Card
        ref={canDrag ? drag : null}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: backgroundColor,
          cursor: canDrag ? 'grab' : 'pointer',
          borderColor: borderColor,
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
      >
        <Box style={{ width: '100%', padding: '10px' }}>
          {selectedList === 'sites' && (
             <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <span style={{ fontWeight: '550', fontSize: '16px', textAlign: 'left' }}><IconBuildingFactory2 size={14} style={{ marginRight: '3px' }}/>{item.site_name}</span>
                  <span style={{ fontSize: '15px', minWidth: '70px' }}>({allocationCount} crews)</span>
                </div>
                <span style={{ fontSize: '14px' }}>{item.client_name}</span>       
              </div>
          )}
          {selectedList === 'vehicles' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{item.vehicle_name}</span>
              <span style={{ fontSize: '14px' }}>{item.vehicle_type}, {item.vehicle_model}</span>       
            </div>
          )}
          {selectedList === 'staff' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconUser size={14} style={{ marginRight: '3px' }} />{item.name}</span>
              <span style={{ fontSize: '14px', marginBottom: 4 }}>{item.position}</span>       
              <span style={{ fontSize: '14px' }}>City: {item.city ? item.city : 'Unspecified'}</span>       
              <span style={{ fontSize: '14px' }}>Rating: {item.staff_rating ? item.staff_rating : 'Unspecified'}</span>    
              <span style={{ fontSize: '14px' }}>Overall Rating: {item.staff_overall_rating ? item.staff_overall_rating : 'Unspecified'}</span>    
            </div>
          )}
          {selectedList === 'equipment' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTool size={14} style={{ marginRight: '3px' }} />{item.equipment_name}</span>
              <span style={{ fontSize: '14px' }}>{item.equipment_type_name}</span>       
            </div>
          )}
        </Box>
      </Card>
    );
  };
  
  const SortableSiteList = ({ sites, crewId, fetchWalkwayCrews, deallocateItem }) => {
    const [siteList, setSiteList] = useState(sites);
  
    useEffect(() => {
      setSiteList(sites);
    }, [sites]);
  
    const moveSite = useCallback((dragIndex, hoverIndex) => {
      const updatedSites = [...siteList];
      const [removed] = updatedSites.splice(dragIndex, 1);
      updatedSites.splice(hoverIndex, 0, removed);
      setSiteList(updatedSites);
    }, [siteList]);
  
    const handleReorder = async () => {
      const site_order = siteList.map(site => site.site.site_id);
      try {
        await api.post(`/walkwayCrew/walkwaycrews/${crewId}/reorder_sites/`, { site_order });
        message.success('Site order updated successfully');
        fetchWalkwayCrews();
        if (isDetailsModalVisible){
          handleDetailsModalCancel();
        }
      } catch (error) {
        console.error('Failed to reorder sites:', error.response?.data?.message);
        message.error(error.response?.data?.message || 'Failed to reorder sites');
      }
    };
  
    return (
      <Box style={{ width: '100%' }}>
        <DndProvider backend={HTML5Backend}>
          <SortableList 
            sites={siteList} 
            moveSite={moveSite} 
            crewId={crewId}
            deallocateItem={deallocateItem}
          />
        </DndProvider>
        <Button type="primary" disabled={sites.length === 0} onClick={handleReorder} style={{ marginTop: '10px', boxShadow: 'none' }}>
          Save Order
        </Button>
      </Box>
    );
  };

  const finalizeWalkwayCrewAllocation = async (CrewId) => {
    try {
      await api.post(`/walkwayCrew/walkwaycrews/${CrewId}/finalize_walkway_allocation/`);
      message.success("Walkway Crew finalized successfully");
      if (expandedRowKey) {
        const expandedRow = walkwayCrews.find(
          (crew) => crew.walkway_crew_id === expandedRowKey
        );
        if (expandedRow && expandedRow.finalized_bool) {
          setExpandedRowKey(null);
          setAllocationMode(false);
        }
      }
      fetchWalkwayCrews();
    } catch (error) {
      console.error("Failed to finalize walkway crew allocation:", error);
      message.error("Failed to finalize walkway crew allocation");
    }
  };

  const UnlockWalkwayCrewAllocation = async (CrewId, password) => {
    try {
      await api.post(`/walkwayCrew/walkwaycrews/${CrewId}/unlock_walkway_allocation/`, {
        password,
      });
      message.success("walkway crew allocation unlocked successfully");
      fetchWalkwayCrews();
    } catch (error) {
      console.error("Failed to unlock walkway crew allocation:", error);
      message.error("Failed to unlock walkway crew allocation");
    }
  };

  useEffect(() => {
    fetchSites();
    fetchStaff();
    fetchCrewVehicles();
    fetchWalkwayEquipment();
    fetchWalkwayCrews();
    fetchSiteRegions();
  }, []);

  useEffect(() => {
    updateFilteredItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, sites, staff, crewVehicles, selectedRegion, selectedStaffPosition, walkwayEquipment]);

  const fetchSites = async () => {
    setLoading(true);
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
      setFilteredSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchStaff = async () => {
    setLoading(true);
    try {
      const response = await api.get('/staff/walkway_staff/');
      setStaff(response.data);
      setFilteredStaff(response.data);
    } catch (error) {
      console.error('Failed to fetch staff:', error);
      message.error('Failed to fetch staff');
    } finally {
      setLoading(false);
    }
  };

  const fetchCrewVehicles = async () => {
    setLoading(true);
    try {
      const response = await api.get('/vehicles/crew_vehicles/');
      setCrewVehicles(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error('Failed to fetch crew vehicles:', error);
      message.error('Failed to fetch crew vehicles');
    } finally {
      setLoading(false);
    }
  };

  const fetchWalkwayEquipment = async () => {
    setLoading(true);
    try {
      const response = await api.get('/equipment/walkway_equipment/');
      setWalkwayEquipment(response.data);
      setFilteredEquipment(response.data);
    } catch (error) {
      console.error('Failed to fetch walkway equipment:', error);
      message.error('Failed to fetch walkway equipment');
    } finally {
      setLoading(false);
    }
  };

  const fetchWalkwayCrews = async () => {
    setLoading(true);
    try {
      const response = await api.get('/walkwayCrew/walkwaycrews/');
      setWalkwayCrews(response.data);
    } catch (error) {
      console.error('Failed to fetch walkway crews:', error);
      message.error('Failed to fetch walkway crews');
    } finally {
      setLoading(false);
    }
  };

  const fetchSiteRegions = async () => {
    try {
      const response = await api.get('/sites/site_regions/');
      setSiteRegions(response.data);
    } catch (error) {
      console.error('Failed to fetch regions:', error);
      message.error('Failed to fetch regions');
    }
  };

  const updateFilteredItems = () => {
    if (selectedList === 'sites') {
      const filtered = selectedRegion === 'all' ? sites : sites.filter((site) => site.region === selectedRegion);
      setFilteredItems(filtered);
    } else if (selectedList === 'staff') {
      let filtered = staff;
      if (selectedStaffPosition !== 'All Walkway Staff') {
        filtered = staff.filter((member) => member.position === selectedStaffPosition);
      }
      setFilteredItems(filtered);
    } else if (selectedList === 'vehicles') {
      setFilteredItems(crewVehicles);
    } else if (selectedList === 'equipment') {
      setFilteredItems(walkwayEquipment);
    } 
  };

  const allocateItem = async (crewId, itemId) => {
    try {
      await api.post(`/walkwayCrew/walkwaycrews/${crewId}/allocate_item/`, { item_id: itemId, item_type: selectedList });
      message.success('Allocated successfully');
      fetchWalkwayCrews();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchCrewVehicles();
      if (selectedList === 'equipment') fetchWalkwayEquipment();
    } catch (error) {
      console.error('Failed to allocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to allocate');
    }
  };

  const deallocateItem = async (crewId, itemId) => {
    try {
      await api.post(`/walkwayCrew/walkwaycrews/${crewId}/deallocate_item/`, {
        item_id: itemId,
        item_type: selectedList,
      });
      message.success('Deallocated successfully');
      fetchWalkwayCrews();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchCrewVehicles();
      if (selectedList === 'equipment') fetchWalkwayEquipment();
    } catch (error) {
      console.error('Failed to deallocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to deallocate');
    }
  };

  const handleUpdateWalkwayCrew = async () => {
    if (!newWalkwayCrewName.trim()) {
      message.error('Walkway Crew Name cannot be empty.');
      return;
    }
    if (newRequiredNumStaff === '' || isNaN(newRequiredNumStaff) || Number(newRequiredNumStaff) < 0) {
      message.error('Required Number of Staff must be a non-negative number.');
      return;
    }
  
    try {
      await api.put(`/walkwayCrew/walkwaycrews/${currentWalkwayCrew.walkway_crew_id}/`, {
        walkway_crew_name: newWalkwayCrewName,
        required_num_staff: Number(newRequiredNumStaff),
      });
      message.success('Walkway Crew updated successfully');
      fetchWalkwayCrews();
      setIsEditModalVisible(false);
      setCurrentWalkwayCrew(null);
      setNewWalkwayCrewName('');
      setNewRequiredNumStaff(0);
    } catch (error) {
      console.error('Failed to update walkway crew:', error.response?.data?.message || error);
      message.error(error.response?.data?.message || 'Failed to update walkway crew');
    }
  };

  const handleRegionSelect = (value) => {
    setSelectedRegion(value);
    const filtered = value === 'all' ? sites : sites.filter((site) => site.region === value);
    setFilteredSites(filtered);
  };

  const handleStaffPositionSelect = (value) => {
    setSelectedStaffPosition(value);
    let filtered = staff;
    if (value !== 'All Walkway Staff') {
      filtered = staff.filter((member) => member.position === value);
    }
    setFilteredStaff(filtered);
  };

  const toggleList = (list) => {
    setSelectedList(list);
    if (list === 'sites') {
      setSelectedRegion('all');
    } else if (list === 'staff') {
      setSelectedStaffPosition('All Walkway Staff');
    }
  };

  const getButtonStyle = (list) => ({
    backgroundColor: selectedList === list ? '#e6f2ed' : '#fff',
    color: selectedList === list ? '#034832' : '#000',
    fontWeight: '550',
    padding: '8px 8px',
    fontSize: '13px',
  });

  const handleCreateWalkwayCrew = async () => {
    try {
      await api.post('/walkwayCrew/walkwaycrews/generate_walkway_crew/', {
        walkway_crew_name: walkwayCrewName,
        walkway_crew_region: region,
        required_num_staff: requiredNumStaff,
      });
      message.success('Walkway Crew created successfully');
      fetchWalkwayCrews();
      setIsModalVisible(false);
      setWalkwayCrewName('');
      setRegion('');
      setRequiredNumStaff(0);
    } catch (error) {
      console.error('Failed to create walkway crew:', error);
      message.error('Failed to create walkway crew');
    }
  };

  const deleteWalkwayCrew = async (crewId) => {
    try {
      const response = await api.delete(`/walkwayCrew/walkwaycrews/${crewId}/delete_walkway_crew/`);
      message.success(response.data.message);
      fetchWalkwayCrews();
    } catch (error) {
      console.error('Failed to delete walkway crew:', error.response?.data?.message || error.message);
      message.error(error.response?.data?.message || 'Failed to delete walkway crew');
    }
  };

  const showDeleteConfirm = (crewId) => {
    confirm({
      title: 'Are you sure you want to delete this walkway crew?',
      content: 'This action cannot be undone, and you must deallocate all items before deletion.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteWalkwayCrew(crewId);
      },
      onCancel() {},
    });
  };

  const showDetailsModal = (walkwayCrew) => {
    setSelectedWalkwayCrew(walkwayCrew);
    setIsDetailsModalVisible(true);
  };

  const showEditModal = (crew) => {
    setCurrentWalkwayCrew(crew);
    setNewWalkwayCrewName(crew.walkway_crew_name);
    setNewRequiredNumStaff(crew.required_num_staff);
    setIsEditModalVisible(true);
  };

  const handleDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setSelectedWalkwayCrew(null);
  };

  const WalkwayCrewDetailsModal = ({ visible, onCancel, crew }) => {
    if (!crew) return null;

    return (
      <Modal
      title={<span style={{ fontSize: '24px', fontWeight: '600' }}>{crew.walkway_crew_name}</span>}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <p><strong>Region:</strong> {crew.walkway_crew_region}</p>
        <p><strong>Assigned Vehicle:</strong> {crew.assigned_vehicle_bool ? 'Yes' : 'No'}</p>
        <p><strong>Total Square Footage:</strong> {crew.square_footage}</p>
        <p><strong>Number of Staff:</strong> {crew.number_of_staff}</p>
        <p><strong>Square Footage Per Staff:</strong> {crew.number_of_staff && crew.number_of_staff > 0 ? (crew.square_footage / crew.number_of_staff).toFixed(2) : 'N/A'}</p>
        <p><strong>Assigned Sites:</strong></p>
        <SortableSiteList sites={crew.sites_walks} crewId={crew.walkway_crew_id} fetchWalkwayCrews={fetchWalkwayCrews} deallocateItem={deallocateItem}/>
        <Button
          type="default"
          danger
          onClick={() => {
              showDeleteConfirm(crew.walkway_crew_id)
            }
          }
          style={{ marginTop: '5px' }}
        >
          Delete Route
        </Button>
      </Modal>
    );
  };

  const expandedRowRender = (record) => {
    const getAssignedLabel = () => {
      switch (selectedList) {
        case "sites":
          return "Assigned Sites";
        case "staff":
          return "Assigned Staff";
        case "vehicles":
          return "Assigned Vehicles";
        case "equipment":
          return "Assigned Equipment";
        default:
          return "Assigned Items";
      }
    };

    const renderAssignedItems = () => {
      switch (selectedList) {
        case "sites":
          return (
            <SortableSiteList 
              sites={record.sites_walks} 
              crewId={record.walkway_crew_id}
              fetchWalkwayCrews={fetchWalkwayCrews}
              deallocateItem={deallocateItem}
            />
          );
        case "equipment":
          return (
            <ul>
            {record.equipment_walks.map((equipment) => (
              <li key={equipment.equipment.equipment_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {equipment.equipment_name} - {equipment.equipment_type_name}
                <Button
                  onClick={() => deallocateItem(record.walkway_crew_id, equipment.equipment.equipment_id)}
                >
                  Deallocate
                </Button>
              </li>
            ))}
            {record.equipment_walks.length === 0 && (
              <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                No Equipment allocated
              </div>
            )}
          </ul>
          );
        case "staff":
          return (
            <ul>
              {record.staff_walks.map((staff) => (
                <li key={staff.staff.staff_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {staff.staff_name} - {staff.staff_position}, Rating: {staff.staff.staff_rating ? staff.staff.staff_rating : "Unspecified"}
                  <Button
                    onClick={() => deallocateItem(record.walkway_crew_id, staff.staff.staff_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.staff_walks.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Staff allocated
                </div>
              )}
            </ul>
          );
        case "vehicles":
          return (
            <ul>
              {record.vehicles_walks.map((vehicle) => (
                <li key={vehicle.vehicle.vehicle_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {vehicle.vehicle_type} - {vehicle.vehicle_name}
                  <Button
                    onClick={() => deallocateItem(record.walkway_crew_id, vehicle.vehicle.vehicle_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.vehicles_walks.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Vehicles allocated
                </div>
              )}
            </ul>
          );
        default:
          return null;
      }
    };

    return (
      <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', marginRight: 10 }}>
          <AllocateCell
            record={record}
            allocateItem={allocateItem}
          />
        </Box>
        <Box style={{ flex: 1 }}>
          <p style={{ fontWeight: 'bold' }}>{getAssignedLabel()}:</p>
          {renderAssignedItems()}
        </Box>
      </Box>
    );
  };

  const onTableExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.walkway_crew_id : null);
    setAllocationMode(expanded);
  };

  const handleWalkwayCrewDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setSelectedWalkwayCrew(null);
  };

  const handleSearchStaff = (value) => {
    setSearchTextStaff(value); 
    const filtered = staff.filter((member) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStaff(filtered);
  };

  const handleSearchSite = (value) => {
    setSearchTextSite(value); 
    const filtered = sites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleSearchVehicle = (value) => {
    setSearchTextVehicle(value); 
    const filtered = crewVehicles.filter((vehicle) =>
      vehicle.vehicle_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  const handleSearchEquipment = (value) => {
    setSearchTextEquipment(value); 
    const filtered = walkwayEquipment.filter((equipment) =>
      equipment.equipment_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredEquipment(filtered);
  };


  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
              <span style={{fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px'}}>
                Walkway Crews
              </span>
              <ToggleButtonGroup>
                <ToggleButton onClick={() => toggleList('sites')} isSelected={selectedList === 'sites'} style={getButtonStyle('sites')}>
                  Sites
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('staff')} isSelected={selectedList === 'staff'} style={getButtonStyle('staff')}>
                  Staff
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('vehicles')} isSelected={selectedList === 'vehicles'} style={getButtonStyle('vehicles')}>
                  Vehicles
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('equipment')} isSelected={selectedList === 'equipment'} style={getButtonStyle('equipment')}>
                  Equipment
                </ToggleButton>
              </ToggleButtonGroup>

              {selectedList === 'sites' && (
                <div style={{width:"100%"}}>
                  <Select
                    placeholder="Select Region"
                    value={selectedRegion}
                    style={{ width: '100%' }}
                    onChange={handleRegionSelect}
                  >
                    <Option value="all">All Regions</Option>
                    {siteRegions.map((region) => (
                      <Option key={region} value={region}>
                        {region.charAt(0).toUpperCase() + region.slice(1)}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    style={{ width: '100%', marginTop:10}}
                    value={searchTextSite}
                    onChange={(e) => handleSearchSite(e.target.value)}
                    placeholder="Search site (from all regions)"
                  />
                </div>
              )}

              {selectedList === 'staff' && (
                <div style={{width:"100%"}}>
                  <Select
                    placeholder="Select Position"
                    value={selectedStaffPosition}
                    style={{ width: '100%' }}
                    onChange={handleStaffPositionSelect}
                  >
                    <Option value="All Walkway Staff">All Walkway Staff</Option>
                    <Option value="Walkway Technician">Walkway Technician</Option>
                    <Option value="Walkway Vehicle Operator">Walkway Vehicle Operator</Option>
                  </Select>
                  <Input
                    style={{ width: '100%', marginTop:10}}
                    value={searchTextStaff}
                    onChange={(e) => handleSearchStaff(e.target.value)}  // Call handleSearch on input change
                    placeholder="Search staff (from all walkway staff)"
                  />
                </div>
              )}

              {selectedList === 'vehicles' && (
                <div style={{width:"100%"}}>
                  <Input
                    style={{ width: '100%', marginTop:10}}
                    value={searchTextVehicle}
                    onChange={(e) => handleSearchVehicle(e.target.value)}  // Call handleSearch on input change
                    placeholder="Search vehicle"
                  />
                </div>
              )}

              {selectedList === 'equipment' && (
                <div style={{width:"100%"}}>
                  <Input
                    style={{ width: '100%', marginTop:10}}
                    value={searchTextEquipment}
                    onChange={(e) => handleSearchEquipment(e.target.value)}  // Call handleSearch on input change
                    placeholder="Search equipment"
                  />
                </div>
              )}

              <ListContainer>
                {selectedList === 'staff' && filteredStaff.map((staff) => (
                  <DragCard key={staff.staff_id} item={staff} allocationMode={allocationMode} />
                ))}
                {selectedList === 'sites' && filteredSites.map((site) => (
                  <DragCard key={site.site_id} item={site} allocationMode={allocationMode} />
                ))}
                {selectedList === 'vehicles' && filteredVehicles.map((vehicle) => (
                  <DragCard key={vehicle.vehicle_id} item={vehicle} allocationMode={allocationMode} />
                ))}
                {selectedList === 'equipment' && filteredEquipment.map((equipment) => (
                  <DragCard key={equipment.equipment_id} item={equipment} allocationMode={allocationMode} />
                ))}
              </ListContainer>
            </div>
            <div style={{ width: 'calc(100% - 320px)', marginLeft: '20px'}}>
              <style>
                {`.locked-row {
                    background-color: #f0f0f0 !important;
                  }
                `}
              </style>
              <style>
                {`.ant-table-tbody > tr:hover > td {
                    background: none !important;
                  }
              `}
              </style>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', marginBottom: '15px' }}>
                <div className={styles.tableButton} onClick={() => setIsModalVisible(true)} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconPlus size={16}/>
                  Create Walkway Crew
                </div>
                <div className={styles.tableButton} onClick={generateWalkwayCrewPDF} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconFile size={16}/>
                  Generate PDF Report
                </div>
              </div>
              <Spin spinning={loading}>
                <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                  <Table
                    dataSource={walkwayCrews}
                    columns={[
                      {
                        title: 'Crew Name',
                        dataIndex: 'walkway_crew_name',
                        key: 'walkway_crew_name',
                      },
                      {
                        title: 'Region',
                        dataIndex: 'walkway_crew_region',
                        key: 'walkway_crew_region',
                      },
                      {
                        title: 'Assigned Vehicle',
                        dataIndex: 'assigned_vehicle_bool',
                        key: 'assigned_vehicle_bool',
                        width: 90,
                        render: (text, record) => (
                          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', width: '100%' }}>
                            <Checkbox
                              checked={record.assigned_vehicle_bool}
                              disabled
                            />
                          </Box>
                        ),
                      },
                      {
                        title: 'Total SQFT',
                        dataIndex: 'square_footage',
                        key: 'square_footage',
                        width: 110,
                        render: (text, record) => (
                          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', width: '100%' }}>
                            {text} sq. ft.
                          </Box>
                        )
                      },
                      {
                        title: 'Staff Allocation Percentage',
                        key: 'staff_allocation_percentage',
                        render: (text, record) => {
                          const { number_of_staff, required_num_staff } = record;
                      
                          // Check if required_num_staff is greater than 0 to avoid division by zero
                          if (required_num_staff && required_num_staff > 0) {
                            const percentage = (number_of_staff / required_num_staff) * 100;
                      
                            // Cap the percentage at 100% for the progress bar
                            const displayPercentage = percentage > 100 ? 100 : percentage;
                      
                            return (
                              <span>
                                {/* Display the percentage with one decimal place */}
                                {number_of_staff} allocated / {required_num_staff} required {' '}
                                <Box style={{ display: "flex", alignItems: "center", width: 250, marginTop: 8 }}>
                                  <LinearProgress 
                                    style={{ flex: 9, marginRight: 8 }} 
                                    variant="determinate" 
                                    value={displayPercentage}
                                  />
                                  <Box style={{ flex: 1 }}>
                                    {percentage.toFixed(1)}%
                                  </Box>
                                </Box>
                                {/* Show 'Over Allocated' if percentage exceeds 100% */}
                                {percentage > 100 && (
                                  <Box style={{ color: 'Orange', marginTop: 4 }}>
                                    Over Allocated
                                  </Box>
                                )}
                              </span>
                            );
                          } else {
                            // Display 'N/A' if required_num_staff is 0 or not provided
                            return <span>{number_of_staff} allocated / {required_num_staff} required {' '}</span>;
                          }
                        },
                      },
                      {
                        title: "Locked",
                        key: "locked",
                        width: 100,
                        render: (_, record) => (
                          <Box>
                            {record.finalized_bool ? (
                              <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                                <IconLock size={18} stroke={2} />
                              </div>
                              
                            ) : (
                              <div style={{ backgroundColor: '#f5f5f5', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ebeaea', borderRadius: '4px', color: "#034832" }}>
                                <IconLockOpen size={18} stroke={2} />
                              </div>
                            )}
                          </Box>
                        ),
                      },
                      {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => (
                          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', gap: '5px' }}>
                            <div
                              className={styles.tableButtonGold}
                              style={{ width: '135px' }}
                              onClick={() => {
                                if (record.finalized_bool) {
                                  setUnlockCrewId(record.walkway_crew_id);
                                  setPasswordModalVisible(true);
                                } else {
                                  finalizeWalkwayCrewAllocation(record.walkway_crew_id);
                                }
                              }}
                            >
                              {record.finalized_bool ? "Unlock Finalization" : "Finalize Allocation"}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'fit-content', gap: '5px' }}>
                              <div
                                onClick={() => showEditModal(record)}
                                className={styles.tableButton}
                              >
                                  <IconEdit size={14} /> Edit
                              </div>
                              <div
                                onClick={() => showDetailsModal(record)}
                                className={styles.tableButton}
                              >
                                <IconInfoCircle size={14} />
                                Details
                              </div>
                            </div>
                          </Box>
                        ),
                      },
                    ]}
                    rowKey="walkway_crew_id"
                    pagination={false}
                    rowClassName={(record) =>
                      record.finalized_bool ? 'locked-row' : ''
                    }
                    expandable={{
                      expandedRowRender,
                      expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                      onExpand: onTableExpand,
                      rowExpandable: (record) => !record.finalized_bool,
                    }}
                  />
                </div>
              </Spin>
            </div>
          </div>

          <Modal
            title="Create Walkway Crew"
            visible={isModalVisible}
            onCancel={() => {
                setIsModalVisible(false)
                setWalkwayCrewName('');
                setRegion('');
                setRequiredNumStaff(0);
              }
            }
            onOk={handleCreateWalkwayCrew}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="Walkway Crew Name" required>
                <Input
                  value={walkwayCrewName}
                  onChange={(e) => setWalkwayCrewName(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Region" required style={{ marginTop: '-15px' }}>
                <Input
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder="Enter Region"
                />
              </Form.Item>
              <Form.Item label="Required Number of Staff" required style={{ marginTop: '-15px' }}>
                <Input
                  value={requiredNumStaff}
                  onChange={(e) => setRequiredNumStaff(e.target.value)}
                  placeholder="Enter # of Staff"
                />
              </Form.Item>
            </Form>
          </Modal>

          <WalkwayCrewDetailsModal
            visible={isDetailsModalVisible}
            onCancel={handleWalkwayCrewDetailsModalCancel}
            crew={selectedWalkwayCrew}
          />
          <Modal
            title="Edit Walkway Crew"
            visible={isEditModalVisible}
            onCancel={() => setIsEditModalVisible(false)}
            onOk={handleUpdateWalkwayCrew}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="Walkway Crew Name" required>
                <Input
                  value={newWalkwayCrewName}
                  onChange={(e) => setNewWalkwayCrewName(e.target.value)}
                  placeholder="Enter Walkway Crew Name"
                />
              </Form.Item>
              <Form.Item label="Required Number of Staff" required style={{ marginTop: '-15px' }}>
                <Input
                  type="number"
                  min="0"
                  value={newRequiredNumStaff}
                  onChange={(e) => setNewRequiredNumStaff(e.target.value)}
                  placeholder="Enter Required Number of Staff"
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Enter Password to Unlock"
            open={passwordModalVisible}
            onCancel={() => setPasswordModalVisible(false)}
            onOk={async () => {
              if (password) {
                await UnlockWalkwayCrewAllocation(unlockCrewId, password);
                setPasswordModalVisible(false);
                setPassword("");
              } else {
                message.error("Please enter a password");
              }
            }}
            centered
          >
            <Input.Password
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default WalkwayCrewAllocationPage;
