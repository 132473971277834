import { useDrop } from 'react-dnd';

const useAllocationDrop = (record, allocateVehicle, allocatePlow, allocateStaff, allocateEquipment, allocateItem) => {
  const [{ isOver }, drop] = useDrop({
    accept: ['vehicle', 'plow', 'equipment', 'staff', 'sites', 'condos', 'loaderSites'],
    drop: (item, monitor) => {
      const itemType = monitor.getItemType();
      
      if (itemType === 'vehicle') {
        if (record.walkway_crew_id) {
          // Allocate vehicle to walkway crew
          allocateItem(record.walkway_crew_id, item.id);
        } else if (record.plowRoute_id) {
          // Allocate vehicle to plow route
          allocateItem(record.plowRoute_id, item.id);
        } else if (record.salting_route_id) {
          // Allocate vehicle to salting route
          allocateItem(record.salting_route_id, item.id);
        } else {
          // Allocate vehicle to site
          allocateVehicle(record.site_id, item.id);
        }
      } else if (itemType === 'staff') {
          if (record.walkway_crew_id) {
            // Allocate staff to walkway crew
            allocateItem(record.walkway_crew_id, item.id);
          } else if (record.plowRoute_id) {
            // Allocate vehicle to plow route
            allocateItem(record.plowRoute_id, item.id);
          } else if (record.salting_route_id){
            // Allocate staff to salting route
            allocateItem(record.salting_route_id, item.id);
          } else {
            // Allocate staff to vehicle
            allocateStaff(record.vehicle_id, item.id);
          }
      } else if (itemType === 'plow') {
        allocatePlow(record.vehicle_id, item.id);
      } else if (itemType === 'equipment') {
        if (record.walkway_crew_id) {
          // Allocate equipment to walkway crew
          allocateItem(record.walkway_crew_id, item.id);
        } else if (record.plowRoute_id) {
          // Allocate equipment to plow route
          allocateItem(record.plowRoute_id, item.id);
        } else {
          allocateEquipment(record.site_id, item.id);
        }
      } else if (itemType === 'sites') {
          if (record.walkway_crew_id) {
            // Allocate site to walkway crew
            allocateItem(record.walkway_crew_id, item.id);
          } else if (record.plowRoute_id) {
            // Allocate condo to plow route
            allocateItem(record.plowRoute_id, item.id); //tech debt, this is allocating a condo toa plow route
          } else if (record.salting_route_id){
            // Allocate site to salting route
            allocateItem(record.salting_route_id, item.id);
          }else {
            allocateVehicle(record.site_id, item.id);
          }
      } else if (itemType === 'condos'){
        if (record.walkway_crew_id) {
          // Allocate condo to walkway crew
          allocateItem(record.walkway_crew_id, item.id);
        } else if (record.salting_route_id){
          // Allocate condo to salting route
          allocateItem(record.salting_route_id, item.id);
        }
      } else if (itemType === 'loaderSites'){ //tech debt, this is allocating a regular site to a plow route
        if (record.plowRoute_id) {
          // Allocate site to plow route
          allocateItem(record.plowRoute_id, item.id);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return { isOver, drop };
};


export default useAllocationDrop;
