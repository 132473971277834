// axiosInstance.js
import axios from 'axios';

const base_url = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';

// Ensure the base URL always ends with /api
const api = axios.create({
  baseURL: base_url,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    console.error('Request Error', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is 401 and if we haven't already retried the request
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          // Try refreshing the token
          const response = await axios.post(`${base_url}/users/refresh/`, {
            refresh: refreshToken,
          });

          // Store new access token in localStorage
          console.log('New Access Token:', response.data.access);
          localStorage.setItem('accessToken', response.data.access);

          // Update the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;

          // Retry the original request with the new token
          return api(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh error:', refreshError);
          // If token refresh fails, log out user or redirect to login
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.href = '/login'; // Redirect to login
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
