  import React, { useState, useEffect } from "react";
  import { ConfigProvider, Table as AntTable, Select, Button, Modal, Spin, Divider, message, Input, Dropdown, Menu, AutoComplete, Checkbox } from "antd";
  import { DndProvider, useDrag } from "react-dnd";
  import { HTML5Backend } from "react-dnd-html5-backend";
  import useAllocationDrop from "../hooks/useAllocationDrop";
  import api from "../authentication/axiosInstance";
  import styled from "styled-components";
  import debounce from "lodash/debounce";
  import { Box, LinearProgress } from '@mui/material';
  import { useNavigate } from 'react-router-dom';
  import { DownOutlined } from '@ant-design/icons';
  import { generateSiteDetailsPDF } from "../utils/pdfUtils";
  import { Theme, Table as RadixTable, DataList } from '@radix-ui/themes';
  import '@radix-ui/themes/styles.css';
  import { IconUser, IconTractor, IconLock, IconLockOpen, IconInfoCircle, IconMap } from '@tabler/icons-react';
  import styles from './EquipmentAllocationTable.module.css';

  const { Option } = Select;

  const isStaffAssigned = (vehicle) => {
    return vehicle.assigned_staff && vehicle.assigned_staff.length > 0;
  };

  const AllocateCell = ({
    record, allocateVehicle, allocateEquipment, isDragging,
  }) => {
    const { isOver, drop } = useAllocationDrop(record,allocateVehicle,allocateEquipment,);

    return (
      <div ref={drop} style={{ width: '80%', height: 140, marginTop: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', border: isOver ? "2px dashed #034832" : "2px dashed #c9c9c9", borderRadius: '5px', color: isOver ? "#034832" : "#c9c9c9", fontSize: '16px', fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
        Drag Here to Allocate
      </div>
    );
  };

  const DetailsCell = ({record,allocateVehicle,allocateEquipment,isDragging,showProjectDetails,
  }) => {
    const { drop } = useAllocationDrop(record,allocateVehicle,allocateEquipment);

    return (
      <div
        ref={drop}
        onClick={() => showProjectDetails(record)}
        className={styles.tableButton}
        style={{
          opacity: isDragging ? 0.5 : 1,
          paddingLeft: "45px",
          paddingRight: "45px",
        }}
      >
        <IconInfoCircle size={14} />
        Details
      </div>
    );
  };

  const VehicleCard = styled.div`
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: grab;
    text-align: center;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    display:flex;
    align-items:center;
    justify-content:center;
  `;

  const VehicleList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    max-height: 650px; /* Set a fixed height */
    overflow-y: scroll; /* Enable vertical scrolling */
    margin: 0;
    padding: 0;
  `;

  const EquipmentAllocationTable = () => {
    const [vehicleToReplace, setVehicleToReplace] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState("all");
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedSubtype, setSelectedSubtype] = useState("all");
    const [sites, setSites] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [filteredSites, setFilteredSites] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [allocationMode, setAllocationMode] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Track expanded rows
    const [regions, setRegions] = useState([]);
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [availablePlows, setAvailablePlows] = useState([]);
    const [availableDoubleDowns, setAvailableDoubleDowns] = useState([]);

    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const [unlockSiteId, setUnlockSiteId] = useState(null); // Store the site to unlock
    const [password, setPassword] = useState(""); // Track the input password

    const [selectedContractStatus, setSelectedContractStatus] = useState("all");
    const [isExportMenuVisible, setIsExportMenuVisible] = useState(false);
    const [selectedContractStatuses, setSelectedContractStatuses] = useState(['Estimate', 'Unconfirmed', 'Confirmed']);

    const [isReplaceModalVisible, setIsReplaceModalVisible] = useState(false);
    const [selectedReplacementVehicle, setSelectedReplacementVehicle] = useState(null);
    const [availableReplacementVehicles, setAvailableReplacementVehicles] = useState([]);

    const MAX_EXPANDED_ROWS = 3; // Set the maximum number of rows that can be expanded

    const finalizeLoaderAllocation = async (siteId) => {
      try {
        await api.post(`/sites/${siteId}/finalize_loader_allocation/`);
        message.success("Loader allocation finalized successfully");
        if (expandedRowKeys.includes(siteId)) {
          setExpandedRowKeys(prevKeys => prevKeys.filter(key => key !== siteId));
          if (expandedRowKeys.length === 1) {
            setAllocationMode(false);
          }
        }
        fetchSites(); // Refresh the data after finalizing
      } catch (error) {
        console.error("Failed to finalize loader allocation:", error);
        message.error("Failed to finalize loader allocation");
      }
    };

    const UnlockLoaderAllocation = async (siteId, password) => {
      try {
        await api.post(`/sites/${siteId}/unlock_loader_allocation/`, {
          password,  // Pass the password in the request body
        });
        message.success("Loader allocation unlocked successfully");
        fetchSites(); // Refresh the data after unlocking
      } catch (error) {
        console.error("Failed to unlock loader allocation:", error);
        message.error("Failed to unlock loader allocation");
      }
    };

    const isAllocationPresent = (vehicle) => {
      return (vehicle.assigned_walkway_crew_bool || vehicle.assigned_salting_route_bool || vehicle.assigned_plow || vehicle.assigned_doubleDown || vehicle.assigned_plow_route_bool || 
        (vehicle.allocated_sites && vehicle.allocated_sites.length > 0) || (vehicle.number_staff_assigned > 0));
    }

    const handleReplaceOpen = (vehicle_id) => {
      const capacity = vehicles.find(vehicle => vehicle.vehicle_id === vehicle_id).vehicle_capacity;

      const availableVehicles = vehicles.filter(
        (vehicle) => vehicle.vehicle_id !== vehicle_id && !isAllocationPresent(vehicle)
      );

      const vehicle_to_replace = vehicles.find(vehicle => vehicle.vehicle_id === vehicle_id);
      
      setAvailableReplacementVehicles(availableVehicles);
      setVehicleToReplace(vehicle_to_replace);
      setSelectedReplacementVehicle(null);
      setIsReplaceModalVisible(true);
    }
    

    const VehiclePopup = ({ vehicle, onClose }) => {
      const [selectedPlowSize, setSelectedPlowSize] = useState(vehicle.plow_size);
      const [selectedDoubleDown, setSelectedDoubleDown] = useState(vehicle.assigned_doubleDown_name);

      const handlePlowSizeChange = async (value) => {
        setSelectedPlowSize(value);

        try {
          await api.post(`/vehicles/${vehicle.vehicle_id}/allocate_plow/`, {
            plow_model: value,
          });
          fetchVehicles();
          fetchAvailablePlows();
          message.success("Plow assigned successfully");
        } catch (error) {
          console.error("Failed to assign plow:", error);
          message.error(error.response?.data?.error || "Failed to assign plow");
        }
      };

      const handleDeallocatePlow = async () => {
        try {
          await api.post(`/vehicles/${vehicle.vehicle_id}/deallocate_plow/`);
          fetchVehicles();
          fetchAvailablePlows();
          setSelectedPlowSize('n/a');
          message.success("Plow deallocated successfully");
        } catch (error) {
          console.error("Failed to deallocate plow:", error);
          message.error(error.response?.data?.error || "Failed to deallocate plow");
        }
      };

      const handleDoubleDownChange = async (value) => {
        setSelectedDoubleDown(value);

        try {
          await api.post(`/vehicles/${vehicle.vehicle_id}/allocate_doubledown/`, {
            doubleDown_name: value,
          });
          fetchVehicles();
          fetchAvailableDoubleDowns();
          message.success("Double Down assigned successfully");
        } catch (error) {
          console.error("Failed to assign Double Down:", error);
          message.error(error.response?.data?.error || "Failed to assign Double Down");
        }
      };

      const handleDeallocateDoubleDown = async () => {
        try {
          await api.post(`/vehicles/${vehicle.vehicle_id}/deallocate_doubledown/`);
          fetchVehicles();
          fetchAvailableDoubleDowns();
          setSelectedDoubleDown('n/a');
          message.success("Double Down deallocated successfully");
        } catch (error) {
          console.error("Failed to deallocate Double Down:", error);
          message.error(error.response?.data?.error || "Failed to deallocate Double Down");
        }
      };

      return (
        <Modal
          title="Vehicle Details"
          open={true}
          onCancel={onClose}
          footer={null}
          centered
        >
          <p>Name: {vehicle.vehicle_name}</p>
          <p>Subtype: {vehicle.vehicle_subtype}</p>
          <p>Model: {vehicle.vehicle_model}</p>
          <p>Vehicle Type: {vehicle.vehicle_type}</p>
          <span>Assigned Double Down:</span>
          <Select
            placeholder="Select Double Down"
            style={{ width: '100%', marginTop: 16 }}
            onChange={handleDoubleDownChange}
            value={selectedDoubleDown}
          >
            {availableDoubleDowns.map(doubleDown => (
              <Option key={doubleDown.doubleDown_id} value={doubleDown.doubleDown_name}>
                {doubleDown.doubleDown_name}
              </Option>
            ))}
          </Select>
          {selectedDoubleDown !== 'n/a' && (
            <Button onClick={handleDeallocateDoubleDown} style={{ marginTop: 16 }}>
              Deallocate DoubleDown
            </Button>
          )}
          <p>Surface Type: {vehicle.surface_type}</p>
          <span>Plow Size:</span>
          <Select
            placeholder="Select Plow Size"
            style={{ width: '100%', marginTop: 16 }}
            onChange={handlePlowSizeChange}
            value={selectedPlowSize}
          >
            {availablePlows.map(plow => (
              <Option key={plow.plow_model} value={plow.plow_model}>
                {plow.plow_model} ({plow.count})
              </Option>
            ))}
          </Select>
          {selectedPlowSize !== 'n/a' && (
            <Button onClick={handleDeallocatePlow} style={{ marginTop: 16 }}>
              Deallocate Plow
            </Button>
          )}
          <p>Max Capacity: {vehicle.vehicle_capacity.toLocaleString()} sq. ft</p>
          <p>Remaining Capacity: {vehicle.remaining_capacity.toLocaleString()} sq. ft</p>
          <p>Assigned Sites: </p>
            {vehicle.allocated_sites && vehicle.allocated_sites.length > 0 ? (
              <ul>
                {vehicle.allocated_sites.map((siteAllocation) => {
                  const site = sites.find(s => s.site_id === siteAllocation.site_id);
                  return (
                    <li key={siteAllocation.site_id}>
                      {site 
                        ? `Site ${site.site_id}: ${site.site_name} - Allocated Capacity: ${siteAllocation.allocated_capacity.toLocaleString()} sq. ft` 
                        : `Site ${siteAllocation.site_id} not found`}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul><li>Not assigned to any sites</li></ul>
            )}
            <Button
              type="primary"
              style={{ width: 175, backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
              onClick={() => handleReplaceOpen(vehicle.vehicle_id)}
            >
              Replace Vehicle
            </Button>
        </Modal>
      );
    };

    const VehicleDragCard = ({ vehicle, allocationMode }) => {
      const [{ isDragging }, drag] = useDrag({
        type: "vehicle",
        item: {
          id: vehicle.vehicle_id,
          type: "vehicle",
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: allocationMode && vehicle.remaining_capacity !== 0 && vehicle.assigned_plow_route_bool === false,
      });

      const [showPopup, setShowPopup] = useState(false);

      const handleClick = (e) => {
        e.preventDefault();
        setShowPopup(true);
      };

      const allocationPercentage = ((vehicle.vehicle_capacity - vehicle.remaining_capacity) / vehicle.vehicle_capacity) * 100;
      const isPartiallyAllocated = allocationPercentage > 0 && allocationPercentage < 100;

      const getBackgroundColor = () => {
        if (vehicle.overallocated_to_site) {
          return '#bfe0e0';
        }

        if (isPartiallyAllocated) {
          return '#fff3cd';
        } else if (allocationPercentage === 100 || vehicle.assigned_plow_route_bool) {
          return '#cce6cc';
        } else {
          return '#f0f0f0';
        }
      };

      return (
        <>
          <VehicleCard
            ref={allocationMode ? drag : null}
            style={{ 
              opacity: isDragging ? 0.5 : 1,
              backgroundColor: getBackgroundColor(),
              cursor: (allocationMode && vehicle.remaining_capacity !== 0 ) ? "grab" : "pointer",
              border: isPartiallyAllocated ? '1px solid #cdaf5e' : 'none',
            }}
            onClick={allocationMode ? undefined : handleClick}
          >
            <Box style={{ width: '100%', padding: '10px', position: 'relative' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{vehicle.vehicle_name}</span>
                <span style={{ fontSize: '14px' }}>{vehicle.vehicle_subtype}</span>       
              </div>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <LinearProgress 
                  style={{ 
                    minWidth: "50px", 
                    flex: 9, 
                    marginRight: 8,
                    backgroundColor: '#e0e0e0',
                  }} 
                  variant="determinate" 
                  value={allocationPercentage} 
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: isPartiallyAllocated ? '#cdaf5e' : (allocationPercentage === 100 ? '#034832' : '#e0e0e0')
                    }
                  }}
                />
                <Box style={{ flex: 1 }}>
                  <span style={{ fontSize: '14px' }}>
                  {allocationPercentage.toFixed(1)}%
                  </span>
                </Box>
              </Box>
              {vehicle.plow_size !== "n/a" && (
                <Box style={{fontSize:"14px", position: 'absolute', top: '0', right: '0', marginTop: '13px', marginRight: '13px'}}>
                  Plow: {vehicle.plow_size}
                </Box>
              )}
              {vehicle.assigned_doubleDown_name !== "n/a" && (
                <Box style={{fontSize:"14px", position: 'absolute', top: '30px', right: '0', marginRight: '13px'}}>
                  DoubleDown: {vehicle.assigned_doubleDown_name}
                </Box>
              )}
            </Box>
          </VehicleCard>
          {showPopup && (
            <VehiclePopup vehicle={vehicle} onClose={() => setShowPopup(false)} />
          )}
        </>
      );
    };

    const fetchSites = async () => {
      try {
        const response = await api.get("/sites/");
        setSites(response.data);
        if (selectedRegion !== "all") {
          const filteredSites = response.data.filter((site) => site.region === selectedRegion);
          setFilteredSites(filteredSites)
        } else {
          setFilteredSites(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch sites:", error);
        message.error("Failed to fetch sites");
      }
    };

    const fetchVehicles = async () => {
      try {
        const response = await api.get("/vehicles/loader_allocation_vehicles/");
        const fetchedVehicles = response.data;
        setVehicles(fetchedVehicles);
        filterVehicles(fetchedVehicles, selectedSubtype, searchText); // Filter vehicles based on subtype and search
      } catch (error) {
        console.error("Failed to fetch vehicles:", error);
        message.error("Failed to fetch vehicles");
      }
    };

    const fetchEquipment = async () => {
      try {
        const response = await api.get("/equipment/");
        setEquipment(response.data);
      } catch (error) {
        console.error("Failed to fetch equipment:", error);
        message.error("Failed to fetch equipment");
      }
    };

    const fetchRegions = async () => {
      try {
        const response = await api.get("/sites/site_regions/");
        setRegions(response.data);
      } catch (error) {
        console.error("Failed to fetch regions:", error);
        message.error("Failed to fetch regions");
      }
    };

    const fetchAvailablePlows = async () => {
      try {
        const response = await api.get("/plows/available_plows/");
        setAvailablePlows(response.data);
      } catch (error) {
        console.error("Failed to fetch available plows:", error);
        message.error("Failed to fetch available plows");
      }
    };

    const fetchAvailableDoubleDowns = async () => {
      try {
        const response = await api.get("/double-down/available_doubleDowns/");
        setAvailableDoubleDowns(response.data);
      } catch (error) {
        console.error("Failed to fetch available double downs:", error);
        message.error("Failed to fetch available double downs");
      }
    };

    useEffect(() => {
      fetchSites();
      fetchVehicles();
      fetchEquipment();
      fetchRegions();
      fetchAvailablePlows();
      fetchAvailableDoubleDowns();
    }, []);

    const allocateVehicle = async (siteId, vehicleId) => {
      try {
        await api.post(
          `/vehicles/${vehicleId}/allocate_vehicle/`,
          {
            site_id: siteId,
          },
        ).then(response => {
          message.success(response.data.message)
        });
        fetchSites();
        fetchVehicles();
      } catch (error) {
        console.error("Failed to assign vehicle:", error.response.data.message);
        message.error(error.response.data.message);
      }
    };

    const deallocateVehicle = async (vehicleId, siteId, surfaceType) => {
      try {
        await api.post(
          `/vehicles/${vehicleId}/deallocate_vehicle/`,
          {
            site_id: siteId,
          }
        ).then(response => {
          message.success(response.data.message);
          fetchSites();
          fetchVehicles(); 
        });
      } catch (error) {
        console.error("Failed to deallocate vehicle:", error.response.data.message);
        message.error(error.response.data.message);
      }
    };

    const replaceVehicle = async (vehicleFromId, vehicleToId) => {
      try {
        // Send the POST request to the backend API for vehicle replacement
        const response = await api.post(`/vehicles/replace_loader_vehicle/`, {
          vehicle_from_id: vehicleFromId,
          vehicle_to_id: vehicleToId,
        });
    
        // If the request is successful, show success message and refresh data
        if (response.status === 200) {
          message.success(response.data.message);
          fetchSites();  // Refresh the site data
          fetchVehicles();  // Refresh the vehicle data
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Failed to replace vehicle:", error.response?.data?.error || error.message);
        message.error(error.response?.data?.error || "Failed to replace vehicle");
      }
    };

    const allocateEquipment = async (siteId, equipmentId) => {
      try {
        await api.post(
          `/equipment/${equipmentId}/assign_site/`,
          {
            site_id: siteId,
          },
        );
        message.success("Equipment assigned to site successfully");
        fetchSites();
        fetchEquipment();
      } catch (error) {
        console.error("Failed to assign equipment:", error);
        message.error("Failed to assign equipment");
      }
    };

    const handleSubtypeSelect = (value) => {
      setSelectedSubtype(value);
      filterVehicles(vehicles, value, searchText); // Reapply filtering with new subtype
    };

    const filterVehicles = (vehiclesList, subtype, searchText) => {
      const filteredVehicles = vehiclesList.filter(vehicle => {
        const subtypeMatch = subtype === "all" || vehicle.vehicle_subtype === subtype;
        const searchMatch = !searchText || vehicle.vehicle_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        return subtypeMatch && searchMatch;
      });

      setFilteredVehicles(filteredVehicles);
      
      // Update autocomplete options based on filtered results
      setAutoCompleteOptions(filteredVehicles.map(vehicle => ({
        value: vehicle.vehicle_name,
        label: vehicle.vehicle_name,
        key: vehicle.vehicle_id,
      })));
    };

    const generateLoaderAllocationPDF = async () => {
      try {
        const response = await api.get(`/vehicles/loader_pdf/`, {
          responseType: 'blob', // Important for file download
        });

        // Create a URL for the blob and set up download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Allocation_by_Loader.pdf'); // file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("Current Allocation by Loader PDF generated successfully");
      } catch (error) {
        console.error("Failed to generate Current Allocation by Loader PDF:", error);
        message.error(error.response?.data?.message || "Failed to generate Current Allocation by Loader PDF");
      }
    };

    const generateGenericLoaderAllocationPDF = async () => {
      try {
        const response = await api.get(`/vehicles/generic_loader_pdf/`, {
          responseType: 'blob', // Important for file download
        });

        // Create a URL for the blob and set up download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Allocation_by_Generic_Loader.pdf'); // file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("Current Allocation by Generic Loader PDF generated successfully");
      } catch (error) {
        console.error("Failed to generate Current Allocation by Generic Loader PDF:", error);
        message.error(error.response?.data?.message || "Failed to generate Current Allocation by Generic Loader PDF");
      }
    };

    const generateSiteAllocationPDF = async () => {
      try {
        const statusParams = selectedContractStatuses.map(status => `status=${encodeURIComponent(status)}`).join('&');
        const response = await api.get(`/vehicles/site_pdf/?${statusParams}`, {
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Allocation_by_Site.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("Current Allocation by Site PDF generated successfully");
        setIsExportMenuVisible(false);  // Close the dropdown after generating PDF
      } catch (error) {
        console.error("Failed to generate Current Allocation by Site PDF:", error);
        message.error(error.response?.data?.message || "Failed to generate Current Allocation by Site PDF");
      }
    };

    const handleContractStatusChange = (value) => {
      setSelectedContractStatus(value);
      filterSites(selectedRegion, value);
    };

    const filterSites = (region, status) => {
      let filtered = sites;
      
      if (region !== "all") {
        filtered = filtered.filter((site) => site.region === region);
      }
      
      if (status !== "all") {
        filtered = filtered.filter((site) => site.contract_status === status);
      }
      
      setFilteredSites(filtered);
    };

    const handleRegionSelect = (value) => {
      setSelectedRegion(value);
      filterSites(value, selectedContractStatus);
    };

    const exportMenu = (
      <Menu>
        <Menu.Item key="1" onClick={generateLoaderAllocationPDF}>
          Export Allocation - By Loader
        </Menu.Item>
        <Menu.SubMenu key="2" title="Export Allocation - By Site">
          <Menu.ItemGroup>
            <div style={{ padding: '8px 16px' }}>
              <Checkbox.Group
                options={[
                  { label: 'Estimate', value: 'Estimate' },
                  { label: 'Unconfirmed', value: 'Unconfirmed' },
                  { label: 'Confirmed', value: 'Confirmed' },
                ]}
                value={selectedContractStatuses}
                onChange={setSelectedContractStatuses}
              />
            </div>
            <div style={{ padding: '8px 16px' }}>
              <Button 
                onClick={generateSiteAllocationPDF} 
                disabled={selectedContractStatuses.length === 0}
                type="primary"
              >
                Generate PDF
              </Button>
            </div>
          </Menu.ItemGroup>
        </Menu.SubMenu>
        <Menu.Item key="3" onClick={generateGenericLoaderAllocationPDF}>
          Export Generic Loader Allocation
        </Menu.Item>
      </Menu>
    );

    const showProjectDetails = (project) => {
      // Find the updated project data from 'sites' state
      const updatedProject = sites.find(site => site.site_id === project.site_id);
      setSelectedProject(updatedProject || project);
      setShowModal(true);
    };

    const handleModalCancel = () => {
      setShowModal(false);
      setSelectedProject(null);
    };

    const handleConfirmedChange = async (checked, vehicleId) => {
      try {
        await api.patch(`/vehicles/${vehicleId}/`, { is_confirmed: checked });
        message.success("Vehicle confirmation status updated successfully");
    
        setSites(prevSites =>
          prevSites.map(site => ({
            ...site,
            vehicles_lots: site.vehicles_lots.map(vehicle =>
              vehicle.vehicle_id === vehicleId ? { ...vehicle, is_confirmed: checked } : vehicle
            ),
          }))
        );
    
        if (selectedProject) {
          setSelectedProject(prevProject => ({
            ...prevProject,
            vehicles_lots: prevProject.vehicles_lots.map(vehicle =>
              vehicle.vehicle_id === vehicleId ? { ...vehicle, is_confirmed: checked } : vehicle
            ),
          }));
        }
      } catch (error) {
        console.error("Failed to update confirmation status:", error);
        message.error("Failed to update confirmation status");
      }
    };

    const handlePlowConfirmedChange = async (checked, vehicleId) => {
      try {
        const vehicle = vehicles.find((v) => v.vehicle_id === vehicleId);
        if (!vehicle || !vehicle.assigned_plow) return;
    
        await api.patch(`/plows/${vehicle.assigned_plow.plow_id}/`, { is_confirmed: checked });
        message.success("Plow confirmation status updated successfully");
    
        // Update the 'vehicles' state
        setVehicles((prevVehicles) =>
          prevVehicles.map((v) =>
            v.vehicle_id === vehicleId
              ? {
                  ...v,
                  assigned_plow: { ...v.assigned_plow, is_confirmed: checked },
                }
              : v
          )
        );
    
        // Update the 'sites' state to persist the change
        setSites((prevSites) =>
          prevSites.map((site) => ({
            ...site,
            vehicles_lots: site.vehicles_lots.map((v) =>
              v.vehicle_id === vehicleId
                ? {
                    ...v,
                    assigned_plow: { ...v.assigned_plow, is_confirmed: checked },
                  }
                : v
            ),
          }))
        );
    
        // Update 'selectedProject' if the modal is open
        if (selectedProject) {
          setSelectedProject((prevProject) => ({
            ...prevProject,
            vehicles_lots: prevProject.vehicles_lots.map((v) =>
              v.vehicle_id === vehicleId
                ? {
                    ...v,
                    assigned_plow: { ...v.assigned_plow, is_confirmed: checked },
                  }
                : v
            ),
          }));
        }
      } catch (error) {
        console.error("Failed to update plow confirmation status:", error);
        message.error("Failed to update plow confirmation status");
      }
    };

    const vehicleColumns = [
      {
        title: 'Vehicle',
        dataIndex: 'vehicle_name',
        key: 'vehicle_name',
      },
      {
        title: 'Vehicle Confirmed',
        dataIndex: 'is_confirmed',
        key: 'is_confirmed',
        render: (isConfirmed, record) => (
          <Checkbox
            checked={isConfirmed}
            onChange={(e) => handleConfirmedChange(e.target.checked, record.vehicle_id)}
          />
        ),
      },
      {
        title: 'Subtype',
        dataIndex: 'vehicle_subtype',
        key: 'vehicle_subtype',
      },
      {
        title: 'Allocated Capacity',
        dataIndex: 'allocated_capacity',
        key: 'allocated_capacity',
        render: (capacity) => `${capacity.toLocaleString()} sq. ft`,
      },
      {
        title: 'Plow',
        dataIndex: 'vehicle_id',
        key: 'plow',
        render: (vehicleId) => getVehiclePlowSize(vehicleId),
      },
      {
        title: 'Plow Confirmed',
        dataIndex: 'vehicle_id',
        key: 'plow',
        render: (vehicleId) => {
          const vehicle = vehicles.find(v => v.vehicle_id === vehicleId);
          if (vehicle && vehicle.assigned_plow) {
            return (
              <Checkbox
                checked={vehicle.assigned_plow.is_confirmed}
                onChange={(e) => handlePlowConfirmedChange(e.target.checked, vehicleId)}
              />
            );
          }
          return "n/a";
        },
      },
    ];

    // Helper function to get plow size from main vehicles list
   // Helper function to get plow size from main vehicles list
    const getVehiclePlowSize = (vehicleId) => {
      const vehicle = vehicles.find(v => v.vehicle_id === vehicleId);
      return vehicle && vehicle.plow_size !== "n/a" ? vehicle.plow_size : "No plow";
    };

    // Reusable VehiclesTable component
    const VehiclesTable = ({ title, vehicles, isOverallocated, deallocateVehicle, record, getVehiclePlowSize, availablePlows, fetchVehicles, fetchAvailablePlows }) => {
      const handlePlowSizeChange = async (vehicleId, value) => {
        try {
          await api.post(`/vehicles/${vehicleId}/allocate_plow/`, {
            plow_model: value,
          });
          fetchVehicles();
          fetchAvailablePlows();
          fetchSites();
          message.success("Plow assigned successfully");
        } catch (error) {
          console.error("Failed to assign plow:", error);
          message.error(error.response?.data?.error || "Failed to assign plow");
        }
      };

      const handleDeallocatePlow = async (vehicleId) => {
        try {
          await api.post(`/vehicles/${vehicleId}/deallocate_plow/`);
          fetchVehicles();
          fetchAvailablePlows();
          fetchSites();
          message.success("Plow deallocated successfully");
        } catch (error) {
          console.error("Failed to deallocate plow:", error);
          message.error(error.response?.data?.error || "Failed to deallocate plow");
        }
      };

      return (
        <Box style={{ marginBottom: "8px" }}>
          <p style={{ fontWeight: "bold", marginBottom: "4px", fontSize: "14px" }}>{title}</p>
          {vehicles.length > 0 ? (
            <RadixTable.Root size="1">
              <RadixTable.Header>
                <RadixTable.Row>
                  <RadixTable.ColumnHeaderCell style={{ width: "15%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Vehicle</RadixTable.ColumnHeaderCell>
                  <RadixTable.ColumnHeaderCell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Allocated Capacity</RadixTable.ColumnHeaderCell>
                  <RadixTable.ColumnHeaderCell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Assigned Staff</RadixTable.ColumnHeaderCell>
                  <RadixTable.ColumnHeaderCell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Plow</RadixTable.ColumnHeaderCell>
                  <RadixTable.ColumnHeaderCell style={{ width: "25%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>Actions</RadixTable.ColumnHeaderCell>
                </RadixTable.Row>
              </RadixTable.Header>

              <RadixTable.Body>
                {vehicles.map((vehicle) => (
                  <RadixTable.Row key={vehicle.vehicle_id}>
                    <RadixTable.Cell style={{ width: "15%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>
                      {vehicle.vehicle_name} ({vehicle.vehicle_subtype})
                    </RadixTable.Cell>
                    <RadixTable.Cell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>
                      {vehicle.allocated_capacity.toLocaleString()} sq. ft{isOverallocated ? " (Overallocated)" : ""}
                    </RadixTable.Cell>
                    <RadixTable.Cell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>
                      {vehicle.assigned_staff && vehicle.assigned_staff.length > 0 ? (
                              <ul style={{ paddingLeft:0, margin: 0,}}>
                              {vehicle.assigned_staff.map((staff, index) => (
                                  <li key={index} style={{ listStyle: "none", marginBottom: "4px" }}>
                                    <strong>{staff.name}</strong> - {staff.position}, Rating: {staff.staff_rating ? staff.staff_rating : "Unspecified"}
                                  </li>
                              ))}
                              </ul>
                          ) : (
                              "Unassigned"
                          )}
                    </RadixTable.Cell>
                    <RadixTable.Cell style={{ width: "20%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>
                      <Select
                        placeholder="Select Plow Size"
                        style={{ width: '100%' }}
                        onChange={(value) => handlePlowSizeChange(vehicle.vehicle_id, value)}
                        value={getVehiclePlowSize(vehicle.vehicle_id)}
                        size="small"
                      >
                        <Select.Option value="n/a">n/a</Select.Option>
                        {availablePlows.map(plow => (
                          <Select.Option key={plow.plow_model} value={plow.plow_model}>
                            {plow.plow_model} ({plow.count})
                          </Select.Option>
                        ))}
                      </Select>
                    </RadixTable.Cell>
                    <RadixTable.Cell style={{ width: "25%", textAlign: "left", padding: "4px 8px", fontSize: "12px" }}>
                      <Button
                        onClick={() => deallocateVehicle(vehicle.vehicle_id, record.site_id, "lots")}
                        type="primary"
                        danger
                        size="small"
                        style={{ marginRight: '4px' }}
                        disabled = {isAllocationPresent(vehicle) || isStaffAssigned(vehicle)}
                        title={isAllocationPresent(vehicle) || isStaffAssigned(vehicle) ? "Deallocate staff and plows before deallocating vehicle from site" : ""}
                      >
                        Deallocate
                      </Button>
                      {getVehiclePlowSize(vehicle.vehicle_id) !== "n/a" && (
                        <Button
                          onClick={() => handleDeallocatePlow(vehicle.vehicle_id)}
                          size="small"
                        >
                          Remove Plow
                        </Button>
                      )}
                    </RadixTable.Cell>
                  </RadixTable.Row>
                ))}
              </RadixTable.Body>
            </RadixTable.Root>
          ) : (
            <p style={{ fontSize: "12px" }}>{isOverallocated ? "No Overallocated Parking Lots Assigned Vehicles" : "No Parking Lots Assigned Vehicles"}</p>
          )}
        </Box>
      );
    };

    const expandedRowRender = (record) => {
      // Separate allocated and overallocated vehicles
      const allocatedVehicles = record.vehicles_lots.filter(vehicle => !vehicle.overallocated_to_site);
      const overallocatedVehicles = record.vehicles_lots.filter(vehicle => vehicle.overallocated_to_site);

      return (
        <Box style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          {/* Allocate Button - Left Side */}
          <Box style={{ flex: "0 0 400px", display: "flex", alignItems: "center" }}>
            <AllocateCell
              record={record}
              allocateVehicle={allocateVehicle}
              allocateEquipment={allocateEquipment}
              isDragging={isDragging}
            />
          </Box>

          {/* Tables - Right Side */}
          <Box style={{ flex: "1", display: "flex", flexDirection: "column" }}>
            {/* Parking Lots Assigned Vehicles Table */}
            <VehiclesTable
              title="Parking Lots Assigned Vehicles:"
              vehicles={allocatedVehicles}
              isOverallocated={false}
              deallocateVehicle={deallocateVehicle}
              record={record}
              getVehiclePlowSize={getVehiclePlowSize}
              availablePlows={availablePlows}
              fetchVehicles={fetchVehicles}
              fetchAvailablePlows={fetchAvailablePlows}
            />

            {/* Overallocated Parking Lots Assigned Vehicles Table */}
            <VehiclesTable
              title="Overallocated Parking Lots Assigned Vehicles:"
              vehicles={overallocatedVehicles}
              isOverallocated={true}
              deallocateVehicle={deallocateVehicle}
              record={record}
              getVehiclePlowSize={getVehiclePlowSize}
              availablePlows={availablePlows}
              fetchVehicles={fetchVehicles}
              fetchAvailablePlows={fetchAvailablePlows}
            />
          </Box>
        </Box>
      );
    };

    const columns = [
      { 
        title: "Customer Name", 
        dataIndex: "client_name", 
        key: "client_name",
        width: 150
      },
      { 
        title: "Site Name", 
        dataIndex: "site_name", 
        key: "site_name",
        width: 200
      },
      {
        title: "Locked",
        key: "locked",
        width: 81,
        render: (_, record) => (
          <Box>
            {record.loader_allocation_finalized ? (
              <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                <IconLock size={18} stroke={2} />
              </div>
              
            ) : (
              <div style={{ backgroundColor: '#f5f5f5', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ebeaea', borderRadius: '4px', color: "#034832" }}>
                <IconLockOpen size={18} stroke={2} />
              </div>
            )}
          </Box>
        ),
      },
      {
        title: "Lots Area (sq. ft)",
        dataIndex: "parking_lots_sqft",
        key: "parking_lots_sqft",
        width: 260,
        render: (lotsArea, record) => {
          const allocationPercentage = (record.parking_lots_sqft_allocated / lotsArea) * 100;
          const displayPercentage = allocationPercentage > 100 ? 100 : allocationPercentage;

          return (
            <span>
              {record.parking_lots_sqft_allocated.toLocaleString()} / {lotsArea.toLocaleString()}
              <Box style={{ display: "flex", alignItems: "center", width: 250 }}>
                <LinearProgress 
                  style={{ flex: 9, marginRight: 8 }} 
                  variant="determinate" 
                  value={displayPercentage}
                />
                <Box style={{ flex: 1 }}>
                  {allocationPercentage.toFixed(1)}%
                </Box>
              </Box>
              {allocationPercentage > 100 && (
                <Box style={{ color: 'Orange', marginTop: 4 }}>
                  Over Allocated
                </Box>
              )}
            </span>
          )
        },
      },
      {
        title: "Overallocated Loaders",
        width: 127,
        dataIndex: "num_overallocated_loaders",
        key: "num_overallocated_loaders",
        render: (text, record) => (
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', width: '100%' }}>
            {text}
          </Box>
        )
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Box style={{ display: "flex", alignItems: "flex-start", gap: "5px", flexDirection: 'column', justifyContent: 'flex-start' }}>
            <div
              className={styles.tableButtonGold}
              style={{ width: '135px' }}
              onClick={() => {
                if (record.loader_allocation_finalized) {
                  // If allocation is locked, show password modal
                  setUnlockSiteId(record.site_id);
                  setPasswordModalVisible(true);
                } else {
                  // Finalize allocation if it's not locked
                  finalizeLoaderAllocation(record.site_id);
                }
              }}
            >
              {record.loader_allocation_finalized ? "Unlock Finalization" : "Finalize Allocation"}
            </div>
            <DetailsCell
              record={record}
              allocateVehicle={allocateVehicle}
              allocateEquipment={allocateEquipment}
              isDragging={isDragging}
              showProjectDetails={showProjectDetails}
            />
           
          </Box>
        ),
      },
    ];

    const onTableExpand = (expanded, record) => {
      if (expanded) {
        setExpandedRowKeys(prevKeys => {
          if (prevKeys.length < MAX_EXPANDED_ROWS) {
            setAllocationMode(true);
            return [...prevKeys, record.site_id];
          }
          message.warning(`You can only expand up to ${MAX_EXPANDED_ROWS} sites at a time.`);
          return prevKeys;
        });
      } else {
        setExpandedRowKeys(prevKeys => {
          const newKeys = prevKeys.filter(key => key !== record.site_id);
          if (newKeys.length === 0) {
            setAllocationMode(false);
          }
          return newKeys;
        });
      }
    };

    const handleSearch = (value) => {
      setSearchText(value);
      filterVehicles(vehicles, selectedSubtype, value); // Reapply filtering with new search text
    };

    const handleSelect = (value) => {
      const selectedVehicle = filteredVehicles.find(vehicle => vehicle.vehicle_name === value);
      if (selectedVehicle) {
        setFilteredVehicles([selectedVehicle]); // Set the filteredVehicles to just the selected one
      }
    };

    const applyFilters = () => {
      const filtered = sites.filter((site) => {
        const filterText = site.site_name
          .toLowerCase()
          .includes(searchText.toLowerCase());
        return filterText;
      });
      setFilteredSites(filtered);
    };

    // const debouncedSearch = debounce((value) => {
    //   handleSearch(value);
    // }, 500);

    const handleSwitchMapView = () => {
      navigate('/map');
    };

    return (
      <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
        <Theme>
          <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '300px',
                }}
              >
                <span style={{fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px'}}>
                  Loaders
                </span>
                <Select
                  placeholder="Select Subtype"
                  defaultValue="all"
                  style={{ width: 'calc(100% - 20px)' }}
                  onChange={handleSubtypeSelect}
                >
                  <Option value="all">All Subtypes</Option>
                  {vehicles
                    .map((vehicle) => vehicle.vehicle_subtype)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map((subtype) => (
                      <Option key={subtype} value={subtype}>
                        {subtype}
                      </Option>
                    ))}
                </Select>
                <Input
                  style={{ width: '100%' }}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  placeholder="Search loaders"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <VehicleList>
                  {filteredVehicles
                    .map((vehicle) => (
                      <VehicleDragCard
                        key={vehicle.vehicle_id}
                        vehicle={vehicle}
                        allocationMode={allocationMode}
                      />
                    ))}
                </VehicleList>
              </div>
              <div style={{ width: 'calc(100% - 320px)', marginLeft: '20px'}}>
                <div style={{ marginBottom: 16, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Select
                      placeholder="Select Region"
                      defaultValue="all"
                      style={{ width: 150 }}
                      onChange={handleRegionSelect}
                    >
                      <Option value="all">All Regions</Option>
                      {regions.map((region) => (
                        <Option key={region} value={region}>
                          {region.charAt(0).toUpperCase() + region.slice(1)}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Select Contract Status"
                      defaultValue="all"
                      style={{ width: 150 }}
                      onChange={handleContractStatusChange}
                    >
                      <Option value="all">All Statuses</Option>
                      <Option value="Estimate">Estimate</Option>
                      <Option value="Unconfirmed">Unconfirmed</Option>
                      <Option value="Confirmed">Confirmed</Option>
                    </Select>
                    <Dropdown overlay={exportMenu} trigger={['click']}>
                      <Button style={{ width: 150 }}>
                        PDF Export <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>

                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <div className={styles.tableButton} onClick={handleSwitchMapView} style={{ fontSize: '14px', padding: '8px 15px' }}>
                      <IconMap size={16}/>
                      Map View
                    </div>
                    <div className={styles.tableButton} onClick={() => navigate('/staffAllocation')} style={{ fontSize: '14px', padding: '8px 15px' }}>
                      <IconUser size={16}/>
                      Staff Allocation
                    </div>
                  </div>
                </div>
                <Spin spinning={loading}>
                <>
                  <style>{`
                    .locked-row {
                      background-color: #f0f0f0; !important;
                    }
                  `}
                  </style>
                  <style>{`
                    .ant-table-tbody > tr:hover > td {
                      background: none !important; /* Removes hover background */
                    }
                  `}</style>
                  <AntTable
                    dataSource={filteredSites}
                    columns={columns}
                    rowKey="site_id"
                    pagination={false}
                    scroll={{ y: 690 }}
                    rowClassName={(record) =>
                      record.loader_allocation_finalized ? 'locked-row' : ''
                    }
                    expandable={{
                      expandedRowRender,
                      expandedRowKeys: expandedRowKeys,
                      onExpand: (expanded, record) => {
                        if (!record.loader_allocation_finalized) {
                          onTableExpand(expanded, record);
                        }
                      },
                      expandIcon: ({ expanded, onExpand, record }) =>
                        record.loader_allocation_finalized ? null : (
                          <Button
                            onClick={(e) => onExpand(record, e)}
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                            disabled={!expanded && expandedRowKeys.length >= MAX_EXPANDED_ROWS}
                          >
                            {expanded ? '-' : '+'}
                          </Button>
                        ),
                    }}
                  />
                </>
                </Spin>
                <Modal
                  title={<span style={{ fontSize: '18px', fontWeight: '600' }}>Site Details</span>}
                  open={showModal}
                  onCancel={handleModalCancel}
                  footer={null}
                  width={800}
                >
                  {selectedProject && (
                    <div>
                      <span style={{ fontSize: '16px', fontWeight: '550'}}>General Information</span>
                      <DataList.Root style={{ gap: '5px', marginTop: '10px' }}>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Client Name</DataList.Label>
                          <DataList.Value>{selectedProject.client_name}</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Site Name</DataList.Label>
                          <DataList.Value>{selectedProject.site_name}</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Parking Lots Area</DataList.Label>
                          <DataList.Value>{selectedProject.parking_lots_sqft.toLocaleString()} sq. ft</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Walks Area</DataList.Label>
                          <DataList.Value>{selectedProject.walkway_sqft.toLocaleString()} sq. ft</DataList.Value>
                        </DataList.Item>
                        <DataList.Item align="center">
                          <DataList.Label minWidth="150px">Salt Usage</DataList.Label>
                          <DataList.Value>{selectedProject.salt_done ? "Yes" : "No"}</DataList.Value>
                        </DataList.Item>
                      </DataList.Root>
                      
                      <Divider />
                      
                      <span style={{ fontSize: '16px', fontWeight: '550'}}>More Information</span>
                      {selectedProject.vehicles_lots?.length > 0 ? (
                        <AntTable 
                          dataSource={selectedProject.vehicles_lots}
                          columns={vehicleColumns}
                          pagination={false}
                          rowKey="vehicle_id"
                          size="small"
                        />
                      ) : (
                        <p style={{ color: "#a9a9a9" }}>No Parking Lots Assigned Vehicles</p>
                      )}
                      
                      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <div
                          className={styles.tableButton}
                          onClick={() => generateSiteDetailsPDF(selectedProject)}
                          >
                            Download PDF
                          </div>
                      </div>
                    </div>
                  )}
                </Modal>
                <Modal
                  title="Enter Password to Unlock"
                  open={passwordModalVisible}
                  onCancel={() => setPasswordModalVisible(false)}
                  onOk={async () => {
                    // Validate password
                    if (password) {  // Ensure a password is entered
                      await UnlockLoaderAllocation(unlockSiteId, password);  // Pass the password to the API call
                      setPasswordModalVisible(false); // Close the modal
                      setPassword(""); // Reset password input
                    } else {
                      message.error("Please enter a password");
                    }
                  }}
                >
                  <Input.Password
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Modal>
                <Modal
                  title="Replace Vehicle"
                  open={isReplaceModalVisible}
                  onCancel={() => {
                    setIsReplaceModalVisible(false);
                    setVehicleToReplace(null);
                    setSelectedReplacementVehicle(null);
                  }}
                  onOk={() => {
                    replaceVehicle(vehicleToReplace.vehicle_id, selectedReplacementVehicle);
                    setIsReplaceModalVisible(false);
                    setVehicleToReplace(null);
                    setSelectedReplacementVehicle(null);
                  }}
                >
                  <p>Replacing vehicle: <strong>{vehicleToReplace?.vehicle_name}</strong></p>

                  {/* Display the list of site allocations */}
                  <p><strong>Site allocations:</strong></p>
                  {vehicleToReplace?.allocated_sites?.length > 0 ? (
                    <ul style={{ listStyle: 'none' }} >
                      {vehicleToReplace.allocated_sites.map((siteAllocation) => {
                        const site = sites.find(s => s.site_id === siteAllocation.site_id);
                        return (
                          <li key={siteAllocation.site_id}>
                            {site ? `${site.site_name} - Allocated Capacity: ${siteAllocation.allocated_capacity.toLocaleString()} sq. ft` : `Site ID: ${siteAllocation.site_id} - Allocated Capacity: ${siteAllocation.allocated_capacity.toLocaleString()} sq. ft`}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p>No site allocations</p>
                  )}

                  {/* Display the list of staff allocations */}
                  <p><strong>Staff allocations:</strong></p>
                  {vehicleToReplace?.assigned_staff?.length > 0 ? (
                    <ul>
                      {vehicleToReplace.assigned_staff.map((staff) => (
                        <li key={staff.staff_id}>
                          {staff.name} ({staff.position})
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No staff allocations</p>
                  )}

                  {/* Display the plow allocation */}
                  <p><strong>Plow allocation:</strong></p>
                  {vehicleToReplace?.plow_size && vehicleToReplace.plow_size !== "n/a" ? (
                    <p>Plow: {vehicleToReplace.plow_size}</p>
                  ) : (
                    <p>No plow assigned</p>
                  )}

                  {/* Replacement vehicle selection */}
                  <br/>
                  <p><strong>Select a replacement vehicle:</strong></p>
                  <Select
                    placeholder="Select a replacement vehicle"
                    value={selectedReplacementVehicle}
                    onChange={(value) => setSelectedReplacementVehicle(value)}
                  >
                    {availableReplacementVehicles.map((vehicle) => (
                      <Option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                        {vehicle.vehicle_name} ({vehicle.vehicle_subtype})
                      </Option>
                    ))}
                  </Select>
                </Modal>
              </div>
            </div>
          </DndProvider>
        </Theme>
      </ConfigProvider>
    );
  };
  export default EquipmentAllocationTable;
