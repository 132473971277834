import React, { useState, useEffect } from "react";
import {Select, Table,Button,Modal,Spin,Pagination,message,Switch,} from "antd";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useAllocationDrop from "../hooks/useAllocationDrop";
import api from "../authentication/axiosInstance";
import styled from "styled-components";
import { Box, LinearProgress, InputLabel } from '@mui/material';
const { Option } = Select;

const ActionCell = ({record, allocateVehicle, allocatePlow, allocateEquipment, isDragging, showProjectDetails, allocationMode,
}) => {
  const { isOver, drop } = useAllocationDrop(record, allocateVehicle, allocatePlow, allocateEquipment
  );

  return (
    <Button ref={drop} type="primary" style={{
        backgroundColor: isOver ? "#cdaf5e" : "#cdaf5e",
        borderColor: isOver ? "#cdaf5e" : "#cdaf5e",
        opacity: isDragging ? 0.5 : 1,
        width:175
      }}  
      onClick={() => showProjectDetails(record)}
    >
      {allocationMode ? "Drag Here to Allocate" : "Vehicle Details"}
    </Button>
  );
};

const PlowCard = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 90%;
  display:flex;
  align-items:center;
  justify-content:center;
`;

const PlowList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 300px;
  max-height: 650px; /* Set a fixed height */
  overflow-y: scroll; /* Enable vertical scrolling */
`;

const PlowAllocationPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [plows, setPlows] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [filteredvehicles, setFilteredVehicles] = useState([]);
  const [vehicleFilter, setVehicleFilter] = useState("any");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [vehicleCurrentPage, setVehicleCurrentPage] = useState(1);
  const [allocationMode, setAllocationMode] = useState(false);
  const [showDeallocatePopup, setShowDeallocatePopup] = useState(false);

  const PlowPopup = ({ plow, onClose }) => {
    return (
      <Modal title="Plow Details" open={true} onCancel={onClose} footer={null}>
        <p>Name: {plow.plow_name}</p>
        <p>Plow Model: {plow.plow_model}</p>
        <p>Plow Type: {plow.plow_type}</p>
        <p>Plow Serial Number: {plow.plow_serial_number}</p>
        <p>Plow Size: {plow.plow_size}</p>
        <p>Plow Brand: {plow.plow_brand}</p>
        <p>Plow Group: {plow.plow_group}</p>
        <br></br>
        <p>Assigned Vehicle:</p>
          {plow.assigned_vehicle ? (
            <ul><li>{plow.assigned_vehicle.vehicle_type}: {plow.assigned_vehicle.vehicle_name}, {plow.assigned_vehicle.vehicle_model}</li></ul>
          ) : (
            <ul><li>No Vehicle Assigned</li></ul>
          )}

      </Modal>
    );
  };

  const PlowDragCard = ({ plow, allocationMode }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "plow",
      item: {
        id: plow.plow_id,
        type: "plow",
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: allocationMode && !plow.assigned_bool,
    });
  
    const [showPopup, setShowPopup] = useState(false);
  
    const handleClick = (e) => {
      e.preventDefault();
      setShowPopup(true);
    };
  
    return (
      <>
        <PlowCard ref={allocationMode ? drag : null} style={{ opacity: isDragging ? 0.5 : 1,
          backgroundColor: plow.assigned_bool ? '#cce6cc' : '#f0f0f0',
          cursor: (allocationMode && !plow.assigned_bool) ? "grab" : "pointer"
          }}
          onClick={allocationMode ? undefined : handleClick}
        >
          <Box style={{ width:"200px", display:"flex", flexDirection:"column", gap:"10px"}}>
            <Box>{plow.plow_name}, {plow.plow_model}</Box>
            <Box style={{ display: "flex", alignItems: "center"}}>
              <Box style={{ flex: 1 }}>
                {plow.assigned_bool ? (
                  <>
                    <LinearProgress style={{ minWidth: "50px", flex: 9 }} variant="determinate" value={100}/>
                    <span>Assigned</span>
                  </>
                ) : (
                  <>
                    <LinearProgress style={{ minWidth: "50px", flex: 9 }} variant="determinate" value={0} />
                    <span>Assignable</span>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </PlowCard>
        {showPopup && (
          <PlowPopup plow={plow} onClose={() => setShowPopup(false)} />
        )}
      </>
    );
  };

  const fetchPlows = async () => {
    try {
      const response = await api.get("/plows/");
      setPlows(response.data);
    } catch (error) {
      console.error("Failed to fetch plows:", error);
      message.error("Failed to fetch plows");
    }
  };

  const fetchVehicles = async () => {
    try {
      const response = await api.get("/vehicles/loader_allocation_vehicles");
      setVehicles(response.data);
    } catch (error) {
      console.error("Failed to fetch vehicles:", error);
      message.error("Failed to fetch vehicles");
    }
  };

  useEffect(() => {
    fetchVehicles();
    fetchPlows();
  }, []);

  const allocatePlow = async (vehicleId, plowId) => {
    try {
      await api.post(
        `/vehicles/${vehicleId}/allocate_plow/`,
        { plow_id: plowId }
      ).then(response => {
        message.success(response.data.message);
      });
      fetchPlows();
      fetchVehicles();
    } catch (error) {
      console.error("Failed to allocate plow:", error.response.data.message);
      message.error(error.response.data.message);
    }
  };

  const deallocatePlow = async (vehicleId, plowId) => {
    try {
      await api.post(
        `/vehicles/${vehicleId}/deallocate_plow/`,
        { plow_id: plowId }
      ).then(response => {
        message.success(response.data.message);
      });
      fetchPlows();
      fetchVehicles();
      setSelectedProject(prevProject => {
        return {
          ...prevProject,
          assigned_plow: null,
        };
      });
    } catch (error) {
      console.error("Failed to deallocate plow:", error.response.data.message);
      message.error(error.response.data.message);
    }
  }

  const generatePlowPDF = async () => {
    try {
      const response = await api.get(`/vehicles/plow_pdf/`, {
        responseType: 'blob', // Important for file download
      });
  
      // Create a URL for the blob and set up download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'plow_report.pdf'); // file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      message.success("Plow PDF generated successfully");
    } catch (error) {
      console.error("Failed to generate plow pdf:", error);
      message.error(error.response?.data?.message || "Failed to generate plow pdf");
    }
  };

  const showProjectDetails = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const showDeallocateDetails = (project) => {
    setSelectedProject(project);
    setShowDeallocatePopup(true);
  };

  const handleDeallocateModalCancel = () => {
    setShowDeallocatePopup(false);
    setSelectedProject(null);
  };

  const handleVehicleTypeFilterChange = (value) => {
    setVehicleFilter(value);
  }

  useEffect(() => {
    if (vehicleFilter === "any") {
      setFilteredVehicles(vehicles);
    } else {
      setFilteredVehicles(vehicles.filter((vehicle) => vehicle.vehicle_type === vehicleFilter));
    }
  }, [vehicleFilter, vehicles]);

  const columns = [
    { 
      title: "Vehicle Name", 
      dataIndex: "vehicle_name", 
      key: "vehicle_name" 
    },
    { 
      title: "Vehicle Type", 
      dataIndex: "vehicle_type", 
      key: "vehicle_type" 
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Box style={{display:"flex", alignItems:"center", gap:"16px"}}>
          <ActionCell
            record={record}
            allocatePlow={allocatePlow}
            isDragging={isDragging}
            showProjectDetails={showProjectDetails}
            allocationMode={allocationMode}
          />
          {allocationMode && (
            <Button
              type="primary"
              style={{backgroundColor:"#cdaf5e", borderColor: "#cdaf5e", opacity: isDragging ? 0.5 : 1, width:175}}  
              onClick={() => showDeallocateDetails(record)}
            >
              Deallocation Menu
            </Button>
          )}
        </Box>
      ),
    },
    {
      title: "Assigned Plow",
      dataIndex: "assigned_plow",
      key: "assigned_plow",
      render: (record) => 
      <span>
        {record ? `${record.plow_name}, ${record.plow_model}` : "No Plow Assigned"}
      </span>,
    },
    {
      title: "Capacity Allocated (sq. ft)",
      dataIndex: "vehicle_capacity",
      key: "vehicle_capacity",
      render: (capacity, record) => 
      <span>
        {(capacity - record.remaining_capacity).toLocaleString()}/{capacity.toLocaleString()}
        <Box style={{display:"flex", alignItems: "center", width:250}}>
          <LinearProgress style={{ flex:9 , marginRight: 8 }} variant="determinate" value={((capacity - record.remaining_capacity)/capacity*100)}/>
          <Box style={{ flex: 1 }}>{((capacity - record.remaining_capacity)/capacity*100).toFixed(1)}%</Box>
        </Box>
      </span>,
    },
    { 
      title: "Make", 
      dataIndex: "vehicle_make", 
      key: "vehicle_make" 
    },
    { 
      title: "Model", 
      dataIndex: "vehicle_model", 
      key: "vehicle_model" 
    },
  ];


  return (
    <DndProvider backend={HTML5Backend}>
      <Box style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px', paddingTop: '20px'}}>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              flex: "0 0 200px",
              display:'flex',
              flexDirection:'column',
              gap:'16px',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
              <span style={{textAlign: "center", fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px' }}>
                Plows
              </span>
              <PlowList>
                {plows
                  .map((plow) => (
                    <PlowDragCard
                      key={plow.plow_id}
                      plow={plow}
                      allocationMode={allocationMode}
                    />
                  ))}
              </PlowList>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ marginBottom: 16, display:"flex", gap:"16px", alignItems:"center" }}>
              <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", borderRadius: '5px' , height: '28px'}}>
                <Box onClick={() => setAllocationMode(true)} sx={{ padding: '0px 25px', fontSize: '14px', cursor: 'pointer', border: "1px solid #d9d9d9", height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px 0 0 5px', "&:hover": { border: '1px solid #1677FE' }, transition: 'all 0.3s ease-in-out', backgroundColor: allocationMode ? "#E6F4FF" : "white" }}>
                  <span>Allocation Mode</span>
                </Box>
                <Box onClick={() => setAllocationMode(false)} sx={{ padding: '0px 25px', fontSize: '14px', cursor: 'pointer', border: "1px solid #d9d9d9", height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0 5px 5px 0', "&:hover": { border: '1px solid #1677FE' }, transition: 'all 0.3s ease-in-out', backgroundColor: !allocationMode ? "#E6F4FF" : "white" }}>
                  <span>Details Mode</span>
                </Box>
              </Box>
              <Box style={{display:"flex", flexDirection:"column", alignItems:"center",}}>
                {/* <InputLabel>Vehicle Type</InputLabel> */}
                  <Select
                    placeholder="Vehicle Type"
                    style={{ width: 250 }}
                    onChange={handleVehicleTypeFilterChange}
                    defaultValue="any"
                  >
                    <Option value="any">All</Option>
                    <Option value="Wheel Loader">Wheel Loader</Option>
                    <Option value="Skidsteer">Skidsteer</Option>
                  </Select>
              </Box>
              <Button style={{ width:250}} onClick={generatePlowPDF}>Export Plow Allocation Data</Button>
            </div>
            <Spin spinning={loading}>
              <Table dataSource={filteredvehicles} columns={columns} rowKey="site_id"/>
            </Spin>
            <Modal title="Vehicle Details" open={showModal} onCancel={handleModalCancel} footer={null}>
              {selectedProject && (
                <div>
                  <p>Vehicle Name: {selectedProject.vehicle_name}</p>
                  <p>Make/Model: {selectedProject.vehicle_make}, {selectedProject.vehicle_model}</p>
                  <p>Surface Type: {selectedProject.surface_type}</p>
                  <p>Max Capacity: {selectedProject.vehicle_capacity.toLocaleString()} sq. ft</p>
                  <p>Remaining Capcity: {selectedProject.remaining_capacity.toLocaleString()} sq. ft</p>
                  <br></br>
                  <p>Assigned Plow:</p>
                    {selectedProject.assigned_plow ? (
                      <ul><li>Plow: {selectedProject.assigned_plow.plow_model}, {selectedProject.assigned_plow.plow_name}</li></ul>
                    ) : (
                      <ul><li>No Plow Assigned</li></ul>
                    )}
                  <p>Assigned Staff:</p>
                    {selectedProject.assigned_staff.length > 0 ? (
                      <ul>
                        {selectedProject.assigned_staff.map((staff) => (
                          <li key={staff.id}>{staff.name} - {staff.position}</li>
                        ))}
                      </ul>
                    ) : (
                      <ul><li>No Staff Assigned</li></ul>
                    )}
                </div>
              )}
            </Modal>
            <Modal title="Deallocate Details" open={showDeallocatePopup} onCancel={handleDeallocateModalCancel} footer={null}>
              {selectedProject && (
                <div>
                  <p>Vehicle Name: {selectedProject.vehicle_name}</p>
                  <p>Make/Model: {selectedProject.vehicle_make}, {selectedProject.vehicle_model}</p>
                  <p>Surface Type: {selectedProject.surface_type}</p>
                  <p>Max Capacity: {selectedProject.vehicle_capacity.toLocaleString()} sq. ft</p>
                  <p>Remaining Capcity: {selectedProject.remaining_capacity.toLocaleString()} sq. ft</p>
                  <br></br>
                  <p>Assigned Plow:</p>
                  {selectedProject.assigned_plow ? (
                      <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        Plow: {selectedProject.assigned_plow.plow_model}, {selectedProject.assigned_plow.plow_name}
                        <Button onClick={() => deallocatePlow(selectedProject.vehicle_id, selectedProject.assigned_plow.plow_id)}>Deallocate</Button>
                      </Box>     
                    ) : (
                      <ul><li>No Plow Assigned</li></ul>
                    )}
                </div>
              )}
            </Modal>
          </div>
        </div>
      </Box>
    </DndProvider>
  );
 };
 
 export default PlowAllocationPage;