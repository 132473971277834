import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, Button, Modal, Typography, Descriptions, Badge } from 'antd';
import api from '../authentication/axiosInstance';
import moment from 'moment';

const { Text, Title } = Typography;

const getDispatchStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'unread':
      return 'default';
    case 'read':
      return 'processing';
    case 'confirmed':
      return 'warning';
    case 'completed':
      return 'success';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const DispatchRequestsTable = () => {
  const [dispatches, setDispatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchDispatches = async () => {
    try {
      setLoading(true);
      const response = await api.get('/request-manager/request-dispatches/');
      setDispatches(response.data);
    } catch (error) {
      console.error('Failed to fetch dispatches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDispatches();
  }, []);

  const handleViewDetails = (dispatch) => {
    setSelectedDispatch(dispatch);
    setModalVisible(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.id - b.id,
      render: (id) => <Text strong>#{id}</Text>,
    },
    {
      title: 'Request Type',
      key: 'request_type',
      width: 120,
      render: (_, record) => (
        <Tag color={record.regular_request ? 'blue' : 'purple'}>
          {record.regular_request ? 'Regular' : 'SnowEdge'}
        </Tag>
      ),
      filters: [
        { text: 'Regular', value: 'regular' },
        { text: 'SnowEdge', value: 'snowedge' },
      ],
      onFilter: (value, record) => 
        value === 'regular' ? !!record.regular_request : !!record.snowedge_request,
    },
    {
      title: 'Site',
      dataIndex: 'site_name',
      key: 'site_name',
      width: 200,
      ellipsis: true,
      sorter: (a, b) => (a.site_name || '').localeCompare(b.site_name || ''),
      render: (site) => site || <Text type="secondary">N/A</Text>,
    },
    {
      title: 'Staff',
      key: 'staff',
      width: 200,
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Text strong>{record.staff_name}</Text>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {record.staff_email}
          </Text>
        </Space>
      ),
      sorter: (a, b) => a.staff_name.localeCompare(b.staff_name),
    },
    {
      title: 'Storm Details',
      key: 'storm',
      width: 150,
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Tag color="blue">{record.storm_type}</Tag>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            Severity: {record.storm_severity}
          </Text>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {moment(record.storm_date).format('MMM D, YYYY')}
          </Text>
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status) => (
        <Badge 
          status={getDispatchStatusColor(status)} 
          text={<Text strong style={{ textTransform: 'capitalize' }}>{status}</Text>}
        />
      ),
      filters: [
        { text: 'Unread', value: 'unread' },
        { text: 'Read', value: 'read' },
        { text: 'Confirmed', value: 'confirmed' },
        { text: 'Completed', value: 'completed' },
        { text: 'Cancelled', value: 'cancelled' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Timing',
      key: 'timing',
      width: 200,
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            Created: {moment(record.dispatched_at).format('MMM D, HH:mm')}
          </Text>
          <Text strong>
            For: {moment(record.dispatched_for).format('MMM D, HH:mm')}
          </Text>
          {record.completed_at && (
            <Text type="secondary" style={{ fontSize: '12px' }}>
              Completed: {moment(record.completed_at).format('MMM D, HH:mm')}
            </Text>
          )}
        </Space>
      ),
      sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      fixed: 'right',
      render: (_, record) => (
        <Button 
          type="primary"
          size="small"
          onClick={() => handleViewDetails(record)}
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dispatches}
        loading={loading}
        rowKey="id"
        scroll={{ x: 1500 }} // Enable horizontal scrolling
        pagination={{ 
          pageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} dispatches`,
        }}
        size="middle"
        bordered
        rowClassName={(record) => {
          if (record.status === 'completed') return 'table-row-completed';
          if (record.status === 'cancelled') return 'table-row-cancelled';
          return '';
        }}
      />

      <Modal
        title={<Title level={4}>Dispatch Details</Title>}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedDispatch && (
          <Descriptions bordered column={2}>
            <Descriptions.Item label="Dispatch ID" span={2}>
              {selectedDispatch.id}
            </Descriptions.Item>
            <Descriptions.Item label="Site">
              {selectedDispatch.site_name}
            </Descriptions.Item>
            <Descriptions.Item label="Staff">
              {selectedDispatch.staff_name}
            </Descriptions.Item>
            <Descriptions.Item label="Storm Type">
              {selectedDispatch.storm_type}
            </Descriptions.Item>
            <Descriptions.Item label="Storm Severity">
              {selectedDispatch.storm_severity}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={2}>
              <Badge 
                status={getDispatchStatusColor(selectedDispatch.status)} 
                text={selectedDispatch.status}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Dispatched At">
              {moment(selectedDispatch.dispatched_at).format('LLL')}
            </Descriptions.Item>
            <Descriptions.Item label="Dispatched For">
              {moment(selectedDispatch.dispatched_for).format('LLL')}
            </Descriptions.Item>
            {selectedDispatch.completed_at && (
              <Descriptions.Item label="Completed At" span={2}>
                {moment(selectedDispatch.completed_at).format('LLL')}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Notes" span={2}>
              {selectedDispatch.notes || 'No notes provided'}
            </Descriptions.Item>
            {selectedDispatch.response_notes && (
              <Descriptions.Item label="Response Notes" span={2}>
                {selectedDispatch.response_notes}
              </Descriptions.Item>
            )}
          </Descriptions>
        )}
      </Modal>
    </>
  );
};

// Add these styles to your CSS
const styles = `
  .table-row-completed {
    background-color: #f6ffed;
  }

  .table-row-cancelled {
    background-color: #fff1f0;
  }

  .ant-table-row:hover {
    cursor: pointer;
  }
`;

export default DispatchRequestsTable; 