// StaffListView.js
import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Button, Input, message, Modal, Form, Select, Popconfirm, Spin, List, Slider, Tag, Collapse, Tooltip, Menu, Dropdown } from 'antd';
import { Box } from '@mui/material';
import api from "../authentication/axiosInstance";
import debounce from 'lodash/debounce';
import {EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';


const { Option } = Select;

const StaffListView = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staff, setStaff] = useState([]);
  const [editingStaff, setEditingStaff] = useState(null);
  const [editForm] = Form.useForm();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false); //These are for the add staff modal
  const [addForm] = Form.useForm()

  const [candidates, setCandidates] = useState([]); // State to store candidates
  const [loadingCandidates, setLoadingCandidates] = useState(false); // Loading state for candidates
  const [selectedCandidates, setSelectedCandidates] = useState([]); // To track selected candidates

  const [ratingRange, setRatingRange] = useState([3, 5]); // Range for choosing candidate ratings

  const [positionFilter, setPositionFilter] = useState(null); //These are for filtering
  const [typeFilter, setTypeFilter] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchCityText, setSearchCityText] = useState('');
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [ratingFilter, setRatingFilter] = useState(undefined);
  const [ratingOverallFilter, setRatingOverallFilter] = useState(undefined);

  const [staffType, setStaffType] = useState(null); //This is for conditionally rendering if type is subcontractor, to allow subcont. name

  const [loadingSync, setLoadingSync] = useState(false); // Staff Sync loading state
  const [loadingViaesysSync, setLoadingViaesysSync] = useState(false);

  const fetchStaff = async () => {
    try {
      const response = await api.get('/staff/');
      setStaff(response.data);
      setFilteredStaff(response.data); // Set filteredStaff to all staff when fetched
    } catch (error) {
      message.error('Failed to fetch staff');
    }
  };


  useEffect(() => {
    fetchStaff();
  },[])

  const showEditModal = (staff) => {
    setEditingStaff(staff);
    setStaffType(staff.type);
    editForm.setFieldsValue({
      ...staff,
      status: staff.status || 'Unconfirmed' // Set default status if not present
    });
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingStaff(null);
    editForm.resetFields();
    setStaffType(staff.type)
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/staff/${id}/`)
      message.success('Staff deleted successfully');
      fetchStaff();
    } catch (error) {
      console.error('Failed to delete staff:', error);
      message.error('Failed to delete staff')
    }
  };

  const isAllocationPresent = (staff) => {
    // Safely check if staff is not null before accessing properties
    return staff && (staff.assigned_walkway_crew_bool || staff.assigned_salting_route_bool || staff.assigned_bool || staff.assigned_plow_route_bool);
  };
  
  const handleModalCancel = () => {
    setShowModal(false);
    setSelectedStaff(null);
  };

  const handleEditSubmit = async (values) => {
    try {
      const isWalkwayPosition = values.position.split(' ')[0].toLowerCase() === "walkway";
      const isSaltingPosition = values.position.split(' ')[0].toLowerCase() === "salt";
      const formattedValues = {
        ...values,
        allocatable_to_walkway_crews: isWalkwayPosition ? 1 : 0,
        allocatable_to_salting_routes: isSaltingPosition? 1 : 0,
      };
      await api.patch(`/staff/${editingStaff.staff_id}/`, formattedValues);
      message.success('Staff updated successfully');
      setIsEditModalVisible(false);
      editForm.resetFields();
      fetchStaff();
    } catch (error) {
      console.error('Failed to update staff:', error);
      message.error('Failed to update staff');
    }
  };

  const showAddModal = () => {
    setIsAddModalVisible(true);
  }

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
    addForm.resetFields();
    setStaffType(null)
  };


  const handleAddModalSubmit = async (values) => {
    try {
      const isWalkwayPosition = values.position.split(' ')[0].toLowerCase() === "walkway";
      const isSaltingPosition = values.position.split(' ')[0].toLowerCase() === "salt";
      const formattedValues = {
        ...values,
        assigned_bool:0,
        first_name: "n/a",
        last_name: "n/a",
        job_title: "n/a",
        pref_name: null,
        pay_rate : parseFloat(values.pay_rate),
        allocatable_to_walkway_crews: isWalkwayPosition ? 1 : 0,
        assigned_walkway_crew_bool: 0,
        allocatable_to_salting_routes: isSaltingPosition? 1 : 0,
        assigned_salting_route_bool: 0,
      };
      setStaffType(null);
      const response = await api.post('/staff/', formattedValues);
      message.success('Staff added successfully');
      setIsAddModalVisible(false);
      addForm.resetFields();
      fetchStaff();
    } catch (error) {
      console.error('Failed to add staff:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        if (typeof error.response.data === 'object' && error.response.data !== null) {
          const errorMessages = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
          message.error(`Failed to add staff: ${errorMessages}`);
        } else {
          message.error('Failed to add staff. Please check your input and try again.');
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        message.error('Network error. Please check your connection and try again.');
      } else {
        message.error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleStatusChange = async (staffId, newStatus) => {
    try {
      await api.patch(`/staff/${staffId}/`, { status: newStatus });
      message.success('Status updated successfully');
      fetchStaff();
    } catch (error) {
      console.error('Failed to update status:', error);
      message.error('Failed to update status');
    }
  };

  const handleCheckboxChange = (staff, field, newValue) => {
    Modal.confirm({
      title: `Are you sure you want to make this change?`,
      onOk: async () => {
        try {
          const updatedData = { [field]: newValue };
          const response = await api.patch(`/staff/${staff.staff_id}/`, updatedData);
          console.log("API response:", response);
          console.log("Staff after update:", staff);
  
          if (response.status === 200 || response.status === 204) {
            message.success(`Field updated successfully`);
  
            setStaff((prevStaff) =>
              prevStaff.map((s) =>
                s.staff_id === staff.staff_id ? { ...s, [field]: newValue } : s
              )
            );
            setFilteredStaff((prevFiltered) =>
              prevFiltered.map((s) =>
                s.staff_id === staff.staff_id ? { ...s, [field]: newValue } : s
              )
            );
          } else {
            console.error('Unexpected response:', response);
            message.error(`Failed to update ${field.replace('_', ' ')}`);
          }
        } catch (error) {
          message.error(`Failed to update ${field.replace('_', ' ')}`);
          console.error('Update error:', error);
        }
      },
      onCancel() {
        message.info('No changes were made');
      },
    });
  };

  const columns = [ // adding a comment here, hopefully change is detected and code is picked up in prod.
    {
      title: 'Staff Name',
      dataIndex: 'name',
      key: 'Staff_Name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Staff Type',
      dataIndex: 'type',
      key: 'Staff_Type',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'Staff_Position',
    },
    {
      title: "City",
      dataIndex: 'city',
      key: "Staff_City"
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status || 'Unconfirmed'}
          style={{ width: 130 }}
          onChange={(newStatus) => handleStatusChange(record.staff_id, newStatus)}
        >
          <Option value="Unconfirmed">Unconfirmed</Option>
          <Option value="Confirmed">Confirmed</Option>
        </Select>
      ),
    },
    {
      title: 'Staff Rating',
      dataIndex: 'staff_rating',
      key: 'Staff_Rating',
      width: 150
    },
    {
      title: 'Overall Rating',
      dataIndex: 'staff_overall_rating',
      key: 'staff_overall_rating',
      width: 120
    },
    {
      title: "Part Time",
      dataIndex: 'is_part_time',
      key: "is_part_time",
      width: 105,
      render: (isPartTime, record) => (
        <Checkbox
          checked={isPartTime}
          onChange={() => handleCheckboxChange(record, 'is_part_time', !isPartTime)}
        />
      ),
    },
    {
      title: "Snow Edge",
      dataIndex: 'is_snow_edge',
      key: "is_snow_edge",
      width: 105,
      render: (isSnowEdge, record) => (
        <Tooltip
        title={record.position === "Loader Operator" ? "" : "Can only be edited for Loader Operator position staff"}
        overlayStyle={{ fontSize: '12px', padding: '4px 8px' }} // Adjust font size and padding
        >          
        <Checkbox
            checked={isSnowEdge}
            onChange={() => handleCheckboxChange(record, 'is_snow_edge', !isSnowEdge)}
            disabled={record.position !== "Loader Operator"} // Disable if position is not "Loader Operator"
          />
      </Tooltip>
      ),
    },
    {
      title: "Back Up",
      dataIndex: 'is_back_up',
      key: "is_back_up",
      width: 105,
      render: (isBackUp, record) => (
        <Checkbox
          checked={isBackUp}
          onChange={() => handleCheckboxChange(record, 'is_back_up', !isBackUp)}
        />
      ),
    },
    {
      title: 'Phone Verified',
      dataIndex: 'is_phone_verified',
      key: 'is_phone_verified',
      sorter: (a, b) => a.is_phone_verified - b.is_phone_verified,
      render: (isPhoneVerified, record) => (
        <>
          <Checkbox checked={isPhoneVerified} disabled />
          {!isPhoneVerified && (
            <Button
              type="Primary"
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginLeft: 8, color: 'white' }}
              onClick={() => sendVerificationMessage(record.staff_id)}
            >
              Verify
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Have Photo",
      dataIndex: 'have_photo',
      key: "have_photo",
      width: 110,
      render: (havePhoto, record) => (
        <Checkbox
          checked={havePhoto}
          onChange={() => handleCheckboxChange(record, 'have_photo', !havePhoto)}
        />
      ),
    },
    {
      title: "In Fleetio",
      dataIndex: 'is_in_fleetio',
      key: "is_in_fleetio",
      width: 110,
      render: (isInFleetio, record) => (
        <Checkbox
          checked={isInFleetio}
          onChange={() => handleCheckboxChange(record, 'is_in_fleetio', !isInFleetio)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 150,
      render: (staff) => (
        <>
          <Button
            onClick={() => showEditModal(staff)}
            type="primary"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
            // disabled={isAllocationPresent(staff)}
            title={isAllocationPresent(staff) ? "Deallocate staff from walkway crews/salting routes before editing/deleting" : ""}
          >
            Edit
          </Button>
          {isAllocationPresent(staff) ? "" : (<Popconfirm
            title={`Are you sure you want to delete staff ${staff.name}?`} 
            onConfirm={() => handleDelete(staff.staff_id)} 
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              type="danger"
              className="delete-button"
            />
          </Popconfirm>)}
        </>
      ),
    },
  ];

  // Fetch candidates from BambooHR API with selected rating range
  const fetchCandidates = async () => {
    setLoadingCandidates(true);
    try {
      const response = await api.get('/bambooHR/rated_candidates/', {
        params: {
          min_rating: ratingRange[0],
          max_rating: ratingRange[1],
        },
      });
      setCandidates(response.data.candidates); // Store the candidates in state
      message.success('Candidates fetched successfully!');
    } catch (error) {
      message.error('Failed to fetch candidates.');
    } finally {
      setLoadingCandidates(false);
    }
  };

  // Handle candidate selection
  const handleCandidateSelection = (candidateId) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(candidateId)) {
        return prevSelected.filter(id => id !== candidateId); // Deselect candidate
      } else {
        return [...prevSelected, candidateId]; // Select candidate
      }
    });
  };

  const handleConfirmCandidates = async () => {
    try {
      // Filter out the selected candidates
      const selectedCandidateData = candidates.filter(candidate => selectedCandidates.includes(candidate.id));

      // Send the selected candidate data to the backend
      const response = await api.post('/staff/convert-candidates/', {
        candidates: selectedCandidateData,  
      });
      message.success('Candidates successfully converted to staff!');
      setSelectedCandidates([]);  // Reset selected candidates
      fetchStaff();  // Refresh the staff list after the update
    } catch (error) {
      console.error('Error converting candidates:', error);
      message.error('Failed to convert candidates.');
    }
  };

  //Filtering and searching
  const applyFilters = () => {
    let filtered = staff;
    if (searchText) {
      filtered = filtered.filter((staff) => 
        staff.name?.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (searchCityText) {
      filtered = filtered.filter((staff) => 
        staff.city?.toLowerCase().includes(searchCityText.toLowerCase())
      );
    }
    if (positionFilter) {
      filtered = filtered.filter((staff) => staff.position === positionFilter);
    }
    if (typeFilter) {
      filtered = filtered.filter((staff) => staff.type === typeFilter);
    }

    if (ratingOverallFilter !== undefined) {
      if (ratingOverallFilter === null) {
        filtered = filtered.filter((staff) => !staff.staff_overall_rating || staff.staff_overall_rating === '');
      } else if (ratingOverallFilter === "Rated") {
        filtered = filtered.filter((staff) => staff.staff_overall_rating && staff.staff_overall_rating !== '');
      } else {
        filtered = filtered.filter((staff) => staff.staff_overall_rating === ratingOverallFilter);
      }
    }

  if (ratingFilter !== undefined) {
    if (ratingFilter === null) {
      // Show only staff with no rating (null or undefined)
      filtered = filtered.filter((staff) => !staff.staff_rating);
    } else if (ratingFilter === "Rated") {
      // Show only staff with a rating (non-null)
      filtered = filtered.filter((staff) => staff.staff_rating);
    } else {
      // Show staff with the selected rating
      filtered = filtered.filter((staff) => staff.staff_rating === ratingFilter);
    }
  }
  
    setFilteredStaff(filtered);
  };


  const debouncedSearch = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouncedCitySearch = debounce((value) => {
    setSearchCityText(value);
  }, 300);


  useEffect(() => {
    applyFilters();
  }, [positionFilter, typeFilter, searchText, searchCityText, ratingFilter, ratingOverallFilter]);

  const handleStaffTypeChange = (value) => {
    setStaffType(value);
  };


  // Function to sync staff members from BambooHR
  const syncStaffMembers = async () => {
    setLoadingSync(true);
    try {
      const response = await api.post('/bambooHR/sync-employee-data/', {}, { responseType: 'blob' });
      const contentType = response.headers['content-type'];
      if (contentType === 'application/pdf') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'BambooSync_Details.pdf');
        message.success('Staff sync completed successfully!');
        fetchStaff();
      } else if (contentType === 'application/json') {
        const errorText = await response.data.text();
        const errorJson = JSON.parse(errorText);
        message.error(`Failed to sync staff: ${errorJson.message || 'Unknown error'}`);
      } else {
        message.error('Failed to sync staff: Unexpected response format.');
      }
    } catch (error) {
      console.error('Failed to sync staff:', error);
        message.error('Failed to sync staff.');
      } finally {
        setLoadingSync(false);
      }
    };

  // Function to sync staff members from Viaesys
  const syncViaesysStaff = async () => {
    setLoadingViaesysSync(true);
    try {
      const response = await api.post('/viaesys/user-sync/', {}, { responseType: 'blob' });
      const contentType = response.headers['content-type'];

      if (contentType === 'application/pdf') {
        // If the response is a PDF, create a blob and download it
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'ViaesysSync_Details.pdf');
        message.success('Viaesys staff sync completed successfully!');
        fetchStaff();
      } else if (contentType === 'application/json') {
        // If the response is JSON, parse it and display an error message
        const errorText = await response.data.text();
        const errorJson = JSON.parse(errorText);
        message.error(`Failed to sync staff: ${errorJson.message || 'Unknown error'}`);
      } else {
        message.error('Failed to sync staff: Unexpected response format.');
      }
    } catch (error) {
      console.error('Failed to sync staff from Viaesys:', error);
      message.error('Failed to sync staff from Viaesys.');
    } finally {
      setLoadingViaesysSync(false);
    }
  };

  const handleExportCSV = async () => {
    try {
      const response = await api.get('/staff/export_csv/', {
        responseType: 'blob'  
      });
      
      // create a download link and trigger it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'staff_export.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      message.error('Failed to export staff data');
      console.error('Export error:', error);
    }
  };

  const verifyStaffNumbers = async () => {
    try {
      const response = await api.post('/staff/send_verification_messages/');
      if (response.status === 201) {
        message.success('Verification messages sent successfully');
      } else {
        message.error('Failed to send verification messages');
      }
    } catch (error) {
      message.error('An error occurred while sending verification messages');
      console.error('Error details:', error);
    }
  };

  const sendVerificationMessage = async (staffId) => {
    try {
      const response = await api.post('/staff/send_individual_verification/', { staff_id: staffId });
      if (response.status === 201) {
        message.success('Verification message sent successfully');
      } else {
        message.error('Failed to send verification message');
      }
    } catch (error) {
      message.error('An error occurred while sending the verification message');
      console.error('Error details:', error);
    }
  };

  const syncMenu = (
    <Menu>
      <Menu.Item key="syncStaff" onClick = {syncStaffMembers}>
          {loadingSync ? (
            <Spin />
          ) : (
            <span>
              Sync Staff Members Bamboo
              <img src="/BambooHR logo black.png" alt="BambooHR" style={{ width: '15px', marginLeft: '8px' }} />
            </span>
          )}
      </Menu.Item>
      <Menu.Item key="syncViaesys" onClick = {syncViaesysStaff}>
          {loadingViaesysSync ? (
            <Spin />
          ) : (
            <span>
              Sync Staff Members Viaesys
              <img src="/Viaesys logo.png" alt="Viaesys" style={{ width: '15px', marginLeft: '8px' }} />
            </span>
          )}
      </Menu.Item>
    </Menu>
  );

  return (
    <Box style={{padding:30}}>
      <h3 style={{fontWeight:"bold",}}>
        Staff:
      </h3>
      {/* Syncing overlay */}
      <Modal
        visible={loadingSync}
        title={null}
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center', padding: 40 }}
      >
        <Spin size="large" style={{ marginBottom: 20 }} />
        <h2>Syncing BambooHR Staff</h2>
        <p>Please wait while we update your information...</p>
      </Modal>
      <Modal
        visible={loadingViaesysSync}
        title={null}
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center', padding: 40 }}
      >
        <Spin size="large" style={{ marginBottom: 20 }} />
        <h2>Syncing Viaesys Staff</h2>
        <p>Please wait while we update your information...</p>
      </Modal>
        <div style={{display:"flex", justifyContent:'space-between'}}>
          <div>
            <Button
                onClick={showAddModal}
                type="primary"
                icon={<PlusOutlined />}
                style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom:20 }}
              >
                Add New Staff
            </Button>
            <Dropdown overlay={syncMenu} placement="bottomLeft">
              <Button type="primary" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom: 20, marginLeft: 10 }}>
                Sync Staff <DownOutlined />
              </Button>
            </Dropdown>
          <Button
            onClick={handleExportCSV}
            type="primary"
            icon={<DownloadOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom: 20, marginLeft: 10 }}
          >
            Export CSV
          </Button>
          <Button
            onClick={verifyStaffNumbers}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom: 20, marginLeft: 10 }}
          >
            Verify Staff Phone Numbers
          </Button>
          </div>
          <div>
            <Input.Search
              placeholder="Search staff"
              onSearch={setSearchText}
              onChange={(e) => debouncedSearch(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Input.Search
              placeholder="Search city"
              onSearch={setSearchCityText}
              onChange={(e) => debouncedCitySearch(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Position"
              style={{ width: 140, marginRight: 8 }}
              onChange={setPositionFilter}
              value={positionFilter}
              allowClear
            >
              <Option value={"Crew Leader"}>Crew Leader</Option>
              <Option value={"Loader Operator"}>Loader Operator</Option>
              <Option value={"Salt Truck Driver"}>Salt Truck Driver</Option>
              <Option value={"Walkway Technician"}>Walkway Technician</Option>
              <Option value={"Walkway Vehicle Operator"}>Walkway Vehicle Operator</Option>
              <Option value={"Territory Manager"}>Territory Manager</Option>
              <Option value={"Office"}>Office</Option>
              <Option value={"SnowEdge Operator"}>SnowEdge Operator</Option>
              <Option value={"Working Site Supervisor"}>Working Site Supervisor</Option>
              <Option value={"Walkway Crew Leader"}>Walkway Crew Leader</Option>
              <Option value={"n/a"}>Not Specified</Option>

            </Select>
            <Select
              placeholder="Staff type"
              style={{ width: 140, marginRight: 8 }}
              onChange={setTypeFilter}
              value={typeFilter}
              allowClear
            >
              <Option value={"Staff"}>Staff</Option>
              <Option value={"Candidate"}>Candidate</Option>
              <Option value={"Subcontractor"}>Subcontractor</Option>
            </Select>
            <Select
              placeholder="Staff Rating"
              style={{ width: 140, marginRight: 8 }}
              onChange={setRatingFilter}
              value={ratingFilter}
              allowClear
            >
              <Option value="1A - Green">1A - Green</Option>
              <Option value="1B - Green">1B - Green</Option>
              <Option value="1C - Blue">1C - Blue</Option>
              <Option value="2 - Yellow">2 - Yellow</Option>
              <Option value="3 - Orange">3 - Orange</Option>
              <Option value="No">No</Option>
              <Option value="Rated">Rated</Option>
              <Option value={null}>Unspecified</Option>
            </Select>
            <Select
              placeholder="Overall Rating"
              style={{ width: 200, marginRight: 8 }}
              onChange={(value) => setRatingOverallFilter(value)}  // New state for overall rating filter
              value={ratingOverallFilter}
              allowClear  // Allow clearing the selection
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="Rated">Rated</Option>
              <Option value={null}>Unspecified</Option>
            </Select>
          </div>
        </div>
      <Table dataSource={filteredStaff} columns={columns} rowKey="staff_id" pagination={false}
          scroll={{ y: 640 }}  />
      <Modal
              title="Staff Details"
              open={showModal}
              onCancel={handleModalCancel}
              footer={null}
            >
              {selectedStaff && (
                <div>
                  <p>Name: {selectedStaff.Staff_Name}</p>
                </div>
              )}
      </Modal>

      <Modal
        title="Add New Staff"
        visible={isAddModalVisible}
        onCancel={handleAddModalCancel}
        footer={null}
        width={800} // Increased width for two-column layout
      >
        <Form form={addForm} onFinish={handleAddModalSubmit} layout="vertical">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <Form.Item name="name" label="Staff name" rules={[{ required: true, message: 'Please enter the staff name' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="position" label="Position" rules={[{ required: true, message: 'Please select the position' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select position">
                <Option value="Crew Leader">Crew Leader</Option>
                <Option value="Loader Operator">Loader Operator</Option>
                <Option value="Salt Truck Driver">Salt Truck Driver</Option>
                <Option value="Walkway Technician">Walkway Technician</Option>
                <Option value="Walkway Vehicle Operator">Walkway Vehicle Operator</Option>
                <Option value="Territory Manager">Territory Manager</Option>
                <Option value="Office">Office</Option>
                <Option value="SnowEdge Operator">SnowEdge Operator</Option>
                <Option value="Working Site Supervisor">Working Site Supervisor</Option>
                <Option value="Walkway Crew Leader">Walkway Crew Leader</Option>
                <Option value="n/a">Not Specified</Option>
              </Select>
            </Form.Item>
            <Form.Item name="contract_type" label="Contract Type" rules={[{ required: false, message: 'Please select the contract type' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select contract type">
                <Option value="Hourly">Hourly</Option>
                <Option value="Salary">Salary</Option>
                <Option value="Standby">Standby</Option>
                <Option value="n/a">Not Specified</Option>
              </Select>
            </Form.Item>
            <Form.Item name="city" label="City" rules={[{ required: false, message: 'Please enter the city' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input /> 
            </Form.Item>
            <Form.Item
              name="type"
              label="Staff Type"
              rules={[{ required: true, message: 'Please select the staff type' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Select placeholder="Select staff type" onChange={handleStaffTypeChange} >
                <Option value="Staff">Staff</Option>
                <Option value="Candidate">Candidate</Option>
                <Option value="Subcontractor">Subcontractor</Option>
              </Select>
            </Form.Item>
            {/* Conditionally render the subcontractor name input */}
            {staffType === 'Subcontractor' && (
            <Form.Item
              name="subcontractor_name"
              label="Subcontractor Name"
              rules={[{ required: true, message: 'Please enter the subcontractor name' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Input placeholder="Enter subcontractor name" />
          </Form.Item>
        )}
            <Form.Item name="pay_rate" label="Pay Rate" rules={[{ required: false, message: 'Please enter the pay rate' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input /> 
            </Form.Item>
            <Form.Item name="work_phone_extension" label="Work Number Extension" rules={[{ required: false, message: 'Please enter the phone extension' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input /> 
            </Form.Item>
            <Form.Item name="work_phone" label="Work Number" rules={[{ required: false, message: 'Please enter the phone number' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input /> 
            </Form.Item>
            <Form.Item name="work_email" label="Email" rules={[{ required: true, message: 'Please enter the email address' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input /> 
            </Form.Item>
            <Form.Item name="staff_rating" label="Staff Rating" rules={[{ required: false, message: 'Please select staff rating' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select staff rating">
                <Option value="1A - Green">1A - Green</Option>
                <Option value="1B - Green">1B - Green</Option>
                <Option value="1C - Blue">1C - Blue</Option>
                <Option value="2 - Yellow">2 - Yellow</Option>
                <Option value="3 - Orange">3 - Orange</Option>
                <Option value="No">No</Option>
                <Option value={null}>Unspecified</Option>
              </Select>
            </Form.Item>
            <Form.Item name="staff_overall_rating" label="Staff Overall Rating" rules={[{ required: false, message: 'Please select staff overall rating' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Select placeholder="Select staff rating">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value={null}>Unspecified</Option>
            </Select>
          </Form.Item>
          </div>
            <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
              Add Staff
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* Edit Staff Modal */}
      <Modal
        title="Edit Staff"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
        width={800}
      >
        <Form form={editForm} onFinish={handleEditSubmit} layout="vertical">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <Form.Item name="name" label="Staff Name" rules={[{ required: true, message: 'Please enter the staff name' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="city" label="Staff city" rules={[{ required: false, message: 'Please enter the staff city' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="work_phone" label="Staff work phone" rules={[{ required: false, message: 'Please enter the staff work phone' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="work_phone_extension" label="Staff work phone extension" rules={[{ required: false, message: 'Please enter the staff work phone extension' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="work_email" label="Staff work email" rules={[{ required: true, message: 'Please enter the staff work email' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="position" label="Position" rules={[{ required: true, message: 'Please select the position' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select disabled = {isAllocationPresent(editingStaff)} placeholder="Select position">
                <Option value="Crew Leader">Crew Leader</Option>
                <Option value="Loader Operator">Loader Operator</Option>
                <Option value="Salt Truck Driver">Salt Truck Driver</Option>
                <Option value="Walkway Technician">Walkway Technician</Option>
                <Option value="Walkway Vehicle Operator">Walkway Vehicle Operator</Option>
                <Option value="Territory Manager">Territory Manager</Option>
                <Option value="Office">Office</Option>  
                <Option value="SnowEdge Operator">SnowEdge Operator</Option>
                <Option value="Working Site Supervisor">Working Site Supervisor</Option>
                <Option value="Walkway Crew Leader">Walkway Crew Leader</Option>
                <Option value="n/a">Not Specified</Option>
              </Select>
            </Form.Item>
            <Form.Item name="contract_type" label="Contract Type" rules={[{ required: false, message: 'Please select the contract type' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select contract type">
                <Option value="Hourly">Hourly</Option>
                <Option value="Salary">Salary</Option>
                <Option value="Standby">Standby</Option>
                <Option value="n/a">Not Specified</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="type"
              label="Staff Type"
              rules={[{ required: true, message: 'Please select the staff type' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Select placeholder="Select staff type" 
              onChange={handleStaffTypeChange} 
              // disabled = {editingStaff?.type === "Staff"}
              // title={editingStaff?.type === "Staff" ? "Staff type is fixed for Staff" : ""}  // Custom hover message
              >
                <Option value="Staff">Staff</Option>
                <Option value="Candidate">Candidate</Option>
                <Option value="Subcontractor">Subcontractor</Option>
              </Select>
            </Form.Item>
            {/* Conditionally render the subcontractor name input */}
            {(staffType === 'Subcontractor' || editingStaff?.type === 'Subcontractor') && (
            <Form.Item
              name="subcontractor_name"
              label="Subcontractor Name"
              rules={[{ required: true, message: 'Please enter the subcontractor name' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Input placeholder="Enter subcontractor name" />
            </Form.Item>
          )}
          <Form.Item
            name="status"
            label="Staff Status"
            rules={[{ required: true, message: 'Please select the staff status' }]}
            style={{ flex: '1 1 calc(50% - 8px)' }}
          >
            <Select placeholder="Select staff status">
              <Option value="Unconfirmed">Unconfirmed</Option>
              <Option value="Confirmed">Confirmed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="staff_rating" label="Staff Rating" rules={[{ required: false, message: 'Please select staff rating' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Select placeholder="Select staff rating">
              <Option value="1A - Green">1A - Green</Option>
              <Option value="1B - Green">1B - Green</Option>
              <Option value="1C - Blue">1C - Blue</Option>
              <Option value="2 - Yellow">2 - Yellow</Option>
              <Option value="3 - Orange">3 - Orange</Option>
              <Option value="No">No</Option>
              <Option value={null}>Unspecified</Option>
            </Select>
          </Form.Item>
          <Form.Item name="staff_overall_rating" label="Staff Overall Rating" rules={[{ required: false, message: 'Please select staff overall rating' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Select placeholder="Select staff rating">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value={null}>Unspecified</Option>
            </Select>
          </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
              Update Staff
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Candidates Section */}
      <h3 style={{ paddingTop: 10, paddingBottom: 10 }}>Candidates:</h3>
      <Collapse>
        <Collapse.Panel header="Import and Select Candidates" key="1">
          <h4>Select Rating Range</h4>
          <Slider
            range
            defaultValue={ratingRange}
            min={1}
            max={5}
            onChange={(value) => setRatingRange(value)}
            style={{ width: 200 }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: 200,
              marginBottom: 10,
              marginLeft: 5,
              fontWeight: "bold"
            }}
          >
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
          <Button
            onClick={fetchCandidates}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginBottom: 20 }}
          >
            {loadingCandidates ? <Spin /> : 'Import Selected Rating Candidates'}
          </Button>

          {/* List of candidates with checkboxes for selection */}
          <List
            bordered
            dataSource={candidates}
            renderItem={(candidate) => (
              <List.Item>
                <Checkbox
                  checked={selectedCandidates.includes(candidate.id)}
                  onChange={() => handleCandidateSelection(candidate.id)}
                >
                  {candidate.applicant.firstName} {candidate.applicant.lastName} - Position: {candidate.job.title.label} - Rating: {candidate.rating}
                </Checkbox>
              </List.Item>
            )}
            style={{ maxHeight: 400, overflowY: 'auto' }}
          />

          {/* Confirm Candidate Selection Button */}
          <Button
            type="primary"
            onClick={handleConfirmCandidates}
            disabled={selectedCandidates.length === 0}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginTop: 20 }}
          >
            Confirm Selected Candidates as Staff
          </Button>
        </Collapse.Panel>
      </Collapse>
    </Box>
  );
};

export default StaffListView;
