import React, { useState, useContext, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, MenuItem, Menu, Avatar, Box, CircularProgress, Backdrop } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from './context/AuthContext';
import api from './authentication/axiosInstance';
import styles from './Layout.module.css';
import { IconFlare, IconUser, IconLogout, IconBell } from '@tabler/icons-react';
import { motion, useAnimate, stagger } from 'framer-motion';
import { message } from 'antd';
import moment from "moment";


const theme = createTheme({
  palette: {
    primary: {
      main: '#003d2f',
    },
    secondary: {
      main: '#f5a623',
    },
  },
});

const Layout = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandAllocate, setExpandAllocate] = useState(false);
  const [expandDatabases, setExpandDatabases] = useState(false);
  const [expandDispatch, setExpandDispatch] = useState(false);
  const [expandIntegrations, setExpandIntegrations] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
  });
  const { setIsAuthenticated, userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSyncing, setIsSyncing] = useState(false);

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/notifications/notifications/');
      console.log(response.data)
      setNotifications(response.data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      message.error('Failed to fetch notifications');
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await api.get('/users/profile/');
        setUser({
          firstName: data.first_name,
          lastName: data.last_name,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    handleMenuClose();
  };

  const handleLogoutClick = async () => {
    try {
      // Get the refresh token from localStorage
      const refreshToken = localStorage.getItem('refreshToken');
  
      // Make API call to logout and blacklist the token
      await api.post('/users/logout/', { refresh_token: refreshToken });
  
      if (!refreshToken) {
        console.error("No refresh token found");
        return;
      }

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      
      // Reset AuthContext state
      setIsAuthenticated(false);
  
      // Redirect the user to the login page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      handleMenuClose(); // Close the dropdown menu after logout
    }
  };

  const [scope, animate] = useAnimate();
  const sublinksRef = useRef([]);
  const allocateLinksRef = useRef([]);
  const dispatchLinksRef = useRef([]);
  const integrationsLinksRef = useRef([]);

  useEffect(() => {
    if (expandDatabases) {
      animate(sublinksRef.current, { opacity: 1, y: 0 }, { delay: stagger(0.1) });
    }
  }, [expandDatabases, animate]);
  useEffect(() => {
    if (expandAllocate) {
      animate(allocateLinksRef.current, { opacity: 1, y: 0 }, { delay: stagger(0.1) });
    }
  }, [expandAllocate, animate]);
  useEffect(() => {
    if (expandDispatch) {
      animate(dispatchLinksRef.current, { opacity: 1, y: 0 }, { delay: stagger(0.1) });
    }
  }, [expandDispatch, animate]);
  useEffect(() => {
    if (expandIntegrations) {
      animate(integrationsLinksRef.current, { opacity: 1, y: 0 }, { delay: stagger(0.1) });
    }
  }, [expandIntegrations, animate]);

  const handleClick = () => {
    setExpandDatabases(!expandDatabases);
    setExpandAllocate(false);
    setExpandDispatch(false);
    setExpandIntegrations(false);
  };

  const handleAllocateClick = () => {
    setExpandAllocate(!expandAllocate);
    setExpandDatabases(false);
    setExpandDispatch(false);
    setExpandIntegrations(false);
  };

  const handleDispatchClick = () => {
    setExpandDispatch(!expandDispatch);
    setExpandAllocate(false);
    setExpandDatabases(false);
    setExpandIntegrations(false);
  };

  const handleIntegrationsClick = () => {
    setExpandIntegrations(!expandIntegrations);
    setExpandAllocate(false);
    setExpandDatabases(false);
    setExpandDispatch(false);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setExpandDatabases(false);
      setExpandAllocate(false);
      setExpandDispatch(false);
      setExpandIntegrations(false);
    }
  }, [location.pathname]);

  const syncFleetioData = async (event) => {
    event.preventDefault();
    setIsSyncing(true);
    console.log("Syncing Fleetio data...");
    try {
      const response = await api.post('/fleetio_connect/', {}, { responseType: 'blob' });
      console.log("Sync response:", response);
      
      // Create a new Blob object using the response data as a Blob
      const blob = new Blob([response.data], { type: 'application/pdf' });
      
      // Create a date string for the filename
      const date = new Date().toISOString().split('T')[0];
      
      // Create a URL for the blob and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `fleetio_sync_report_${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      message.success('Data synced successfully. Download started for the sync report.');
    } catch (error) {
      console.error("Failed to sync Fleetio data:", error);
      message.error(error.response?.data?.message || "An error occurred while syncing data");
    } finally {
      setIsSyncing(false);
      console.log("Sync process completed");
    }
  };

  const [openNotifications, setOpenNotifications] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleNotificationClick = async (id) => {
    try {
      const response = await api.post("/notifications/notifications/", {
        mark_as_read: true,
        notification_id: id,
      });
    } catch (error) {
      console.error("failed to mark as read:", error);
    } finally {
      fetchNotifications();
      message.success("Notification marked as read");
      const notification_type = notifications.filter((notification) => notification.notification_id === id)[0].type;
      if (notification_type === "Dispatch") {
        navigate("/dispatchHistory");
      } else if (notification_type === "requests") {
        navigate("/requests");
      }
    }
  };
  
  

  const markAllAsRead = async () => {
    try {
      const response = await api.post("/notifications/notifications/", {
        mark_all_as_read: true,
      });
    } catch (error) {
      console.error("Failed to mark all as read:", error);
    } finally {
      fetchNotifications();
      message.success("All Notifications marked as read");
    }
  };

  const clearAllNotifications = () => {
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppBar position="static" className={styles.main} style={{ backgroundImage: location.pathname === "/" ? "linear-gradient(to right, #F1EFE8, #FCF3EA)" : "white" }}>
          <Toolbar style={{ gap: "0px" }}>
            <Box className={styles.left} onClick={() => navigate('/')}>
              <Box className={styles.icon} style={{ backgroundColor: location.pathname === "/" ? "white" : "#f5f5f5" }}>
                <IconFlare style={{ color: "#034832"}} size={18} strokeWidth={3}/>
              </Box>
              <Typography className={styles.title}>
                Facilitate
              </Typography>
            </Box>
            {location.pathname !== "/" && userRole !== 'client' && (
              <Box className={styles.links}>
                <motion.div
                  onClick={handleAllocateClick}
                  initial={{ x: 0 }}
                  animate={{ x: expandAllocate ? 0 : 0 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                  style={{ cursor: 'pointer'}}
                >
                  <Typography className={expandAllocate ? styles.activeLink : styles.link}>Allocate</Typography>
                </motion.div>
                {expandAllocate && (
                  <motion.div ref={scope} style={{ display: 'flex', gap: '15px', alignItems: 'center'}}>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (allocateLinksRef.current[0] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/allocation">
                        Loader
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (allocateLinksRef.current[1] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/walkwayCrew">
                        Walkway Crew
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (allocateLinksRef.current[2] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/SaltingRoute">
                        Salting Route
                      </Typography>
                    </motion.div>
                  </motion.div>
                )}
                {/* New Dispatch section */}
                <motion.div
                  onClick={handleDispatchClick}
                  initial={{ x: 0 }}
                  animate={{ x: expandDispatch ? 0 : 0 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                  style={{ cursor: 'pointer'}}
                >
                  <Typography className={expandDispatch ? styles.activeLink : styles.link}>Dispatch</Typography>
                </motion.div>
                {expandDispatch && (
                  <motion.div ref={scope} style={{ display: 'flex', gap: '15px', alignItems: 'center'}}>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (dispatchLinksRef.current[0] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/walkwayCrewDispatch">
                        Walkway Crews
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (dispatchLinksRef.current[1] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/SaltingRouteDispatch">
                        Salting Routes
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (dispatchLinksRef.current[2] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/loaderDispatch">
                        Loaders
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (dispatchLinksRef.current[3] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/dispatchHistory">
                        History
                      </Typography>
                    </motion.div>
                  </motion.div>
                )}
                <Typography className={styles.link} component={Link} to="/estimatepage">Estimate</Typography>
                <Typography className={styles.link} component={Link} to="/projection">Project</Typography>
                <Typography className={styles.link} component={Link} to="/requests">Requests</Typography>
                <motion.div
                  onClick={handleClick}
                  initial={{ x: 0 }}
                  animate={{ x: expandDatabases ? 0 : 0 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                  style={{ cursor: 'pointer'}}
                >
                  <Typography className={expandDatabases ? styles.activeLink : styles.link}>Databases</Typography>
                </motion.div>
                {expandDatabases && (
                  <motion.div ref={scope} style={{ display: 'flex', gap: '15px', alignItems: 'center'}}>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (sublinksRef.current[0] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/clients">
                        Clients
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10, display: 'flex', flexDirection: 'row' }}
                      ref={(el) => (sublinksRef.current[1] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/sites">
                        Sites
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10 }}
                      ref={(el) => (sublinksRef.current[2] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/vehicles">
                        Vehicles
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10 }}
                      ref={(el) => (sublinksRef.current[3] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/equipments">
                        Equipments
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10 }}
                      ref={(el) => (sublinksRef.current[4] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/staff">
                        Staff
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10 }}
                      ref={(el) => (sublinksRef.current[5] = el)}
                    >
                      <Typography className={styles.secondaryLink} component={Link} to="/subtypes">
                        Vehicle Subtypes
                      </Typography>
                    </motion.div>
                  </motion.div>
                )}
                <motion.div onClick={handleIntegrationsClick} style={{ cursor: 'pointer' }}>
                  <Typography className={expandIntegrations ? styles.activeLink : styles.link}>Integrations</Typography>
                </motion.div>
                {expandIntegrations && (
                  <motion.div ref={scope} style={{ display: 'flex', gap: '15px', alignItems: 'center'}}>
                    <motion.div
                      className="sublink"
                      style={{ opacity: 0, y: -10 }}
                      ref={(el) => (integrationsLinksRef.current[0] = el)}
                    >
                      <Typography
                        className={styles.secondaryLink}
                        onClick={syncFleetioData}
                        style={{ cursor: 'pointer' }}
                      >
                        <img src = "/Fleetio Logo.png" style={{height:30}}></img>
                      </Typography>
                    </motion.div>
                  </motion.div>
                )}
              </Box>
            )}
            {userRole === 'client' && (
              <Box className={styles.links}>
                <Typography className={styles.link} component={Link} to="/requests">Requests</Typography>
              </Box>
            )}
            
            {userRole !== 'client' && (
              <Box className={styles.notificationButton} onClick={(e) => setOpenNotifications(e.currentTarget)}>
                <IconBell size={20} />
                {notifications.filter((notification) => !notification.read).length > 0 && <Box className={styles.notificationBadge} />}
              </Box>
            )}
            <Avatar className={styles.avatar} onClick={handleMenuClick} src="https://i.pinimg.com/474x/d6/cd/f2/d6cdf2a5daaf96462127cc31fb621851.jpg">
              {user?.firstName ? user.firstName.charAt(0) : ''}
            </Avatar>
            <Menu
              anchorEl={openNotifications}
              open={Boolean(openNotifications)}
              onClose={() => setOpenNotifications(null)}
              disableAutoFocusItem
              sx={{
                "& .MuiMenu-paper": {
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  borderRadius: "3px",
                  fontFamily: "var(--font)",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginTop: "5px",
                  width: "450px",
                  marginLeft: "-18px",
                  border: "0.5px solid #E4E3E3"
                },
              }}
            >
               <Box className={styles.notificationsContainerHeader}>
                <Typography className={styles.nchTitle}>
                  Notifications
                </Typography>
                <Typography className={styles.nchTextButton} onClick={markAllAsRead}>
                  Mark all as read
                </Typography>
              </Box>

              <Box className={styles.notificationsContainer}>
                {notifications && notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <Box 
                      key={notification.notification_id} 
                      className={styles.notificationCard} 
                      onClick={() => handleNotificationClick(notification.notification_id)}
                      style={{ backgroundColor: notification.status === "Denied" ? '#fcebeb' : '#d3edd5' }}
                    >
                      <Box 
                        className={styles.notificationCardIndicator} 
                        style={{ backgroundColor: notification.read ? 'transparent' : undefined }} 
                      />
                      <Box className={styles.ncInnerCard}>
                        <Typography
                          sx={{
                            fontFamily: "var(--font)",
                            fontSize: "14px",
                            textWrap: "wrap",
                            width: "375px",
                          }}
                        >
                          <span style={{ fontWeight: '600' }}>{notification.staff_details.staff_name}</span> {notification.status === "Confirmed" ? "Confirmed" : "Denied"} <span style={{ fontWeight: '600' }}>Dispatch id: {notification.dispatch_log_details.dispatch_log_id} - </span> (Dispatch Type: <span style={{ fontWeight: '600' }}>{notification.dispatch_log_details.dispatch_type}</span> - for {new Date(new Date(notification.dispatch_log_details.dispatched_for).toLocaleString("en-US", { timeZone: "America/New_York" })).toISOString().replace("T", " ").slice(0, -5)} EST)
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "var(--font)",
                            fontSize: "10px",
                            color: "grey",
                          }}
                          >
                           <span>{moment(notification.dispatched_at).format("MMMM Do YYYY")} @ </span>
                           <span>{moment(notification.dispatched_at).format("h:mm:ss a")}</span>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography>No notifications available</Typography>
                )}
              </Box>
            </Menu>
            
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              disableAutoFocusItem
              sx={{
                "& .MuiMenu-paper": {
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  borderRadius: "3px",
                  fontFamily: "var(--font)",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginTop: "5px",
                  marginLeft: "-16px"
                },
              }}
            >
              <MenuItem onClick={handleProfileClick} className={styles.menuItem}>
                <IconUser size={18} />
                <Typography className={styles.menuText}>
                  Profile
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogoutClick} className={styles.menuItem}>
                <IconLogout size={18} />
                <Typography className={styles.menuText}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div style={{ flexGrow: 1 }}>
          <Outlet />
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSyncing}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" size={60} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Syncing Fleetio Data</Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1">Please wait while we update your information...</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            {[0, 1, 2].map((index) => (
              <motion.div
                key={index}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  margin: '0 5px',
                }}
                animate={{
                  y: [0, -20, 0],
                  opacity: [1, 0.5, 1],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: index * 0.2,
                }}
              />
            ))}
          </Box>
        </Box>
      </Backdrop>
    </ThemeProvider>
  );
};

export default Layout;