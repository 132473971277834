// src/pages/RegisterPage.js
import React, { useState } from 'react';
import { Typography, Box, Snackbar, Alert } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../authentication/axiosInstance';
import companyLogo from '../assets/company-logo.png';
import styles from "./RegisterPage.module.css";
import { motion } from 'framer-motion';
import FormTextField from '../components/FormTextField';
import image2 from '../assets/login-page.jpg';

const RegisterPage = () => {
  const [formValues, setFormValues] = useState({ firstName: '', lastName: '', phoneNumber: '', password: '', confirmPassword: '' });
  const handleChange =
    (prop) => (event) => {
      setFormValues({ ...formValues, [prop]: event.target.value });
    };
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleRegister = async (event) => {
    event.preventDefault();
    if (formValues.password !== formValues.confirmPassword) {
      setNotificationMessage('Passwords do not match');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
    try {
      const response = await api.post('/users/register/', {
        token,
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        phone_number: formValues.phoneNumber,
        password: formValues.password,
      });

      const { accessToken, refreshToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      setNotificationMessage('Registration successful! Redirecting to login page...');
      setNotificationSeverity('success');
      setNotificationOpen(true);
      
      setTimeout(() => {
        window.location.href = '/login';
      }, 1500);
    } catch (error) {
      setNotificationMessage(error.response?.data?.message || 'Failed to register');
      setNotificationSeverity('error');
    } finally {
      setNotificationOpen(true);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  return (
    <Box className={styles.main}>
      <Box className={styles.left}>
        <img src={companyLogo} alt="Company Logo" className={styles.logo} />
        <motion.div 
          initial={{ opacity: 0, translateY: 200 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
          }}
          className={styles.formContainer}
        >
          <Box className={styles.textContainer}>
            <Typography className={styles.title}>
              Get Started!
            </Typography>
            <Typography className={styles.subtitle}>
              Please sign up to continue.
            </Typography>
          </Box>
          <Box className={styles.form}>
            <Box className={styles.twoBox}>
              <FormTextField
                labelName="First Name"
                idName="firstName"
                value={formValues.firstName}
                onChange={handleChange('firstName')}
                placeholder="Enter your first name..."
              />
              <FormTextField
                labelName="Last Name"
                idName="lastName"
                value={formValues.lastName}
                onChange={handleChange('lastName')}
                placeholder="Enter your last name..."
              />
            </Box>
            <FormTextField
              labelName="Phone Number"
              idName="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange('phoneNumber')}
              placeholder="Enter your phone number..."
              type="phone"
            />
            <FormTextField
              labelName="Password"
              idName="password"
              value={formValues.password}
              onChange={handleChange('password')}
              placeholder="Enter your password..."
              type="password"
            />
            <FormTextField
              labelName="Confirm Password"
              idName="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleChange('confirmPassword')}
              placeholder="Confirm your password..."
              type="password"
            />
            <Typography className={styles.forgotPassword} onClick={() => { navigate('/login') }}>
              Already Have An Account?
            </Typography>
            <Box onClick={handleRegister} className={styles.submitButton}>
              Sign Up
            </Box>
          </Box>
        </motion.div>
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
        >
          <Alert onClose={handleNotificationClose} severity={notificationSeverity} className={styles.alert}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        <Typography className={styles.copyright}>
            &copy; {new Date().getFullYear()} Facilitate
          </Typography>
      </Box>
      <Box className={styles.right}>
        <Box className={styles.rightImage} component="img" src={image2} />
      </Box>
    </Box>
  );
};

export default RegisterPage;
