// SaltingRoutesAllocationPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, Table, Button, Spin, message, Input, Select, Modal, Form, Checkbox } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import api from '../authentication/axiosInstance';
import { Box } from '@mui/material';
import useAllocationDrop from '../hooks/useAllocationDrop';
import { saveAs } from 'file-saver';
import styles from './SaltingRoutesAllocationPage.module.css';
import { IconPlus, IconFile, IconBuildingFactory2, IconUser, IconTractor, IconLock, IconLockOpen, IconInfoCircle, IconEdit } from '@tabler/icons-react';

const { Option } = Select;
const { confirm } = Modal;

const Card = styled.div`
  background-color: #f0f0f0;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: grab;
  text-align: center;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 300px;
  max-height: 650px;
  overflow-y: scroll;
`;

const ToggleButtonGroup = styled.div`
  display: flex;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  overflow: hidden;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 5px 15px;
  background-color: ${({ isSelected }) => (isSelected ? '#e6f7ff' : '#fff')};
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#034832' : '#000')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e6f7ff;
    color: #034832;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
`;

const AllocateCell = ({ record, allocateItem }) => {
  const { isOver, drop } = useAllocationDrop(record, () => {}, () => {}, () => {}, () => {}, allocateItem);

  return (
    <div ref={drop} style={{ width: '80%', height: 140, marginTop: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', border: isOver ? "2px dashed #034832" : "2px dashed #c9c9c9", borderRadius: '5px', color: isOver ? "#034832" : "#c9c9c9", fontSize: '16px', fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
      Drag Here to Allocate
    </div>
  );
};

const SortableItem = ({ site, index, moveSite, crewId, deallocateItem }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'site',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSite(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'site',
    item: { type: 'site', id: site.site.site_id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '8px',
        marginBottom: '4px',
        backgroundColor: '#fafafa',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>
        <strong>{index + 1}. </strong>
        {site.site.client_name} - {site.site.site_name} - {site.region} - {site.area_allocated} sqft
      </span>
      <Button onClick={() => deallocateItem(crewId, site.site.site_id)} size="small">Deallocate</Button>
    </li>
  );
};

const SortableList = ({ sites, moveSite, crewId, deallocateItem }) => {
  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {sites.map((site, index) => (
        <SortableItem 
          key={site.site.site_id} 
          site={site} 
          index={index} 
          moveSite={moveSite} 
          crewId={crewId}
          deallocateItem={deallocateItem}
        />
      ))}
      {sites.length === 0 && (
        <li style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
          No sites allocated
        </li>
      )}
    </ul>
  );
};;

const generateSaltingRoutePDF = async () => {
  try {
    const response = await api.get('/saltingRoute/saltingRoutes/pdf/', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'salting_route_allocation_report.pdf');
  } catch (error) {
    console.error('Failed to generate PDF:', error);
    message.error('Failed to generate PDF report');
  }
};

const SaltingRoutesAllocationPage = () => {
  const [sites, setSites] = useState([]);
  const [staff, setStaff] = useState([]);
  const [siteRegions, setSiteRegions] = useState([]);
  const [saltingVehicles, setSaltingVehicles] = useState([]);
  const [saltingRoutes, setSaltingRoutes] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allocationMode, setAllocationMode] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [selectedList, setSelectedList] = useState('sites');
  const [selectedStaffPosition, setSelectedStaffPosition] = useState('All Salting Staff');

  const [isEditing, setIsEditing] = useState(false);
  const [editingSaltingRoute, setEditingSaltingRoute] = useState(null);
  const [newSaltingRouteName, setNewSaltingRouteName] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedSaltingRoute, setSelectedSaltingRoute] = useState(null);
  const [saltingRouteName, setSaltingRouteName] = useState('');
  const [region, setRegion] = useState('');

  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [unlockRouteId, setUnlockRouteId] = useState(null);
  const [password, setPassword] = useState("");

  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [searchTextStaff, setSearchTextStaff] = useState("");
  const [searchTextSite, setSearchTextSite] = useState("");
  const [searchTextVehicle, setSearchTextVehicle] = useState("");

  const DragCard = ({ item, allocationMode }) => {
    let itemId;
    let itemType;
  
    let isAllocated;
    if (selectedList === 'sites') {
      itemId = item.site_id;
      itemType = 'sites';
      isAllocated = item.assigned_salting_route_bool;
    } else if (selectedList === 'staff') {
      itemId = item.staff_id;
      itemType = 'staff';
      isAllocated = item.assigned_salting_route_bool || item.assigned_plow_route_bool;
    } else if (selectedList === 'vehicles') {
      itemId = item.vehicle_id;
      itemType = 'vehicle';
      isAllocated = item.assigned_salting_route_bool;
    }

    let canDrag;
    if (itemType === 'sites') { //sites can be allocated to multiple walkway crews
      canDrag = allocationMode
    }
    else {
      canDrag = allocationMode && !isAllocated;
    }
  
    const [{ isDragging }, drag] = useDrag({
      type: itemType,
      item: { id: itemId, type: itemType },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: canDrag,
    });
  
    const backgroundColor = isAllocated ? '#E5F2EC' : '#f0f0f0';
    const borderColor = (selectedList === 'sites') && item.assigned_salting_route_bool ? '#cdaf5e' : 'transparent';
    const allocationCount = item.assigned_salting_route_count || 0;

    return (
      <Card
        ref={canDrag ? drag : null}
        style={{
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: '2px',
          borderStyle: 'solid',
          cursor: canDrag ? 'grab' : 'pointer',
          
        }}
      >
        <Box style={{ width: '100%', padding: '10px'}}>
          {selectedList === 'sites' && (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ fontWeight: '550', fontSize: '16px', textAlign: 'left' }}><IconBuildingFactory2 size={14} style={{ marginRight: '3px' }}/>{item.site_name}</span>
                    <span style={{ fontSize: '15px', minWidth: '70px' }}>({allocationCount} crews)</span>
                  </div>
                  <span style={{ fontSize: '14px' }}>{item.client_name}</span>       
                </div>
          )}
          {selectedList === 'vehicles' && (
             <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
             <span style={{ fontWeight: '600', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{item.vehicle_name}</span>
             <span style={{ fontSize: '14px' }}>{item.vehicle_model}</span>       
           </div>
          )}
          {selectedList === 'staff' && (
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
              <span style={{ fontWeight: '600', fontSize: '16px' }}><IconUser size={14} style={{ marginRight: '3px' }} />{item.name}</span>
              <span style={{ fontSize: '14px', marginBottom: 4 }}>{item.position}</span>       
              <span style={{ fontSize: '14px' }}>City: {item.city ? item.city : 'Unspecified'}</span>       
              <span style={{ fontSize: '14px' }}>Rating: {item.staff_rating ? item.staff_rating : 'Unspecified'}</span>     
              <span style={{ fontSize: '14px' }}>Overall Rating: {item.staff_overall_rating ? item.staff_overall_rating : 'Unspecified'}</span>     
            </div>
          )}
        </Box>
      </Card>
    );
  };

  const SortableSiteList = ({ sites, crewId, fetchSaltingRoutes, deallocateItem }) => {
    const [siteList, setSiteList] = useState(sites);
  
    useEffect(() => {
      setSiteList(sites);
    }, [sites]);
  
    const moveSite = useCallback((dragIndex, hoverIndex) => {
      const updatedSites = [...siteList];
      const [removed] = updatedSites.splice(dragIndex, 1);
      updatedSites.splice(hoverIndex, 0, removed);
      setSiteList(updatedSites);
    }, [siteList]);
  
    const handleReorder = async () => {
      const site_order = siteList.map(site => site.site.site_id);
      try {
        await api.post(`/saltingRoute/saltingRoutes/${crewId}/reorder_sites/`, { site_order });
        message.success('Site order updated successfully');
        fetchSaltingRoutes();
        if (isDetailsModalVisible){
          handleDetailsModalCancel();
        }
      } catch (error) {
        console.error('Failed to reorder sites:', error.response?.data?.message);
        message.error(error.response?.data?.message || 'Failed to reorder sites');
      }
    };
  
    return (
      <Box style={{ width: '100%' }}>
        <DndProvider backend={HTML5Backend}>
          <SortableList 
            sites={siteList} 
            moveSite={moveSite} 
            crewId={crewId}
            deallocateItem={deallocateItem}
          />
        </DndProvider>
        <Button type="primary" disabled={sites.length === 0} onClick={handleReorder} style={{ marginTop: '10px', marginLeft: 'auto', boxShadow: 'none' }}>
          Save Order
        </Button>
      </Box>
    );
  }

  const finalizeSaltingRouteAllocation = async (RouteId) => {
    try {
      await api.post(`/saltingRoute/saltingRoutes/${RouteId}/finalize_salting_allocation/`);
      message.success("Salting Route finalized successfully");
      if (expandedRowKey) {
        const expandedRow = saltingRoutes.find(
          (route) => route.salting_route_id === expandedRowKey
        );
        if (expandedRow && expandedRow.finalized_bool) {
          setExpandedRowKey(null);
          setAllocationMode(false);
        }
      }
      fetchSaltingRoutes();
    } catch (error) {
      console.error("Failed to finalize Salting Route allocation:", error);
      message.error("Failed to finalize Salting Route allocation");
    }
  };

  const UnlockSaltingRouteAllocation = async (RouteId, password) => {
    try {
      await api.post(`/saltingRoute/saltingRoutes/${RouteId}/unlock_salting_allocation/`, {
        password,
      });
      message.success("Salting Route allocation unlocked successfully");
      fetchSaltingRoutes();
    } catch (error) {
      console.error("Failed to unlock Salting Route allocation:", error);
      message.error("Failed to unlock Salting Route allocation");
    }
  };

  useEffect(() => {
    fetchSites();
    fetchStaff();
    fetchSaltingVehicles();
    fetchSaltingRoutes();
    fetchSiteRegions();
  }, []);

  useEffect(() => {
    updateFilteredItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, sites, staff, saltingVehicles, selectedRegion, selectedStaffPosition]);

  const fetchSites = async () => {
    setLoading(true);
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
      setFilteredSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    } finally {
      setLoading(false);
    }
  };

  const fetchStaff = async () => {
    setLoading(true);
    try {
      const response = await api.get('/staff/salting_staff/');
      setStaff(response.data);
      setFilteredStaff(response.data);
    } catch (error) {
      console.error('Failed to fetch staff:', error);
      message.error('Failed to fetch staff');
    } finally {
      setLoading(false);
    }
  };

  const fetchSaltingVehicles = async () => {
    setLoading(true);
    try {
      const response = await api.get('/vehicles/salting_vehicles/');
      setSaltingVehicles(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error('Failed to fetch crew vehicles:', error);
      message.error('Failed to fetch crew vehicles');
    } finally {
      setLoading(false);
    }
  };

  const fetchSaltingRoutes = async () => {
    setLoading(true);
    try {
      const response = await api.get('/saltingRoute/saltingRoutes/');
      setSaltingRoutes(response.data);
    } catch (error) {
      console.error('Failed to fetch salting routes:', error);
      message.error('Failed to fetch salting routes');
    } finally {
      setLoading(false);
    }
  };

  const fetchSiteRegions = async () => {
    try {
      const response = await api.get('/sites/site_regions/');
      setSiteRegions(response.data);
    } catch (error) {
      console.error('Failed to fetch regions:', error);
      message.error('Failed to fetch regions');
    }
  };

  const updateFilteredItems = () => {
    if (selectedList === 'sites') {
      const filtered = selectedRegion === 'all' ? sites : sites.filter((site) => site.region === selectedRegion);
      setFilteredItems(filtered);
    } else if (selectedList === 'staff') {
      let filtered = staff;
      if (selectedStaffPosition !== 'All Salting Staff') {
        filtered = staff.filter((member) => member.position === selectedStaffPosition);
      }
      setFilteredItems(filtered);
    } else if (selectedList === 'vehicles') {
      setFilteredItems(saltingVehicles);
    } 
  };

  const allocateItem = async (crewId, itemId) => {
    try {
      await api.post(`/saltingRoute/saltingRoutes/${crewId}/allocate_item/`, { item_id: itemId, item_type: selectedList }); 
      message.success('Allocated successfully');
      fetchSaltingRoutes();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchSaltingVehicles();
    } catch (error) {
      console.error('Failed to allocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to allocate');
    }
  };

  const deallocateItem = async (crewId, itemId) => {
    try {
      await api.post(`/saltingRoute/saltingRoutes/${crewId}/deallocate_item/`, { 
        item_id: itemId,
        item_type: selectedList,
      });
      message.success('Deallocated successfully');
      fetchSaltingRoutes();
      if (selectedList === 'sites') fetchSites();
      if (selectedList === 'staff') fetchStaff();
      if (selectedList === 'vehicles') fetchSaltingVehicles();
    } catch (error) {
      console.error('Failed to deallocate:', error.response?.data?.message);
      message.error(error.response?.data?.message || 'Failed to deallocate');
    }
  };

  const handleRegionSelect = (value) => {
    setSelectedRegion(value);
    const filtered = value === 'all' ? sites : sites.filter((site) => site.region === value);
    setFilteredSites(filtered);
  };

  const handleStaffPositionSelect = (value) => {
    setSelectedStaffPosition(value);
    let filtered = staff;
    if (value !== 'All Salting Staff') {
      filtered = staff.filter((member) => member.position === value);
    }
    setFilteredStaff(filtered);
  };

  const toggleList = (list) => {
    setSelectedList(list);
    if (list === 'sites') {
      setSelectedRegion('all');
    } else if (list === 'staff') {
      setSelectedStaffPosition('All Salting Staff');
    }
  };

  const getButtonStyle = (list) => ({
    backgroundColor: selectedList === list ? '#e6f2ed' : '#fff',
    color: selectedList === list ? '#034832' : '#000',
    fontWeight: '550',
    padding: '8px 12px',
  });

  const handleEditSaltingRoute = (saltingRoute) => {
    setIsEditing(true);
    setEditingSaltingRoute(saltingRoute);
    setNewSaltingRouteName(saltingRoute.salting_route_name);
  };

  const handleSaveEditedName = async () => {
    try {
      await api.post(`/saltingRoute/saltingRoutes/${editingSaltingRoute.salting_route_id}/edit_salting_route_name/`, {
        new_name: newSaltingRouteName,
      });
      message.success('Salting Route name updated successfully');
      fetchSaltingRoutes();
      setIsEditing(false);
      setEditingSaltingRoute(null);
      setNewSaltingRouteName('');
    } catch (error) {
      console.error('Failed to update salting route name:', error);
      message.error('Failed to update salting route name');
    }
  };

  const showDeleteConfirm = (crewId) => {
    confirm({
      title: 'Are you sure you want to delete this Salting Route?',
      content: 'This action cannot be undone, and you must deallocate all items before deletion.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSaltingRoute(crewId);
      },
      onCancel() {},
    });
  };

  const deleteSaltingRoute = async (crewId) => {
    try {
      const response = await api.delete(`/saltingRoute/saltingRoutes/${crewId}/delete_salting_route/`);
      message.success(response.data.message);
      fetchSaltingRoutes();
    } catch (error) {
      console.error('Failed to delete salting route:', error.response?.data?.message || error.message);
      message.error(error.response?.data?.message || 'Failed to delete salting route');
    }
  };

  const showDetailsModal = (walkwayCrew) => {
    setSelectedSaltingRoute(walkwayCrew);
    setIsDetailsModalVisible(true);
  };

  const handleDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setSelectedSaltingRoute(null);
  };

  const SaltingRouteDetailsModal = ({ visible, onCancel, crew }) => {
    if (!crew) return null;

    return (
      <Modal
        title={<span style={{ fontSize: '24px', fontWeight: '600' }}>{crew.salting_route_name}</span>}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <p><strong>Region:</strong> {crew.salting_route_region}</p>
        <p><strong>Assigned Vehicle:</strong> {crew.assigned_vehicle_bool ? 'Yes' : 'No'}</p>
        <p><strong>Total Square Footage:</strong> {crew.total_area}</p>
        <p><strong>Number of Staff:</strong> {crew.number_of_staff}</p>
        <p><strong>Square Footage Per Staff:</strong> {crew.number_of_staff && crew.number_of_staff > 0 ? (crew.total_area / crew.number_of_staff).toFixed(2) : 'N/A'}</p>
        <p><strong>Assigned Sites:</strong></p>
        <SortableSiteList sites={crew.sites_routes} crewId={crew.salting_route_id} fetchSaltingRoutes={fetchSaltingRoutes} deallocateItem={deallocateItem} />
        <Button
          type="default"
          danger
          onClick={() => {
              showDeleteConfirm(crew.salting_route_id)
            }
          }
          style={{ marginTop: '5px' }}
        >
          Delete Route
        </Button>
      </Modal>
    );
  };

  const expandedRowRender = (record) => {
    const getAssignedLabel = () => {
      switch (selectedList) {
        case "sites":
          return "Assigned Sites";
        case "staff":
          return "Assigned Staff";
        case "vehicles":
          return "Assigned Vehicles";
        default:
          return "Assigned Items";
      }
    };

    const renderAssignedItems = () => {
      switch (selectedList) {
        case "sites":
          return (
            <SortableSiteList 
              sites={record.sites_routes} 
              crewId={record.salting_route_id}
              fetchSaltingRoutes={fetchSaltingRoutes}
              deallocateItem={deallocateItem}
            />
          );
        case "staff":
          return (
            <ul>
              {record.staff_routes.map((staff) => (
                <li key={staff.staff.staff_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {staff.staff_name} - {staff.staff_position}, Rating: {staff.staff.staff_rating ? staff.staff.staff_rating : "Unspecified"}
                  <Button
                    onClick={() => deallocateItem(record.salting_route_id, staff.staff.staff_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.staff_routes.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Staff allocated
                </div>
              )}
            </ul>
          );
        case "vehicles":
          return (
            <ul>
              {record.vehicles_routes.map((vehicle) => (
                <li key={vehicle.vehicle.vehicle_id} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {vehicle.vehicle_type} - {vehicle.vehicle_name}
                  <Button
                    onClick={() => deallocateItem(record.salting_route_id, vehicle.vehicle.vehicle_id)}
                  >
                    Deallocate
                  </Button>
                </li>
              ))}
              {record.vehicles_routes.length === 0 && (
                <div style={{ padding: '8px', marginBottom: '4px', backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRadius: '4px', marginLeft: '-40px' }}>
                  No Vehicles allocated
                </div>
              )}
            </ul>
          );
        default:
          return null;
      }
    };

    return (
      <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', marginRight: 10 }}>
          <AllocateCell
            record={record}
            allocateItem={allocateItem}
          />
        </Box>
        <Box style={{ flex: 1 }}>
          <p style={{ fontWeight: 'bold' }}>{getAssignedLabel()}:</p>
          {renderAssignedItems()}
        </Box>
      </Box>
    );
  };

  const onTableExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.salting_route_id : null);
    setAllocationMode(expanded);
  };

  const handleCreateSaltingRoute = async () => {
    try {
      await api.post('/saltingRoute/saltingRoutes/generate_salting_route/', {
        salting_route_name: saltingRouteName,
        salting_route_region: region,
      });
      message.success('Salting Route created successfully');
      fetchSaltingRoutes();
      setIsModalVisible(false);
      setSaltingRouteName('');
      setRegion('');
    } catch (error) {
      console.error('Failed to create Salting Route:', error);
      message.error('Failed to create Salting Route');
    }
  };

  const handleSearchStaff = (value) => {
    setSearchTextStaff(value); 
    const filtered = staff.filter((member) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStaff(filtered);
  };

  const handleSearchSite = (value) => {
    setSearchTextSite(value); 
    const filtered = sites.filter((site) =>
      site.site_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSites(filtered);
  };

  const handleSearchVehicle = (value) => {
    setSearchTextVehicle(value); 
    const filtered = saltingVehicles.filter((vehicle) =>
      vehicle.vehicle_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px',}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
              <span style={{fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px'}}>
                Salting Routes
              </span>
              <ToggleButtonGroup>
                <ToggleButton onClick={() => toggleList('sites')} isSelected={selectedList === 'sites'} style={getButtonStyle('sites')}>
                  Sites
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('staff')} isSelected={selectedList === 'staff'} style={getButtonStyle('staff')}>
                  Staff
                </ToggleButton>
                <ToggleButton onClick={() => toggleList('vehicles')} isSelected={selectedList === 'vehicles'} style={getButtonStyle('vehicles')}>
                  Vehicles
                </ToggleButton>
              </ToggleButtonGroup>

              {selectedList === 'sites' && (
                <div style={{width:"100%"}}>
                  <Select
                    placeholder="Select Region"
                    value={selectedRegion}
                    style={{ width: '100%' }}
                    onChange={handleRegionSelect}
                  >
                    <Option value="all">All Regions</Option>
                    {siteRegions.map((region) => (
                      <Option key={region} value={region}>
                        {region.charAt(0).toUpperCase() + region.slice(1)}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    style={{ width: '100%', marginTop:10}}
                    value={searchTextSite}
                    onChange={(e) => handleSearchSite(e.target.value)}
                    placeholder="Search site (from all regions)"
                  />
                </div>
              )}

              {selectedList === 'staff' && (
                <div style={{width:"100%"}}>
                 <Select
                   placeholder="Select Position"
                   value={selectedStaffPosition}
                   style={{ width: '100%' }}
                   onChange={handleStaffPositionSelect}
                 >
                   <Option value="All Salting Staff">All Salting Staff</Option>
                   <Option value="Salt Truck Driver">Salt Truck Driver</Option>
                 </Select>
                 <Input
                   style={{ width: '100%', marginTop:10}}
                   value={searchTextStaff}
                   onChange={(e) => handleSearchStaff(e.target.value)}
                   placeholder="Search staff (from all salting staff)"
                 />
               </div>
             )}
 
             {selectedList === 'vehicles' && (
               <div style={{width:"100%"}}>
                 <Input
                   style={{ width: '100%', marginTop:10}}
                   value={searchTextVehicle}
                   onChange={(e) => handleSearchVehicle(e.target.value)}
                   placeholder="Search vehicle"
                 />
               </div>
              )}


              <ListContainer>
                {selectedList === 'staff' && filteredStaff.map((staff) => (
                  <DragCard key={staff.staff_id} item={staff} allocationMode={allocationMode} />
                ))}
                {selectedList === 'sites' && filteredSites.map((site) => (
                  <DragCard key={site.site_id} item={site} allocationMode={allocationMode} />
                ))}
                {selectedList === 'vehicles' && filteredVehicles.map((vehicle) => (
                  <DragCard key={vehicle.vehicle_id} item={vehicle} allocationMode={allocationMode} />
                ))}
              </ListContainer>
            </div>
            <div style={{ width: 'calc(100% - 320px)', marginLeft: '20px'}}>
              <style>
                {`.locked-row {
                    background-color: #f0f0f0 !important;
                  }
                `}
              </style>
              <style>
                {`.ant-table-tbody > tr:hover > td {
                    background: none !important;
                  }
              `}
              </style>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', marginBottom: '15px' }}>
                <div className={styles.tableButton} onClick={() => setIsModalVisible(true)} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconPlus size={16}/>
                  Create Salting Route
                </div>
                <div className={styles.tableButton} onClick={generateSaltingRoutePDF} style={{ fontSize: '14px', padding: '8px 15px' }}>
                  <IconFile size={16}/>
                  Generate PDF Report
                </div>
              </div>
              <Spin spinning={loading}>
                <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                  <Table
                    dataSource={saltingRoutes}
                    columns={[
                      {
                        title: 'Route Name',
                        dataIndex: 'salting_route_name',
                        key: 'salting_route_name',
                        width: 120,
                        render: (text, record) => (
                          <Box display="flex" alignItems="center">
                            {text}
                          </Box>
                        ),
                      },
                      {
                        title: 'Region',
                        dataIndex: 'salting_route_region',
                        key: 'salting_route_region',
                      },
                      {
                        title: 'Assigned Vehicle',
                        dataIndex: 'assigned_vehicle_bool',
                        key: 'assigned_vehicle_bool',
                        width: 90,
                        render: (text, record) => (
                          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', width: '100%' }}>
                            <Checkbox
                              checked={record.assigned_vehicle_bool}
                              disabled
                            />
                          </Box>
                        ),
                      },
                      {
                        title: 'Total Square Footage',
                        dataIndex: 'total_area',
                        key: 'total_area',
                      },
                      {
                        title: 'Salt Required',
                        key: 'salt_required',
                        render: (text, record) => {
                          const totalArea = record.total_area;
                          if (totalArea === 0) {
                            return 'N/A';
                          }
                          const lowerBound = (totalArea / 1000) * 0.00175;
                          const upperBound = lowerBound * 2;
                          return `${lowerBound.toFixed(2)} - ${upperBound.toFixed(2)} tons`;
                        }
                      },
                      {
                        title: 'Number Of Staff',
                        dataIndex: 'number_of_staff',
                        key: 'number_of_staff',
                      },
                      {
                        title: 'Square Footage Per Staff',
                        key: 'square_footage_per_staff',
                        render: (text, record) => {
                          const { total_area, number_of_staff } = record;
                          if (number_of_staff && number_of_staff > 0) {
                            return (total_area / number_of_staff).toFixed(2);
                          } else {
                            return 'N/A';
                          }
                        },
                      },
                      {
                        title: "Locked",
                        key: "locked",
                        width: 100,
                        render: (_, record) => (
                          <Box>
                            {record.finalized_bool ? (
                              <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                                <IconLock size={18} stroke={2} />
                              </div>
                              
                            ) : (
                              <div style={{ backgroundColor: '#f5f5f5', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ebeaea', borderRadius: '4px', color: "#034832" }}>
                                <IconLockOpen size={18} stroke={2} />
                              </div>
                            )}
                          </Box>
                        ),
                      },
                      {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => (
                          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', gap: '5px' }}>
                            <div
                              className={styles.tableButtonGold}
                              style={{ width: '135px' }}
                              onClick={() => {
                                if (record.finalized_bool) {
                                  setUnlockRouteId(record.salting_route_id);
                                  setPasswordModalVisible(true);
                                } else {
                                  finalizeSaltingRouteAllocation(record.salting_route_id);
                                }
                              }}
                            >
                              {record.finalized_bool ? "Unlock Finalization" : "Finalize Allocation"}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'fit-content', gap: '5px' }}>
                              <div
                                onClick={() => handleEditSaltingRoute(record)}
                                className={styles.tableButton}
                              >
                                  <IconEdit size={14} /> Edit
                              </div>
                              <div
                                onClick={() => showDetailsModal(record)}
                                className={styles.tableButton}
                              >
                                <IconInfoCircle size={14} />
                                Details
                              </div>
                            </div>
                          </Box>
                        ),
                      },
                    ]}
                    rowKey="salting_route_id"
                    pagination={false}
                    rowClassName={(record) =>
                      record.finalized_bool ? 'locked-row' : ''
                    }
                    expandable={{
                      expandedRowRender,
                      expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                      onExpand: onTableExpand,
                      rowExpandable: (record) => !record.finalized_bool,
                    }}
                  />
                 </div> 
              </Spin>
            </div>
          </div>

          <Modal
            title="Create Salting Route"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleCreateSaltingRoute}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="Salting Route Name" required>
                <Input
                  value={saltingRouteName}
                  onChange={(e) => setSaltingRouteName(e.target.value)}
                  placeholder="Enter Salting Route Name"
                />
              </Form.Item>
              <Form.Item label="Region" required style={{ marginTop: '-15px' }}>
                <Input
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  placeholder="Enter Region"
                />
              </Form.Item>
            </Form>
          </Modal>

          <SaltingRouteDetailsModal
            visible={isDetailsModalVisible}
            onCancel={handleDetailsModalCancel}
            crew={selectedSaltingRoute}
          />
          <Modal
            title="Edit Salting Route Name"
            visible={isEditing}
            onCancel={() => setIsEditing(false)}
            onOk={handleSaveEditedName}
            centered
          >
            <Form layout="vertical">
              <Form.Item label="New Salting Route Name">
                <Input
                  value={newSaltingRouteName}
                  onChange={(e) => setNewSaltingRouteName(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Enter Password to Unlock"
            open={passwordModalVisible}
            onCancel={() => setPasswordModalVisible(false)}
            onOk={async () => {
              if (password) {
                await UnlockSaltingRouteAllocation(unlockRouteId, password);
                setPasswordModalVisible(false);
                setPassword("");
              } else {
                message.error("Please enter a password");
              }
            }}
            centered
          >
            <Input.Password
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Modal>
      </DndProvider>
    </ConfigProvider>
  );
};

export default SaltingRoutesAllocationPage;
