// src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, TextField, Button, Snackbar, Alert, MenuItem, Select, InputLabel, FormControl, Collapse, IconButton, Tooltip, Menu, Checkbox, ListItemText, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../authentication/axiosInstance';
import companyLogo from '../assets/company-logo.png';
import styles from './ProfilePage.module.css';
import FormTextField from '../components/FormTextField';
import { motion } from 'framer-motion';
import { IconDoorExit } from '@tabler/icons-react';

const groupMembersByRole = (members) => {
  const groups = members.reduce((acc, member) => {
    const role = member.role;
    if (!acc[role]) {
      acc[role] = [];
    }
    acc[role].push(member);
    return acc;
  }, {});

  const roleOrder = ['admin', 'standard', 'client'];
  
  return roleOrder
    .filter(role => groups[role]?.length > 0)
    .map(role => [role, groups[role]]);
};

const ProfilePage = () => {
  const [profileEmail, setProfileEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('user');
  const [dateJoined, setDateJoined] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('standard');
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [profileOpen, setProfileOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const [roleSelectOpen, setRoleSelectOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [selectedAccessibleSites, setSelectedAccessibleSites] = useState([]);
  const [availableSites, setAvailableSites] = useState([]);
  const [hasSnowEdgeAccess, setHasSnowEdgeAccess] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({
    admin: true,
    standard: true,
    client: true
  });
  const [selectedClientFilter, setSelectedClientFilter] = useState('all');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data: profile } = await api.get('/users/profile/');
        setProfileEmail(profile.email);
        console.log(profile.email)
        setFirstName(profile.first_name);
        setLastName(profile.last_name);
        setPhoneNumber(profile.phone_number);
        setRole(profile.role);
        setDateJoined(profile.date_joined);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    const fetchClients = async () => {
      try {
        const { data: clientList } = await api.get('/users/clients/');
        setClients(clientList);
        console.log('clients: ', clientList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchTeamMembers = async () => {
      try {
        const { data: users } = await api.get('/users/team/');
        const sortedUsers = users.sort((a, b) => {
          if (a.last_name < b.last_name) return -1;
          if (a.last_name > b.last_name) return 1;
          if (a.first_name < b.first_name) return -1;
          if (a.first_name > b.first_name) return 1;
          return 0;
        });
        console.log('Team members: ', sortedUsers);
        setTeamMembers(sortedUsers);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchProfile();
    fetchClients();
    fetchTeamMembers();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data: clientList } = await api.get('/clients/');
        setClients(clientList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      if (selectedClient) {
        try {
          const response = await api.get(`/clients/${selectedClient}/sites/`);
          setAvailableSites(response.data);
        } catch (error) {
          console.error('Error fetching sites:', error);
        }
      } else {
        setAvailableSites([]);
      }
    };

    fetchSites();
  }, [selectedClient]);

  const handleInvite = async (event) => {
    event.preventDefault();
    const inviteData = { 
      email: inviteEmail, 
      role: inviteRole,
      has_snowedge_access: hasSnowEdgeAccess,
    };
    console.log('Sending invite data:', inviteData);
    if (inviteRole === 'client') {
      inviteData.client = selectedClient;
      inviteData.accessible_sites = selectedAccessibleSites;
    }

    console.log("Sending invitation data:", inviteData);  // Add this line

    try {
      await api.post('/users/invite/', inviteData);
      setNotificationMessage('Invitation sent successfully');
      setNotificationSeverity('success');
    } catch (error) {
      console.error('Invitation error:', error.response?.data || error.message);
      setNotificationMessage(error.response?.data?.error || 'Failed to send invitation');
      setNotificationSeverity('error');
    } finally {
      setNotificationOpen(true);
      setInviteEmail('');
      setInviteRole('user');
      setSelectedClient('');
      setSelectedAccessibleSites([]);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleRoleChange = async (userId, newRole, userRole) => {
    if (userRole === 'admin' && role !== 'admin') {
      setNotificationMessage('Only an admin can change the role of another admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    if (profileEmail === teamMembers.find(member => member.id === userId).email) {
      setNotificationMessage('You cannot change your own role.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    const confirmed = window.confirm('Are you sure you want to change this user\'s role?');
    if (confirmed) {
      try {
        await api.patch(`/users/update-role/${userId}/`, { role: newRole });
        const updatedTeamMembers = teamMembers.map(member =>
          member.id === userId ? { ...member, role: newRole } : member
        );
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User role updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to update user role');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleDeleteUser = async (userId, userRole) => {
    if (userRole === 'admin' && role !== 'admin') {
      setNotificationMessage('Only an admin can delete another admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (profileEmail === teamMembers.find(member => member.id === userId).email) {
      setNotificationMessage('You cannot delete yourself.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await api.delete(`/users/delete-user/${userId}/`);
        const updatedTeamMembers = teamMembers.filter(member => member.id !== userId);
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User deleted successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to delete user');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to update your profile?');
    if (confirmed) {
      try {
        await api.patch('/users/profile/', { first_name: firstName, last_name: lastName, phone_number: phoneNumber });
        setNotificationMessage('Profile updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to update profile');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const [activeTab, setActiveTab] = useState('profile');

  const handleOpenMenu = (event, memberId) => {
    setRoleSelectOpen({
      anchor: event.currentTarget,
      memberId: memberId
    });
  };

  const handleCloseMenu = () => {
    setRoleSelectOpen(null);
  };

  const handleGroupToggle = (role) => {
    setExpandedGroups(prev => ({
      ...prev,
      [role]: !prev[role]
    }));
  };

  const getFilteredMembers = (members, roleGroup) => {
    if (roleGroup !== 'client' || selectedClientFilter === 'all') {
      return members;
    }
    return members.filter(member => member.client_id === selectedClientFilter);
  };

  return (
    <Box className={styles.main}>
      <motion.div 
        className={styles.navBar}
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 30,
          delay: 0.2
        }}>
        <Box className={styles.tabs}>
          <Box className={activeTab === "profile" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('profile')}>
            Profile
          </Box>
          {role === 'admin' && (
            <>
              <Box className={activeTab === "invite" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('invite')}>
                Invite User
              </Box>
              <Box className={activeTab === "team" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('team')}>
                Team
              </Box>
            </>
          )}
          {role === 'standard' && (
            <Box className={activeTab === "team" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('team')}>
              Team
            </Box>
          )}
        </Box>
      </motion.div>
      <Box className={styles.tabContent}>
        {activeTab === "profile" && (
          <motion.div 
            className={styles.tabContentInner} 
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 30,
              delay: 0.2
            }}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>
                Profile
              </Typography>
              <Typography className={styles.subtitle}>
                Manage your profile and account information.
              </Typography>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Profile Picture
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  Update your profile picture.
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <Box className={styles.pfpUploadContainer}>
                  <Box className={styles.buttonContainer}>
                    <Box className={styles.uploadButton}>
                      Upload New Picture
                    </Box>
                    <Box className={styles.removeButtonTwo}>
                      Clear
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Name
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  What should we call you?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
                <FormTextField
                  labelName=""
                  idName="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Contact Information
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  How can we get a hold of you?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="email"
                  value={profileEmail}
                  onChange={(e) => setProfileEmail(e.target.value)}
                  placeholder="Email Address"
                  disabled={true}
                />
                <FormTextField
                  labelName=""
                  idName="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                  type="phone"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Permissions
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  What can you access?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="role"
                  value={role}
                  select
                  disabled
                  options={['standard', 'admin', 'client']}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Standard"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.buttonContainer}>
              <Box className={styles.cancelButton} onClick={() => setRefresh(true)}>
                Reset
              </Box>
              <Box className={styles.submitButton} onClick={handleProfileUpdate}>
                Update Profile
              </Box>
            </Box>
          </motion.div>
        )}
        {activeTab === "invite" && (
        <motion.div 
          className={styles.tabContentInner} 
          initial={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
            delay: 0.2
          }}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Invite User
            </Typography>
            <Typography className={styles.subtitle}>
              Send an invitation to join your organization.
            </Typography>
          </Box>
          <Box className={styles.lineBreak} />
          <Box className={styles.sectionContainer}>
            <Box className={styles.sectionContainerLeft}>
              <Typography className={styles.sectionTitle}>
                Email Address
              </Typography>
              <Typography className={styles.sectionSubtitle}>
                Where should we send the invitation?
              </Typography>
            </Box>
            <Box className={styles.sectionContainerRight}>
              <TextField
                label="Email Address"
                id="inviteEmail"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                placeholder="Email Address"
                fullWidth
              />
            </Box>
          </Box>
          <Box className={styles.lineBreak} />
          <Box className={styles.sectionContainer}>
            <Box className={styles.sectionContainerLeft}>
              <Typography className={styles.sectionTitle}>
                User Role
              </Typography>
              <Typography className={styles.sectionSubtitle}>
                What permissions should this user have?
              </Typography>
            </Box>
            <Box className={styles.sectionContainerRight}>
              <FormControl fullWidth>
                <InputLabel id="inviteRoleLabel">User Role</InputLabel>
                <Select
                  labelId="inviteRoleLabel"
                  id="inviteRole"
                  value={inviteRole}
                  onChange={(e) => setInviteRole(e.target.value)}
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                >
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          {inviteRole === 'client' && (
            <>
              <Box className={styles.lineBreak} />
              <Box className={styles.sectionContainer}>
                <Box className={styles.sectionContainerLeft}>
                  <Typography className={styles.sectionTitle}>
                    Select Client
                  </Typography>
                  <Typography className={styles.sectionSubtitle}>
                    Which client is this user associated with?
                  </Typography>
                </Box>
                <Box className={styles.sectionContainerRight}>
                  <FormControl fullWidth>
                    <InputLabel id="selectClientLabel">Client</InputLabel>
                    <Select
                      labelId="selectClientLabel"
                      id="selectClient"
                      value={selectedClient}
                      onChange={(e) => setSelectedClient(e.target.value)}
                      variant="outlined"
                      style={{ marginTop: '10px' }}
                    >
                      {clients.map((client) => (
                        <MenuItem key={client.client_id} value={client.client_id}>
                          {client.client_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {selectedClient && (
                <>
                  <Box className={styles.lineBreak} />
                  <Box className={styles.sectionContainer}>
                    <Box className={styles.sectionContainerLeft}>
                      <Typography className={styles.sectionTitle}>
                        Select Accessible Sites
                      </Typography>
                      <Typography className={styles.sectionSubtitle}>
                        Assign specific sites this client user can access.
                      </Typography>
                    </Box>
                    <Box className={styles.sectionContainerRight}>
                      <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                        <InputLabel id="accessibleSitesLabel">Accessible Sites</InputLabel>
                        <Select
                          labelId="accessibleSitesLabel"
                          id="accessibleSites"
                          multiple
                          value={selectedAccessibleSites}
                          onChange={(e) => setSelectedAccessibleSites(e.target.value)}
                          renderValue={(selected) => selected.map(id => {
                            const site = availableSites.find(site => site.site_id === id);
                            return site ? site.site_name : id;
                          }).join(', ')}
                          label="Accessible Sites"
                        >
                          {availableSites.map((site) => (
                            <MenuItem key={site.site_id} value={site.site_id}>
                              <Checkbox checked={selectedAccessibleSites.indexOf(site.site_id) > -1} />
                              <ListItemText primary={site.site_name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </>
              )}
              <Box className={styles.lineBreak} />
              <Box className={styles.sectionContainer}>
                <Box className={styles.sectionContainerLeft}>
                  <Typography className={styles.sectionTitle}>
                    SnowEdge Access
                  </Typography>
                  <Typography className={styles.sectionSubtitle}>
                    Allow this client to submit SnowEdge requests?
                  </Typography>
                </Box>
                <Box className={styles.sectionContainerRight}>
                  <FormControl>
                    <Switch
                      checked={hasSnowEdgeAccess}
                      onChange={(e) => {
                        console.log('Setting hasSnowEdgeAccess to:', e.target.checked);
                        setHasSnowEdgeAccess(e.target.checked);
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </>
          )}

          <Box className={styles.lineBreak} />
          <Box className={styles.buttonContainer}>
            <Button variant="contained" color="primary" onClick={handleInvite}>
              Send Invitation
            </Button>
          </Box>
        </motion.div>
      )}
      {activeTab === "team" && (
  <motion.div 
    className={styles.tabContentInner} 
    initial={{ opacity: 0, translateY: 20 }}
    animate={{ opacity: 1, translateY: 0 }}
    transition={{
      type: "spring",
      stiffness: 150,
      damping: 30,
      delay: 0.2
    }}>
    <Box className={styles.titleContainer}>
      <Typography className={styles.title}>
        Team
      </Typography>
      <Typography className={styles.subtitle}>
        Manage your team members and their roles.
      </Typography>
    </Box>
    <Box className={styles.lineBreak} />
    
    {teamMembers.length > 0 ? (
      groupMembersByRole(teamMembers).map(([roleGroup, members]) => (
        <React.Fragment key={roleGroup}>
          {roleGroup === 'client' && (
            <Box className={styles.filterContainer}>
              <FormControl 
                variant="outlined" 
                className={styles.clientFilter}
                size="small"
              >
                <InputLabel>Filter by Client</InputLabel>
                <Select
                  value={selectedClientFilter}
                  onChange={(e) => setSelectedClientFilter(e.target.value)}
                  label="Filter by Client"
                >
                  <MenuItem value="all">All Clients</MenuItem>
                  {clients.map((client) => (
                    <MenuItem key={client.client_id} value={client.client_id}>
                      {client.client_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          
          <Box 
            className={styles.roleGroupHeader}
            onClick={() => handleGroupToggle(roleGroup)}
            sx={{ cursor: 'pointer' }}
          >
            <Box className={styles.roleGroupTitleContainer}>
              <Typography className={styles.roleGroupTitle}>
                {roleGroup.charAt(0).toUpperCase() + roleGroup.slice(1)}s
                <Typography component="span" className={styles.memberCount}>
                  ({getFilteredMembers(members, roleGroup).length})
                </Typography>
              </Typography>
              <ExpandMoreIcon 
                className={`${styles.expandIcon} ${expandedGroups[roleGroup] ? styles.expanded : ''}`}
              />
            </Box>
          </Box>
          
          <Collapse in={expandedGroups[roleGroup]}>
            <Box className={styles.sectionContainerTable}>
              <Box className={styles.tableHeader}>
                <Box className={styles.tableHeaderItem} style={{ width: '50px' }}></Box>
                <Box className={styles.tableHeaderItem}>First Name</Box>
                <Box className={styles.tableHeaderItem}>Last Name</Box>
                <Box className={styles.tableHeaderItem} style={{ width: "230px" }}>Email</Box>
                <Box className={styles.tableHeaderItem}>Role</Box>
                {roleGroup === 'client' && (
                  <Box className={styles.tableHeaderItem}>Client</Box>
                )}
                {role === 'admin' && (
                  <Box className={styles.tableHeaderItem}>Actions</Box>
                )}
              </Box>
              
              {getFilteredMembers(members, roleGroup).map(member => (
                <Box 
                  className={styles.tableRow} 
                  key={member.id}
                >
                  <Box className={styles.tableRowItem} style={{ width: "50px" }}>
                    <Box className={styles.memberAvatar} component="img" src="https://i.pinimg.com/474x/d6/cd/f2/d6cdf2a5daaf96462127cc31fb621851.jpg"/>
                  </Box>
                  <Box className={styles.tableRowItem}>
                    {member.first_name}
                  </Box>
                  <Box className={styles.tableRowItem}>
                    {member.last_name}
                  </Box>
                  <Box className={styles.tableRowItem} style={{ width: "230px" }}>
                    {member.email}
                  </Box>
                  <Box className={styles.tableRowItem}>
                    {role === 'admin' ? (
                      <>
                        <Box className={styles.roleSelect} onClick={(e) => handleOpenMenu(e, member.id)}>
                          {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                        </Box>
                        <Menu
                          anchorEl={roleSelectOpen && roleSelectOpen.anchor}
                          open={roleSelectOpen && roleSelectOpen.memberId === member.id}
                          onClose={handleCloseMenu}
                          disableAutoFocusItem
                          sx={{
                            "& .MuiMenu-paper": {
                              boxShadow:
                                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                              borderRadius: "3px",
                              fontFamily: "var(--font)",
                              fontWeight: "600",
                              fontSize: "16px",
                              marginTop: "5px",
                            },
                          }}
                        >
                          <MenuItem className={styles.menuItem} onClick={() => handleRoleChange(member.id, 'standard')}>
                            <Typography className={styles.menuText}>
                              Standard
                            </Typography>
                          </MenuItem>
                          <MenuItem className={styles.menuItem} onClick={() => handleRoleChange(member.id, 'admin')}>
                            <Typography className={styles.menuText}>
                              Admin
                            </Typography>
                          </MenuItem>
                          <MenuItem className={styles.menuItem} onClick={() => handleRoleChange(member.id, 'client')}>
                            <Typography className={styles.menuText}>
                              Client
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <Box className={styles.roleSelect}>
                        {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                      </Box>
                    )}
                  </Box>
                  {roleGroup === 'client' && (
                    <Box className={styles.tableRowItem}>
                      {clients.find(c => c.client_id === member.client_id)?.client_name || 'N/A'}
                    </Box>
                  )}
                  {role === 'admin' && (
                    <>
                      <Box className={styles.tableRowItem}>
                        <Tooltip title="Remove User">
                          <Box className={styles.removeButton}>
                            <IconDoorExit size={18} onClick={() => handleDeleteUser(member.id, member.role)}/>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Collapse>
          <Box className={styles.roleGroupDivider} />
        </React.Fragment>
      ))
    ) : (
      <Typography>No team members available.</Typography>
    )}
  </motion.div>
)}
      </Box>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfilePage; 