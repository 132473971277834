// SitesListView.js
import React, { useState, useEffect } from 'react';
import { Table, message, Input, Select} from 'antd';
import { Box} from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from "../authentication/axiosInstance";
import debounce from 'lodash/debounce';
const { Option } = Select;


const VehicleSubtypeListView = () => {
  const [subtypes, setSubtypes] = useState([]);

  const [searchSubtype, setSearchSubtype] = useState('');
  const [searchVehicleType, setSearchVehicleType] = useState(undefined);
  const [searchSurfaceType, setSearchSurfaceType] = useState(undefined);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const fetchSubtypes = async () => {
    try {
      const response = await api.get('/vehicle-subtypes/');
      setSubtypes(response.data);
      setFilteredVehicles(response.data);
    } catch (error) {
      console.error('Error fetching subtypes:', error);
      message.error('Failed to fetch subtypes');
    }
  }

  useEffect(() => {
    fetchSubtypes();
  },[])

  const columns = [
    {
      title: 'Vehicle Subtype',
      dataIndex: 'vehicle_subtype',
      key: 'vehicle_subtype',
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicle_type',
      key: 'vehicle_type',
    },
    {
      title: "Vehicle Capacity (sq. ft.)",
      dataIndex: 'vehicle_capacity',
      key: "vehicle_capacity",
      render: (vehicle_capacity) => <span>{vehicle_capacity.toLocaleString()} sq. ft.</span>
    },
    {
      title: 'Vehicle Cost ($)',
      dataIndex: 'vehicle_cost',
      key: 'vehicle_cost',
      render: (vehicle_cost) => <span>${parseFloat(vehicle_cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
    },
    {
        title: 'Surface Type',
        dataIndex: 'surface_type',
        key: 'surface_type',
    },
  ];

  const debouncedSearchSubtype = debounce((value) => {
    handleSearchSubtype(value);
  }, 300);

  const handleSearchSubtype = (value) => {
    setSearchSubtype(value);
  };

  const debouncedSearchVehicleType = debounce((value) => {
    handleSearchVehicleType(value);
  }, 300);

  const handleSearchVehicleType = (value) => {
    setSearchVehicleType(value);
  };

  const debouncedSearchSurfaceType = debounce((value) => {
    handleSearchSurfaceType(value);
  }, 300);

  const handleSearchSurfaceType = (value) => {
    setSearchSurfaceType(value);
  };

  const applyFilters = () => {
    const filtered = subtypes.filter((subtype) => {
      const filterSubtype = subtype.vehicle_subtype.toLowerCase().includes(searchSubtype.toLowerCase());
      const filterVehicleType = searchVehicleType ? subtype.vehicle_type === searchVehicleType : true;
      const filterSurfaceType = searchSurfaceType ? subtype.surface_type === searchSurfaceType : true;
      return filterSubtype && filterVehicleType && filterSurfaceType;
    });
    setFilteredVehicles(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [searchSubtype, searchVehicleType, searchSurfaceType]);

  
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:"end"}}>
      <div style={{paddingRight:30, paddingTop:30}}>
        <Input.Search
          placeholder="Search subtype"
          onSearch={setSearchSubtype}
          onChange={(e) => debouncedSearchSubtype(e.target.value)}
          style={{ width: 200, marginRight: 8 }}
          prefix={<SearchOutlined />}
        />
        <Select
          placeholder="Search vehicle type"
          value={searchVehicleType}
          onChange={(value) => setSearchVehicleType(value)}
          style={{ width: 200, marginRight: 8 }}
          allowClear
        >
          {Array.from(new Set(subtypes.map((subtype) => subtype.vehicle_type)))
            .map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
          ))}
        </Select>

        <Select
          placeholder="Search surface type"
          value={searchSurfaceType}
          onChange={(value) => setSearchSurfaceType(value)}
          style={{ width: 200, marginRight: 8 }}
          allowClear
        >
          {Array.from(new Set(subtypes.map((subtype) => subtype.surface_type)))
            .map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
          ))}
        </Select>
      </div>
      <Box style={{padding:30}}>
        <Table dataSource={filteredVehicles} columns={columns} rowKey="" pagination={false} scroll={{ y:640 }}/>
      </Box>
    </div>
  );
};

export default VehicleSubtypeListView;